/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CollectionAccountBankDetailsOpenApi } from './CollectionAccountBankDetailsOpenApi';
import {
    CollectionAccountBankDetailsOpenApiFromJSON,
    CollectionAccountBankDetailsOpenApiFromJSONTyped,
    CollectionAccountBankDetailsOpenApiToJSON,
} from './CollectionAccountBankDetailsOpenApi';
import type { CollectionAccountPurposeOpenApi } from './CollectionAccountPurposeOpenApi';
import {
    CollectionAccountPurposeOpenApiFromJSON,
    CollectionAccountPurposeOpenApiFromJSONTyped,
    CollectionAccountPurposeOpenApiToJSON,
} from './CollectionAccountPurposeOpenApi';
import type { CurrencyOpenApi } from './CurrencyOpenApi';
import {
    CurrencyOpenApiFromJSON,
    CurrencyOpenApiFromJSONTyped,
    CurrencyOpenApiToJSON,
} from './CurrencyOpenApi';

/**
 * 
 * @export
 * @interface CollectionAccountOpenApi
 */
export interface CollectionAccountOpenApi {
    /**
     * 
     * @type {string}
     * @memberof CollectionAccountOpenApi
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionAccountOpenApi
     */
    wallexId: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionAccountOpenApi
     */
    wallexAccountId: string;
    /**
     * 
     * @type {Date}
     * @memberof CollectionAccountOpenApi
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof CollectionAccountOpenApi
     */
    modified: Date;
    /**
     * 
     * @type {Date}
     * @memberof CollectionAccountOpenApi
     */
    wallexCreated: Date;
    /**
     * 
     * @type {Array<CollectionAccountBankDetailsOpenApi>}
     * @memberof CollectionAccountOpenApi
     */
    bankDetailsList: Array<CollectionAccountBankDetailsOpenApi>;
    /**
     * 
     * @type {CurrencyOpenApi}
     * @memberof CollectionAccountOpenApi
     */
    currency: CurrencyOpenApi;
    /**
     * 
     * @type {string}
     * @memberof CollectionAccountOpenApi
     */
    description?: string;
    /**
     * 
     * @type {CollectionAccountPurposeOpenApi}
     * @memberof CollectionAccountOpenApi
     */
    purpose: CollectionAccountPurposeOpenApi;
    /**
     * 
     * @type {string}
     * @memberof CollectionAccountOpenApi
     */
    reference?: string;
}

/**
 * Check if a given object implements the CollectionAccountOpenApi interface.
 */
export function instanceOfCollectionAccountOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "wallexId" in value;
    isInstance = isInstance && "wallexAccountId" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "wallexCreated" in value;
    isInstance = isInstance && "bankDetailsList" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "purpose" in value;

    return isInstance;
}

export function CollectionAccountOpenApiFromJSON(json: any): CollectionAccountOpenApi {
    return CollectionAccountOpenApiFromJSONTyped(json, false);
}

export function CollectionAccountOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectionAccountOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'wallexId': json['wallexId'],
        'wallexAccountId': json['wallexAccountId'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'wallexCreated': (new Date(json['wallexCreated'])),
        'bankDetailsList': ((json['bankDetailsList'] as Array<any>).map(CollectionAccountBankDetailsOpenApiFromJSON)),
        'currency': CurrencyOpenApiFromJSON(json['currency']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'purpose': CollectionAccountPurposeOpenApiFromJSON(json['purpose']),
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
    };
}

export function CollectionAccountOpenApiToJSON(value?: CollectionAccountOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'wallexId': value.wallexId,
        'wallexAccountId': value.wallexAccountId,
        'created': (value.created.toISOString()),
        'modified': (value.modified.toISOString()),
        'wallexCreated': (value.wallexCreated.toISOString()),
        'bankDetailsList': ((value.bankDetailsList as Array<any>).map(CollectionAccountBankDetailsOpenApiToJSON)),
        'currency': CurrencyOpenApiToJSON(value.currency),
        'description': value.description,
        'purpose': CollectionAccountPurposeOpenApiToJSON(value.purpose),
        'reference': value.reference,
    };
}

