import {UpdateFileOpenApi, UpdateOpenApi} from "../../generated"
import {CompanyOrDealType, UpdateFileType, UpdateType} from "./update.type"
import {mapFileToOpenApi, mapOpenApiToFile} from "../file/file.mapper"
import {mapCompanyToOpenApi, mapOpenApiToCompany} from "../company/company.mapper"
import {mapDealToOpenApi, mapOpenApiToDeal} from "../deal/deal.mapper"

export function mapOpenApiToUpdate(update: UpdateOpenApi): UpdateType {
    return {
        id: update.id,
        date: update.date,
        description: update.description,
        files: update.files.map(mapOpenApiToUpdateFile),
        companyOrDeal: mapOpenApiToCompanyOrDeal(update)
    }
}

function mapOpenApiToCompanyOrDeal(update: UpdateOpenApi): CompanyOrDealType {
    if (update.company != null) {
        return {
            type: "COMPANY",
            company: mapOpenApiToCompany(update.company)
        }
    }
    if (update.deal != null) {
        return {
            type: "DEAL",
            deal: mapOpenApiToDeal(update.deal)
        }
    }
    throw new Error("No company or deal attached to update.")
}

export function mapUpdateToOpenApi(update: UpdateType): UpdateOpenApi {
    return {
        id: update.id,
        date: update.date,
        description: update.description,
        files: update.files.map(mapUpdateFileToOpenApi),
        company: update.companyOrDeal.type === "COMPANY" ? mapCompanyToOpenApi(update.companyOrDeal.company) : undefined,
        deal: update.companyOrDeal.type === "DEAL" ? mapDealToOpenApi(update.companyOrDeal.deal) : undefined
    }
}

export function mapOpenApiToUpdateFile(updateFile: UpdateFileOpenApi): UpdateFileType {
    return {
        title: updateFile.title,
        sequentialId: updateFile.sequentialId,
        order: updateFile.order,
        file: mapOpenApiToFile(updateFile.file)
    }
}

export function mapUpdateFileToOpenApi(updateFile: UpdateFileType): UpdateFileOpenApi {
    return {
        title: updateFile.title,
        sequentialId: updateFile.sequentialId,
        order: updateFile.order,
        file: mapFileToOpenApi(updateFile.file)
    }
}