import {SinglePurposeVehicleType} from "./single-purpose-vehicle.type"
import {mapOpenApiToSinglePurposeVehicle, mapSinglePurposeVehicleToOpenApi} from "./single-purpose-vehicle.mapper"
import {
    SinglePurposeVehicleApiClient,
    SinglePurposeVehicleFiltersOpenApi,
    SinglePurposeVehicleOpenApi,
    SinglePurposeVehicleSortOpenApi
} from "../../generated"
import {PaginatedType, PaginationType} from "../../tech/actions/pagination/pagination.type"

export class SinglePurposeVehicleApi {

    constructor(private apiClient: SinglePurposeVehicleApiClient) {
    }

    public async create(spv: SinglePurposeVehicleType): Promise<SinglePurposeVehicleType> {
        const savedSpv = await this.apiClient.singlePurposeVehicleCreate({ singlePurposeVehicleOpenApi: mapSinglePurposeVehicleToOpenApi(spv) })
        return mapOpenApiToSinglePurposeVehicle(savedSpv)
    }

    public async delete(id: string): Promise<void> {
        await this.apiClient.singlePurposeVehicleDelete({ id })
    }

    public async getAllPaginated(
        sort: SinglePurposeVehicleSortOpenApi,
        filters?: SinglePurposeVehicleFiltersOpenApi,
        pagination?: PaginationType
    ): Promise<PaginatedType<SinglePurposeVehicleType>> {
        const result = await this.apiClient.singlePurposeVehicleGetAllPaginated({
            singlePurposeVehicleFiltersOpenApi: filters,
            elementsPerPage: pagination?.elementsPerPage,
            page: pagination?.page,
            sort
        })
        return {
            elements: result.singlePurposeVehicles
                .map((singlePurposeVehicle: SinglePurposeVehicleOpenApi) => mapOpenApiToSinglePurposeVehicle(singlePurposeVehicle)),
            total: result.total
        }
    }

    public async get(id: string): Promise<SinglePurposeVehicleType> {
        const spv = await this.apiClient.singlePurposeVehicleGet({ id })
        return mapOpenApiToSinglePurposeVehicle(spv)
    }

    public async update(id: string, spv: SinglePurposeVehicleType): Promise<SinglePurposeVehicleType> {
        const response = await this.apiClient.singlePurposeVehicleUpdate({ id, singlePurposeVehicleOpenApi: mapSinglePurposeVehicleToOpenApi(spv) })
        return mapOpenApiToSinglePurposeVehicle(response)
    }

}