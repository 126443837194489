import React, {useContext, useState} from "react"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {useNavigate} from "react-router-dom"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import {SubmitHandler} from "react-hook-form"
import {WAITLIST_ENTRY} from "../../paths"
import {WaitlistEntryType} from "./waitlist.type"
import WaitlistEntryForm from "./form.component"
import {normalizeValues} from "../../tech/form/form.util"
import BackendValidationErrorsAlert from "../../tech/validation/alert.component"
import {match} from "ts-pattern"

const WaitlistEntryNew = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const navigate = useNavigate()
    const [state, setState] = useState<"LOADING" | "NONE" | "ERROR">("NONE")
    const [errors, setErrors] = useState<string[]>([])

    const onSubmit: SubmitHandler<WaitlistEntryType> = async (waitlistEntry) => {
        setState("LOADING")
        const result = await fetchClient.waitlistApi.create(normalizeValues(waitlistEntry))
        match(result)
            .with(
                { type: "RESPONSE" },
                (res) => {
                    navigate(WAITLIST_ENTRY(res.val!.id!))
                }
            )
            .with(
                { type: "RESPONSE_ERROR" },
                () => {
                    window.scrollTo({ top: 0 })
                    setState("ERROR")
                }
            )
            .with(
                { type: "VALIDATION_ERRORS" },
                (res) => {
                    window.scrollTo({ top: 0 })
                    setState("ERROR")
                    setErrors(res.errors)
                }
            )
            .exhaustive()
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {errors.length > 0 && <BackendValidationErrorsAlert errors={errors}/>}
            {state === "ERROR" && errors.length < 1 && (
                <Alert
                    type={AlertType.ERROR}
                    text="An error occurred. Please try again or contact the tech team."
                />
            )}
            {(state === "NONE" || state === "ERROR") && (
                <Section>
                    <SectionHeading title="Create new waitlist entry"/>
                    <WaitlistEntryForm
                        onSubmit={onSubmit}
                        submitText="Create waitlist entry"
                    />
                </Section>
            )}
        </>
    )
}

export default WaitlistEntryNew