import {useParams} from "react-router-dom"
import React, {useContext, useEffect, useState} from "react"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {UserType} from "./user.type"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import UserForm from "./form.component"
import {SubmitHandler} from "react-hook-form"
import tableStyles from "../../tech/table/table.module.css"
import {normalizeValues} from "../../tech/form/form.util"

const UserEdit = () => {
    let { id } = useParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR" | "SUCCESS">("LOADING")
    const [user, setUser] = useState<UserType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                setUser(await fetchClient.userApi.get(id!))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const onSubmit: SubmitHandler<UserType> = async (user) => {
        setState("LOADING")
        try {
            setUser(await fetchClient.userApi.update(id!, normalizeValues(user)))
            window.scrollTo({ top: 0 })
            setState("SUCCESS")
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="An error occurred. Please try again or contact the tech team."
                />
            )}
            {state === "SUCCESS" && (
                <Alert
                    type={AlertType.SUCCESS}
                    text="Saved successfully."
                />
            )}
            {(state === "LOADED" || state === "ERROR" || state === "SUCCESS") && user && (
                <Section>
                    <SectionHeading title={`Edit user: ${user.email ? user.email : user.userId}`} />
                    <div className={tableStyles.tableWrapper}>
                        <table className={tableStyles.table}>
                            <tbody>
                            <tr>
                                <td><strong>Id</strong></td>
                                <td>{user.id}</td>
                            </tr>
                            <tr>
                                <td><strong>Identifier</strong></td>
                                <td>{user.userId}</td>
                            </tr>
                            <tr>
                                <td><strong>Email</strong></td>
                                <td>{user.email}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <UserForm
                        user={user}
                        onSubmit={onSubmit}
                        submitText="Save user"
                    />
                </Section>
            )}
        </>
    )
}

export default UserEdit