import {ActionApiClient, AsynchronousActionTypeOpenApi} from "../../../generated"
import {AsynchronousActionOverviewType} from "./overview/overview.type"
import {mapOpenApiToAsynchronousActionOverview} from "./overview/overview.mapper"

export class AsynchronousActionApi {

    constructor(private apiClient: ActionApiClient) {
    }

    public async getOverview(): Promise<AsynchronousActionOverviewType> {
        return mapOpenApiToAsynchronousActionOverview(await this.apiClient.actionGetAllErrors());
    }

    public async reset(
        actionId: string,
        actionType: AsynchronousActionTypeOpenApi
    ): Promise<AsynchronousActionOverviewType> {
        return mapOpenApiToAsynchronousActionOverview(await this.apiClient.actionReset({
            id: actionId,
            asynchronousActionResetRequestOpenApi: {
                type: actionType
            }
        }))
    }

}