/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ManualReconciliationBankAccountTransferOriginOpenApi = {
    EurFromOutsideSepaOrForeignCurrency: 'EUR_FROM_OUTSIDE_SEPA_OR_FOREIGN_CURRENCY',
    EurFromWithinSepa: 'EUR_FROM_WITHIN_SEPA',
    OutsideUs: 'OUTSIDE_US',
    WithinUs: 'WITHIN_US'
} as const;
export type ManualReconciliationBankAccountTransferOriginOpenApi = typeof ManualReconciliationBankAccountTransferOriginOpenApi[keyof typeof ManualReconciliationBankAccountTransferOriginOpenApi];


export function ManualReconciliationBankAccountTransferOriginOpenApiFromJSON(json: any): ManualReconciliationBankAccountTransferOriginOpenApi {
    return ManualReconciliationBankAccountTransferOriginOpenApiFromJSONTyped(json, false);
}

export function ManualReconciliationBankAccountTransferOriginOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualReconciliationBankAccountTransferOriginOpenApi {
    return json as ManualReconciliationBankAccountTransferOriginOpenApi;
}

export function ManualReconciliationBankAccountTransferOriginOpenApiToJSON(value?: ManualReconciliationBankAccountTransferOriginOpenApi | null): any {
    return value as any;
}

