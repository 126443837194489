/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UserFiltersOpenApi,
  UserOpenApi,
  UserPaginatedResponseOpenApi,
  UserSortOpenApi,
} from '../models';
import {
    UserFiltersOpenApiFromJSON,
    UserFiltersOpenApiToJSON,
    UserOpenApiFromJSON,
    UserOpenApiToJSON,
    UserPaginatedResponseOpenApiFromJSON,
    UserPaginatedResponseOpenApiToJSON,
    UserSortOpenApiFromJSON,
    UserSortOpenApiToJSON,
} from '../models';

export interface UserGetRequest {
    id: string;
}

export interface UserGetAllPaginatedRequest {
    elementsPerPage?: number;
    page?: number;
    sort?: UserSortOpenApi;
    userFiltersOpenApi?: UserFiltersOpenApi;
}

export interface UserUpdateRequest {
    id: string;
    userOpenApi?: UserOpenApi;
}

/**
 * 
 */
export class UserApiClient extends runtime.BaseAPI {

    /**
     * Get user by id
     */
    async userGetRaw(requestParameters: UserGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOpenApiFromJSON(jsonValue));
    }

    /**
     * Get user by id
     */
    async userGet(requestParameters: UserGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserOpenApi> {
        const response = await this.userGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all users (paginated)
     */
    async userGetAllPaginatedRaw(requestParameters: UserGetAllPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserPaginatedResponseOpenApi>> {
        const queryParameters: any = {};

        if (requestParameters.elementsPerPage !== undefined) {
            queryParameters['elementsPerPage'] = requestParameters.elementsPerPage;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/user/paginated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserFiltersOpenApiToJSON(requestParameters.userFiltersOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPaginatedResponseOpenApiFromJSON(jsonValue));
    }

    /**
     * Get all users (paginated)
     */
    async userGetAllPaginated(requestParameters: UserGetAllPaginatedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserPaginatedResponseOpenApi> {
        const response = await this.userGetAllPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all users without investor
     */
    async userGetAllWithoutInvestorRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserOpenApi>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/user/without-investor`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserOpenApiFromJSON));
    }

    /**
     * Get all users without investor
     */
    async userGetAllWithoutInvestor(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserOpenApi>> {
        const response = await this.userGetAllWithoutInvestorRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update user
     */
    async userUpdateRaw(requestParameters: UserUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserOpenApiToJSON(requestParameters.userOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOpenApiFromJSON(jsonValue));
    }

    /**
     * Update user
     */
    async userUpdate(requestParameters: UserUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserOpenApi> {
        const response = await this.userUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
