import React, {FunctionComponent, useContext, useEffect, useMemo, useState} from "react"
import {useNavigate, useSearchParams} from "react-router-dom"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import {INVESTMENT} from "../../paths"
import tableStyles from "../../tech/table/table.module.css"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import {InvestmentType} from "./investment.type"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {DealType} from "../deal/deal.type"
import {InvestorType, InvestorWithDetailsType} from "../investor/investor.type"
import {assembleSortOptions, getCurrentSortOption, InvestmentSort} from "./sort.util"
import {assembleFilterOptions, getCurrentFilter} from "./filter.util"
import {compareDates} from "../../tech/sort/sort.util"
import Button from "../../tech/button/button.component"
import {ButtonStyle} from "../../tech/button/style.enum"
import {exportInvestments} from "./export.util"
import InvestorKycTableCell from "../investor/kyc/kyc-table-cell.component"
import DownloadDealContracts from "../deal/document/contract/download-deal-contracts.component"
import Actions from "../../tech/actions/actions.component"
import styles from "./overview.module.sass"
import {formatDatetime} from "../../tech/format/format.util"

const InvestmentOverview = () => {
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [searchParams] = useSearchParams()
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [investments, setInvestments] = useState<InvestmentType[]>([])
    const [filteredAndSortedInvestments, setFilteredAndSortedInvestments] = useState<InvestmentType[]>([])
    const [deal, setDeal] = useState<DealType>()
    const [investorWithDetails, setInvestorWithDetails] = useState<InvestorWithDetailsType>()
    const params = Object.fromEntries(searchParams.entries())

    const sortOptions = assembleSortOptions()
    const currentSortOption = useMemo(
        () => getCurrentSortOption(sortOptions, searchParams),
        [sortOptions, searchParams]
    )

    const filters = useMemo(() => assembleFilterOptions(), [])
    const currentFilter = useMemo(
        () => getCurrentFilter(filters, searchParams),
        [filters, searchParams]
    )

    useEffect(() => {
        const fetch = async () => {
            try {
                const dealId = params.deal ? params.deal : undefined
                const investorId = params.investor ? params.investor : undefined

                const dealPromise = dealId ? fetchClient.dealApi.get(dealId) : Promise.resolve()
                const investorPromise = investorId ? fetchClient.investorApi.get(investorId) : Promise.resolve()
                const investmentsPromise = fetchClient.investmentApi.search(dealId, investorId)
                const [deal, investor, investments ] = await Promise.all([dealPromise, investorPromise, investmentsPromise])

                deal && setDeal(deal)
                investor && setInvestorWithDetails(investor)
                setInvestments(investments)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, params.deal, params.investor])

    useEffect(() => {
        setFilteredAndSortedInvestments(investments
            .filter(i => {
                if (currentFilter === undefined) return true
                switch (currentFilter.type) {
                    case "ID":
                        return i.id === (currentFilter.value as string)
                    case "PAID":
                        return currentFilter.value === "true"
                            ? i.paid
                            : !i.paid
                    default:
                        return true
                }
            })
            .sort((a, b) => {
                switch (currentSortOption.id) {
                    case InvestmentSort.CREATED_ASCENDING:
                        return compareDates(a.created, b.created, "ASCENDING")
                    case InvestmentSort.CREATED_DESCENDING:
                        return compareDates(a.created, b.created, "DESCENDING")
                    default:
                        return 1
                }
            }))
    }, [currentFilter, currentSortOption.id, investments])

    return (
        <Section>
            <SectionHeading
                title={getTitle(deal, investorWithDetails?.investor)}
                subTitle={`${filteredAndSortedInvestments.length} investments`}
                button={
                    <div className={styles.buttons}>
                        {filteredAndSortedInvestments && filteredAndSortedInvestments.length > 0 && (
                            <>
                                {deal && !investorWithDetails && (
                                    <DownloadDealContracts
                                        dealId={deal.id!}
                                        numberOfSignedInvestments={filteredAndSortedInvestments?.filter(i => i.signed).length}
                                    />
                                )}
                                <Button
                                    title="Export as CSV"
                                    style={ButtonStyle.SECONDARY}
                                    onClick={() => exportInvestments(filteredAndSortedInvestments)}
                                    type="button"
                                />
                            </>
                        )}
                    </div>
                }
            />
            <Actions
                filter={{
                    current: currentFilter,
                    filters
                }}
                sort={{
                    current: currentSortOption,
                    options: sortOptions
                }}
            >
                {state === "LOADING" && <LoadingDots/>}
                {state === "LOADED" && (
                    <div className={tableStyles.tableWrapper}>
                        <table className={tableStyles.tablePointer}>
                            <thead>
                            <tr>
                                <th>Amount</th>
                                <th>Initiated</th>
                                <th>Deal</th>
                                <th>Investor</th>
                                <th>KYC Status</th>
                                <th>State</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredAndSortedInvestments.map((investment, i) => (
                                <tr
                                    key={i}
                                    onClick={() => navigate(INVESTMENT(investment.id!))}
                                >
                                    <td>{investment.amountFormatted}</td>
                                    <td>{formatDatetime(investment.created)}</td>
                                    <td>{investment.deal.name.completeOnboarding}</td>
                                    <td>{investment.investor.person?.fullName}</td>
                                    <InvestorKycTableCell investor={investment.investor}/>
                                    <td>
                                        <small>
                                            Agreed: <State state={investment.agreed}/><br/>
                                            Signed: <State state={investment.signed}/><br/>
                                            Paid: <State state={investment.paid}/>
                                        </small>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {state === "ERROR" && (
                    <Alert
                        type={AlertType.ERROR}
                        text="Failed to load investments"
                    />
                )}
            </Actions>
        </Section>
    )
}

export default InvestmentOverview

const State: FunctionComponent<{ state: boolean }> = ({ state }) => {
    return state
        ? <strong className={styles.complete}>Yes</strong>
        : <strong className={styles.incomplete}>No</strong>
}

function getTitle(deal?: DealType, investor?: InvestorType) {
    if (deal && investor) {
        return `Investments for deal '${deal.name.completeOnboarding}' and investor '${investor.person?.fullName}'`
    }
    else if (deal) {
        return `Investments for deal '${deal.name.completeOnboarding}'`
    }
    else if (investor) {
        return `Investments for investor '${investor.person?.fullName}'`
    }
    else {
        return "Investments "
    }
}