import React, {FunctionComponent, useContext, useEffect, useState} from "react"
import {Link} from "react-router-dom"
import LoadingDots from "../../tech/loading/dots/dots.component"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {KYC_REVIEWS} from "../../paths"
import {KycLogType} from "../kyc/log/kyc-log.type"
import styles from "./dashboard.module.css"

const DashboardOpenKycReviews: FunctionComponent = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [openKycReviews, setOpenKycReviews] = useState<KycLogType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                setOpenKycReviews(await fetchClient.kycApi.getAllKycLogsToBeReviewed())
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    return (
        <div className={openKycReviews.length > 0 ? styles.boxRed : styles.box}>
            <div>
                <h3>Open KYC reviews</h3>
                {state === "LOADED" && (
                    <>
                        {openKycReviews.length > 0 ? (
                            <>
                                <strong>
                                    {openKycReviews.length} KYC document(s) need to be reviewed.
                                </strong>
                                <Link to={KYC_REVIEWS}>
                                    &rarr; Go to open KYC reviews
                                </Link>
                            </>
                        ) : (
                            <>No open KYC reviews.</>
                        )}
                    </>
                )}
                {state === "LOADING" && <LoadingDots/>}
                {state === "ERROR" && <i>Error while loading.</i>}
            </div>
        </div>
    )
}

export default DashboardOpenKycReviews