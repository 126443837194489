/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FFileOpenApi } from './FFileOpenApi';
import {
    FFileOpenApiFromJSON,
    FFileOpenApiFromJSONTyped,
    FFileOpenApiToJSON,
} from './FFileOpenApi';
import type { InvestorOpenApi } from './InvestorOpenApi';
import {
    InvestorOpenApiFromJSON,
    InvestorOpenApiFromJSONTyped,
    InvestorOpenApiToJSON,
} from './InvestorOpenApi';
import type { KycLogClassificationOpenApi } from './KycLogClassificationOpenApi';
import {
    KycLogClassificationOpenApiFromJSON,
    KycLogClassificationOpenApiFromJSONTyped,
    KycLogClassificationOpenApiToJSON,
} from './KycLogClassificationOpenApi';
import type { KycLogTypeOpenApi } from './KycLogTypeOpenApi';
import {
    KycLogTypeOpenApiFromJSON,
    KycLogTypeOpenApiFromJSONTyped,
    KycLogTypeOpenApiToJSON,
} from './KycLogTypeOpenApi';

/**
 * 
 * @export
 * @interface KycLogOpenApi
 */
export interface KycLogOpenApi {
    /**
     * 
     * @type {string}
     * @memberof KycLogOpenApi
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof KycLogOpenApi
     */
    created: Date;
    /**
     * 
     * @type {KycLogTypeOpenApi}
     * @memberof KycLogOpenApi
     */
    type: KycLogTypeOpenApi;
    /**
     * 
     * @type {KycLogClassificationOpenApi}
     * @memberof KycLogOpenApi
     */
    classification: KycLogClassificationOpenApi;
    /**
     * 
     * @type {string}
     * @memberof KycLogOpenApi
     */
    comment?: string;
    /**
     * 
     * @type {InvestorOpenApi}
     * @memberof KycLogOpenApi
     */
    investor: InvestorOpenApi;
    /**
     * 
     * @type {Array<FFileOpenApi>}
     * @memberof KycLogOpenApi
     */
    files: Array<FFileOpenApi>;
}

/**
 * Check if a given object implements the KycLogOpenApi interface.
 */
export function instanceOfKycLogOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "classification" in value;
    isInstance = isInstance && "investor" in value;
    isInstance = isInstance && "files" in value;

    return isInstance;
}

export function KycLogOpenApiFromJSON(json: any): KycLogOpenApi {
    return KycLogOpenApiFromJSONTyped(json, false);
}

export function KycLogOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): KycLogOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'type': KycLogTypeOpenApiFromJSON(json['type']),
        'classification': KycLogClassificationOpenApiFromJSON(json['classification']),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'investor': InvestorOpenApiFromJSON(json['investor']),
        'files': ((json['files'] as Array<any>).map(FFileOpenApiFromJSON)),
    };
}

export function KycLogOpenApiToJSON(value?: KycLogOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'type': KycLogTypeOpenApiToJSON(value.type),
        'classification': KycLogClassificationOpenApiToJSON(value.classification),
        'comment': value.comment,
        'investor': InvestorOpenApiToJSON(value.investor),
        'files': ((value.files as Array<any>).map(FFileOpenApiToJSON)),
    };
}

