/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SinglePurposeVehicleFiltersOpenApi,
  SinglePurposeVehicleOpenApi,
  SinglePurposeVehiclePaginatedResponseOpenApi,
  SinglePurposeVehicleSortOpenApi,
  ValidationResponseOpenApi,
} from '../models';
import {
    SinglePurposeVehicleFiltersOpenApiFromJSON,
    SinglePurposeVehicleFiltersOpenApiToJSON,
    SinglePurposeVehicleOpenApiFromJSON,
    SinglePurposeVehicleOpenApiToJSON,
    SinglePurposeVehiclePaginatedResponseOpenApiFromJSON,
    SinglePurposeVehiclePaginatedResponseOpenApiToJSON,
    SinglePurposeVehicleSortOpenApiFromJSON,
    SinglePurposeVehicleSortOpenApiToJSON,
    ValidationResponseOpenApiFromJSON,
    ValidationResponseOpenApiToJSON,
} from '../models';

export interface SinglePurposeVehicleCreateRequest {
    singlePurposeVehicleOpenApi?: SinglePurposeVehicleOpenApi;
}

export interface SinglePurposeVehicleDeleteRequest {
    id: string;
}

export interface SinglePurposeVehicleGetRequest {
    id: string;
}

export interface SinglePurposeVehicleGetAllPaginatedRequest {
    elementsPerPage?: number;
    page?: number;
    sort?: SinglePurposeVehicleSortOpenApi;
    singlePurposeVehicleFiltersOpenApi?: SinglePurposeVehicleFiltersOpenApi;
}

export interface SinglePurposeVehicleUpdateRequest {
    id: string;
    singlePurposeVehicleOpenApi?: SinglePurposeVehicleOpenApi;
}

/**
 * 
 */
export class SinglePurposeVehicleApiClient extends runtime.BaseAPI {

    /**
     * Create new single purpose vehicle (SPV)
     */
    async singlePurposeVehicleCreateRaw(requestParameters: SinglePurposeVehicleCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SinglePurposeVehicleOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/single-purpose-vehicle`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SinglePurposeVehicleOpenApiToJSON(requestParameters.singlePurposeVehicleOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SinglePurposeVehicleOpenApiFromJSON(jsonValue));
    }

    /**
     * Create new single purpose vehicle (SPV)
     */
    async singlePurposeVehicleCreate(requestParameters: SinglePurposeVehicleCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SinglePurposeVehicleOpenApi> {
        const response = await this.singlePurposeVehicleCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete single purpose vehicle (SPV) by id
     */
    async singlePurposeVehicleDeleteRaw(requestParameters: SinglePurposeVehicleDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling singlePurposeVehicleDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/single-purpose-vehicle/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete single purpose vehicle (SPV) by id
     */
    async singlePurposeVehicleDelete(requestParameters: SinglePurposeVehicleDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.singlePurposeVehicleDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get single purpose vehicle (SPV) by id
     */
    async singlePurposeVehicleGetRaw(requestParameters: SinglePurposeVehicleGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SinglePurposeVehicleOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling singlePurposeVehicleGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/single-purpose-vehicle/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SinglePurposeVehicleOpenApiFromJSON(jsonValue));
    }

    /**
     * Get single purpose vehicle (SPV) by id
     */
    async singlePurposeVehicleGet(requestParameters: SinglePurposeVehicleGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SinglePurposeVehicleOpenApi> {
        const response = await this.singlePurposeVehicleGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all single purpose vehicles (SPVs) (paginated)
     */
    async singlePurposeVehicleGetAllPaginatedRaw(requestParameters: SinglePurposeVehicleGetAllPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SinglePurposeVehiclePaginatedResponseOpenApi>> {
        const queryParameters: any = {};

        if (requestParameters.elementsPerPage !== undefined) {
            queryParameters['elementsPerPage'] = requestParameters.elementsPerPage;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/single-purpose-vehicle/paginated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SinglePurposeVehicleFiltersOpenApiToJSON(requestParameters.singlePurposeVehicleFiltersOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SinglePurposeVehiclePaginatedResponseOpenApiFromJSON(jsonValue));
    }

    /**
     * Get all single purpose vehicles (SPVs) (paginated)
     */
    async singlePurposeVehicleGetAllPaginated(requestParameters: SinglePurposeVehicleGetAllPaginatedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SinglePurposeVehiclePaginatedResponseOpenApi> {
        const response = await this.singlePurposeVehicleGetAllPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update single purpose vehicle (SPV)
     */
    async singlePurposeVehicleUpdateRaw(requestParameters: SinglePurposeVehicleUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SinglePurposeVehicleOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling singlePurposeVehicleUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/single-purpose-vehicle/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SinglePurposeVehicleOpenApiToJSON(requestParameters.singlePurposeVehicleOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SinglePurposeVehicleOpenApiFromJSON(jsonValue));
    }

    /**
     * Update single purpose vehicle (SPV)
     */
    async singlePurposeVehicleUpdate(requestParameters: SinglePurposeVehicleUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SinglePurposeVehicleOpenApi> {
        const response = await this.singlePurposeVehicleUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
