/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    AccreditedInvestorStatusToBeDeclaredOnboardingStageOpenApi,
    instanceOfAccreditedInvestorStatusToBeDeclaredOnboardingStageOpenApi,
    AccreditedInvestorStatusToBeDeclaredOnboardingStageOpenApiFromJSON,
    AccreditedInvestorStatusToBeDeclaredOnboardingStageOpenApiFromJSONTyped,
    AccreditedInvestorStatusToBeDeclaredOnboardingStageOpenApiToJSON,
} from './AccreditedInvestorStatusToBeDeclaredOnboardingStageOpenApi';
import {
    AcknowledgeSuccessOnboardingStageOpenApi,
    instanceOfAcknowledgeSuccessOnboardingStageOpenApi,
    AcknowledgeSuccessOnboardingStageOpenApiFromJSON,
    AcknowledgeSuccessOnboardingStageOpenApiFromJSONTyped,
    AcknowledgeSuccessOnboardingStageOpenApiToJSON,
} from './AcknowledgeSuccessOnboardingStageOpenApi';
import {
    GeneralDataOnboardingStageOpenApi,
    instanceOfGeneralDataOnboardingStageOpenApi,
    GeneralDataOnboardingStageOpenApiFromJSON,
    GeneralDataOnboardingStageOpenApiFromJSONTyped,
    GeneralDataOnboardingStageOpenApiToJSON,
} from './GeneralDataOnboardingStageOpenApi';
import {
    KycDocumentUploadNecessaryOnboardingStageOpenApi,
    instanceOfKycDocumentUploadNecessaryOnboardingStageOpenApi,
    KycDocumentUploadNecessaryOnboardingStageOpenApiFromJSON,
    KycDocumentUploadNecessaryOnboardingStageOpenApiFromJSONTyped,
    KycDocumentUploadNecessaryOnboardingStageOpenApiToJSON,
} from './KycDocumentUploadNecessaryOnboardingStageOpenApi';
import {
    KycWaitingForReviewOnboardingStageOpenApi,
    instanceOfKycWaitingForReviewOnboardingStageOpenApi,
    KycWaitingForReviewOnboardingStageOpenApiFromJSON,
    KycWaitingForReviewOnboardingStageOpenApiFromJSONTyped,
    KycWaitingForReviewOnboardingStageOpenApiToJSON,
} from './KycWaitingForReviewOnboardingStageOpenApi';
import {
    NoneOnboardingStageOpenApi,
    instanceOfNoneOnboardingStageOpenApi,
    NoneOnboardingStageOpenApiFromJSON,
    NoneOnboardingStageOpenApiFromJSONTyped,
    NoneOnboardingStageOpenApiToJSON,
} from './NoneOnboardingStageOpenApi';

/**
 * @type InvestorOnboardingStageOpenApi
 * 
 * @export
 */
export type InvestorOnboardingStageOpenApi = { objectType: 'AccreditedInvestorStatusToBeDeclared' } & AccreditedInvestorStatusToBeDeclaredOnboardingStageOpenApi | { objectType: 'AcknowledgeSuccess' } & AcknowledgeSuccessOnboardingStageOpenApi | { objectType: 'GeneralData' } & GeneralDataOnboardingStageOpenApi | { objectType: 'KycDocumentUploadNecessary' } & KycDocumentUploadNecessaryOnboardingStageOpenApi | { objectType: 'KycWaitingForReview' } & KycWaitingForReviewOnboardingStageOpenApi | { objectType: 'None' } & NoneOnboardingStageOpenApi;

export function InvestorOnboardingStageOpenApiFromJSON(json: any): InvestorOnboardingStageOpenApi {
    return InvestorOnboardingStageOpenApiFromJSONTyped(json, false);
}

export function InvestorOnboardingStageOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorOnboardingStageOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['objectType']) {
        case 'AccreditedInvestorStatusToBeDeclared':
            return {...AccreditedInvestorStatusToBeDeclaredOnboardingStageOpenApiFromJSONTyped(json, true), objectType: 'AccreditedInvestorStatusToBeDeclared'};
        case 'AcknowledgeSuccess':
            return {...AcknowledgeSuccessOnboardingStageOpenApiFromJSONTyped(json, true), objectType: 'AcknowledgeSuccess'};
        case 'GeneralData':
            return {...GeneralDataOnboardingStageOpenApiFromJSONTyped(json, true), objectType: 'GeneralData'};
        case 'KycDocumentUploadNecessary':
            return {...KycDocumentUploadNecessaryOnboardingStageOpenApiFromJSONTyped(json, true), objectType: 'KycDocumentUploadNecessary'};
        case 'KycWaitingForReview':
            return {...KycWaitingForReviewOnboardingStageOpenApiFromJSONTyped(json, true), objectType: 'KycWaitingForReview'};
        case 'None':
            return {...NoneOnboardingStageOpenApiFromJSONTyped(json, true), objectType: 'None'};
        default:
            throw new Error(`No variant of InvestorOnboardingStageOpenApi exists with 'objectType=${json['objectType']}'`);
    }
}

export function InvestorOnboardingStageOpenApiToJSON(value?: InvestorOnboardingStageOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['objectType']) {
        case 'AccreditedInvestorStatusToBeDeclared':
            return AccreditedInvestorStatusToBeDeclaredOnboardingStageOpenApiToJSON(value);
        case 'AcknowledgeSuccess':
            return AcknowledgeSuccessOnboardingStageOpenApiToJSON(value);
        case 'GeneralData':
            return GeneralDataOnboardingStageOpenApiToJSON(value);
        case 'KycDocumentUploadNecessary':
            return KycDocumentUploadNecessaryOnboardingStageOpenApiToJSON(value);
        case 'KycWaitingForReview':
            return KycWaitingForReviewOnboardingStageOpenApiToJSON(value);
        case 'None':
            return NoneOnboardingStageOpenApiToJSON(value);
        default:
            throw new Error(`No variant of InvestorOnboardingStageOpenApi exists with 'objectType=${value['objectType']}'`);
    }

}

