/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FaqEntryVisibilityOpenApi } from './FaqEntryVisibilityOpenApi';
import {
    FaqEntryVisibilityOpenApiFromJSON,
    FaqEntryVisibilityOpenApiFromJSONTyped,
    FaqEntryVisibilityOpenApiToJSON,
} from './FaqEntryVisibilityOpenApi';

/**
 * 
 * @export
 * @interface FaqEntryOpenApi
 */
export interface FaqEntryOpenApi {
    /**
     * 
     * @type {string}
     * @memberof FaqEntryOpenApi
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqEntryOpenApi
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof FaqEntryOpenApi
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof FaqEntryOpenApi
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof FaqEntryOpenApi
     */
    order: number;
    /**
     * 
     * @type {FaqEntryVisibilityOpenApi}
     * @memberof FaqEntryOpenApi
     */
    visibility: FaqEntryVisibilityOpenApi;
}

/**
 * Check if a given object implements the FaqEntryOpenApi interface.
 */
export function instanceOfFaqEntryOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "order" in value;
    isInstance = isInstance && "visibility" in value;

    return isInstance;
}

export function FaqEntryOpenApiFromJSON(json: any): FaqEntryOpenApi {
    return FaqEntryOpenApiFromJSONTyped(json, false);
}

export function FaqEntryOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): FaqEntryOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'category': json['category'],
        'title': json['title'],
        'text': json['text'],
        'order': json['order'],
        'visibility': FaqEntryVisibilityOpenApiFromJSON(json['visibility']),
    };
}

export function FaqEntryOpenApiToJSON(value?: FaqEntryOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'category': value.category,
        'title': value.title,
        'text': value.text,
        'order': value.order,
        'visibility': FaqEntryVisibilityOpenApiToJSON(value.visibility),
    };
}

