/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CancellationOpenApi
 */
export interface CancellationOpenApi {
    /**
     * 
     * @type {Date}
     * @memberof CancellationOpenApi
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof CancellationOpenApi
     */
    terminationDate: Date;
    /**
     * 
     * @type {string}
     * @memberof CancellationOpenApi
     */
    reason: string;
}

/**
 * Check if a given object implements the CancellationOpenApi interface.
 */
export function instanceOfCancellationOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "terminationDate" in value;
    isInstance = isInstance && "reason" in value;

    return isInstance;
}

export function CancellationOpenApiFromJSON(json: any): CancellationOpenApi {
    return CancellationOpenApiFromJSONTyped(json, false);
}

export function CancellationOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancellationOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': (new Date(json['created'])),
        'terminationDate': (new Date(json['terminationDate'])),
        'reason': json['reason'],
    };
}

export function CancellationOpenApiToJSON(value?: CancellationOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': (value.created.toISOString()),
        'terminationDate': (value.terminationDate.toISOString().substr(0,10)),
        'reason': value.reason,
    };
}

