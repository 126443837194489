/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AsynchronousActionStateOpenApi } from './AsynchronousActionStateOpenApi';
import {
    AsynchronousActionStateOpenApiFromJSON,
    AsynchronousActionStateOpenApiFromJSONTyped,
    AsynchronousActionStateOpenApiToJSON,
} from './AsynchronousActionStateOpenApi';
import type { InvestorOpenApi } from './InvestorOpenApi';
import {
    InvestorOpenApiFromJSON,
    InvestorOpenApiFromJSONTyped,
    InvestorOpenApiToJSON,
} from './InvestorOpenApi';

/**
 * 
 * @export
 * @interface StripeCustomerUpdateAsynchronousActionOpenApi
 */
export interface StripeCustomerUpdateAsynchronousActionOpenApi {
    /**
     * 
     * @type {string}
     * @memberof StripeCustomerUpdateAsynchronousActionOpenApi
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof StripeCustomerUpdateAsynchronousActionOpenApi
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof StripeCustomerUpdateAsynchronousActionOpenApi
     */
    modified: Date;
    /**
     * 
     * @type {AsynchronousActionStateOpenApi}
     * @memberof StripeCustomerUpdateAsynchronousActionOpenApi
     */
    state: AsynchronousActionStateOpenApi;
    /**
     * 
     * @type {string}
     * @memberof StripeCustomerUpdateAsynchronousActionOpenApi
     */
    message?: string;
    /**
     * 
     * @type {Date}
     * @memberof StripeCustomerUpdateAsynchronousActionOpenApi
     */
    processed?: Date;
    /**
     * 
     * @type {number}
     * @memberof StripeCustomerUpdateAsynchronousActionOpenApi
     */
    retryCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof StripeCustomerUpdateAsynchronousActionOpenApi
     */
    retryTime?: Date;
    /**
     * 
     * @type {InvestorOpenApi}
     * @memberof StripeCustomerUpdateAsynchronousActionOpenApi
     */
    investor: InvestorOpenApi;
}

/**
 * Check if a given object implements the StripeCustomerUpdateAsynchronousActionOpenApi interface.
 */
export function instanceOfStripeCustomerUpdateAsynchronousActionOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "investor" in value;

    return isInstance;
}

export function StripeCustomerUpdateAsynchronousActionOpenApiFromJSON(json: any): StripeCustomerUpdateAsynchronousActionOpenApi {
    return StripeCustomerUpdateAsynchronousActionOpenApiFromJSONTyped(json, false);
}

export function StripeCustomerUpdateAsynchronousActionOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeCustomerUpdateAsynchronousActionOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'state': AsynchronousActionStateOpenApiFromJSON(json['state']),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'processed': !exists(json, 'processed') ? undefined : (new Date(json['processed'])),
        'retryCount': !exists(json, 'retryCount') ? undefined : json['retryCount'],
        'retryTime': !exists(json, 'retryTime') ? undefined : (new Date(json['retryTime'])),
        'investor': InvestorOpenApiFromJSON(json['investor']),
    };
}

export function StripeCustomerUpdateAsynchronousActionOpenApiToJSON(value?: StripeCustomerUpdateAsynchronousActionOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'modified': (value.modified.toISOString()),
        'state': AsynchronousActionStateOpenApiToJSON(value.state),
        'message': value.message,
        'processed': value.processed === undefined ? undefined : (value.processed.toISOString()),
        'retryCount': value.retryCount,
        'retryTime': value.retryTime === undefined ? undefined : (value.retryTime.toISOString()),
        'investor': InvestorOpenApiToJSON(value.investor),
    };
}

