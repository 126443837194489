/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CountryOpenApi } from './CountryOpenApi';
import {
    CountryOpenApiFromJSON,
    CountryOpenApiFromJSONTyped,
    CountryOpenApiToJSON,
} from './CountryOpenApi';

/**
 * 
 * @export
 * @interface AddressOpenApi
 */
export interface AddressOpenApi {
    /**
     * 
     * @type {string}
     * @memberof AddressOpenApi
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof AddressOpenApi
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AddressOpenApi
     */
    zipOrPostalCode: string;
    /**
     * 
     * @type {CountryOpenApi}
     * @memberof AddressOpenApi
     */
    country: CountryOpenApi;
}

/**
 * Check if a given object implements the AddressOpenApi interface.
 */
export function instanceOfAddressOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "line1" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "zipOrPostalCode" in value;
    isInstance = isInstance && "country" in value;

    return isInstance;
}

export function AddressOpenApiFromJSON(json: any): AddressOpenApi {
    return AddressOpenApiFromJSONTyped(json, false);
}

export function AddressOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'line1': json['line1'],
        'city': json['city'],
        'zipOrPostalCode': json['zipOrPostalCode'],
        'country': CountryOpenApiFromJSON(json['country']),
    };
}

export function AddressOpenApiToJSON(value?: AddressOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'line1': value.line1,
        'city': value.city,
        'zipOrPostalCode': value.zipOrPostalCode,
        'country': CountryOpenApiToJSON(value.country),
    };
}

