/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FFileOpenApi } from './FFileOpenApi';
import {
    FFileOpenApiFromJSON,
    FFileOpenApiFromJSONTyped,
    FFileOpenApiToJSON,
} from './FFileOpenApi';

/**
 * 
 * @export
 * @interface InvestorKycDocumentsOpenApi
 */
export interface InvestorKycDocumentsOpenApi {
    /**
     * 
     * @type {Array<FFileOpenApi>}
     * @memberof InvestorKycDocumentsOpenApi
     */
    passportFiles?: Array<FFileOpenApi>;
    /**
     * 
     * @type {Array<FFileOpenApi>}
     * @memberof InvestorKycDocumentsOpenApi
     */
    proofOfAddressFiles?: Array<FFileOpenApi>;
    /**
     * 
     * @type {Array<FFileOpenApi>}
     * @memberof InvestorKycDocumentsOpenApi
     */
    proofOfAiStatusFiles?: Array<FFileOpenApi>;
}

/**
 * Check if a given object implements the InvestorKycDocumentsOpenApi interface.
 */
export function instanceOfInvestorKycDocumentsOpenApi(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InvestorKycDocumentsOpenApiFromJSON(json: any): InvestorKycDocumentsOpenApi {
    return InvestorKycDocumentsOpenApiFromJSONTyped(json, false);
}

export function InvestorKycDocumentsOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorKycDocumentsOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'passportFiles': !exists(json, 'passportFiles') ? undefined : ((json['passportFiles'] as Array<any>).map(FFileOpenApiFromJSON)),
        'proofOfAddressFiles': !exists(json, 'proofOfAddressFiles') ? undefined : ((json['proofOfAddressFiles'] as Array<any>).map(FFileOpenApiFromJSON)),
        'proofOfAiStatusFiles': !exists(json, 'proofOfAiStatusFiles') ? undefined : ((json['proofOfAiStatusFiles'] as Array<any>).map(FFileOpenApiFromJSON)),
    };
}

export function InvestorKycDocumentsOpenApiToJSON(value?: InvestorKycDocumentsOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'passportFiles': value.passportFiles === undefined ? undefined : ((value.passportFiles as Array<any>).map(FFileOpenApiToJSON)),
        'proofOfAddressFiles': value.proofOfAddressFiles === undefined ? undefined : ((value.proofOfAddressFiles as Array<any>).map(FFileOpenApiToJSON)),
        'proofOfAiStatusFiles': value.proofOfAiStatusFiles === undefined ? undefined : ((value.proofOfAiStatusFiles as Array<any>).map(FFileOpenApiToJSON)),
    };
}

