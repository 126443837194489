/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CurrencyOpenApi } from './CurrencyOpenApi';
import {
    CurrencyOpenApiFromJSON,
    CurrencyOpenApiFromJSONTyped,
    CurrencyOpenApiToJSON,
} from './CurrencyOpenApi';

/**
 * 
 * @export
 * @interface InvestmentSelectCurrencyRequestOpenApi
 */
export interface InvestmentSelectCurrencyRequestOpenApi {
    /**
     * 
     * @type {CurrencyOpenApi}
     * @memberof InvestmentSelectCurrencyRequestOpenApi
     */
    currency: CurrencyOpenApi;
    /**
     * Missing exchange preview id indicates that the deal currency should has been selected
     * @type {string}
     * @memberof InvestmentSelectCurrencyRequestOpenApi
     */
    exchangePreviewId?: string;
}

/**
 * Check if a given object implements the InvestmentSelectCurrencyRequestOpenApi interface.
 */
export function instanceOfInvestmentSelectCurrencyRequestOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currency" in value;

    return isInstance;
}

export function InvestmentSelectCurrencyRequestOpenApiFromJSON(json: any): InvestmentSelectCurrencyRequestOpenApi {
    return InvestmentSelectCurrencyRequestOpenApiFromJSONTyped(json, false);
}

export function InvestmentSelectCurrencyRequestOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestmentSelectCurrencyRequestOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': CurrencyOpenApiFromJSON(json['currency']),
        'exchangePreviewId': !exists(json, 'exchangePreviewId') ? undefined : json['exchangePreviewId'],
    };
}

export function InvestmentSelectCurrencyRequestOpenApiToJSON(value?: InvestmentSelectCurrencyRequestOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': CurrencyOpenApiToJSON(value.currency),
        'exchangePreviewId': value.exchangePreviewId,
    };
}

