/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MimeTypeOpenApi } from './MimeTypeOpenApi';
import {
    MimeTypeOpenApiFromJSON,
    MimeTypeOpenApiFromJSONTyped,
    MimeTypeOpenApiToJSON,
} from './MimeTypeOpenApi';

/**
 * 
 * @export
 * @interface FileFiltersOpenApi
 */
export interface FileFiltersOpenApi {
    /**
     * 
     * @type {string}
     * @memberof FileFiltersOpenApi
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FileFiltersOpenApi
     */
    identifier?: string;
    /**
     * 
     * @type {Array<MimeTypeOpenApi>}
     * @memberof FileFiltersOpenApi
     */
    mimeTypes?: Array<MimeTypeOpenApi>;
}

/**
 * Check if a given object implements the FileFiltersOpenApi interface.
 */
export function instanceOfFileFiltersOpenApi(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FileFiltersOpenApiFromJSON(json: any): FileFiltersOpenApi {
    return FileFiltersOpenApiFromJSONTyped(json, false);
}

export function FileFiltersOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileFiltersOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'mimeTypes': !exists(json, 'mimeTypes') ? undefined : ((json['mimeTypes'] as Array<any>).map(MimeTypeOpenApiFromJSON)),
    };
}

export function FileFiltersOpenApiToJSON(value?: FileFiltersOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'identifier': value.identifier,
        'mimeTypes': value.mimeTypes === undefined ? undefined : ((value.mimeTypes as Array<any>).map(MimeTypeOpenApiToJSON)),
    };
}

