/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DealOnboardingDependentOpenApi } from './DealOnboardingDependentOpenApi';
import {
    DealOnboardingDependentOpenApiFromJSON,
    DealOnboardingDependentOpenApiFromJSONTyped,
    DealOnboardingDependentOpenApiToJSON,
} from './DealOnboardingDependentOpenApi';
import type { InvestmentWithPaymentDetailsOpenApi } from './InvestmentWithPaymentDetailsOpenApi';
import {
    InvestmentWithPaymentDetailsOpenApiFromJSON,
    InvestmentWithPaymentDetailsOpenApiFromJSONTyped,
    InvestmentWithPaymentDetailsOpenApiToJSON,
} from './InvestmentWithPaymentDetailsOpenApi';

/**
 * 
 * @export
 * @interface DealOnboardingDependentWithInvestmentOpenApi
 */
export interface DealOnboardingDependentWithInvestmentOpenApi {
    /**
     * 
     * @type {DealOnboardingDependentOpenApi}
     * @memberof DealOnboardingDependentWithInvestmentOpenApi
     */
    deal: DealOnboardingDependentOpenApi;
    /**
     * 
     * @type {InvestmentWithPaymentDetailsOpenApi}
     * @memberof DealOnboardingDependentWithInvestmentOpenApi
     */
    investmentWithPaymentDetails?: InvestmentWithPaymentDetailsOpenApi;
}

/**
 * Check if a given object implements the DealOnboardingDependentWithInvestmentOpenApi interface.
 */
export function instanceOfDealOnboardingDependentWithInvestmentOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deal" in value;

    return isInstance;
}

export function DealOnboardingDependentWithInvestmentOpenApiFromJSON(json: any): DealOnboardingDependentWithInvestmentOpenApi {
    return DealOnboardingDependentWithInvestmentOpenApiFromJSONTyped(json, false);
}

export function DealOnboardingDependentWithInvestmentOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealOnboardingDependentWithInvestmentOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deal': DealOnboardingDependentOpenApiFromJSON(json['deal']),
        'investmentWithPaymentDetails': !exists(json, 'investmentWithPaymentDetails') ? undefined : InvestmentWithPaymentDetailsOpenApiFromJSON(json['investmentWithPaymentDetails']),
    };
}

export function DealOnboardingDependentWithInvestmentOpenApiToJSON(value?: DealOnboardingDependentWithInvestmentOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deal': DealOnboardingDependentOpenApiToJSON(value.deal),
        'investmentWithPaymentDetails': InvestmentWithPaymentDetailsOpenApiToJSON(value.investmentWithPaymentDetails),
    };
}

