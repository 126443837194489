/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CurrencyOpenApi } from './CurrencyOpenApi';
import {
    CurrencyOpenApiFromJSON,
    CurrencyOpenApiFromJSONTyped,
    CurrencyOpenApiToJSON,
} from './CurrencyOpenApi';
import type { ManualReconciliationBankAccountTransferOriginOpenApi } from './ManualReconciliationBankAccountTransferOriginOpenApi';
import {
    ManualReconciliationBankAccountTransferOriginOpenApiFromJSON,
    ManualReconciliationBankAccountTransferOriginOpenApiFromJSONTyped,
    ManualReconciliationBankAccountTransferOriginOpenApiToJSON,
} from './ManualReconciliationBankAccountTransferOriginOpenApi';
import type { ManualReconciliationBankOpenApi } from './ManualReconciliationBankOpenApi';
import {
    ManualReconciliationBankOpenApiFromJSON,
    ManualReconciliationBankOpenApiFromJSONTyped,
    ManualReconciliationBankOpenApiToJSON,
} from './ManualReconciliationBankOpenApi';
import type { SinglePurposeVehicleOpenApi } from './SinglePurposeVehicleOpenApi';
import {
    SinglePurposeVehicleOpenApiFromJSON,
    SinglePurposeVehicleOpenApiFromJSONTyped,
    SinglePurposeVehicleOpenApiToJSON,
} from './SinglePurposeVehicleOpenApi';

/**
 * 
 * @export
 * @interface ManualReconciliationBankAccountOpenApi
 */
export interface ManualReconciliationBankAccountOpenApi {
    /**
     * 
     * @type {string}
     * @memberof ManualReconciliationBankAccountOpenApi
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ManualReconciliationBankAccountOpenApi
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ManualReconciliationBankAccountOpenApi
     */
    modified?: Date;
    /**
     * 
     * @type {ManualReconciliationBankAccountTransferOriginOpenApi}
     * @memberof ManualReconciliationBankAccountOpenApi
     */
    transferOrigin: ManualReconciliationBankAccountTransferOriginOpenApi;
    /**
     * 
     * @type {ManualReconciliationBankOpenApi}
     * @memberof ManualReconciliationBankAccountOpenApi
     */
    bank: ManualReconciliationBankOpenApi;
    /**
     * 
     * @type {CurrencyOpenApi}
     * @memberof ManualReconciliationBankAccountOpenApi
     */
    currency: CurrencyOpenApi;
    /**
     * 
     * @type {SinglePurposeVehicleOpenApi}
     * @memberof ManualReconciliationBankAccountOpenApi
     */
    holderSinglePurposeVehicle: SinglePurposeVehicleOpenApi;
    /**
     * 
     * @type {string}
     * @memberof ManualReconciliationBankAccountOpenApi
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof ManualReconciliationBankAccountOpenApi
     */
    swift?: string;
    /**
     * 
     * @type {string}
     * @memberof ManualReconciliationBankAccountOpenApi
     */
    achRoutingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ManualReconciliationBankAccountOpenApi
     */
    aba?: string;
}

/**
 * Check if a given object implements the ManualReconciliationBankAccountOpenApi interface.
 */
export function instanceOfManualReconciliationBankAccountOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "transferOrigin" in value;
    isInstance = isInstance && "bank" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "holderSinglePurposeVehicle" in value;
    isInstance = isInstance && "number" in value;

    return isInstance;
}

export function ManualReconciliationBankAccountOpenApiFromJSON(json: any): ManualReconciliationBankAccountOpenApi {
    return ManualReconciliationBankAccountOpenApiFromJSONTyped(json, false);
}

export function ManualReconciliationBankAccountOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualReconciliationBankAccountOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'transferOrigin': ManualReconciliationBankAccountTransferOriginOpenApiFromJSON(json['transferOrigin']),
        'bank': ManualReconciliationBankOpenApiFromJSON(json['bank']),
        'currency': CurrencyOpenApiFromJSON(json['currency']),
        'holderSinglePurposeVehicle': SinglePurposeVehicleOpenApiFromJSON(json['holderSinglePurposeVehicle']),
        'number': json['number'],
        'swift': !exists(json, 'swift') ? undefined : json['swift'],
        'achRoutingNumber': !exists(json, 'achRoutingNumber') ? undefined : json['achRoutingNumber'],
        'aba': !exists(json, 'aba') ? undefined : json['aba'],
    };
}

export function ManualReconciliationBankAccountOpenApiToJSON(value?: ManualReconciliationBankAccountOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'transferOrigin': ManualReconciliationBankAccountTransferOriginOpenApiToJSON(value.transferOrigin),
        'bank': ManualReconciliationBankOpenApiToJSON(value.bank),
        'currency': CurrencyOpenApiToJSON(value.currency),
        'holderSinglePurposeVehicle': SinglePurposeVehicleOpenApiToJSON(value.holderSinglePurposeVehicle),
        'number': value.number,
        'swift': value.swift,
        'achRoutingNumber': value.achRoutingNumber,
        'aba': value.aba,
    };
}

