import {InvestmentApiClient} from "../../generated"
import {InvestmentType} from "./investment.type"
import {mapOpenApiToInvestment} from "./investment.mapper"

export class InvestmentApi {

    constructor(private apiClient: InvestmentApiClient) {
    }

    public async get(id: string): Promise<InvestmentType> {
        const investment = await this.apiClient.investmentGet({ id })
        return mapOpenApiToInvestment(investment)
    }

    public async getPendingInvestments(): Promise<InvestmentType[]> {
        return (await this.apiClient.investmentGetAllPendingBackoffice())
            .map(mapOpenApiToInvestment)
    }

    public async search(
        dealId?: string,
        investorId?: string
    ): Promise<InvestmentType[]> {
        return (await this.apiClient.investmentSearch({
                deal: dealId,
                investor: investorId
            }))
            .map(investment => mapOpenApiToInvestment(investment))
    }

    public async searchForManualReconciliation(dealOrInvestorName: string) {
        return (await this.apiClient.investmentSearchForManualReconciliation({ dealOrInvestorName }))
            .map(investment => mapOpenApiToInvestment(investment))
    }

}