import {InvestorGroupApiClient, InvestorGroupOpenApi} from "../../generated"
import {InvestorGroupType} from "./investor-group.type"
import {mapInvestorGroupToOpenApi, mapOpenApiToInvestorGroup} from "./investor-group.mapper"

export class InvestorGroupApi {

    constructor(private apiClient: InvestorGroupApiClient) {
    }

    public async create(investorGroup: InvestorGroupType): Promise<InvestorGroupType> {
        const savedInvestorGroup = await this.apiClient.investorGroupCreate({ investorGroupOpenApi: mapInvestorGroupToOpenApi(investorGroup) })
        return mapOpenApiToInvestorGroup(savedInvestorGroup)
    }

    public async delete(id: string): Promise<void> {
        await this.apiClient.investorGroupDelete({ id })
    }

    public async getAll(): Promise<InvestorGroupType[]> {
        return (await this.apiClient.investorGroupGetAll())
            .map((investorGroup: InvestorGroupOpenApi) => mapOpenApiToInvestorGroup(investorGroup))
    }

    public async get(id: string): Promise<InvestorGroupType> {
        const investorGroup = await this.apiClient.investorGroupGet({ id })
        return mapOpenApiToInvestorGroup(investorGroup)
    }

    public async update(id: string, investorGroup: InvestorGroupType): Promise<InvestorGroupType> {
        const response = await this.apiClient.investorGroupUpdate({ id, investorGroupOpenApi: mapInvestorGroupToOpenApi(investorGroup) })
        return mapOpenApiToInvestorGroup(response)
    }

}