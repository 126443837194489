/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CompanySortOpenApi = {
    CreatedAscending: 'CREATED_ASCENDING',
    CreatedDescending: 'CREATED_DESCENDING',
    ModifiedAscending: 'MODIFIED_ASCENDING',
    ModifiedDescending: 'MODIFIED_DESCENDING',
    LegalNameAscending: 'LEGAL_NAME_ASCENDING',
    LegalNameDescending: 'LEGAL_NAME_DESCENDING'
} as const;
export type CompanySortOpenApi = typeof CompanySortOpenApi[keyof typeof CompanySortOpenApi];


export function CompanySortOpenApiFromJSON(json: any): CompanySortOpenApi {
    return CompanySortOpenApiFromJSONTyped(json, false);
}

export function CompanySortOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySortOpenApi {
    return json as CompanySortOpenApi;
}

export function CompanySortOpenApiToJSON(value?: CompanySortOpenApi | null): any {
    return value as any;
}

