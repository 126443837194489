import {FFileOpenApi, FileApiClient, FileFiltersOpenApi, FileSortOpenApi, FileTypeOpenApi} from "../../generated"
import {FileDownloadLinkType, FileType, FileUpdateType} from "./file.type"
import {mapFileUpdateToOpenApi, mapOpenApiToFile, mapOpenApiToFileDownloadLink} from "./file.mapper"
import {PaginatedType, PaginationType} from "../../tech/actions/pagination/pagination.type"

export class FileApi {

    constructor(private apiClient: FileApiClient) {
    }

    public async delete(id: string): Promise<void> {
        await this.apiClient.fileDelete({ id })
    }

    public async getAllPaginated(
        sort: FileSortOpenApi,
        filters?: FileFiltersOpenApi,
        pagination?: PaginationType
    ): Promise<PaginatedType<FileType>> {
        const result = await this.apiClient.fileGetAllPaginated({
            fileFiltersOpenApi: filters,
            elementsPerPage: pagination?.elementsPerPage,
            page: pagination?.page,
            sort
        })
        return {
            elements: result.files.map((file: FFileOpenApi) => mapOpenApiToFile(file)),
            total: result.total
        }
    }

    public async get(id: string): Promise<FileType> {
        const file = await this.apiClient.fileGet({ id })
        return mapOpenApiToFile(file)
    }

    public async getDownloadLink(id: string): Promise<FileDownloadLinkType> {
        const fileDownloadLinkOpenApi = await this.apiClient.fileGetDownloadLink({ id })
        return mapOpenApiToFileDownloadLink(fileDownloadLinkOpenApi)
    }

    public async update(id: string, update: FileUpdateType): Promise<FileType> {
        const response = await this.apiClient.fileUpdate({ id, fileUpdateOpenApi: mapFileUpdateToOpenApi(update) })
        return mapOpenApiToFile(response)
    }

    public async upload(
        file: File,
        fileType: FileTypeOpenApi,
        identifier?: string
    ): Promise<FileType> {
        const savedFile = await this.apiClient.fileUploadAsBackofficeUser({
            file,
            fileType,
            identifier
        })
        return mapOpenApiToFile(savedFile)
    }

}

