/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const KycLogClassificationOpenApi = {
    DocuSignIdentityVerification: 'DOCU_SIGN_IDENTITY_VERIFICATION',
    UserUpload: 'USER_UPLOAD'
} as const;
export type KycLogClassificationOpenApi = typeof KycLogClassificationOpenApi[keyof typeof KycLogClassificationOpenApi];


export function KycLogClassificationOpenApiFromJSON(json: any): KycLogClassificationOpenApi {
    return KycLogClassificationOpenApiFromJSONTyped(json, false);
}

export function KycLogClassificationOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): KycLogClassificationOpenApi {
    return json as KycLogClassificationOpenApi;
}

export function KycLogClassificationOpenApiToJSON(value?: KycLogClassificationOpenApi | null): any {
    return value as any;
}

