import React, {FunctionComponent} from "react"
import {Link} from "react-router-dom"
import {ArrowRightCircleIcon} from "@heroicons/react/24/outline"
import styles from "./link.module.css"

type ActionLinkProps = {
    text: string
    link: string
    blank?: boolean
}

const ActionLink: FunctionComponent<ActionLinkProps> = ({ text, link, blank = false }) => {
    const inner = (
        <>
            <div><ArrowRightCircleIcon className="w-4 h-4 mt-0.5 mr-1"/></div>
            {text}
        </>
    )

    return blank ? (
            <a
                href={link}
                target="_blank"
                rel="noreferrer"
                className={styles.actionLink}
            >
                {inner}
            </a>
        ) : (
            <Link
                to={link}
                className={styles.actionLink}
            >
                {inner}
            </Link>
        )
}

export default ActionLink