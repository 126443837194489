import {SubmitHandler, useForm} from "react-hook-form"
import React, {FunctionComponent, useEffect} from "react"
import {KycLogReviewType} from "../kyc-log.type"
import formStyles from "../../../../tech/form/form.module.css"
import FormError from "../../../../tech/form/error.component"
import {KycLogReviewResultOpenApi, KycLogTypeOpenApi} from "../../../../generated"

type KycReviewFormProps = {
    kycLogType: KycLogTypeOpenApi
    onSubmit: SubmitHandler<KycLogReviewType>
    submitText: string
}

const KycReviewForm: FunctionComponent<KycReviewFormProps> = ({ kycLogType, onSubmit, submitText }) => {
    const {
        register,
        unregister,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm<KycLogReviewType>()
    const result = watch("result")

    useEffect(() => {
        if (result === KycLogReviewResultOpenApi.Confirmed) {
            register("validTill");
        } else {
            unregister("validTill");
        }
        if (result === KycLogReviewResultOpenApi.Rejected) {
            register("comment");
        } else {
            unregister("comment");
        }
    }, [register, unregister, result])

    return (
        <form
            className={formStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <label>
                <span>Result</span>
                <select {...register("result")}>
                    <option value="CONFIRMED">CONFIRM</option>
                    <option value="REJECTED">REJECT</option>
                </select>
            </label>

            {result === KycLogReviewResultOpenApi.Rejected && (
                <label>
                    <span>Comment</span>
                    <textarea
                        placeholder="Comment"
                        {...register("comment")}
                    ></textarea>
                    <FormError field="comment" errors={errors}/>
                </label>
                )}

            {result === KycLogReviewResultOpenApi.Confirmed && kycLogType !== "PROOF_OF_AI_STATUS_UPLOADED" && (
                <label>
                <span>Document valid till</span>
                    {kycLogType === "PASSPORT_UPLOADED" && (
                        <div className="text-sm text-rose-400 my-1.5">
                            For passport/ID: optional, but required if document has an expiry date
                        </div>
                    )}
                    {kycLogType === "PROOF_OF_ADDRESS_UPLOADED" && (
                        <div className="text-sm text-rose-400 my-1.5">
                            For proof of address: date of document + 6 months
                        </div>
                    )}
                    <input
                        type="date"
                        {...register("validTill", {
                            setValueAs: value => transformToDate(value),
                            validate: (value, formValues) => {
                                if (
                                    !isValidDate(value)
                                    && formValues.result === "CONFIRMED"
                                    && kycLogType !== "PASSPORT_UPLOADED"
                                ) return "Required when confirmed"
                                if (isValidDate(value) && formValues.result === "REJECTED") return "Must be empty when rejected"
                                if (isValidDate(value) && (value as Date) < new Date()) return "Must be in the future"
                                return true
                            }
                        })}
                    />
                    <FormError field="validTill" errors={errors} />
                </label>
            )}

            <button
                type="submit"
                className={formStyles.submitButton}
            >
                {submitText}
            </button>
        </form>
    )
}

export default KycReviewForm

function isValidDate(d: any): boolean {
    // @ts-ignore
    return d instanceof Date && !isNaN(d)
}

function transformToDate(d: any): Date | undefined {
    const date = new Date(d)
    return isValidDate(date)
        ? date
        : undefined
}