/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyOpenApi } from './CompanyOpenApi';
import {
    CompanyOpenApiFromJSON,
    CompanyOpenApiFromJSONTyped,
    CompanyOpenApiToJSON,
} from './CompanyOpenApi';

/**
 * 
 * @export
 * @interface CompanyPaginatedResponseOpenApi
 */
export interface CompanyPaginatedResponseOpenApi {
    /**
     * 
     * @type {Array<CompanyOpenApi>}
     * @memberof CompanyPaginatedResponseOpenApi
     */
    companies: Array<CompanyOpenApi>;
    /**
     * 
     * @type {number}
     * @memberof CompanyPaginatedResponseOpenApi
     */
    total: number;
}

/**
 * Check if a given object implements the CompanyPaginatedResponseOpenApi interface.
 */
export function instanceOfCompanyPaginatedResponseOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companies" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function CompanyPaginatedResponseOpenApiFromJSON(json: any): CompanyPaginatedResponseOpenApi {
    return CompanyPaginatedResponseOpenApiFromJSONTyped(json, false);
}

export function CompanyPaginatedResponseOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyPaginatedResponseOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companies': ((json['companies'] as Array<any>).map(CompanyOpenApiFromJSON)),
        'total': json['total'],
    };
}

export function CompanyPaginatedResponseOpenApiToJSON(value?: CompanyPaginatedResponseOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companies': ((value.companies as Array<any>).map(CompanyOpenApiToJSON)),
        'total': value.total,
    };
}

