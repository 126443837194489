import {AsynchronousActionStateOpenApi, AsynchronousActionTypeOpenApi} from "../../../../../../generated"
import {AsynchronousActionType} from "../../../action.type"
import {INVESTMENT} from "../../../../../../paths"

export class WallexCollectionAccountCreationAsynchronousActionType implements AsynchronousActionType {
    public id: string
    public created: Date
    public modified: Date
    public state: AsynchronousActionStateOpenApi
    public message?: string
    public processed?: Date
    public investmentId: string

    constructor({
        id,
        created,
        modified,
        state,
        message,
        processed,
        investmentId
    } : {
        id: string,
        created: Date,
        modified: Date,
        state: AsynchronousActionStateOpenApi,
        message?: string,
        processed?: Date,
        investmentId: string
    }) {
        this.id = id
        this.created = created
        this.modified = modified
        this.state = state
        this.message = message
        this.processed = processed
        this.investmentId = investmentId
    }

    get actionType(): AsynchronousActionTypeOpenApi {
        return AsynchronousActionTypeOpenApi.WallexCollectionAccountCreation
    }

    get details(): { title: string, content: string }[] {
        return [{
            title: "Investment ID",
            content: this.investmentId
        }]
    }

    getLink(): { title: string, link: string } {
        return {
            title: "Go to Investment",
            link: INVESTMENT(this.investmentId)
        }
    }

}