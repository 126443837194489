import React, {FunctionComponent, useContext, useState} from "react"
import {DealType} from "../../deal/deal.type"
import {InvestorType} from "../../investor/investor.type"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import FormError from "../../../tech/form/error.component"
import {SubmitHandler, useForm} from "react-hook-form"
import {SyndicateInvitationConfirmAddingExistingInvestorFormType} from "./confirm-existing-investor.type"
import {SyndicateType} from "../syndicate.type"
import formStyles from "../../../tech/form/form.module.css"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import Button from "../../../tech/button/button.component"
import {useNavigate} from "react-router-dom"
import {SYNDICATE_MEMBERS_INVITATIONS} from "../../../paths"
import {ButtonStyle} from "../../../tech/button/style.enum"

type SyndicateInvitationNewConfirmAddingExistingInvestorProps = {
    deals: DealType[]
    investor: InvestorType
    syndicate: SyndicateType
}

const SyndicateInvitationNewConfirmAddingExistingInvestor: FunctionComponent<SyndicateInvitationNewConfirmAddingExistingInvestorProps> = ({
    deals,
    investor,
    syndicate
}) => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const navigate = useNavigate()
    const [state, setState] = useState<"NONE" | "LOADING" | "ERROR" | "SUCCESS">("NONE")

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SyndicateInvitationConfirmAddingExistingInvestorFormType>()

    const onSubmit: SubmitHandler<SyndicateInvitationConfirmAddingExistingInvestorFormType> = async (
        formData: SyndicateInvitationConfirmAddingExistingInvestorFormType
    ) => {
        setState("LOADING")
        try {
            await fetchClient.syndicateApi.confirmAddingExistingInvestor(
                investor.id!,
                syndicate.id!,
                formData.targetInvestorGroup.id!,
                formData.selectedDeal?.id === "NONE" ? undefined : formData.selectedDeal?.id
            )
            setState("SUCCESS")
        }
        catch (err) {
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {(state === "ERROR" || state === "NONE") && (
                <form
                    className={formStyles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    {state === "ERROR" && (
                        <Alert
                            type={AlertType.ERROR}
                            text="Failed to add investor to syndicate."
                        />
                    )}
                    <Alert
                        type={AlertType.INFO}
                        text={(
                            <>
                                <strong>The given email address belongs to an already existing
                                    investor.</strong> Instead of sending an invitation you can add the investor
                                directly to the syndicate.<br/><br/>

                                Do you want to
                                add <strong>{investor.person?.fullName} ({investor.person?.contact.email})</strong> to
                                the syndicate?
                            </>
                        )}
                    />
                    <label>
                        <span>Target Investor Group</span>
                        <select
                            {...register("targetInvestorGroup.id", {
                                required: "Target Investor Group is required."
                            })}
                        >
                            {syndicate.investorGroups.map((investorGroup, index) => (
                                <option
                                    value={investorGroup.id}
                                    key={`investor-group-${index + 1}`}
                                >
                                    {investorGroup.name} ({investorGroup.id})
                                </option>
                            ))}
                        </select>
                        <FormError field="targetInvestorGroup.id" errors={errors}/>
                    </label>
                    <label>
                        <span>Deal to promote in notification</span>
                        <select
                            {...register("selectedDeal.id")}
                        >
                            <option value="NONE">NONE</option>
                            {deals.map((deal, index) => (
                                <option
                                    value={deal.id}
                                    key={`deal-${index + 1}`}
                                >
                                    {deal.name.completeOnboarding} ({deal.id})
                                </option>
                            ))}
                        </select>
                        <FormError field="selectedDeal.id" errors={errors}/>
                    </label>
                    <button
                        type="submit"
                        className={formStyles.submitButton}
                    >
                        Confirm
                    </button>
                </form>
            )}
            {state === "SUCCESS" && (
                <>
                    <Alert
                        type={AlertType.SUCCESS}
                        text={(
                            <>Successfully added investor <strong>{investor.person?.fullName}</strong> to syndicate.</>
                        )}
                    />
                    <Button
                        title="Go to Members & Invitations overview"
                        style={ButtonStyle.PRIMARY}
                        type="button"
                        onClick={() => navigate(SYNDICATE_MEMBERS_INVITATIONS(syndicate.id!))}
                    />
                </>
            )}
        </>
    )
}

export default SyndicateInvitationNewConfirmAddingExistingInvestor