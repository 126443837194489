import React, {FunctionComponent} from "react"
import styles from "./bar.module.sass"

const LoadingBar: FunctionComponent = () => {
    return (
        <div className={styles.loadingBar}>
            <div/>
        </div>
    )
}

export default LoadingBar