import {ActionsFilterType, ActionsFilterTypeAndValueType} from "../../../tech/actions/filter/filter.type"
import {FileFiltersOpenApi, MimeTypeOpenApi} from "../../../generated"
import {getFilter, getFilterValue} from "../../../tech/actions/filter/filter.util"

export type FileFilterType = "ID" | "IDENTIFIER" | "MIME_TYPE"

export function assembleFilterOptions(): ActionsFilterType<FileFilterType>[] {
    return [
        {
            type: "ID",
            label: "ID",
            value: "STRING"
        },
        {
            type: "IDENTIFIER",
            label: "Identifier",
            value: "STRING"
        },
        {
            type: "MIME_TYPE",
            label: "MIME-Type",
            value: {
                type: "ENUM",
                values: Object.entries(MimeTypeOpenApi).map(m => ({
                    label: m[1],
                    value: m[1]
                }))
            }
        }
    ]
}

export function getCurrentFilter(
    filters: ActionsFilterType<FileFilterType>[],
    searchParams: URLSearchParams
): ActionsFilterTypeAndValueType<FileFilterType> | undefined {
    const foundFilter = filters.find(f => f.type === getFilter(searchParams))
    const foundFilterValue = getFilterValue(searchParams)
    if (foundFilter && foundFilterValue) {
        return {
            type: foundFilter.type,
            value: foundFilterValue
        }
    }
    return undefined
}

export function mapToFileFiltersOpenApi(filter: ActionsFilterTypeAndValueType<FileFilterType> | undefined): FileFiltersOpenApi | undefined {
    if (filter === undefined) return undefined

    switch (filter.type) {
        case "ID":
            return { id: filter.value }
        case "IDENTIFIER":
            return { identifier: filter.value }
        case "MIME_TYPE":
            return { mimeTypes: [ filter.value ] }
    }
}