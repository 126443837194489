import {Environment} from "./environment.enum"

export function getEnvironment(): Environment {
    const environment = process.env.REACT_APP_VERCEL_ENV
    switch (environment) {
        case "production":
            return Environment.PRODUCTION
        case "develop":
            return Environment.DEVELOP
        case "preview":
            return Environment.PREVIEW
        default:
            return Environment.LOCAL
    }
}