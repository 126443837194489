/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvestorOpenApi } from './InvestorOpenApi';
import {
    InvestorOpenApiFromJSON,
    InvestorOpenApiFromJSONTyped,
    InvestorOpenApiToJSON,
} from './InvestorOpenApi';

/**
 * 
 * @export
 * @interface DealQuestionOpenApi
 */
export interface DealQuestionOpenApi {
    /**
     * 
     * @type {string}
     * @memberof DealQuestionOpenApi
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof DealQuestionOpenApi
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof DealQuestionOpenApi
     */
    modified: Date;
    /**
     * 
     * @type {string}
     * @memberof DealQuestionOpenApi
     */
    question: string;
    /**
     * 
     * @type {boolean}
     * @memberof DealQuestionOpenApi
     */
    resolved: boolean;
    /**
     * 
     * @type {string}
     * @memberof DealQuestionOpenApi
     */
    dealId: string;
    /**
     * 
     * @type {InvestorOpenApi}
     * @memberof DealQuestionOpenApi
     */
    investor: InvestorOpenApi;
}

/**
 * Check if a given object implements the DealQuestionOpenApi interface.
 */
export function instanceOfDealQuestionOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "question" in value;
    isInstance = isInstance && "resolved" in value;
    isInstance = isInstance && "dealId" in value;
    isInstance = isInstance && "investor" in value;

    return isInstance;
}

export function DealQuestionOpenApiFromJSON(json: any): DealQuestionOpenApi {
    return DealQuestionOpenApiFromJSONTyped(json, false);
}

export function DealQuestionOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealQuestionOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'question': json['question'],
        'resolved': json['resolved'],
        'dealId': json['dealId'],
        'investor': InvestorOpenApiFromJSON(json['investor']),
    };
}

export function DealQuestionOpenApiToJSON(value?: DealQuestionOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'modified': (value.modified.toISOString()),
        'question': value.question,
        'resolved': value.resolved,
        'dealId': value.dealId,
        'investor': InvestorOpenApiToJSON(value.investor),
    };
}

