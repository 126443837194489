import {ActionsSortOptionType} from "../../../tech/actions/sort/sort.type"
import {getSort} from "../../../tech/actions/sort/sort.util"
import {CompanyContactSortOpenApi} from "../../../generated"

export const DEFAULT_SORT = {
    id: CompanyContactSortOpenApi.ModifiedDescending,
    label: "Modified (DESC)"
}

export function assembleSortOptions(): ActionsSortOptionType<CompanyContactSortOpenApi>[] {
    return [
        {
            id: CompanyContactSortOpenApi.CreatedAscending,
            label: "Created (ASC)"
        },
        {
            id: CompanyContactSortOpenApi.CreatedDescending,
            label: "Created (DESC)"
        },
        {
            id: CompanyContactSortOpenApi.ModifiedAscending,
            label: "Modified (ASC)"
        },
        DEFAULT_SORT,
        {
            id: CompanyContactSortOpenApi.NameAscending,
            label: "Name (ASC)"
        },
        {
            id: CompanyContactSortOpenApi.NameDescending,
            label: "Name (DESC)"
        },
    ]
}

export function getCurrentSortOption(
    options: ActionsSortOptionType<CompanyContactSortOpenApi>[],
    searchParams: URLSearchParams
): ActionsSortOptionType<CompanyContactSortOpenApi> {
    return (options.find(o => o.id === getSort(searchParams)) || DEFAULT_SORT)
}