/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyOpenApi } from './CompanyOpenApi';
import {
    CompanyOpenApiFromJSON,
    CompanyOpenApiFromJSONTyped,
    CompanyOpenApiToJSON,
} from './CompanyOpenApi';
import type { DealOpenApi } from './DealOpenApi';
import {
    DealOpenApiFromJSON,
    DealOpenApiFromJSONTyped,
    DealOpenApiToJSON,
} from './DealOpenApi';
import type { UpdateFileOpenApi } from './UpdateFileOpenApi';
import {
    UpdateFileOpenApiFromJSON,
    UpdateFileOpenApiFromJSONTyped,
    UpdateFileOpenApiToJSON,
} from './UpdateFileOpenApi';

/**
 * 
 * @export
 * @interface UpdateOpenApi
 */
export interface UpdateOpenApi {
    /**
     * 
     * @type {string}
     * @memberof UpdateOpenApi
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateOpenApi
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateOpenApi
     */
    description: string;
    /**
     * 
     * @type {Array<UpdateFileOpenApi>}
     * @memberof UpdateOpenApi
     */
    files: Array<UpdateFileOpenApi>;
    /**
     * 
     * @type {CompanyOpenApi}
     * @memberof UpdateOpenApi
     */
    company?: CompanyOpenApi;
    /**
     * 
     * @type {DealOpenApi}
     * @memberof UpdateOpenApi
     */
    deal?: DealOpenApi;
}

/**
 * Check if a given object implements the UpdateOpenApi interface.
 */
export function instanceOfUpdateOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "files" in value;

    return isInstance;
}

export function UpdateOpenApiFromJSON(json: any): UpdateOpenApi {
    return UpdateOpenApiFromJSONTyped(json, false);
}

export function UpdateOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': (new Date(json['date'])),
        'description': json['description'],
        'files': ((json['files'] as Array<any>).map(UpdateFileOpenApiFromJSON)),
        'company': !exists(json, 'company') ? undefined : CompanyOpenApiFromJSON(json['company']),
        'deal': !exists(json, 'deal') ? undefined : DealOpenApiFromJSON(json['deal']),
    };
}

export function UpdateOpenApiToJSON(value?: UpdateOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': (value.date.toISOString().substr(0,10)),
        'description': value.description,
        'files': ((value.files as Array<any>).map(UpdateFileOpenApiToJSON)),
        'company': CompanyOpenApiToJSON(value.company),
        'deal': DealOpenApiToJSON(value.deal),
    };
}

