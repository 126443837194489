import React, {useContext, useEffect, useState} from "react"
import {SubmitHandler} from "react-hook-form"
import {useNavigate} from "react-router-dom"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import CompanyForm from "./form.component"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {COMPANY} from "../../paths"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import {normalizeValues} from "../../tech/form/form.util"
import {CompanyType} from "./company.type"
import {CompanyContactType} from "./contact/contact.type"
import {DealType} from "../deal/deal.type"

const CompanyNew = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const navigate = useNavigate()
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [contacts, setContacts] = useState<CompanyContactType[]>([])
    const [deals, setDeals] = useState<DealType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const [
                    fetchedContacts,
                    fetchedDeals,
                ] = await Promise.all([
                    fetchClient.companyContactApi.getAll(),
                    fetchClient.dealApi.getAll(),
                ])
                setContacts(fetchedContacts)
                setDeals(fetchedDeals)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    const onSubmit: SubmitHandler<CompanyType> = async (company) => {
        setState("LOADING")
        try {
            const savedCompany = await fetchClient.companyApi.create(normalizeValues(company))
            navigate(COMPANY(savedCompany.id!))
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to save company."
                />
            )}
            {(state === "LOADED" || state === "ERROR") && (
                <Section>
                    <SectionHeading title="Create new company"/>
                    <CompanyForm
                        contacts={contacts}
                        deals={deals}
                        onSubmit={onSubmit} submitText="Create company"
                    />
                </Section>
            )}
        </>
    )
}

export default CompanyNew