import {ActionsFilterType, ActionsFilterTypeAndValueType} from "../../tech/actions/filter/filter.type"
import {getFilter, getFilterValue} from "../../tech/actions/filter/filter.util"
import {SinglePurposeVehicleFiltersOpenApi} from "../../generated"

export type SinglePurposeVehicleFilterType = "ID" | "NAME"

export function assembleFilterOptions(): ActionsFilterType<SinglePurposeVehicleFilterType>[] {
    return [
        {
            type: "ID",
            label: "ID",
            value: "STRING"
        },
        {
            type: "NAME",
            label: "Name",
            value: "STRING"
        },
    ]
}

export function getCurrentFilter(
    filters: ActionsFilterType<SinglePurposeVehicleFilterType>[],
    searchParams: URLSearchParams
): ActionsFilterTypeAndValueType<SinglePurposeVehicleFilterType> | undefined {
    const foundFilter = filters.find(f => f.type === getFilter(searchParams))
    const foundFilterValue = getFilterValue(searchParams)
    if (foundFilter && foundFilterValue) {
        return {
            type: foundFilter.type,
            value: foundFilterValue
        }
    }
    return undefined
}

export function mapToSinglePurposeVehiclesFiltersOpenApi(filter: ActionsFilterTypeAndValueType<SinglePurposeVehicleFilterType> | undefined): SinglePurposeVehicleFiltersOpenApi | undefined {
    if (filter === undefined) return undefined

    switch (filter.type) {
        case "ID":
            return { id: filter.value }
        case "NAME":
            return { name: filter.value }
    }
}