/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MimeTypeOpenApi = {
    Jpg: 'JPG',
    Pdf: 'PDF',
    Png: 'PNG',
    Svg: 'SVG'
} as const;
export type MimeTypeOpenApi = typeof MimeTypeOpenApi[keyof typeof MimeTypeOpenApi];


export function MimeTypeOpenApiFromJSON(json: any): MimeTypeOpenApi {
    return MimeTypeOpenApiFromJSONTyped(json, false);
}

export function MimeTypeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): MimeTypeOpenApi {
    return json as MimeTypeOpenApi;
}

export function MimeTypeOpenApiToJSON(value?: MimeTypeOpenApi | null): any {
    return value as any;
}

