import React, {FunctionComponent} from "react"
import Select from "react-select"
import {Controller, SubmitHandler, useForm} from "react-hook-form"
import formStyles from "../../tech/form/form.module.css"
import FormError from "../../tech/form/error.component"
import {CompanyType} from "./company.type"
import {CompanyContactType} from "./contact/contact.type"
import {DealType} from "../deal/deal.type"
import {formatDatetime} from "../../tech/format/format.util"

type CompanyFormProps = {
    company?: CompanyType
    contacts: CompanyContactType[]
    deals: DealType[]
    onSubmit: SubmitHandler<CompanyType>
    submitText: string
}

const CompanyForm: FunctionComponent<CompanyFormProps> = ({
    company,
    contacts,
    deals,
    onSubmit,
    submitText
}) => {
    const {
        control,
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<CompanyType>({
        defaultValues: company
    })

    return (
        <form
            className={formStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <input type="hidden" {...register("id")} />

            {company && (
                <label>
                    <span>ID:</span> {company.id}<br/>
                    <span>Created:</span> {formatDatetime(company.created)}<br/>
                    <span>Modified:</span> {formatDatetime(company.modified)}
                </label>
            )}

            <label>
                <span>Legal Name</span>
                <input
                    type="text"
                    placeholder="Legal Name"
                    {...register("legalName", {
                        required: "Legal Name is required.",
                        minLength: {value: 3, message: "Too short."}
                    })}
                />
                <FormError field="legalName" errors={errors} />
            </label>

            <label>
                <span>URL</span>
                <input
                    type="text"
                    placeholder="URL"
                    {...register("url", {
                        pattern: {
                            value: /^(https?):\/\/[^\s/$.?#].[^\s]*$/,
                            message: "Please enter a valid URL."
                        }
                    })}
                />
                <FormError field="slug" errors={errors} />
            </label>

            {contacts.length > 0 && (
                <label>
                    <span>Contacts</span>
                    <Controller
                        name="contacts"
                        control={control}
                        render={({field: {onChange, value}}) => {
                            const options = contacts.map(contact => ({
                                label: `${contact.name} (${contact.id})`,
                                value: contact
                            }))
                            const valueIds = value ? value.map(v => v.id) : []
                            return (
                                <Select
                                    options={options}
                                    value={options.filter((o) => valueIds.includes(o.value.id))}
                                    onChange={val => onChange(val.map(v => v.value))}
                                    isMulti
                                />
                            )
                        }}
                    />
                </label>
            )}

            {deals.length > 0 && (
                <label>
                    <span>Deals</span>
                    <Controller
                        name="deals"
                        control={control}
                        render={({field: {onChange, value}}) => {
                            const options = deals.map(deal => ({
                                label: `${deal.name.completeOnboarding} (${deal.id})`,
                                value: deal
                            }))
                            const valueIds = value ? value.map(v => v.id) : []
                            return (
                                <Select
                                    options={options}
                                    value={options.filter((o) => valueIds.includes(o.value.id))}
                                    onChange={val => onChange(val.map(v => v.value))}
                                    isMulti
                                />
                            )
                        }}
                    />
                </label>
            )}

            <button
                type="submit"
                className={formStyles.submitButton}
            >
                {submitText}
            </button>
        </form>
    )
}

export default CompanyForm