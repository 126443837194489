/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DealStageOpenApi = {
    None: 'NONE',
    EarlyStage: 'EARLY_STAGE',
    GrowthStage: 'GROWTH_STAGE',
    LateStage: 'LATE_STAGE',
    Fund: 'FUND',
    Seed: 'SEED',
    SeriesA: 'SERIES_A',
    SeriesB: 'SERIES_B',
    SeriesC: 'SERIES_C',
    PreIpo: 'PRE_IPO'
} as const;
export type DealStageOpenApi = typeof DealStageOpenApi[keyof typeof DealStageOpenApi];


export function DealStageOpenApiFromJSON(json: any): DealStageOpenApi {
    return DealStageOpenApiFromJSONTyped(json, false);
}

export function DealStageOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealStageOpenApi {
    return json as DealStageOpenApi;
}

export function DealStageOpenApiToJSON(value?: DealStageOpenApi | null): any {
    return value as any;
}

