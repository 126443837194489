import {
    ManualReconciliationBankAccountOpenApi,
    ManualReconciliationBankAccountWithDealsOpenApi,
    ManualReconciliationBankOpenApi
} from "../../../generated"
import {
    ManualReconciliationBankAccountType,
    ManualReconciliationBankAccountWithDealsType,
    ManualReconciliationBankType
} from "./manual-reconciliation-bank-account.type"
import {mapOpenApiToDeal} from "../../deal/deal.mapper"
import {
    mapOpenApiToSinglePurposeVehicle,
    mapSinglePurposeVehicleToOpenApi
} from "../../single-purpose-vehicle/single-purpose-vehicle.mapper"

export function mapOpenApiToManualReconciliationBankAccount(account: ManualReconciliationBankAccountOpenApi): ManualReconciliationBankAccountType {
    return {
        id: account.id,
        created: account.created,
        modified: account.modified,
        transferOrigin: account.transferOrigin,
        bank: mapOpenApiToManualReconciliationBank(account.bank),
        currency: account.currency,
        holderSinglePurposeVehicle: mapOpenApiToSinglePurposeVehicle(account.holderSinglePurposeVehicle),
        number: account.number,
        swift: account.swift,
        achRoutingNumber: account.achRoutingNumber,
        aba: account.aba
    }
}

export function mapManualReconciliationBankAccountToOpenApi(account: ManualReconciliationBankAccountType): ManualReconciliationBankAccountOpenApi {
    return {
        id: account.id,
        created: account.created,
        modified: account.modified,
        transferOrigin: account.transferOrigin,
        bank: mapManualReconciliationBankToOpenApi(account.bank),
        currency: account.currency,
        holderSinglePurposeVehicle: mapSinglePurposeVehicleToOpenApi(account.holderSinglePurposeVehicle),
        number: account.number,
        swift: account.swift,
        achRoutingNumber: account.achRoutingNumber,
        aba: account.aba
    }
}

export function mapOpenApiToManualReconciliationBankAccountWithDeals(account: ManualReconciliationBankAccountWithDealsOpenApi): ManualReconciliationBankAccountWithDealsType {
    return {
        id: account.id,
        created: account.created,
        modified: account.modified,
        transferOrigin: account.transferOrigin,
        bank: mapOpenApiToManualReconciliationBank(account.bank),
        currency: account.currency,
        holderSinglePurposeVehicle: mapOpenApiToSinglePurposeVehicle(account.holderSinglePurposeVehicle),
        number: account.number,
        swift: account.swift,
        achRoutingNumber: account.achRoutingNumber,
        aba: account.aba,
        deals: account.deals.map(mapOpenApiToDeal)
    }
}

export function mapManualReconciliationBankAccountWithDealsToOpenApi(account: ManualReconciliationBankAccountWithDealsType): ManualReconciliationBankAccountWithDealsOpenApi {
    return {
        id: account.id,
        created: account.created,
        modified: account.modified,
        transferOrigin: account.transferOrigin,
        bank: mapManualReconciliationBankToOpenApi(account.bank),
        currency: account.currency,
        holderSinglePurposeVehicle: mapSinglePurposeVehicleToOpenApi(account.holderSinglePurposeVehicle),
        number: account.number,
        swift: account.swift,
        achRoutingNumber: account.achRoutingNumber,
        aba: account.aba,
        deals: []
    }
}

export function mapOpenApiToManualReconciliationBank(bank: ManualReconciliationBankOpenApi): ManualReconciliationBankType {
    return {
        address: bank.address,
        name: bank.name
    }
}

export function mapManualReconciliationBankToOpenApi(bank: ManualReconciliationBankType): ManualReconciliationBankOpenApi {
    return {
        address: bank.address,
        name: bank.name
    }
}