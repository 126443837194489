import {KycApiClient, KycLogOpenApi} from "../../generated"
import {KycLogReviewType, KycLogType} from "./log/kyc-log.type"
import {mapKycLogReviewToOpenApi, mapOpenApiToKycLog} from "./log/kyc-log.mapper"

export class KycApi {

    constructor(private apiClient: KycApiClient) {
    }

    public async get(id: string): Promise<KycLogType> {
        const kycLog = await this.apiClient.kycLogGet({ id })
        return mapOpenApiToKycLog(kycLog)
    }

    public async getAllKycLogsToBeReviewed(): Promise<KycLogType[]> {
        return (await this.apiClient.kycLogsToBeReviewedGetAll())
            .map((kycLog: KycLogOpenApi) => mapOpenApiToKycLog(kycLog))
    }

    public async review(id: string, review: KycLogReviewType): Promise<void> {
        await this.apiClient.kycLogReview({
            id,
            kycLogReviewOpenApi: mapKycLogReviewToOpenApi(review)
        })
    }

}