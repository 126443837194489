import {DealSortOpenApi} from "../../generated"
import {ActionsSortOptionType} from "../../tech/actions/sort/sort.type"
import {getSort} from "../../tech/actions/sort/sort.util"

export const DEFAULT_SORT = {
    id: DealSortOpenApi.ModifiedDescending,
    label: "Modified (DESC)"
}

export function assembleSortOptions(): ActionsSortOptionType<DealSortOpenApi>[] {
    return [
        {
            id: DealSortOpenApi.FinalDeadlineAscending,
            label: "Final Deadline (ASC)"
        },
        {
            id: DealSortOpenApi.FinalDeadlineDescending,
            label: "Final Deadline (DESC)"
        },
        {
            id: DealSortOpenApi.MaxTargetAscending,
            label: "Maximum Target (ASC)"
        },
        {
            id: DealSortOpenApi.MaxTargetDescending,
            label: "Maximum Target (DESC)"
        },
        {
            id: DealSortOpenApi.MinTargetAscending,
            label: "Minimum Target (ASC)"
        },
        {
            id: DealSortOpenApi.MinTargetDescending,
            label: "Minimum Target (DESC)"
        },
        {
            id: DealSortOpenApi.MinTicketAscending,
            label: "Minimum Ticket (ASC)"
        },
        {
            id: DealSortOpenApi.MinTicketDescending,
            label: "Minimum Ticket (DESC)"
        },
        {
            id: DealSortOpenApi.ModifiedAscending,
            label: "Modified (ASC)"
        },
        DEFAULT_SORT,
        {
            id: DealSortOpenApi.NameAscending,
            label: "Name (ASC)"
        },
        {
            id: DealSortOpenApi.NameDescending,
            label: "Name (DESC)"
        },
        {
            id: DealSortOpenApi.OutcomeAscending,
            label: "Outcome (ASC)"
        },
        {
            id: DealSortOpenApi.OutcomeDescending,
            label: "Outcome (DESC)"
        },
    ]
}

export function getCurrentSortOption(
    options: ActionsSortOptionType<DealSortOpenApi>[],
    searchParams: URLSearchParams
): ActionsSortOptionType<DealSortOpenApi> {
    return (options.find(o => o.id === getSort(searchParams)) || DEFAULT_SORT)
}