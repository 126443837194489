/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ManualReconciliationBankAccountOpenApi } from './ManualReconciliationBankAccountOpenApi';
import {
    ManualReconciliationBankAccountOpenApiFromJSON,
    ManualReconciliationBankAccountOpenApiFromJSONTyped,
    ManualReconciliationBankAccountOpenApiToJSON,
} from './ManualReconciliationBankAccountOpenApi';

/**
 * 
 * @export
 * @interface DealManualReconciliationBankAccountOpenApi
 */
export interface DealManualReconciliationBankAccountOpenApi {
    /**
     * 
     * @type {ManualReconciliationBankAccountOpenApi}
     * @memberof DealManualReconciliationBankAccountOpenApi
     */
    manualReconciliationBankAccount: ManualReconciliationBankAccountOpenApi;
    /**
     * 
     * @type {number}
     * @memberof DealManualReconciliationBankAccountOpenApi
     */
    order: number;
}

/**
 * Check if a given object implements the DealManualReconciliationBankAccountOpenApi interface.
 */
export function instanceOfDealManualReconciliationBankAccountOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "manualReconciliationBankAccount" in value;
    isInstance = isInstance && "order" in value;

    return isInstance;
}

export function DealManualReconciliationBankAccountOpenApiFromJSON(json: any): DealManualReconciliationBankAccountOpenApi {
    return DealManualReconciliationBankAccountOpenApiFromJSONTyped(json, false);
}

export function DealManualReconciliationBankAccountOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealManualReconciliationBankAccountOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'manualReconciliationBankAccount': ManualReconciliationBankAccountOpenApiFromJSON(json['manualReconciliationBankAccount']),
        'order': json['order'],
    };
}

export function DealManualReconciliationBankAccountOpenApiToJSON(value?: DealManualReconciliationBankAccountOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'manualReconciliationBankAccount': ManualReconciliationBankAccountOpenApiToJSON(value.manualReconciliationBankAccount),
        'order': value.order,
    };
}

