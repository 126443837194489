import React, {useContext, useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import DealGroupForm from "./form.component"
import {SubmitHandler} from "react-hook-form"
import {DealGroupType} from "./deal-group.type"
import {normalizeValues} from "../../tech/form/form.util"

const DealGroupEdit = () => {
    let { id } = useParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR" | "SUCCESS">("LOADING")
    const [dealGroup, setDealGroup] = useState<DealGroupType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                setDealGroup(await fetchClient.dealGroupApi.get(id!))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const onSubmit: SubmitHandler<DealGroupType> = async (dealGroup) => {
        setState("LOADING")
        try {
            setDealGroup(await fetchClient.dealGroupApi.update(id!, normalizeValues(dealGroup)))
            window.scrollTo({ top: 0 })
            setState("SUCCESS")
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="An error occurred. Please try again or contact the tech team."
                />
            )}
            {state === "SUCCESS" && (
                <Alert
                    type={AlertType.SUCCESS}
                    text="Saved successfully."
                />
            )}
            {(state === "LOADED" || state === "ERROR" || state === "SUCCESS") && dealGroup && (
                <Section>
                    <SectionHeading title={`Edit deal group: ${dealGroup.name}`}/>
                    <DealGroupForm
                        dealGroup={dealGroup}
                        onSubmit={onSubmit}
                        submitText="Save deal group"
                    />
                </Section>
            )}
        </>
    )
}

export default DealGroupEdit