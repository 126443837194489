import {ActionsSortOptionType} from "../../tech/actions/sort/sort.type"
import {getSort} from "../../tech/actions/sort/sort.util"
import {UpdateSortOpenApi} from "../../generated"

export const DEFAULT_SORT = {
    id: UpdateSortOpenApi.ModifiedDescending,
    label: "Modified (DESC)"
}

export function assembleSortOptions(): ActionsSortOptionType<UpdateSortOpenApi>[] {
    return [
        {
            id: UpdateSortOpenApi.DateAscending,
            label: "Date (ASC)"
        },
        {
            id: UpdateSortOpenApi.DateDescending,
            label: "Date (DESC)"
        },
        {
            id: UpdateSortOpenApi.ModifiedAscending,
            label: "Modified (ASC)"
        },
        DEFAULT_SORT,
    ]
}

export function getCurrentSortOption(
    options: ActionsSortOptionType<UpdateSortOpenApi>[],
    searchParams: URLSearchParams
): ActionsSortOptionType<UpdateSortOpenApi> {
    return (options.find(o => o.id === getSort(searchParams)) || DEFAULT_SORT)
}