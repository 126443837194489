/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DealPaymentProcessingTypeOpenApi = {
    ManualReconciliation: 'MANUAL_RECONCILIATION',
    Wallex: 'WALLEX'
} as const;
export type DealPaymentProcessingTypeOpenApi = typeof DealPaymentProcessingTypeOpenApi[keyof typeof DealPaymentProcessingTypeOpenApi];


export function DealPaymentProcessingTypeOpenApiFromJSON(json: any): DealPaymentProcessingTypeOpenApi {
    return DealPaymentProcessingTypeOpenApiFromJSONTyped(json, false);
}

export function DealPaymentProcessingTypeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealPaymentProcessingTypeOpenApi {
    return json as DealPaymentProcessingTypeOpenApi;
}

export function DealPaymentProcessingTypeOpenApiToJSON(value?: DealPaymentProcessingTypeOpenApi | null): any {
    return value as any;
}

