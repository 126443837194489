import React from "react"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import DashboardDealsWithoutValuation from "./deals-without-valuation.component"
import DashboardDealsWithoutCompany from "./deals-without-company.component"
import DashboardPoliticallyExposedPersonsNotCleared from "./pep-not-cleared.component"
import DashboardAsynchronousActionErrors from "./asynchronous-action-errors.component"
import DashboardOpenKycReviews from "./open-kyc-reviews.component"
import DashboardOpenCollectionDeviations from "./open-collection-deviations.component"
import styles from "./dashboard.module.css"
import DashboardPendingInvestments from "./pending-investments.component"

const Dashboard = () => {
    return (
        <Section>
            <SectionHeading title="Dashboard"/>
            <div className={styles.dashboard}>
                <DashboardOpenCollectionDeviations/>
                <DashboardOpenKycReviews/>
                <DashboardAsynchronousActionErrors/>
                <DashboardPendingInvestments/>
                <DashboardPoliticallyExposedPersonsNotCleared/>
                <DashboardDealsWithoutValuation/>
                <DashboardDealsWithoutCompany/>
            </div>
        </Section>
    )
}

export default Dashboard