/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ValidationResponseOpenApi,
  WaitlistEntryOpenApi,
  WaitlistFiltersOpenApi,
  WaitlistPaginatedResponseOpenApi,
  WaitlistRequestOpenApi,
  WaitlistSortOpenApi,
} from '../models';
import {
    ValidationResponseOpenApiFromJSON,
    ValidationResponseOpenApiToJSON,
    WaitlistEntryOpenApiFromJSON,
    WaitlistEntryOpenApiToJSON,
    WaitlistFiltersOpenApiFromJSON,
    WaitlistFiltersOpenApiToJSON,
    WaitlistPaginatedResponseOpenApiFromJSON,
    WaitlistPaginatedResponseOpenApiToJSON,
    WaitlistRequestOpenApiFromJSON,
    WaitlistRequestOpenApiToJSON,
    WaitlistSortOpenApiFromJSON,
    WaitlistSortOpenApiToJSON,
} from '../models';

export interface WaitlistEnterRequest {
    waitlistRequestOpenApi?: WaitlistRequestOpenApi;
}

export interface WaitlistEntryCreateRequest {
    waitlistEntryOpenApi?: WaitlistEntryOpenApi;
}

export interface WaitlistEntryDeleteRequest {
    id: string;
}

export interface WaitlistEntryGetRequest {
    id: string;
}

export interface WaitlistEntryUpdateRequest {
    id: string;
    waitlistEntryOpenApi?: WaitlistEntryOpenApi;
}

export interface WaitlistGetAllPaginatedRequest {
    elementsPerPage?: number;
    page?: number;
    sort?: WaitlistSortOpenApi;
    waitlistFiltersOpenApi?: WaitlistFiltersOpenApi;
}

/**
 * 
 */
export class WaitlistApiClient extends runtime.BaseAPI {

    /**
     * Create new waitlist entry (public endpoint for customers)
     */
    async waitlistEnterRaw(requestParameters: WaitlistEnterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/waitlist/enter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WaitlistRequestOpenApiToJSON(requestParameters.waitlistRequestOpenApi),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create new waitlist entry (public endpoint for customers)
     */
    async waitlistEnter(requestParameters: WaitlistEnterRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.waitlistEnterRaw(requestParameters, initOverrides);
    }

    /**
     * Create new waitlist entry
     */
    async waitlistEntryCreateRaw(requestParameters: WaitlistEntryCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaitlistEntryOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/waitlist`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WaitlistEntryOpenApiToJSON(requestParameters.waitlistEntryOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaitlistEntryOpenApiFromJSON(jsonValue));
    }

    /**
     * Create new waitlist entry
     */
    async waitlistEntryCreate(requestParameters: WaitlistEntryCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaitlistEntryOpenApi> {
        const response = await this.waitlistEntryCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete waitlist entry by id
     */
    async waitlistEntryDeleteRaw(requestParameters: WaitlistEntryDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling waitlistEntryDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/waitlist/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete waitlist entry by id
     */
    async waitlistEntryDelete(requestParameters: WaitlistEntryDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.waitlistEntryDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get waitlist entry by id
     */
    async waitlistEntryGetRaw(requestParameters: WaitlistEntryGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaitlistEntryOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling waitlistEntryGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/waitlist/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaitlistEntryOpenApiFromJSON(jsonValue));
    }

    /**
     * Get waitlist entry by id
     */
    async waitlistEntryGet(requestParameters: WaitlistEntryGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaitlistEntryOpenApi> {
        const response = await this.waitlistEntryGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update waitlist entry
     */
    async waitlistEntryUpdateRaw(requestParameters: WaitlistEntryUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaitlistEntryOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling waitlistEntryUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/waitlist/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WaitlistEntryOpenApiToJSON(requestParameters.waitlistEntryOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaitlistEntryOpenApiFromJSON(jsonValue));
    }

    /**
     * Update waitlist entry
     */
    async waitlistEntryUpdate(requestParameters: WaitlistEntryUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaitlistEntryOpenApi> {
        const response = await this.waitlistEntryUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all waitlist entries (paginated)
     */
    async waitlistGetAllPaginatedRaw(requestParameters: WaitlistGetAllPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaitlistPaginatedResponseOpenApi>> {
        const queryParameters: any = {};

        if (requestParameters.elementsPerPage !== undefined) {
            queryParameters['elementsPerPage'] = requestParameters.elementsPerPage;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/waitlist/paginated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WaitlistFiltersOpenApiToJSON(requestParameters.waitlistFiltersOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaitlistPaginatedResponseOpenApiFromJSON(jsonValue));
    }

    /**
     * Get all waitlist entries (paginated)
     */
    async waitlistGetAllPaginated(requestParameters: WaitlistGetAllPaginatedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaitlistPaginatedResponseOpenApi> {
        const response = await this.waitlistGetAllPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
