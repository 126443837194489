/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvestorGroupOpenApi } from './InvestorGroupOpenApi';
import {
    InvestorGroupOpenApiFromJSON,
    InvestorGroupOpenApiFromJSONTyped,
    InvestorGroupOpenApiToJSON,
} from './InvestorGroupOpenApi';
import type { SyndicateOpenApi } from './SyndicateOpenApi';
import {
    SyndicateOpenApiFromJSON,
    SyndicateOpenApiFromJSONTyped,
    SyndicateOpenApiToJSON,
} from './SyndicateOpenApi';

/**
 * 
 * @export
 * @interface SyndicateInvitationOpenApi
 */
export interface SyndicateInvitationOpenApi {
    /**
     * 
     * @type {string}
     * @memberof SyndicateInvitationOpenApi
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof SyndicateInvitationOpenApi
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SyndicateInvitationOpenApi
     */
    modified?: Date;
    /**
     * 
     * @type {string}
     * @memberof SyndicateInvitationOpenApi
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof SyndicateInvitationOpenApi
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof SyndicateInvitationOpenApi
     */
    entered?: Date;
    /**
     * 
     * @type {SyndicateOpenApi}
     * @memberof SyndicateInvitationOpenApi
     */
    syndicate: SyndicateOpenApi;
    /**
     * 
     * @type {InvestorGroupOpenApi}
     * @memberof SyndicateInvitationOpenApi
     */
    targetInvestorGroup: InvestorGroupOpenApi;
}

/**
 * Check if a given object implements the SyndicateInvitationOpenApi interface.
 */
export function instanceOfSyndicateInvitationOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "syndicate" in value;
    isInstance = isInstance && "targetInvestorGroup" in value;

    return isInstance;
}

export function SyndicateInvitationOpenApiFromJSON(json: any): SyndicateInvitationOpenApi {
    return SyndicateInvitationOpenApiFromJSONTyped(json, false);
}

export function SyndicateInvitationOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyndicateInvitationOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'code': !exists(json, 'code') ? undefined : json['code'],
        'email': json['email'],
        'entered': !exists(json, 'entered') ? undefined : (new Date(json['entered'])),
        'syndicate': SyndicateOpenApiFromJSON(json['syndicate']),
        'targetInvestorGroup': InvestorGroupOpenApiFromJSON(json['targetInvestorGroup']),
    };
}

export function SyndicateInvitationOpenApiToJSON(value?: SyndicateInvitationOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'code': value.code,
        'email': value.email,
        'entered': value.entered === undefined ? undefined : (value.entered.toISOString()),
        'syndicate': SyndicateOpenApiToJSON(value.syndicate),
        'targetInvestorGroup': InvestorGroupOpenApiToJSON(value.targetInvestorGroup),
    };
}

