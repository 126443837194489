/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyFiltersOpenApi
 */
export interface CompanyFiltersOpenApi {
    /**
     * 
     * @type {string}
     * @memberof CompanyFiltersOpenApi
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyFiltersOpenApi
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyFiltersOpenApi
     */
    contactId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyFiltersOpenApi
     */
    contactName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyFiltersOpenApi
     */
    dealId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyFiltersOpenApi
     */
    dealName?: string;
}

/**
 * Check if a given object implements the CompanyFiltersOpenApi interface.
 */
export function instanceOfCompanyFiltersOpenApi(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CompanyFiltersOpenApiFromJSON(json: any): CompanyFiltersOpenApi {
    return CompanyFiltersOpenApiFromJSONTyped(json, false);
}

export function CompanyFiltersOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyFiltersOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'legalName': !exists(json, 'legalName') ? undefined : json['legalName'],
        'contactId': !exists(json, 'contactId') ? undefined : json['contactId'],
        'contactName': !exists(json, 'contactName') ? undefined : json['contactName'],
        'dealId': !exists(json, 'dealId') ? undefined : json['dealId'],
        'dealName': !exists(json, 'dealName') ? undefined : json['dealName'],
    };
}

export function CompanyFiltersOpenApiToJSON(value?: CompanyFiltersOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'legalName': value.legalName,
        'contactId': value.contactId,
        'contactName': value.contactName,
        'dealId': value.dealId,
        'dealName': value.dealName,
    };
}

