/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WaitlistSortOpenApi = {
    CreatedAscending: 'CREATED_ASCENDING',
    CreatedDescending: 'CREATED_DESCENDING',
    FullNameAscending: 'FULL_NAME_ASCENDING',
    FullNameDescending: 'FULL_NAME_DESCENDING'
} as const;
export type WaitlistSortOpenApi = typeof WaitlistSortOpenApi[keyof typeof WaitlistSortOpenApi];


export function WaitlistSortOpenApiFromJSON(json: any): WaitlistSortOpenApi {
    return WaitlistSortOpenApiFromJSONTyped(json, false);
}

export function WaitlistSortOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaitlistSortOpenApi {
    return json as WaitlistSortOpenApi;
}

export function WaitlistSortOpenApiToJSON(value?: WaitlistSortOpenApi | null): any {
    return value as any;
}

