import React, {useContext, useEffect, useState} from "react"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import {useParams} from "react-router-dom"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import {SubmitHandler} from "react-hook-form"
import {InvestorType, InvestorWithDetailsType} from "../investor.type"
import InvestorForm from "../form.component"
import {DealGroupType} from "../../deal-group/deal-group.type"
import SubscriptionSummary from "../../subscription/summary.component"
import {normalizeValues} from "../../../tech/form/form.util"
import styles from "./edit.module.sass"
import BackendValidationErrorsAlert from "../../../tech/validation/alert.component"
import {match} from "ts-pattern"

const InvestorEdit = () => {
    let { id } = useParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR" | "SUCCESS">("LOADING")
    const [errors, setErrors] = useState<string[]>([])
    const [investorWithDetails, setInvestorWithDetails] = useState<InvestorWithDetailsType>()
    const [dealGroups, setDealGroups] = useState<DealGroupType[]>([])
    const [investorGroups, setInvestorGroups] = useState<DealGroupType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const [fetchedInvestor, fetchedDealGroups, fetchedInvestorGroups] = await Promise.all([
                    fetchClient.investorApi.get(id!),
                    fetchClient.dealGroupApi.getAll(),
                    fetchClient.investorGroupApi.getAll()
                ])
                setInvestorWithDetails(fetchedInvestor)
                setDealGroups(fetchedDealGroups)
                setInvestorGroups(fetchedInvestorGroups)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const onSubmit: SubmitHandler<InvestorType> = async (investor) => {
        setState("LOADING")
        const result = await fetchClient.investorApi.update(id!, normalizeValues(investor))
        match(result)
            .with(
                { type: "RESPONSE" },
                (res) => {
                    window.scrollTo({ top: 0 })
                    setState("SUCCESS")
                    setInvestorWithDetails(res.val)
                }
            )
            .with(
                { type: "RESPONSE_ERROR" },
                () => {
                    window.scrollTo({ top: 0 })
                    setState("ERROR")
                }
            )
            .with(
                { type: "VALIDATION_ERRORS" },
                (res) => {
                    window.scrollTo({ top: 0 })
                    setState("ERROR")
                    setErrors(res.errors)
                }
            )
            .exhaustive()
    }

    const fullName = investorWithDetails?.investor.person?.fullName

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {errors.length > 0 && <BackendValidationErrorsAlert errors={errors}/>}
            {state === "ERROR" && errors.length < 1 && (
                <Alert
                    type={AlertType.ERROR}
                    text="An error occurred. Please try again or contact the tech team."
                />
            )}
            {state === "SUCCESS" && (
                <Alert
                    type={AlertType.SUCCESS}
                    text="Saved successfully."
                />
            )}
            {(state === "LOADED" || state === "ERROR" || state === "SUCCESS") && investorWithDetails && (
                <Section>
                    <SectionHeading title={`Edit investor: ${fullName ? fullName : investorWithDetails.investor.id}`}/>
                    <InvestorForm
                        investor={investorWithDetails.investor}
                        dealGroups={dealGroups}
                        investorGroups={investorGroups}
                        onSubmit={onSubmit}
                        submitText="Save investor"
                    />

                    <div className={styles.subscription}>
                        <h3>Subscription</h3>
                        <SubscriptionSummary subscription={investorWithDetails.subscription}/>
                    </div>
                </Section>
            )}
        </>
    )
}

export default InvestorEdit