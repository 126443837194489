import React, {FunctionComponent, useCallback, useEffect} from "react"
import {useSearchParams} from "react-router-dom"
import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon
} from "@heroicons/react/24/outline"
import {getPage, setPage} from "./pagination.util"
import styles from "./pagination.module.sass"

export type PaginationProps = {
    label: string
    elementsPerPage: number
    numberElements: number
}

const Pagination: FunctionComponent<PaginationProps> = ({
    label,
    elementsPerPage,
    numberElements
}) => {
    const [searchParams, setSearchParams] = useSearchParams()

    const updatePage = useCallback(
        (page: number) => setSearchParams(setPage(page, searchParams)),
        [searchParams, setSearchParams]
    )

    const numberPages = Math.ceil(numberElements / elementsPerPage)
    const currentPage = determineCurrentPage(numberPages, searchParams)

    useEffect(() => {
        if (getPageFromURL(searchParams) !== currentPage) {
            updatePage(currentPage)
        }
    }, [currentPage, searchParams, updatePage])

    const pages: number[] = []
    for (let i = 0; i < numberPages; i++) pages.push(i + 1)

    if (numberPages < 2) return <></>

    return (
        <div className={styles.pagination}>
            <div className={styles.left}>
                <div>
                    {(currentPage - 1) * elementsPerPage + 1}-{Math.min(currentPage * elementsPerPage, numberElements)} of {numberElements} {label}
                </div>
            </div>
            <div className={styles.right}>
                <div>
                    {currentPage > 1 && (
                        <>
                            <button onClick={() => updatePage(1)}>
                                <ChevronDoubleLeftIcon/>
                            </button>
                            <button onClick={() => updatePage(currentPage - 1)}>
                                <ChevronLeftIcon/>
                            </button>
                        </>
                    )}
                    {pages.map(page => (
                        <button
                            className={currentPage === page ? styles.active : ""}
                            onClick={() => updatePage(page)}
                            key={`page-${page}`}
                        >
                            {page}
                        </button>
                    ))}
                    {currentPage < numberPages && (
                        <>
                            <button onClick={() => updatePage(currentPage + 1)}>
                                <ChevronRightIcon/>
                            </button>
                            <button onClick={() => updatePage(numberPages)}>
                                <ChevronDoubleRightIcon/>
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Pagination

function determineCurrentPage(
    numberPages: number,
    searchParams: URLSearchParams
): number {
    const pageAsNumber = getPageFromURL(searchParams)
    return pageAsNumber && pageAsNumber > 0 && pageAsNumber <= numberPages
        ? pageAsNumber
        : 1
}

function getPageFromURL(searchParams: URLSearchParams) {
    const page = getPage(searchParams)
    return page ? parseInt(page) : undefined
}