/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvestmentDocumentSynchronizationAsynchronousActionOpenApi } from './InvestmentDocumentSynchronizationAsynchronousActionOpenApi';
import {
    InvestmentDocumentSynchronizationAsynchronousActionOpenApiFromJSON,
    InvestmentDocumentSynchronizationAsynchronousActionOpenApiFromJSONTyped,
    InvestmentDocumentSynchronizationAsynchronousActionOpenApiToJSON,
} from './InvestmentDocumentSynchronizationAsynchronousActionOpenApi';
import type { NotificationAsynchronousActionOpenApi } from './NotificationAsynchronousActionOpenApi';
import {
    NotificationAsynchronousActionOpenApiFromJSON,
    NotificationAsynchronousActionOpenApiFromJSONTyped,
    NotificationAsynchronousActionOpenApiToJSON,
} from './NotificationAsynchronousActionOpenApi';
import type { StripeCustomerUpdateAsynchronousActionOpenApi } from './StripeCustomerUpdateAsynchronousActionOpenApi';
import {
    StripeCustomerUpdateAsynchronousActionOpenApiFromJSON,
    StripeCustomerUpdateAsynchronousActionOpenApiFromJSONTyped,
    StripeCustomerUpdateAsynchronousActionOpenApiToJSON,
} from './StripeCustomerUpdateAsynchronousActionOpenApi';
import type { StripeEventAsynchronousActionOpenApi } from './StripeEventAsynchronousActionOpenApi';
import {
    StripeEventAsynchronousActionOpenApiFromJSON,
    StripeEventAsynchronousActionOpenApiFromJSONTyped,
    StripeEventAsynchronousActionOpenApiToJSON,
} from './StripeEventAsynchronousActionOpenApi';
import type { WallexCollectionAccountCreationAsynchronousActionOpenApi } from './WallexCollectionAccountCreationAsynchronousActionOpenApi';
import {
    WallexCollectionAccountCreationAsynchronousActionOpenApiFromJSON,
    WallexCollectionAccountCreationAsynchronousActionOpenApiFromJSONTyped,
    WallexCollectionAccountCreationAsynchronousActionOpenApiToJSON,
} from './WallexCollectionAccountCreationAsynchronousActionOpenApi';
import type { WallexCollectionSynchronizationAsynchronousActionOpenApi } from './WallexCollectionSynchronizationAsynchronousActionOpenApi';
import {
    WallexCollectionSynchronizationAsynchronousActionOpenApiFromJSON,
    WallexCollectionSynchronizationAsynchronousActionOpenApiFromJSONTyped,
    WallexCollectionSynchronizationAsynchronousActionOpenApiToJSON,
} from './WallexCollectionSynchronizationAsynchronousActionOpenApi';

/**
 * 
 * @export
 * @interface AsynchronousActionOverviewOpenApi
 */
export interface AsynchronousActionOverviewOpenApi {
    /**
     * 
     * @type {Array<InvestmentDocumentSynchronizationAsynchronousActionOpenApi>}
     * @memberof AsynchronousActionOverviewOpenApi
     */
    investmentDocumentSynchronization: Array<InvestmentDocumentSynchronizationAsynchronousActionOpenApi>;
    /**
     * 
     * @type {Array<NotificationAsynchronousActionOpenApi>}
     * @memberof AsynchronousActionOverviewOpenApi
     */
    notification: Array<NotificationAsynchronousActionOpenApi>;
    /**
     * 
     * @type {Array<StripeCustomerUpdateAsynchronousActionOpenApi>}
     * @memberof AsynchronousActionOverviewOpenApi
     */
    stripeCustomerUpdate: Array<StripeCustomerUpdateAsynchronousActionOpenApi>;
    /**
     * 
     * @type {Array<StripeEventAsynchronousActionOpenApi>}
     * @memberof AsynchronousActionOverviewOpenApi
     */
    stripeEvent: Array<StripeEventAsynchronousActionOpenApi>;
    /**
     * 
     * @type {Array<WallexCollectionAccountCreationAsynchronousActionOpenApi>}
     * @memberof AsynchronousActionOverviewOpenApi
     */
    wallexCollectionAccountCreation: Array<WallexCollectionAccountCreationAsynchronousActionOpenApi>;
    /**
     * 
     * @type {Array<WallexCollectionSynchronizationAsynchronousActionOpenApi>}
     * @memberof AsynchronousActionOverviewOpenApi
     */
    wallexCollectionSynchronization: Array<WallexCollectionSynchronizationAsynchronousActionOpenApi>;
}

/**
 * Check if a given object implements the AsynchronousActionOverviewOpenApi interface.
 */
export function instanceOfAsynchronousActionOverviewOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "investmentDocumentSynchronization" in value;
    isInstance = isInstance && "notification" in value;
    isInstance = isInstance && "stripeCustomerUpdate" in value;
    isInstance = isInstance && "stripeEvent" in value;
    isInstance = isInstance && "wallexCollectionAccountCreation" in value;
    isInstance = isInstance && "wallexCollectionSynchronization" in value;

    return isInstance;
}

export function AsynchronousActionOverviewOpenApiFromJSON(json: any): AsynchronousActionOverviewOpenApi {
    return AsynchronousActionOverviewOpenApiFromJSONTyped(json, false);
}

export function AsynchronousActionOverviewOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): AsynchronousActionOverviewOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'investmentDocumentSynchronization': ((json['investmentDocumentSynchronization'] as Array<any>).map(InvestmentDocumentSynchronizationAsynchronousActionOpenApiFromJSON)),
        'notification': ((json['notification'] as Array<any>).map(NotificationAsynchronousActionOpenApiFromJSON)),
        'stripeCustomerUpdate': ((json['stripeCustomerUpdate'] as Array<any>).map(StripeCustomerUpdateAsynchronousActionOpenApiFromJSON)),
        'stripeEvent': ((json['stripeEvent'] as Array<any>).map(StripeEventAsynchronousActionOpenApiFromJSON)),
        'wallexCollectionAccountCreation': ((json['wallexCollectionAccountCreation'] as Array<any>).map(WallexCollectionAccountCreationAsynchronousActionOpenApiFromJSON)),
        'wallexCollectionSynchronization': ((json['wallexCollectionSynchronization'] as Array<any>).map(WallexCollectionSynchronizationAsynchronousActionOpenApiFromJSON)),
    };
}

export function AsynchronousActionOverviewOpenApiToJSON(value?: AsynchronousActionOverviewOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'investmentDocumentSynchronization': ((value.investmentDocumentSynchronization as Array<any>).map(InvestmentDocumentSynchronizationAsynchronousActionOpenApiToJSON)),
        'notification': ((value.notification as Array<any>).map(NotificationAsynchronousActionOpenApiToJSON)),
        'stripeCustomerUpdate': ((value.stripeCustomerUpdate as Array<any>).map(StripeCustomerUpdateAsynchronousActionOpenApiToJSON)),
        'stripeEvent': ((value.stripeEvent as Array<any>).map(StripeEventAsynchronousActionOpenApiToJSON)),
        'wallexCollectionAccountCreation': ((value.wallexCollectionAccountCreation as Array<any>).map(WallexCollectionAccountCreationAsynchronousActionOpenApiToJSON)),
        'wallexCollectionSynchronization': ((value.wallexCollectionSynchronization as Array<any>).map(WallexCollectionSynchronizationAsynchronousActionOpenApiToJSON)),
    };
}

