/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const KycDocumentUploadStateOpenApi = {
    NewUploadRequired: 'NEW_UPLOAD_REQUIRED',
    None: 'NONE',
    Rejected: 'REJECTED',
    ToBeReviewed: 'TO_BE_REVIEWED',
    UpdateRequired: 'UPDATE_REQUIRED'
} as const;
export type KycDocumentUploadStateOpenApi = typeof KycDocumentUploadStateOpenApi[keyof typeof KycDocumentUploadStateOpenApi];


export function KycDocumentUploadStateOpenApiFromJSON(json: any): KycDocumentUploadStateOpenApi {
    return KycDocumentUploadStateOpenApiFromJSONTyped(json, false);
}

export function KycDocumentUploadStateOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): KycDocumentUploadStateOpenApi {
    return json as KycDocumentUploadStateOpenApi;
}

export function KycDocumentUploadStateOpenApiToJSON(value?: KycDocumentUploadStateOpenApi | null): any {
    return value as any;
}

