import {ValidationErrors} from "../validation/validation.type"
import {ResponseError, ValidationResponseOpenApiFromJSON} from "../../generated"
import {ExtractedResponse} from "./response.type"
import {FetchResponseError} from "./error.type"

export async function extractError<T>(err: unknown): Promise<ExtractedResponse<T>> {
    if (err instanceof ResponseError) {
        if (err.response.status === 400) {
            const errors = ValidationResponseOpenApiFromJSON(await err.response.json()).errors?.map(e => e.message) ?? []
            return new ValidationErrors(errors)
        }
        return new FetchResponseError(
            `Unknown error response. ${err}`,
            err.response.status
        )
    }
    return new FetchResponseError(`Failed to extract response. ${err}`)
}