export type SortDirection = "ASCENDING" | "DESCENDING"

export function compareDates(a: Date, b: Date, direction: SortDirection): number {
    return direction === "ASCENDING"
        ? compareDatesAscending(a, b)
        : -compareDatesAscending(a, b)
}

function compareDatesAscending(a: Date, b: Date): number {
    if (a < b) return -1
    if (a > b) return 1
    return 0
}

export function compareNumbers(a: number, b: number, direction: SortDirection): number {
    return direction === "ASCENDING"
        ? a - b
        : b - a
}

export function compareStrings(a: string|undefined, b: string|undefined, direction: SortDirection): number {
    return direction === "ASCENDING"
        ? compareStringsAscending(a, b)
        : -compareStringsAscending(a, b)
}

function compareStringsAscending(a: string|undefined, b: string|undefined): number {
    if (a === undefined && b === undefined) return 0
    if (a !== undefined && b === undefined) return 1
    if (a === undefined && b !== undefined) return -1
    return a!.localeCompare(b!)
}