import React, {useContext, useEffect, useState} from "react"
import {SubmitHandler} from "react-hook-form"
import {Navigate} from "react-router-dom"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import DealForm from "../form/form.component"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import {DealType} from "../deal.type"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import {SinglePurposeVehicleSortOpenApi} from "../../../generated"
import {normalizeDeal} from "../deal.util"
import {
    ComponentState,
    ComponentStateErrorAction,
    ComponentStateErrorLoading,
    ComponentStateLoaded,
    ComponentStateLoading,
    ComponentStateLoadingWithData,
    ComponentStateSuccess
} from "../../../tech/state/state.type"
import {DealComponentData} from "../data.type"
import {match, P} from "ts-pattern"
import BackendValidationErrorsAlert from "../../../tech/validation/alert.component"
import {DEAL} from "../../../paths"
import {ShareClassType} from "../../share-class/share-class.type"

const DealNew = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<ComponentState<DealComponentData>>(new ComponentStateLoading())

    useEffect(() => {
        const fetch = async () => {
            try {
                const [
                    fetchedDealCategories,
                    fetchedDealGeographies,
                    fetchedDealGroups,
                    fetchedDocumentTemplates,
                    fetchedInvestorGroups,
                    fetchedManualReconciliationBankAccounts,
                    fetchedShareClasses,
                    fetchedSinglePurposeVehicles,
                ] = await Promise.all([
                    fetchClient.dealCategoryApi.getAll(),
                    fetchClient.dealGeographyApi.getAll(),
                    fetchClient.dealGroupApi.getAll(),
                    fetchClient.documentApi.getAll(),
                    fetchClient.investorGroupApi.getAll(),
                    fetchClient.reconciliationApi.getAllManualReconciliationBankAccounts(),
                    fetchClient.shareClassApi.getAll(),
                    fetchClient.singlePurposeVehicleApi.getAllPaginated(SinglePurposeVehicleSortOpenApi.NameAscending),
                ])
                setState(new ComponentStateLoaded<DealComponentData>({
                    categories: fetchedDealCategories,
                    documentTemplates: fetchedDocumentTemplates,
                    geographies: fetchedDealGeographies,
                    groups: fetchedDealGroups,
                    investorGroups: fetchedInvestorGroups,
                    manualReconciliationBankAccounts: fetchedManualReconciliationBankAccounts,
                    shareClasses: fetchedShareClasses,
                    singlePurposeVehicles: fetchedSinglePurposeVehicles.elements,
                }))
            } catch (err) {
                console.error(err)
                setState(new ComponentStateErrorLoading())
            }
        }
        fetch()
    }, [fetchClient])

    const onSubmit: SubmitHandler<DealType> = async (deal) => {
        if (state.type !== "LOADED" && state.type !== "ERROR_ACTION") {
            throw new Error("Not allowed.")
        }
        setState(new ComponentStateLoadingWithData(state.data))
        const res = await fetchClient.dealApi.create(normalizeDeal(
            deal,
            state.data.geographies,
            state.data.shareClasses,
            state.data.singlePurposeVehicles,
        ))
        match(res)
            .with(
                { type: "RESPONSE" },
                (r) => setState(new ComponentStateSuccess({
                    ...state.data,
                    deal: r.val
                }))
            )
            .with(
                { type: "RESPONSE_ERROR" },
                () => {
                    window.scrollTo({ top: 0 })
                    setState(new ComponentStateErrorAction({
                        ...state.data,
                        deal
                    }))
                }
            )
            .with(
                { type: "VALIDATION_ERRORS" },
                (res) => {
                    window.scrollTo({ top: 0 })
                    setState(new ComponentStateErrorAction({
                        ...state.data,
                        deal
                    }, res.errors))
                }
            )
            .exhaustive()
    }

    return match(state)
        .with(
            P.union({ type: "LOADED" }, { type: "ERROR_ACTION" }),
            (s) => (
                <Section>
                    <SectionHeading title="Create new deal"/>
                    {s.type === "ERROR_ACTION" && s.errors && <BackendValidationErrorsAlert errors={s.errors}/>}
                    {s.type === "ERROR_ACTION" && !s.errors && (
                        <Alert
                            type={AlertType.ERROR}
                            text="Failed to save."
                        />
                    )}
                    <DealForm
                        deal={s.data.deal}
                        dealCategories={s.data.categories}
                        dealGeographies={s.data.geographies}
                        dealGroups={s.data.groups}
                        documentTemplates={s.data.documentTemplates}
                        investorGroups={s.data.investorGroups}
                        manualReconciliationBankAccounts={s.data.manualReconciliationBankAccounts}
                        shareClasses={s.data.shareClasses}
                        singlePurposeVehicles={s.data.singlePurposeVehicles}
                        updateShareClasses={(shareClasses: ShareClassType[]) => s.data.shareClasses = shareClasses}
                        onSubmit={onSubmit}
                        submitText="Create deal"
                    />
                </Section>
            )
        )
        .with(
            { type: "ERROR_LOADING" },
            () => (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load."
                />
            )
        )
        .with(
            P.union({ type: "LOADING" }, { type: "LOADING_WITH_DATA" }),
            () => <LoadingDots/>
        )
        .with(
            { type: "SUCCESS" },
            (s) => <Navigate to={DEAL(s.data.deal!.id!)}/>
        )
        .exhaustive()
}

export default DealNew