import React, {FunctionComponent, ReactNode} from "react"
import styles from "./section.module.sass"

const Section: FunctionComponent<{ children: ReactNode }> = ({children}) => {
    return (
        <section className={styles.section}>
            {children}
        </section>
    )
}

export default Section