/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const KycLogReviewResultOpenApi = {
    Confirmed: 'CONFIRMED',
    Rejected: 'REJECTED'
} as const;
export type KycLogReviewResultOpenApi = typeof KycLogReviewResultOpenApi[keyof typeof KycLogReviewResultOpenApi];


export function KycLogReviewResultOpenApiFromJSON(json: any): KycLogReviewResultOpenApi {
    return KycLogReviewResultOpenApiFromJSONTyped(json, false);
}

export function KycLogReviewResultOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): KycLogReviewResultOpenApi {
    return json as KycLogReviewResultOpenApi;
}

export function KycLogReviewResultOpenApiToJSON(value?: KycLogReviewResultOpenApi | null): any {
    return value as any;
}

