/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FFileOpenApi,
  FileDownloadLinkOpenApi,
  FileFiltersOpenApi,
  FilePaginatedResponseOpenApi,
  FileSortOpenApi,
  FileTypeOpenApi,
  FileUpdateOpenApi,
  ValidationResponseOpenApi,
} from '../models';
import {
    FFileOpenApiFromJSON,
    FFileOpenApiToJSON,
    FileDownloadLinkOpenApiFromJSON,
    FileDownloadLinkOpenApiToJSON,
    FileFiltersOpenApiFromJSON,
    FileFiltersOpenApiToJSON,
    FilePaginatedResponseOpenApiFromJSON,
    FilePaginatedResponseOpenApiToJSON,
    FileSortOpenApiFromJSON,
    FileSortOpenApiToJSON,
    FileTypeOpenApiFromJSON,
    FileTypeOpenApiToJSON,
    FileUpdateOpenApiFromJSON,
    FileUpdateOpenApiToJSON,
    ValidationResponseOpenApiFromJSON,
    ValidationResponseOpenApiToJSON,
} from '../models';

export interface FileDeleteRequest {
    id: string;
}

export interface FileGetRequest {
    id: string;
}

export interface FileGetAllPaginatedRequest {
    elementsPerPage?: number;
    page?: number;
    sort?: FileSortOpenApi;
    fileFiltersOpenApi?: FileFiltersOpenApi;
}

export interface FileGetDownloadLinkRequest {
    id: string;
}

export interface FileUpdateRequest {
    id: string;
    fileUpdateOpenApi?: FileUpdateOpenApi;
}

export interface FileUploadAsBackofficeUserRequest {
    fileType: FileTypeOpenApi;
    identifier?: string;
    file?: Blob;
}

export interface FileUploadAsUserRequest {
    identifier?: string;
    file?: Blob;
}

/**
 * 
 */
export class FileApiClient extends runtime.BaseAPI {

    /**
     * Delete file by id
     */
    async fileDeleteRaw(requestParameters: FileDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fileDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/file/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete file by id
     */
    async fileDelete(requestParameters: FileDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.fileDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get file by id
     */
    async fileGetRaw(requestParameters: FileGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FFileOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fileGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/file/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FFileOpenApiFromJSON(jsonValue));
    }

    /**
     * Get file by id
     */
    async fileGet(requestParameters: FileGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FFileOpenApi> {
        const response = await this.fileGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all files (paginated)
     */
    async fileGetAllPaginatedRaw(requestParameters: FileGetAllPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilePaginatedResponseOpenApi>> {
        const queryParameters: any = {};

        if (requestParameters.elementsPerPage !== undefined) {
            queryParameters['elementsPerPage'] = requestParameters.elementsPerPage;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/file/paginated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FileFiltersOpenApiToJSON(requestParameters.fileFiltersOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilePaginatedResponseOpenApiFromJSON(jsonValue));
    }

    /**
     * Get all files (paginated)
     */
    async fileGetAllPaginated(requestParameters: FileGetAllPaginatedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilePaginatedResponseOpenApi> {
        const response = await this.fileGetAllPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a download link for the specified file
     */
    async fileGetDownloadLinkRaw(requestParameters: FileGetDownloadLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadLinkOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fileGetDownloadLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/file/{id}/download-link`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadLinkOpenApiFromJSON(jsonValue));
    }

    /**
     * Get a download link for the specified file
     */
    async fileGetDownloadLink(requestParameters: FileGetDownloadLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadLinkOpenApi> {
        const response = await this.fileGetDownloadLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update file
     */
    async fileUpdateRaw(requestParameters: FileUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FFileOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fileUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/file/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FileUpdateOpenApiToJSON(requestParameters.fileUpdateOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FFileOpenApiFromJSON(jsonValue));
    }

    /**
     * Update file
     */
    async fileUpdate(requestParameters: FileUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FFileOpenApi> {
        const response = await this.fileUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a file (as backoffice user)
     */
    async fileUploadAsBackofficeUserRaw(requestParameters: FileUploadAsBackofficeUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FFileOpenApi>> {
        if (requestParameters.fileType === null || requestParameters.fileType === undefined) {
            throw new runtime.RequiredError('fileType','Required parameter requestParameters.fileType was null or undefined when calling fileUploadAsBackofficeUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.fileType !== undefined) {
            queryParameters['fileType'] = requestParameters.fileType;
        }

        if (requestParameters.identifier !== undefined) {
            queryParameters['identifier'] = requestParameters.identifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/file/upload-backoffice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FFileOpenApiFromJSON(jsonValue));
    }

    /**
     * Upload a file (as backoffice user)
     */
    async fileUploadAsBackofficeUser(requestParameters: FileUploadAsBackofficeUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FFileOpenApi> {
        const response = await this.fileUploadAsBackofficeUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a file (as user)
     */
    async fileUploadAsUserRaw(requestParameters: FileUploadAsUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FFileOpenApi>> {
        const queryParameters: any = {};

        if (requestParameters.identifier !== undefined) {
            queryParameters['identifier'] = requestParameters.identifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/file/upload-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FFileOpenApiFromJSON(jsonValue));
    }

    /**
     * Upload a file (as user)
     */
    async fileUploadAsUser(requestParameters: FileUploadAsUserRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FFileOpenApi> {
        const response = await this.fileUploadAsUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
