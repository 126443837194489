import React, {useContext, useEffect, useState} from "react"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import SectionHeading from "../../../tech/section/section-heading.component"
import Section from "../../../tech/section/section.component"
import {InvestorWithDetailsType} from "../investor.type"
import {useParams} from "react-router-dom"
import {SubscriptionLogType} from "../../subscription/log/log.type"
import tableStyles from "../../../tech/table/table.module.css"
import {CurrencyOpenApi} from "../../../generated"
import {formatDatetime} from "../../../tech/format/format.util"

const SubscriptionLogs = () => {
    let { id } = useParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [investorWithDetails, setInvestorWithDetails] = useState<InvestorWithDetailsType>()
    const [subscriptionLogs, setSubscriptionLogs] = useState<SubscriptionLogType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const investorId = id!
                const [investor, subscriptionLogs] = await Promise.all([
                    fetchClient.investorApi.get(investorId),
                    fetchClient.investorApi.getAllSubscriptionLogs(investorId)
                ])
                setInvestorWithDetails(investor)
                setSubscriptionLogs(subscriptionLogs)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && investorWithDetails && subscriptionLogs && (
                <Section>
                    <SectionHeading
                        title={`Investor: ${investorWithDetails.investor.person?.fullName} (Subscription Logs)`}
                    />
                    <div className={tableStyles.tableWrapper}>
                        <table className={tableStyles.table}>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Type</th>
                                <th>Created</th>
                                <th>Modified</th>
                                <th>Subscription Type</th>
                                <th>Price</th>
                                <th>Payment Interval</th>
                                <th>Cancellation</th>
                                <th>Stripe ID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {subscriptionLogs.map((subscriptionLog, i) => (
                                <tr key={i}>
                                    <td>{subscriptionLog.id}</td>
                                    <td>{subscriptionLog.type}</td>
                                    <td>{formatDatetime(subscriptionLog.created)}</td>
                                    <td>{formatDatetime(subscriptionLog.modified)}</td>
                                    <td>{subscriptionLog.subscriptionType}</td>
                                    <td>
                                        {subscriptionLog.price?.toLocaleString("en", {
                                            currency: CurrencyOpenApi.Sgd,
                                            style: 'currency',
                                            maximumFractionDigits: 0
                                        })}
                                    </td>
                                    <td>{subscriptionLog.paymentInterval}</td>
                                    <td>
                                        {subscriptionLog.terminationDate && (
                                            <>
                                                Termination Date: {formatDatetime(subscriptionLog.terminationDate)}<br/>
                                                Reason: {subscriptionLog.cancellationReason}
                                            </>
                                        )}
                                    </td>
                                    <td>{subscriptionLog.stripeSubscriptionId}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </Section>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load subscription logs."
                />
            )}
        </>
    )
}

export default SubscriptionLogs