/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UserSortOpenApi = {
    CreatedAscending: 'CREATED_ASCENDING',
    CreatedDescending: 'CREATED_DESCENDING',
    RoleAscending: 'ROLE_ASCENDING',
    RoleDescending: 'ROLE_DESCENDING'
} as const;
export type UserSortOpenApi = typeof UserSortOpenApi[keyof typeof UserSortOpenApi];


export function UserSortOpenApiFromJSON(json: any): UserSortOpenApi {
    return UserSortOpenApiFromJSONTyped(json, false);
}

export function UserSortOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSortOpenApi {
    return json as UserSortOpenApi;
}

export function UserSortOpenApiToJSON(value?: UserSortOpenApi | null): any {
    return value as any;
}

