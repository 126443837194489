import React, {FunctionComponent, useState} from "react"
import {ShareClassType} from "../../../../../share-class/share-class.type"
import Button from "../../../../../../tech/button/button.component"
import {ButtonStyle} from "../../../../../../tech/button/style.enum"
import DealInvestorGroupFeeStructureShareClassFormError from "./error.component"

type DealInvestorGroupFeeStructureShareClassFormProps = {
    save: (shareClass: ShareClassType) => void
    shareClass?: ShareClassType
}

const DealInvestorGroupFeeStructureShareClassForm: FunctionComponent<DealInvestorGroupFeeStructureShareClassFormProps> = ({
    save,
    shareClass
}) => {
    const [shareClassTmp, setShareClassTmp] = useState<ShareClassType>(shareClass || {
        name: "",
        nameInDealContract: "",
        feeStructure: {
            placementFee: 1.5,
            managementFee: 0,
            carry: 20
        }
    })
    const [errors, setErrors] = useState<{
        name: string | undefined,
        nameInDealContract: string | undefined,
        feeStructure: {
            placementFee: string | undefined,
            managementFee: string | undefined,
            carry: string | undefined
        }
    }>({
        name: undefined,
        nameInDealContract: undefined,
        feeStructure: {
            placementFee: undefined,
            managementFee: undefined,
            carry: undefined
        }
    })

    return (
        <>
            <label>
                <span>Name</span>
                <input
                    type="text"
                    placeholder="Name"
                    value={shareClassTmp.name}
                    onChange={(e) => {
                        setShareClassTmp({
                            ...shareClassTmp,
                            name: e.target.value
                        })
                        setErrors({
                            ...errors,
                            name: isBlank(e.target.value) ? "Name is required." : undefined
                        })
                    }}
                />
                <DealInvestorGroupFeeStructureShareClassFormError error={errors.name}/>
            </label>
            <label>
                <span>Name in deal contract</span>
                <input
                    type="text"
                    placeholder="Name in deal contract"
                    value={shareClassTmp.nameInDealContract}
                    onChange={(e) => {
                        setShareClassTmp({
                            ...shareClassTmp,
                            nameInDealContract: e.target.value
                        })
                        setErrors({
                            ...errors,
                            nameInDealContract: isBlank(e.target.value) ? "Name in deal contract is required." : undefined
                        })
                    }}
                />
                <DealInvestorGroupFeeStructureShareClassFormError error={errors.nameInDealContract}/>
            </label>
            <label>
                <span>Setup fee (%)</span>
                <input
                    type="number"
                    step={0.05}
                    value={shareClassTmp.feeStructure.placementFee}
                    onChange={(e) => {
                        if (e.target.value) {
                            setShareClassTmp({
                                ...shareClassTmp,
                                feeStructure: {
                                    ...shareClassTmp.feeStructure,
                                    placementFee: parseFloat(e.target.value)
                                }
                            })
                        }
                        setErrors({
                            ...errors,
                            feeStructure: {
                                ...errors.feeStructure,
                                placementFee: validateFeeStructurePartial(e.target.value)
                            }
                        })
                    }}
                />
                <DealInvestorGroupFeeStructureShareClassFormError error={errors.feeStructure.placementFee}/>
            </label>
            <label>
                <span>Setup fee explanation</span>
                <input
                    type="text"
                    placeholder="Setup fee explanation"
                    value={shareClassTmp.feeStructure.placementFeeExplanation || ""}
                    onChange={(e) => {
                        if (e.target.value) {
                            setShareClassTmp({
                                ...shareClassTmp,
                                feeStructure: {
                                    ...shareClassTmp.feeStructure,
                                    placementFeeExplanation: e.target.value
                                }
                            })
                        }
                    }}
                />
            </label>
            <label>
                <span>Management fee (%)</span>
                <input
                    type="number"
                    step={0.05}
                    value={shareClassTmp.feeStructure.managementFee}
                    onChange={(e) => {
                        if (e.target.value) {
                            setShareClassTmp({
                                ...shareClassTmp,
                                feeStructure: {
                                    ...shareClassTmp.feeStructure,
                                    managementFee: parseFloat(e.target.value)
                                }
                            })
                        }
                        setErrors({
                            ...errors,
                            feeStructure: {
                                ...errors.feeStructure,
                                managementFee: validateFeeStructurePartial(e.target.value)
                            }
                        })
                    }}
                />
                <DealInvestorGroupFeeStructureShareClassFormError error={errors.feeStructure.managementFee}/>
            </label>
            <label>
                <span>Carry (%)</span>
                <input
                    type="number"
                    step={0.05}
                    value={shareClassTmp.feeStructure.carry}
                    onChange={(e) => {
                        if (e.target.value) {
                            setShareClassTmp({
                                ...shareClassTmp,
                                feeStructure: {
                                    ...shareClassTmp.feeStructure,
                                    carry: parseFloat(e.target.value)
                                }
                            })
                        }
                        setErrors({
                            ...errors,
                            feeStructure: {
                                ...errors.feeStructure,
                                carry: validateFeeStructurePartial(e.target.value)
                            }
                        })
                    }}
                />
                <DealInvestorGroupFeeStructureShareClassFormError error={errors.feeStructure.carry}/>
            </label>
            <Button
                title="Save"
                style={ButtonStyle.SECONDARY}
                type="button"
                onClick={() => save({
                    ...shareClassTmp,
                    feeStructure: {
                        ...shareClassTmp.feeStructure,
                        placementFeeExplanation: isBlank(shareClassTmp.feeStructure.placementFeeExplanation)
                            ? undefined
                            : shareClassTmp.feeStructure.placementFeeExplanation
                    }
                })}
            />
        </>
    )
}

export default DealInvestorGroupFeeStructureShareClassForm

function isBlank(str: string | undefined): boolean {
    return str === undefined
        || str.trim() === ""
}

function validateFeeStructurePartial(str: string | undefined): string | undefined {
    if (str === undefined) {
        return "Required."
    }
    if (parseFloat(str) < 0) {
        return "Must not be negative."
    }
    if (parseFloat(str) > 100) {
        return "Must not be greater than 100 percent."
    }
}