/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CollectionAccountPurposeOpenApi = {
    Collection: 'COLLECTION',
    Intermediary: 'INTERMEDIARY'
} as const;
export type CollectionAccountPurposeOpenApi = typeof CollectionAccountPurposeOpenApi[keyof typeof CollectionAccountPurposeOpenApi];


export function CollectionAccountPurposeOpenApiFromJSON(json: any): CollectionAccountPurposeOpenApi {
    return CollectionAccountPurposeOpenApiFromJSONTyped(json, false);
}

export function CollectionAccountPurposeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectionAccountPurposeOpenApi {
    return json as CollectionAccountPurposeOpenApi;
}

export function CollectionAccountPurposeOpenApiToJSON(value?: CollectionAccountPurposeOpenApi | null): any {
    return value as any;
}

