/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    DealIncompleteOnboardingOpenApi,
    instanceOfDealIncompleteOnboardingOpenApi,
    DealIncompleteOnboardingOpenApiFromJSON,
    DealIncompleteOnboardingOpenApiFromJSONTyped,
    DealIncompleteOnboardingOpenApiToJSON,
} from './DealIncompleteOnboardingOpenApi';
import {
    DealOpenApi,
    instanceOfDealOpenApi,
    DealOpenApiFromJSON,
    DealOpenApiFromJSONTyped,
    DealOpenApiToJSON,
} from './DealOpenApi';

/**
 * @type DealOnboardingDependentOpenApi
 * 
 * @export
 */
export type DealOnboardingDependentOpenApi = { objectType: 'Deal' } & DealOpenApi | { objectType: 'DealIncompleteOnboarding' } & DealIncompleteOnboardingOpenApi;

export function DealOnboardingDependentOpenApiFromJSON(json: any): DealOnboardingDependentOpenApi {
    return DealOnboardingDependentOpenApiFromJSONTyped(json, false);
}

export function DealOnboardingDependentOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealOnboardingDependentOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['objectType']) {
        case 'Deal':
            return {...DealOpenApiFromJSONTyped(json, true), objectType: 'Deal'};
        case 'DealIncompleteOnboarding':
            return {...DealIncompleteOnboardingOpenApiFromJSONTyped(json, true), objectType: 'DealIncompleteOnboarding'};
        default:
            throw new Error(`No variant of DealOnboardingDependentOpenApi exists with 'objectType=${json['objectType']}'`);
    }
}

export function DealOnboardingDependentOpenApiToJSON(value?: DealOnboardingDependentOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['objectType']) {
        case 'Deal':
            return DealOpenApiToJSON(value);
        case 'DealIncompleteOnboarding':
            return DealIncompleteOnboardingOpenApiToJSON(value);
        default:
            throw new Error(`No variant of DealOnboardingDependentOpenApi exists with 'objectType=${value['objectType']}'`);
    }

}

