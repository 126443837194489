/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { KycLogReviewResultOpenApi } from './KycLogReviewResultOpenApi';
import {
    KycLogReviewResultOpenApiFromJSON,
    KycLogReviewResultOpenApiFromJSONTyped,
    KycLogReviewResultOpenApiToJSON,
} from './KycLogReviewResultOpenApi';

/**
 * 
 * @export
 * @interface KycLogReviewOpenApi
 */
export interface KycLogReviewOpenApi {
    /**
     * 
     * @type {KycLogReviewResultOpenApi}
     * @memberof KycLogReviewOpenApi
     */
    result: KycLogReviewResultOpenApi;
    /**
     * 
     * @type {string}
     * @memberof KycLogReviewOpenApi
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof KycLogReviewOpenApi
     */
    validTill?: Date;
}

/**
 * Check if a given object implements the KycLogReviewOpenApi interface.
 */
export function instanceOfKycLogReviewOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;

    return isInstance;
}

export function KycLogReviewOpenApiFromJSON(json: any): KycLogReviewOpenApi {
    return KycLogReviewOpenApiFromJSONTyped(json, false);
}

export function KycLogReviewOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): KycLogReviewOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': KycLogReviewResultOpenApiFromJSON(json['result']),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'validTill': !exists(json, 'validTill') ? undefined : (new Date(json['validTill'])),
    };
}

export function KycLogReviewOpenApiToJSON(value?: KycLogReviewOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': KycLogReviewResultOpenApiToJSON(value.result),
        'comment': value.comment,
        'validTill': value.validTill === undefined ? undefined : (value.validTill.toISOString().substr(0,10)),
    };
}

