import {AddressOpenApi} from "../../generated"
import {AddressType} from "./address.type"

export function mapOpenApiToAddress(address: AddressOpenApi): AddressType {
    return {
        line1: address.line1,
        city: address.city,
        zipOrPostalCode: address.zipOrPostalCode,
        country: address.country
    }
}

export function mapAddressToOpenApi(address: AddressType): AddressOpenApi {
    return {
        line1: address.line1,
        city: address.city,
        zipOrPostalCode: address.zipOrPostalCode,
        country: address.country
    }
}