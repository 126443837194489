import {DealGroupApiClient, DealGroupOpenApi} from "../../generated"
import {DealGroupType} from "./deal-group.type"
import {mapDealGroupToOpenApi, mapOpenApiToDealGroup} from "./deal-group.mapper"

export class DealGroupApi {

    constructor(private apiClient: DealGroupApiClient) {
    }

    public async create(dealGroup: DealGroupType): Promise<DealGroupType> {
        const savedDealGroup = await this.apiClient.dealGroupCreate({ dealGroupOpenApi: mapDealGroupToOpenApi(dealGroup) })
        return mapOpenApiToDealGroup(savedDealGroup)
    }

    public async delete(id: string): Promise<void> {
        await this.apiClient.dealGroupDelete({ id })
    }

    public async getAll(): Promise<DealGroupType[]> {
        return (await this.apiClient.dealGroupGetAll())
            .map((dealGroup: DealGroupOpenApi) => mapOpenApiToDealGroup(dealGroup))
    }

    public async get(id: string): Promise<DealGroupType> {
        const dealGroup = await this.apiClient.dealGroupGet({ id })
        return mapOpenApiToDealGroup(dealGroup)
    }

    public async update(id: string, dealGroup: DealGroupType): Promise<DealGroupType> {
        const response = await this.apiClient.dealGroupUpdate({ id, dealGroupOpenApi: mapDealGroupToOpenApi(dealGroup) })
        return mapOpenApiToDealGroup(response)
    }

}