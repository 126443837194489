import React, {FunctionComponent, useContext, useState} from "react"
import {Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch} from "react-hook-form"
import AsyncSelect from "react-select/async"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import {CompanyOrDealType, CompanyOrDealTypeCompany, CompanyOrDealTypeDeal, UpdateType} from "../update.type"
import {CompanySortOpenApi, DealSortOpenApi} from "../../../generated"
import styles from "./company-or-deal.module.sass"
import FormError from "../../../tech/form/error.component"

type UpdateCompanyOrDealProps = {
    control: Control<UpdateType, any>
    errors: FieldErrors
    update?: UpdateType
    setValue: UseFormSetValue<UpdateType>
    watch: UseFormWatch<UpdateType>
}

const UpdateCompanyOrDeal: FunctionComponent<UpdateCompanyOrDealProps> = ({
    control,
    errors,
    update,
    setValue,
    watch
}) => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [type, setType] = useState<"COMPANY" | "DEAL">(update === undefined ? "COMPANY" : update.companyOrDeal.type)

    function setTypeAndReset(t: "COMPANY" | "DEAL") {
        setType(t)
        // @ts-ignore
        setValue("companyOrDeal", undefined)
    }

    async function search(name: string | undefined): Promise<{ label: string, value: CompanyOrDealType }[]> {
        let companiesOrDeals: { label: string, value: CompanyOrDealType }[] = []
        if (name === undefined || name.length < 3) {
            return companiesOrDeals
        }
        if (type === "COMPANY") {
            companiesOrDeals = (await fetchClient.companyApi.getAllPaginated(
                CompanySortOpenApi.ModifiedDescending,
                { legalName: name },
                {
                    page: 1,
                    elementsPerPage: 999
                }
            )).elements.map(company => ({
                type: "COMPANY",
                company
            } as CompanyOrDealTypeCompany)).map(company => ({
                label: `${company.company.legalName} (${company.company.id})`,
                value: company
            }))
        }
        if (type === "DEAL") {
            companiesOrDeals = (await fetchClient.dealApi.getAllPaginated(
                DealSortOpenApi.ModifiedDescending,
                { name: name },
                {
                    page: 1,
                    elementsPerPage: 999
                }
            )).elements.map(deal => ({
                type: "DEAL",
                deal
            } as CompanyOrDealTypeDeal)).map(deal => ({
                label: `${deal.deal.name.completeOnboarding} (${deal.deal.singlePurposeVehicle.name})`,
                value: deal
            }))
        }
        return companiesOrDeals
    }

    return (
        <>
            <div className={styles.type}>
                <button
                    type="button"
                    onClick={() => setTypeAndReset("COMPANY")}
                    className={type === "COMPANY" ? styles.active : ""}
                >
                    Company
                </button>
                <button
                    type="button"
                    onClick={() => setTypeAndReset("DEAL")}
                    className={type === "DEAL" ? styles.active : ""}
                >
                    Deal
                </button>
            </div>
            <div className={styles.content}>
                <Controller
                    name="companyOrDeal"
                    control={control}
                    render={({ field: { onChange } }) => {
                        const companyOrDeal = watch("companyOrDeal")
                        return (
                            <AsyncSelect
                                placeholder={`Search by ${type === "COMPANY" ? "company" : "deal"} name`}
                                value={companyOrDeal ? {
                                    label: assembleLabel(companyOrDeal),
                                    value: companyOrDeal
                                } : undefined}
                                cacheOptions
                                loadOptions={search}
                                onChange={val => onChange(val?.value)}
                                key={`async-select-${type}`}
                            />
                        )
                    }}
                    rules={{
                        required: "Company or deal is required."
                    }}
                />
                <FormError field="companyOrDeal" errors={errors}/>
            </div>
        </>
    )
}

export default UpdateCompanyOrDeal

function assembleLabel(companyOrDeal: CompanyOrDealType): string {
    if (companyOrDeal.type === "COMPANY") {
        return `${companyOrDeal.company.legalName} (${companyOrDeal.company.id})`
    }
    return `${companyOrDeal.deal.name.completeOnboarding} (${companyOrDeal.deal.singlePurposeVehicle.name})`
}