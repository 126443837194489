export const normalizeValues = (obj: any) => {
    if (obj !== null && obj !== undefined) {
        Object.keys(obj).forEach(k => {
            if (typeof obj[k] == "object") {
                obj[k] = normalizeValues(obj[k])
            }
            else if (obj[k] === "") {
                obj[k] = undefined
            }
        })
    }
    return obj
}

export function preventSubmissionOnEnter(event: any) {
    if (event.key === "Enter") {
        event.preventDefault()
    }
}

export function isEmpty(x: any): boolean {
    return typeof x === "string"
        ? x === ""
        : x === undefined
}