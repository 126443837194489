import {ResponseWithType} from "../response/response.type"

export class ValidationErrors implements ResponseWithType {
    private readonly _errors: string[]

    constructor(errors: string[]) {
        this._errors = errors
    }

    get errors(): string[] {
        return this._errors
    }

    public get type(): "VALIDATION_ERRORS" {
        return "VALIDATION_ERRORS"
    }
}