import {DocumentOpenApi} from "../../generated"
import {DocumentType} from "./document.type"
import {mapFileToOpenApi, mapOpenApiToFile} from "../file/file.mapper"

export function mapOpenApiToDocument(document: DocumentOpenApi): DocumentType {
    return {
        id: document.id,
        created: document.created,
        modified: document.modified,
        documentId: document.documentId,
        name: document.name,
        completed: document.completed,
        dispatched: document.dispatched,
        signed: document.signed,
        file: document.file && mapOpenApiToFile(document.file)
    }
}

export function mapDocumentToOpenApi(document: DocumentType): DocumentOpenApi {
    return {
        id: document.id,
        created: document.created,
        modified: document.modified,
        documentId: document.documentId,
        name: document.name,
        completed: document.completed,
        dispatched: document.dispatched,
        signed: document.signed,
        file: document.file && mapFileToOpenApi(document.file)
    }
}