/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvestorOnboardingStageWrapperOpenApi } from './InvestorOnboardingStageWrapperOpenApi';
import {
    InvestorOnboardingStageWrapperOpenApiFromJSON,
    InvestorOnboardingStageWrapperOpenApiFromJSONTyped,
    InvestorOnboardingStageWrapperOpenApiToJSON,
} from './InvestorOnboardingStageWrapperOpenApi';
import type { InvestorWithDetailsOpenApi } from './InvestorWithDetailsOpenApi';
import {
    InvestorWithDetailsOpenApiFromJSON,
    InvestorWithDetailsOpenApiFromJSONTyped,
    InvestorWithDetailsOpenApiToJSON,
} from './InvestorWithDetailsOpenApi';
import type { SyndicateForPresentationOpenApi } from './SyndicateForPresentationOpenApi';
import {
    SyndicateForPresentationOpenApiFromJSON,
    SyndicateForPresentationOpenApiFromJSONTyped,
    SyndicateForPresentationOpenApiToJSON,
} from './SyndicateForPresentationOpenApi';

/**
 * 
 * @export
 * @interface InvestorOnboardingOpenApi
 */
export interface InvestorOnboardingOpenApi {
    /**
     * 
     * @type {boolean}
     * @memberof InvestorOnboardingOpenApi
     */
    blocked: boolean;
    /**
     * 
     * @type {InvestorWithDetailsOpenApi}
     * @memberof InvestorOnboardingOpenApi
     */
    investorWithDetails?: InvestorWithDetailsOpenApi;
    /**
     * 
     * @type {InvestorOnboardingStageWrapperOpenApi}
     * @memberof InvestorOnboardingOpenApi
     */
    stage: InvestorOnboardingStageWrapperOpenApi;
    /**
     * 
     * @type {Array<SyndicateForPresentationOpenApi>}
     * @memberof InvestorOnboardingOpenApi
     */
    syndicatesWithPendingInvitation: Array<SyndicateForPresentationOpenApi>;
}

/**
 * Check if a given object implements the InvestorOnboardingOpenApi interface.
 */
export function instanceOfInvestorOnboardingOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "blocked" in value;
    isInstance = isInstance && "stage" in value;
    isInstance = isInstance && "syndicatesWithPendingInvitation" in value;

    return isInstance;
}

export function InvestorOnboardingOpenApiFromJSON(json: any): InvestorOnboardingOpenApi {
    return InvestorOnboardingOpenApiFromJSONTyped(json, false);
}

export function InvestorOnboardingOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorOnboardingOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blocked': json['blocked'],
        'investorWithDetails': !exists(json, 'investorWithDetails') ? undefined : InvestorWithDetailsOpenApiFromJSON(json['investorWithDetails']),
        'stage': InvestorOnboardingStageWrapperOpenApiFromJSON(json['stage']),
        'syndicatesWithPendingInvitation': ((json['syndicatesWithPendingInvitation'] as Array<any>).map(SyndicateForPresentationOpenApiFromJSON)),
    };
}

export function InvestorOnboardingOpenApiToJSON(value?: InvestorOnboardingOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blocked': value.blocked,
        'investorWithDetails': InvestorWithDetailsOpenApiToJSON(value.investorWithDetails),
        'stage': InvestorOnboardingStageWrapperOpenApiToJSON(value.stage),
        'syndicatesWithPendingInvitation': ((value.syndicatesWithPendingInvitation as Array<any>).map(SyndicateForPresentationOpenApiToJSON)),
    };
}

