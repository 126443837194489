/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UpdateSortOpenApi = {
    DateAscending: 'DATE_ASCENDING',
    DateDescending: 'DATE_DESCENDING',
    ModifiedAscending: 'MODIFIED_ASCENDING',
    ModifiedDescending: 'MODIFIED_DESCENDING'
} as const;
export type UpdateSortOpenApi = typeof UpdateSortOpenApi[keyof typeof UpdateSortOpenApi];


export function UpdateSortOpenApiFromJSON(json: any): UpdateSortOpenApi {
    return UpdateSortOpenApiFromJSONTyped(json, false);
}

export function UpdateSortOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSortOpenApi {
    return json as UpdateSortOpenApi;
}

export function UpdateSortOpenApiToJSON(value?: UpdateSortOpenApi | null): any {
    return value as any;
}

