import React, {FunctionComponent} from "react"
import {FieldErrors} from "react-hook-form"
import formStyles from "./form.module.css"

type FormErrorProps = {
    field: string
    errors: FieldErrors
}

const FormError: FunctionComponent<FormErrorProps> = ({ field, errors }) => {
    const message = access(field, errors)?.message

    if (message !== undefined) {
        return (
            <div className={formStyles.error}>
                {typeof message === "string" ? message : "Invalid."}
            </div>
        )
    }
    return <></>
}

export default FormError

const access = (field: string, object: any) => {
    return field
        .split('.')
        .reduce((o, i) => o !== undefined && o[i], object)
}