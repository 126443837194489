import React, {FunctionComponent} from "react"
import {
    BoldItalicUnderlineToggles,
    CreateLink,
    diffSourcePlugin,
    linkDialogPlugin,
    linkPlugin,
    listsPlugin,
    ListsToggle,
    MDXEditor,
    toolbarPlugin,
    UndoRedo
} from "@mdxeditor/editor"
import "@mdxeditor/editor/style.css"
import FormError from "../form/error.component"
import {FieldErrors} from "react-hook-form"
import styles from "./editor.module.sass"

type MarkdownEditorProps = {
    label: string
    fieldId: string
    markdown: string
    onChange: (val: string) => void
    errors: FieldErrors
}

const MarkdownEditor: FunctionComponent<MarkdownEditorProps> = ({
    label,
    fieldId,
    markdown,
    onChange,
    errors
}) => {
    return (
        <div className={styles.wrapper}>
            <label>
                <span>{label}</span>
            </label>
            <div className={styles.editor}>
                <MDXEditor
                    markdown={markdown || ""}
                    plugins={[
                        linkPlugin(),
                        linkDialogPlugin(),
                        listsPlugin(),
                        diffSourcePlugin(),
                        toolbarPlugin({
                            toolbarContents: () => (
                                <>
                                    {" "}
                                    <UndoRedo/>
                                    <BoldItalicUnderlineToggles/>
                                    <CreateLink/>
                                    <ListsToggle options={["bullet", "number"]}/>
                                </>
                            )
                        })
                    ]}
                    onChange={onChange}
                />
            </div>
            <FormError field={fieldId} errors={errors}/>
        </div>
    )
}

export default MarkdownEditor