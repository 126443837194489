/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DealIndustryOpenApi = {
    Advertising: 'ADVERTISING',
    AgriculturalTech: 'AGRICULTURAL_TECH',
    Ai: 'AI',
    ClimateTech: 'CLIMATE_TECH',
    Commerce: 'COMMERCE',
    Consumer: 'CONSUMER',
    DeepTech: 'DEEP_TECH',
    Education: 'EDUCATION',
    Entertainment: 'ENTERTAINMENT',
    Fintech: 'FINTECH',
    FoodAndRestaurantTech: 'FOOD_AND_RESTAURANT_TECH',
    Fund: 'FUND',
    Health: 'HEALTH',
    HospitalityAndPropTech: 'HOSPITALITY_AND_PROP_TECH',
    MobilityAndLogistics: 'MOBILITY_AND_LOGISTICS',
    Others: 'OTHERS',
    Saas: 'SAAS'
} as const;
export type DealIndustryOpenApi = typeof DealIndustryOpenApi[keyof typeof DealIndustryOpenApi];


export function DealIndustryOpenApiFromJSON(json: any): DealIndustryOpenApi {
    return DealIndustryOpenApiFromJSONTyped(json, false);
}

export function DealIndustryOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealIndustryOpenApi {
    return json as DealIndustryOpenApi;
}

export function DealIndustryOpenApiToJSON(value?: DealIndustryOpenApi | null): any {
    return value as any;
}

