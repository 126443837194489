/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CollectionPaymentTypeOpenApi = {
    Inter: 'INTER',
    Local: 'LOCAL',
    Swift: 'SWIFT'
} as const;
export type CollectionPaymentTypeOpenApi = typeof CollectionPaymentTypeOpenApi[keyof typeof CollectionPaymentTypeOpenApi];


export function CollectionPaymentTypeOpenApiFromJSON(json: any): CollectionPaymentTypeOpenApi {
    return CollectionPaymentTypeOpenApiFromJSONTyped(json, false);
}

export function CollectionPaymentTypeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectionPaymentTypeOpenApi {
    return json as CollectionPaymentTypeOpenApi;
}

export function CollectionPaymentTypeOpenApiToJSON(value?: CollectionPaymentTypeOpenApi | null): any {
    return value as any;
}

