/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DealOutcomeOpenApi } from './DealOutcomeOpenApi';
import {
    DealOutcomeOpenApiFromJSON,
    DealOutcomeOpenApiFromJSONTyped,
    DealOutcomeOpenApiToJSON,
} from './DealOutcomeOpenApi';

/**
 * 
 * @export
 * @interface DealSetOutcomeRequestOpenApi
 */
export interface DealSetOutcomeRequestOpenApi {
    /**
     * 
     * @type {DealOutcomeOpenApi}
     * @memberof DealSetOutcomeRequestOpenApi
     */
    outcome: DealOutcomeOpenApi;
}

/**
 * Check if a given object implements the DealSetOutcomeRequestOpenApi interface.
 */
export function instanceOfDealSetOutcomeRequestOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "outcome" in value;

    return isInstance;
}

export function DealSetOutcomeRequestOpenApiFromJSON(json: any): DealSetOutcomeRequestOpenApi {
    return DealSetOutcomeRequestOpenApiFromJSONTyped(json, false);
}

export function DealSetOutcomeRequestOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealSetOutcomeRequestOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'outcome': DealOutcomeOpenApiFromJSON(json['outcome']),
    };
}

export function DealSetOutcomeRequestOpenApiToJSON(value?: DealSetOutcomeRequestOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'outcome': DealOutcomeOpenApiToJSON(value.outcome),
    };
}

