import {CollectionApiClient, CollectionDeviationStateOpenApi, ResponseError} from "../../generated"
import {CollectionAccountType} from "./account/collection-account.type"
import {mapOpenApiToCollectionAccount} from "./account/collection-account.mapper"
import {CollectionType} from "./collection.type"
import {mapOpenApiToCollection} from "./collection.mapper"
import {CollectionDeviationType} from "./deviation/collection-deviation.type"
import {mapOpenApiToCollectionDeviation} from "./deviation/collection-deviation.mapper"

export class CollectionApi {

    constructor(private apiClient: CollectionApiClient) {
    }

    public async getAllCollectionsForInvestmentId(investmentId: string): Promise<CollectionType[]> {
        return (await this.apiClient.collectionGetAllForInvestmentId({ investmentId }))
            .map(c => mapOpenApiToCollection(c))
    }

    public async getCollectionAccountForInvestmentId(investmentId: string): Promise<CollectionAccountType | undefined> {
        try {
            const collectionAccount = await this.apiClient.collectionAccountGetForInvestmentId({ investmentId })
            return mapOpenApiToCollectionAccount(collectionAccount)
        }
        catch (err) {
            if (err instanceof ResponseError && (err as ResponseError).response.status === 404) {
                return undefined
            }
            else {
                throw err
            }
        }
    }

    public async getCollectionDeviation(id: string): Promise<CollectionDeviationType> {
        const collectionDeviation = await this.apiClient.collectionDeviationGet({ id })
        return mapOpenApiToCollectionDeviation(collectionDeviation)
    }

    public async getAllCollectionDeviations(
        investmentId?: string,
        state?: CollectionDeviationStateOpenApi
    ): Promise<CollectionDeviationType[]> {
        return (await this.apiClient.collectionDeviationGetAll({ investmentId, state }))
            .map(d => mapOpenApiToCollectionDeviation(d))
    }

    public async updateCollectionDeviationState(id: string, state: CollectionDeviationStateOpenApi): Promise<CollectionDeviationType> {
        const collectionDeviation = await this.apiClient.collectionDeviationUpdate({ id, collectionDeviationUpdateRequestOpenApi: { state } })
        return mapOpenApiToCollectionDeviation(collectionDeviation)
    }

}