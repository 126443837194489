import React, {useContext, useEffect, useState} from "react"
import {Navigate, useParams} from "react-router-dom"
import {FetchClient} from "../../../../tech/fetch/client"
import FetchContext from "../../../../tech/fetch/fetch.context"
import {
    ComponentState,
    ComponentStateErrorAction,
    ComponentStateErrorLoading,
    ComponentStateLoaded,
    ComponentStateLoading,
    ComponentStateLoadingWithData,
    ComponentStateSuccess
} from "../../../../tech/state/state.type"
import {DealUnpublishComponentData} from "./data.type"
import {match, P} from "ts-pattern"
import Alert from "../../../../tech/alert/alert.component"
import {AlertType} from "../../../../tech/alert/type.enum"
import LoadingDots from "../../../../tech/loading/dots/dots.component"
import Section from "../../../../tech/section/section.component"
import Button from "../../../../tech/button/button.component"
import {ButtonStyle} from "../../../../tech/button/style.enum"
import {DEAL} from "../../../../paths"

const DealUnpublish = () => {
    let { id } = useParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<ComponentState<DealUnpublishComponentData>>(new ComponentStateLoading())

    useEffect(() => {
        const fetch = async () => {
            try {
                const [
                    fetchedDeal,
                    fetchedInvestments
                ] = await Promise.all([
                    fetchClient.dealApi.get(id!),
                    fetchClient.investmentApi.search(id!)
                ])
                setState(new ComponentStateLoaded<DealUnpublishComponentData>({
                    deal: fetchedDeal,
                    investments: fetchedInvestments
                }))
            } catch (err) {
                console.error(err)
                setState(new ComponentStateErrorLoading())
            }
        }
        fetch()
    }, [fetchClient, id])

    const unpublish = async () => {
        if (state.type !== "LOADED" && state.type !== "ERROR_ACTION") {
            throw new Error("Not allowed.")
        }
        setState(new ComponentStateLoadingWithData(state.data))
        const res = await fetchClient.dealApi.unpublish(id!)
        match(res)
            .with(
                { type: "RESPONSE" },
                () => setState(new ComponentStateSuccess(state.data))
            )
            .with(
                P.union({ type: "RESPONSE_ERROR" }, { type: "VALIDATION_ERRORS" }),
                () => setState(new ComponentStateErrorAction(state.data))
            )
            .exhaustive()
    }

    return match(state)
        .with(
            { type: "LOADED" },
            (s) => s.data.deal.published ? (
                <Section>
                    <div className="mb-5">
                        Do you really want to unpublish the deal <strong>{s.data.deal.name.completeOnboarding}</strong> with id <strong>{s.data.deal.id}</strong>?
                    </div>
                    <div className="mb-5">
                        The deal currently has <strong>{s.data.investments.length} investments</strong> and unpublishing it might break ongoing investment processes.
                    </div>
                    <Button
                        title="Unpublish anyway"
                        style={ButtonStyle.DELETE}
                        onClick={unpublish}
                        type="button"
                    />
                </Section>
            ) : <Navigate to={DEAL(s.data.deal.id!)}/>
        )
        .with(
            P.union({ type: "ERROR_ACTION" }, { type: "ERROR_LOADING" }),
            () => (
                <Alert
                    type={AlertType.ERROR}
                    text="An error occurred. Please try again or contact the tech team."
                />
            )
        )
        .with(
            P.union({ type: "LOADING" }, { type: "LOADING_WITH_DATA" }),
            () => <LoadingDots/>
        )
        .with(
            { type: "SUCCESS" },
            (s) => <Navigate to={DEAL(s.data.deal.id!)}/>
        )
        .exhaustive()
}

export default DealUnpublish