import React, {useContext, useEffect, useState} from "react"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {useNavigate, useParams} from "react-router-dom"
import {InvestmentType} from "./investment.type"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import tableStyles from "../../tech/table/table.module.css"
import DocumentsTable from "../document/table/table.component"
import {CollectionAccountType} from "../collection/account/collection-account.type"
import CollectionAccount from "../collection/account/collection-account.component"
import {CollectionType} from "../collection/collection.type"
import CollectionsTable from "../collection/table.component"
import CollectionDeviationsTable from "../collection/deviation/table.component"
import {CollectionDeviationType} from "../collection/deviation/collection-deviation.type"
import InvestmentTable from "./table.component"
import {ManualReconciliationLogType} from "../reconciliation/log/manual-reconciliation-log.type"
import ManualReconciliationLogTable from "../reconciliation/log/table.component"
import Button from "../../tech/button/button.component"
import {ButtonStyle} from "../../tech/button/style.enum"
import {MANUAL_RECONCILIATION_LOG_NEW} from "../../paths"
import {DealPaymentProcessingTypeOpenApi} from "../../generated"
import styles from "./single.module.sass"

const InvestmentSingle = () => {
    let { id } = useParams()
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [investment, setInvestment] = useState<InvestmentType>()
    const [collectionAccount, setCollectionAccount] = useState<CollectionAccountType>()
    const [collectionDeviations, setCollectionDeviations] = useState<CollectionDeviationType[]>([])
    const [collections, setCollections] = useState<CollectionType[]>([])
    const [manualReconciliationLogs, setManualReconciliationLogs] = useState<ManualReconciliationLogType[]>()

    useEffect(() => {
        const fetch = async () => {
            try {
                const [
                    fetchedCollectionAccount,
                    fetchedCollectionDeviations,
                    fetchedCollections,
                    fetchedInvestment,
                    fetchedManualReconciliationLogs
                ] = await Promise.all([
                    fetchClient.collectionApi.getCollectionAccountForInvestmentId(id!),
                    fetchClient.collectionApi.getAllCollectionDeviations(id!, undefined),
                    fetchClient.collectionApi.getAllCollectionsForInvestmentId(id!),
                    fetchClient.investmentApi.get(id!),
                    fetchClient.reconciliationApi.getAllManualReconciliationLogsByInvestmentId(id!)
                ])
                setCollectionAccount(fetchedCollectionAccount)
                setCollectionDeviations(fetchedCollectionDeviations)
                setCollections(fetchedCollections)
                setInvestment(fetchedInvestment)
                setManualReconciliationLogs(fetchedManualReconciliationLogs)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && investment && (
                <Section>
                    <SectionHeading
                        title={`Investment: '${investment.investor.person?.fullName}' invested in '${investment.deal.name.completeOnboarding}' (${investment.id})`}
                    />
                    <InvestmentTable investment={investment}/>

                    <h3 className={tableStyles.separatingHeadline}>Documents</h3>
                    <DocumentsTable documents={investment.documents}/>

                    {investment.deal.paymentProcessingType === DealPaymentProcessingTypeOpenApi.ManualReconciliation ? (
                        <>
                            <h3 className={tableStyles.separatingHeadline}>Manual reconciliation logs</h3>
                            <div className={styles.manualReconciliationLogs}>
                                {manualReconciliationLogs && manualReconciliationLogs.length > 0 ? (
                                    <ManualReconciliationLogTable
                                        logs={manualReconciliationLogs}
                                        showInvestment={false}
                                    />
                                ) : (
                                    <>No logs available.</>
                                )}
                            </div>
                            <Button
                                title="New manual reconciliation log"
                                style={ButtonStyle.SECONDARY}
                                type="button"
                                onClick={() => navigate(MANUAL_RECONCILIATION_LOG_NEW)}
                            />
                        </>
                    ) : (
                        <>
                            <h3 className={tableStyles.separatingHeadline}>Collection account</h3>
                            <CollectionAccount collectionAccount={collectionAccount}/>

                            <h3 className={tableStyles.separatingHeadline}>Collections</h3>
                            <CollectionsTable collections={collections} />

                            <h3 className={tableStyles.separatingHeadline}>Collection deviations</h3>
                            <CollectionDeviationsTable collectionDeviations={collectionDeviations} />
                        </>
                    )}
                </Section>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load investment and/or related data."
                />
            )}
        </>
    )
}

export default InvestmentSingle