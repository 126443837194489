import React, {FunctionComponent, useContext, useEffect, useState} from "react"
import {ArrowDownTrayIcon} from "@heroicons/react/24/outline"
import {FileType} from "../file.type"
import styles from "./download.module.sass"
import LoadingCircle from "../../../tech/loading/circle/circle.component"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"

type FileDownloadProps = {
    file: FileType
}

const FileDownload: FunctionComponent<FileDownloadProps> = ({ file }) => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [privateFileUrl, setPrivateFileUrl] = useState<string>()

    useEffect(() => {
        const fetch = async () => setPrivateFileUrl((await fetchClient.fileApi.getDownloadLink(file.id)).link)
        if (file.source.type === "IMGIX_SCR_SET") {
            fetch()
        }
    }, [fetchClient, file])

    if (file.source.type === "IMGIX_SCR_SET" && privateFileUrl === undefined) {
        return (
            <LoadingCircle
                width={24}
                height={24}
            />
        )
    }

    return (
        <div
            className={styles.download}
            onClick={() => openInNewTab(privateFileUrl || file.source.src)}
        >
            <ArrowDownTrayIcon className={styles.icon}/>
        </div>
    )
}

export default FileDownload

function openInNewTab(url: string) {
    window.open(url, "_blank", "noreferrer")
}