import React, {FunctionComponent, ReactNode, useState} from "react"
import Header from "../header/header.component"
import Sidebar from "../sidebar/sidebar.component"
import styles from "./layout.module.css"

const Layout: FunctionComponent<{ children: ReactNode }> = ({children}) => {
    const [sidebarHidden, setSidebarHidden] = useState<boolean>(true)

    const toggleSidebar = () => setSidebarHidden(!sidebarHidden)

    return (
        <div className={styles.layout}>
            <Sidebar
                hidden={sidebarHidden}
                toggle={toggleSidebar}
            />
            <div className={styles.wrapper}>
                <Header toggleSidebar={toggleSidebar} />
                <main className={styles.content}>
                    {children}
                </main>
            </div>
        </div>
    )
}

export default Layout