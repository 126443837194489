import React, {FunctionComponent, ReactNode, useEffect} from "react"
import styles from "./modal.module.sass"
import {XMarkIcon} from "@heroicons/react/20/solid"

type ModalProps = {
    children: ReactNode
    close?: () => void
}

const Modal: FunctionComponent<ModalProps> = ({
    children,
    close
}) => {
    function closeInternal() {
        document.getElementsByTagName("body")[0].style.overflow = "scroll"
        if (close) close()
    }

    useEffect(() => {
        document.getElementsByTagName("body")[0].style.overflow = "hidden"
        return () => {
            document.getElementsByTagName("body")[0].style.overflow = "scroll"
        }
    }, [])

    return (
        <>
            <div className={styles.overlay}>
                <div className={styles.close}>
                    <XMarkIcon
                        className="w-10 h-10 text-teal-400"
                        onClick={closeInternal}
                    />
                </div>
            </div>
            <div className={styles.modal}>
                <div>
                    {children}
                </div>
            </div>
        </>
    )
}

export default Modal