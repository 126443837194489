import {ActionsFilterType, ActionsFilterTypeAndValueType} from "../../tech/actions/filter/filter.type"
import {getFilter, getFilterValue} from "../../tech/actions/filter/filter.util"
import {CompanyFiltersOpenApi} from "../../generated"

export type CompanyFilterType = "ID"
    | "LEGAL_NAME"
    | "CONTACT_ID"
    | "CONTACT_NAME"
    | "DEAL_ID"
    | "DEAL_NAME"

export function assembleFilterOptions(): ActionsFilterType<CompanyFilterType>[] {
    return [
        {
            type: "ID",
            label: "ID",
            value: "STRING"
        },
        {
            type: "LEGAL_NAME",
            label: "Legal Name",
            value: "STRING"
        },
        {
            type: "CONTACT_ID",
            label: "Contact ID",
            value: "STRING"
        },
        {
            type: "CONTACT_NAME",
            label: "Contact Name",
            value: "STRING"
        },
        {
            type: "DEAL_ID",
            label: "Deal ID",
            value: "STRING"
        },
        {
            type: "DEAL_NAME",
            label: "Deal Name",
            value: "STRING"
        },
    ]
}

export function getCurrentFilter(
    filters: ActionsFilterType<CompanyFilterType>[],
    searchParams: URLSearchParams
): ActionsFilterTypeAndValueType<CompanyFilterType> | undefined {
    const foundFilter = filters.find(f => f.type === getFilter(searchParams))
    const foundFilterValue = getFilterValue(searchParams)
    if (foundFilter && foundFilterValue) {
        return {
            type: foundFilter.type,
            value: foundFilterValue
        }
    }
    return undefined
}

export function mapToCompanyFiltersOpenApi(filter: ActionsFilterTypeAndValueType<CompanyFilterType> | undefined): CompanyFiltersOpenApi | undefined {
    if (filter === undefined) return undefined

    switch (filter.type) {
        case "ID":
            return { id: filter.value }
        case "LEGAL_NAME":
            return { legalName: filter.value }
        case "CONTACT_ID":
            return { contactId: filter.value }
        case "CONTACT_NAME":
            return { contactName: filter.value }
        case "DEAL_ID":
            return { dealId: filter.value }
        case "DEAL_NAME":
            return { dealName: filter.value }
    }
}