import React, {FunctionComponent, useState} from "react"
import FileUpload from "./upload/upload.component"
import FileSelect, {FileSelectSearchType} from "./select/select.component"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {FolderArrowDownIcon, PlusIcon} from "@heroicons/react/16/solid"
import {FileTypeOpenApi, MimeTypeOpenApi} from "../../../generated"
import {FileType} from "../file.type"
import FileUploadCurrentFilePreview from "./current-file/current-file.component"
import Modal from "../../../tech/modal/modal.component"
import styles from "./upload-select.module.sass"

type FileUploadSelectProps = {
    accept: MimeTypeOpenApi[]
    currentFile?: FileType
    defaultFileType: FileTypeOpenApi
    filesProvider: (searchType: FileSelectSearchType, searchValue: string) => Promise<FileType[]>
    setFormValue: (value: FileType) => void
}

const FileUploadSelect: FunctionComponent<FileUploadSelectProps> =({
    accept,
    currentFile,
    defaultFileType,
    filesProvider,
    setFormValue
}) => {
    const [active, setActive] = useState<"UPLOAD" | "SELECT">("UPLOAD")
    const [opened, setOpened] = useState<boolean>(false)

    function open() {
        setOpened(true)
    }

    function close() {
        setOpened(false)
    }

    return (
        <>
            <div className={styles.upload}>
                <FileUploadCurrentFilePreview currentFile={currentFile}/>
                <Button
                    title={currentFile ? <>Replace&nbsp;current&nbsp;file</> : <>Upload&nbsp;or&nbsp;select&nbsp;a&nbsp;file</>}
                    style={ButtonStyle.SECONDARY}
                    type="button"
                    onClick={open}
                    icon={{
                        elem: currentFile ? <FolderArrowDownIcon className="w-4 h-4 mr-1"/> : <PlusIcon className="w-4 h-4 mr-1"/>,
                        left: true
                    }}
                    small
                />
            </div>
            {opened && (
                <Modal close={close}>
                    <>
                        <div className={styles.head}>
                            <div
                                className={active === "UPLOAD" ? styles.active : ""}
                                onClick={() => setActive("UPLOAD")}
                            >
                                Upload
                            </div>
                            <div
                                className={active === "SELECT" ? styles.active : ""}
                                onClick={() => setActive("SELECT")}
                            >
                                Select
                            </div>
                        </div>
                        <div className={styles.body}>
                            {active === "UPLOAD" ? (
                                <FileUpload
                                    accept={accept}
                                    close={close}
                                    defaultFileType={defaultFileType}
                                    setFormValue={setFormValue}
                                />
                            ) : (
                                <FileSelect
                                    close={close}
                                    filesProvider={filesProvider}
                                    setFormValue={setFormValue}
                                />
                            )}
                        </div>
                    </>
                </Modal>
            )}
        </>
    )
}

export default FileUploadSelect