/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DealGeographyOpenApi,
  ValidationResponseOpenApi,
} from '../models';
import {
    DealGeographyOpenApiFromJSON,
    DealGeographyOpenApiToJSON,
    ValidationResponseOpenApiFromJSON,
    ValidationResponseOpenApiToJSON,
} from '../models';

export interface DealGeographyCreateRequest {
    dealGeographyOpenApi?: DealGeographyOpenApi;
}

export interface DealGeographyDeleteRequest {
    id: string;
}

export interface DealGeographyGetRequest {
    id: string;
}

export interface DealGeographyUpdateRequest {
    id: string;
    dealGeographyOpenApi?: DealGeographyOpenApi;
}

/**
 * 
 */
export class DealGeographyApiClient extends runtime.BaseAPI {

    /**
     * Create new deal geography
     */
    async dealGeographyCreateRaw(requestParameters: DealGeographyCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealGeographyOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal-geography`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DealGeographyOpenApiToJSON(requestParameters.dealGeographyOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealGeographyOpenApiFromJSON(jsonValue));
    }

    /**
     * Create new deal geography
     */
    async dealGeographyCreate(requestParameters: DealGeographyCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealGeographyOpenApi> {
        const response = await this.dealGeographyCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete deal geography by id
     */
    async dealGeographyDeleteRaw(requestParameters: DealGeographyDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealGeographyDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal-geography/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete deal geography by id
     */
    async dealGeographyDelete(requestParameters: DealGeographyDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dealGeographyDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get deal geography by id
     */
    async dealGeographyGetRaw(requestParameters: DealGeographyGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealGeographyOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealGeographyGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal-geography/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealGeographyOpenApiFromJSON(jsonValue));
    }

    /**
     * Get deal geography by id
     */
    async dealGeographyGet(requestParameters: DealGeographyGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealGeographyOpenApi> {
        const response = await this.dealGeographyGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all deal geographies
     */
    async dealGeographyGetAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DealGeographyOpenApi>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal-geography`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DealGeographyOpenApiFromJSON));
    }

    /**
     * Get all deal geographies
     */
    async dealGeographyGetAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DealGeographyOpenApi>> {
        const response = await this.dealGeographyGetAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update deal geography
     */
    async dealGeographyUpdateRaw(requestParameters: DealGeographyUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealGeographyOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealGeographyUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal-geography/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DealGeographyOpenApiToJSON(requestParameters.dealGeographyOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealGeographyOpenApiFromJSON(jsonValue));
    }

    /**
     * Update deal geography
     */
    async dealGeographyUpdate(requestParameters: DealGeographyUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealGeographyOpenApi> {
        const response = await this.dealGeographyUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
