import React, {FunctionComponent, useCallback, useContext, useState} from "react"
import {useDropzone} from "react-dropzone"
import {FileType} from "../file.type"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import styles from "./upload.module.css"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import {FileTypeOpenApi} from "../../../generated"

type FileUploadProps = {
    id: string
    updateFile: (file: FileType) => void
}

const FileUpload: FunctionComponent<FileUploadProps> = ({ id, updateFile }) => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"NONE" | "LOADING" | "SUCCESS" | "ERROR">("NONE")

    const onDropAccepted = useCallback(async (acceptedFiles: File[]) => {
        setState("LOADING")
        if (acceptedFiles.length === 0 || acceptedFiles.length > 1) {
            console.error("Too many filed")
            setState("ERROR")
        }
        updateFile(await fetchClient.fileApi.upload(acceptedFiles[0], FileTypeOpenApi.BackofficePublicFile))
        setState("SUCCESS")
    }, [fetchClient, updateFile])
    const {getRootProps, getInputProps} = useDropzone({ onDropAccepted })

    return (
        <div>
            {state === "NONE" && (
                <div className={styles.upload}>
                    <div {...getRootProps({className: 'dropzone'})}>
                        <div className="space-y-1 text-center">
                            <svg
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                            >
                                <path
                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="flex text-sm text-gray-600">
                                <label
                                    htmlFor={id}
                                    className={styles.labelButton}
                                >
                                    <span>Upload a file or drag and drop</span>
                                    <input
                                        id={id}
                                        type="file"
                                        className="sr-only"
                                        {...getInputProps()}
                                    />
                                </label>
                            </div>
                            <p className="text-xs text-gray-500">JPG, PDF, PNG, SVG up to 20MB</p>
                        </div>
                    </div>
                </div>
            )}
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Upload failed"
                />
            )}
        </div>
    )
}

export default FileUpload