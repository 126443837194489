/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ManualReconciliationLogSortOpenApi = {
    CreatedAscending: 'CREATED_ASCENDING',
    CreatedDescending: 'CREATED_DESCENDING',
    ModifiedAscending: 'MODIFIED_ASCENDING',
    ModifiedDescending: 'MODIFIED_DESCENDING'
} as const;
export type ManualReconciliationLogSortOpenApi = typeof ManualReconciliationLogSortOpenApi[keyof typeof ManualReconciliationLogSortOpenApi];


export function ManualReconciliationLogSortOpenApiFromJSON(json: any): ManualReconciliationLogSortOpenApi {
    return ManualReconciliationLogSortOpenApiFromJSONTyped(json, false);
}

export function ManualReconciliationLogSortOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualReconciliationLogSortOpenApi {
    return json as ManualReconciliationLogSortOpenApi;
}

export function ManualReconciliationLogSortOpenApiToJSON(value?: ManualReconciliationLogSortOpenApi | null): any {
    return value as any;
}

