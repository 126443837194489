import {Navigate, useParams} from "react-router-dom"
import React, {useContext, useEffect, useRef, useState} from "react"
import {FetchClient} from "../../../../tech/fetch/client"
import FetchContext from "../../../../tech/fetch/fetch.context"
import {
    ComponentState,
    ComponentStateErrorAction,
    ComponentStateErrorLoading,
    ComponentStateLoaded,
    ComponentStateLoading,
    ComponentStateLoadingWithData,
    ComponentStateSuccess
} from "../../../../tech/state/state.type"
import {mapDealOutcomeToString} from "../../deal.type"
import {match, P} from "ts-pattern"
import LoadingDots from "../../../../tech/loading/dots/dots.component"
import {DEAL} from "../../../../paths"
import Alert from "../../../../tech/alert/alert.component"
import {AlertType} from "../../../../tech/alert/type.enum"
import Section from "../../../../tech/section/section.component"
import SectionHeading from "../../../../tech/section/section-heading.component"
import BackendValidationErrorsAlert from "../../../../tech/validation/alert.component"
import {DealOutcomeOpenApi} from "../../../../generated"
import Button from "../../../../tech/button/button.component"
import {ButtonStyle} from "../../../../tech/button/style.enum"
import styles from "./outcome.module.sass"
import {OutcomeData} from "./data.type"

const DealOutcome = () => {
    let { id } = useParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const outcomeSelectRef = useRef<HTMLSelectElement>(null)
    const [state, setState] = useState<ComponentState<OutcomeData>>(new ComponentStateLoading())

    useEffect(() => {
        const fetch = async () => {
            try {
                const [
                    fetchedDeal,
                    fetchedInvestments
                ] = await Promise.all([
                    fetchClient.dealApi.get(id!),
                    fetchClient.investmentApi.search(id!),
                ])
                setState(new ComponentStateLoaded<OutcomeData>({
                    deal: fetchedDeal,
                    investments: fetchedInvestments
                }))
            } catch (err) {
                console.error(err)
                setState(new ComponentStateErrorLoading())
            }
        }
        fetch()
    }, [fetchClient, id])

    const setOutcome = async () => {
        if (state.type !== "LOADED" && state.type !== "ERROR_ACTION") {
            throw new Error("Not allowed.")
        }
        setState(new ComponentStateLoadingWithData(state.data))
        const res = await fetchClient.dealApi.setOutcome(id!, outcomeSelectRef.current!.value as DealOutcomeOpenApi)
        match(res)
            .with(
                { type: "RESPONSE" },
                (r) => setState(new ComponentStateSuccess({
                    ...state.data,
                    deal: r.val
                }))
            )
            .with(
                { type: "RESPONSE_ERROR" },
                () => setState(new ComponentStateErrorAction(state.data))
            )
            .with(
                { type: "VALIDATION_ERRORS" },
                (r) => setState(new ComponentStateErrorAction(state.data, r.errors))
            )
            .exhaustive()
    }

    return match(state)
        .with(
            P.union({ type: "LOADED" }, { type: "ERROR_ACTION" }),
            (s) => {
                const unpaidInvestments = s.data.investments.filter(i => !i.paid)
                return (
                    <Section>
                        <SectionHeading title={`Set outcome for deal: ${s.data.deal.name.completeOnboarding} (${s.data.deal.id!})`}/>
                        {s.type === "ERROR_ACTION" && s.errors && <BackendValidationErrorsAlert errors={s.errors}/>}
                        {s.type === "ERROR_ACTION" && !s.errors && (
                            <Alert
                                type={AlertType.ERROR}
                                text="Failed to set outcome."
                            />
                        )}
                        {s.data.deal.outcome !== "NONE" ? (
                            <Navigate to={DEAL(s.data.deal.id!)}/>
                        ) : (
                            <>
                                Without tech support, setting the outcome is a one-way operation. Setting the outcome automatically triggers some automated processes and, above all, notifications to the affected customers. <strong>So make sure you're on the right track.</strong>
                                {unpaidInvestments.length > 0 && (
                                    <div className={styles.investments}>
                                        <strong>From {s.data.investments.length} investments in this deal {unpaidInvestments.length} of them are unpaid:</strong>
                                        <ul>
                                            {s.data.investments.map(i => (
                                                <li key={i.id}>
                                                    {i.investor.person!.fullName} (ID: {i.id})
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                <div className={styles.select}>
                                    <select ref={outcomeSelectRef}>
                                        {Object.entries(DealOutcomeOpenApi)
                                            .filter(o => o[1] !== "NONE")
                                            .map((o, index) => (
                                                <option
                                                    value={o[1]}
                                                    key={index}
                                                >
                                                    {mapDealOutcomeToString(o[1])}
                                                </option>
                                            ))
                                        }
                                    </select>
                                    <Button
                                        title="Set outcome"
                                        style={ButtonStyle.PRIMARY}
                                        onClick={setOutcome}
                                        type="button"
                                    />
                                </div>
                            </>
                        )}
                    </Section>
                )
            }
        )
        .with(
            { type: "ERROR_LOADING" },
            () => (
                <Alert
                    type={AlertType.ERROR}
                    text="Loading failed."
                />
            )
        )
        .with(
            P.union({ type: "LOADING" }, { type: "LOADING_WITH_DATA" }),
            () => <LoadingDots/>
        )
        .with(
            { type: "SUCCESS" },
            (s) => <Navigate to={DEAL(s.data.deal.id!)}/>
        )
        .exhaustive()
}

export default DealOutcome