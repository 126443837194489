import React, {FunctionComponent} from "react"
import styles from "./badge.module.sass"

type SidebarAlertBadgeProps = {
    count: number | undefined
}

const SidebarAlertBadge: FunctionComponent<SidebarAlertBadgeProps> = ({ count }) => {
    if (count === undefined || count < 1) return <></>
    return (
        <div className={styles.badge}>
            {count}
        </div>
    )
}

export default SidebarAlertBadge