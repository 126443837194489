import {FunctionComponent, useEffect, useState} from "react"
import {fetchUserAttributes, signOut} from "aws-amplify/auth"
import {Bars3Icon} from "@heroicons/react/20/solid"
import styles from "./header.module.css"

type HeaderProps = {
    toggleSidebar: () => void
}

const Header: FunctionComponent<HeaderProps> = ({ toggleSidebar }) => {
    const [email, setEmail] = useState<string>()

    useEffect(() => {
        const fetch = async () => setEmail((await fetchUserAttributes()).email)
        fetch()
    }, [])

    return (
        <header className={styles.header}>
            <div className={styles.headerLeft}>
                <Bars3Icon
                    className={styles.sidebarToggle}
                    onClick={toggleSidebar}
                />
            </div>
            <div className={styles.headerRight}>
                <div className={styles.email}>
                    {email}
                </div>
                <a
                    href="/"
                    className={styles.logout}
                    onClick={() => signOut()}
                >
                    Logout
                </a>
            </div>
        </header>
    )
}

export default Header