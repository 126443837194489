import React, {FunctionComponent} from "react"
import {DocumentIcon} from "@heroicons/react/24/outline"
import {FileType} from "../file.type"
import {formatBytes} from "../../../tech/format/bytes.util"
import {formatDatetime} from "../../../tech/format/format.util"
import FilePreviewImage from "./image.component"
import styles from "./preview.module.sass"

type FilePreviewProps = {
    file: FileType
    maxHeight: number
}

const FilePreview: FunctionComponent<FilePreviewProps> = ({ file, maxHeight }) => {
    return (
        <div className={styles.preview}>
            {file.source === undefined ? (
                <div className={styles.icon}>
                    <DocumentIcon className={styles.documentIcon}/>
                </div>
            ) : (
                <div className={styles.image}>
                    <FilePreviewImage
                        currentFile={file}
                        maxHeight={maxHeight}
                    />
                </div>
            )}
            {file.bytes !== undefined && (
                <div className={styles.dimensions}>
                    <small>
                        Size: {formatBytes(file.bytes)}
                    </small>
                </div>
            )}
            <div>
                <small>Uploaded {formatDatetime(file.created)}</small>
            </div>
        </div>
    )
}

export default FilePreview