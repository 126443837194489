/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const NotificationChannelOpenApi = {
    Mail: 'MAIL',
    Whatsapp: 'WHATSAPP'
} as const;
export type NotificationChannelOpenApi = typeof NotificationChannelOpenApi[keyof typeof NotificationChannelOpenApi];


export function NotificationChannelOpenApiFromJSON(json: any): NotificationChannelOpenApi {
    return NotificationChannelOpenApiFromJSONTyped(json, false);
}

export function NotificationChannelOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationChannelOpenApi {
    return json as NotificationChannelOpenApi;
}

export function NotificationChannelOpenApiToJSON(value?: NotificationChannelOpenApi | null): any {
    return value as any;
}

