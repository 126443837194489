/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const KycLogTypeOpenApi = {
    PassportUploaded: 'PASSPORT_UPLOADED',
    PassportRejected: 'PASSPORT_REJECTED',
    PassportConfirmed: 'PASSPORT_CONFIRMED',
    ProofOfAddressUploaded: 'PROOF_OF_ADDRESS_UPLOADED',
    ProofOfAddressRejected: 'PROOF_OF_ADDRESS_REJECTED',
    ProofOfAddressConfirmed: 'PROOF_OF_ADDRESS_CONFIRMED',
    ProofOfAiStatusUploaded: 'PROOF_OF_AI_STATUS_UPLOADED',
    ProofOfAiStatusRejected: 'PROOF_OF_AI_STATUS_REJECTED',
    ProofOfAiStatusConfirmed: 'PROOF_OF_AI_STATUS_CONFIRMED'
} as const;
export type KycLogTypeOpenApi = typeof KycLogTypeOpenApi[keyof typeof KycLogTypeOpenApi];


export function KycLogTypeOpenApiFromJSON(json: any): KycLogTypeOpenApi {
    return KycLogTypeOpenApiFromJSONTyped(json, false);
}

export function KycLogTypeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): KycLogTypeOpenApi {
    return json as KycLogTypeOpenApi;
}

export function KycLogTypeOpenApiToJSON(value?: KycLogTypeOpenApi | null): any {
    return value as any;
}

