/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DealInvestmentInitializationOpenApi
 */
export interface DealInvestmentInitializationOpenApi {
    /**
     * 
     * @type {number}
     * @memberof DealInvestmentInitializationOpenApi
     */
    amount: number;
    /**
     * 
     * @type {boolean}
     * @memberof DealInvestmentInitializationOpenApi
     */
    agreedToTerms: boolean;
}

/**
 * Check if a given object implements the DealInvestmentInitializationOpenApi interface.
 */
export function instanceOfDealInvestmentInitializationOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "agreedToTerms" in value;

    return isInstance;
}

export function DealInvestmentInitializationOpenApiFromJSON(json: any): DealInvestmentInitializationOpenApi {
    return DealInvestmentInitializationOpenApiFromJSONTyped(json, false);
}

export function DealInvestmentInitializationOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealInvestmentInitializationOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'agreedToTerms': json['agreedToTerms'],
    };
}

export function DealInvestmentInitializationOpenApiToJSON(value?: DealInvestmentInitializationOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'agreedToTerms': value.agreedToTerms,
    };
}

