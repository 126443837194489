import React, {useContext, useEffect, useState} from "react"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import {useNavigate} from "react-router-dom"
import {UserType} from "../../user/user.type"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import InvestorForm from "../form.component"
import {SubmitHandler} from "react-hook-form"
import {InvestorType} from "../investor.type"
import {INVESTOR} from "../../../paths"
import {DealGroupType} from "../../deal-group/deal-group.type"
import {InvestorGroupType} from "../../investor-group/investor-group.type"
import {normalizeValues} from "../../../tech/form/form.util"
import {match} from "ts-pattern"
import BackendValidationErrorsAlert from "../../../tech/validation/alert.component"

const InvestorNew = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const navigate = useNavigate()
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [errors, setErrors] = useState<string[]>([])
    const [usersWithoutInvestor, setUsersWithoutInvestor] = useState<UserType[]>([])
    const [dealGroups, setDealGroups] = useState<DealGroupType[]>([])
    const [investorGroups, setInvestorGroups] = useState<InvestorGroupType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const [
                    fetchedDealGroups,
                    fetchedInvestorGroups,
                    fetchedUsersWithoutInvestor,
                ] = await Promise.all([
                    fetchClient.dealGroupApi.getAll(),
                    fetchClient.investorGroupApi.getAll(),
                    fetchClient.userApi.getAllWithoutInvestor(),
                ])
                setDealGroups(fetchedDealGroups)
                setInvestorGroups(fetchedInvestorGroups)
                setUsersWithoutInvestor(fetchedUsersWithoutInvestor)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    const onSubmit: SubmitHandler<InvestorType> = async (investor) => {
        setState("LOADING")
        const result = await fetchClient.investorApi.create(normalizeValues(investor))
        match(result)
            .with(
                { type: "RESPONSE" },
                (res) => {
                    navigate(INVESTOR(res.val!.investor.id!))
                }
            )
            .with(
                { type: "RESPONSE_ERROR" },
                () => {
                    window.scrollTo({ top: 0 })
                    setState("ERROR")
                }
            )
            .with(
                { type: "VALIDATION_ERRORS" },
                (res) => {
                    window.scrollTo({ top: 0 })
                    setState("ERROR")
                    setErrors(res.errors)
                }
            )
            .exhaustive()
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {errors.length > 0 && <BackendValidationErrorsAlert errors={errors}/>}
            {state === "ERROR" && errors.length < 1 && (
                <Alert
                    type={AlertType.ERROR}
                    text="An error occurred. Please try again or contact the tech team."
                />
            )}
            {(state === "LOADED" || state === "ERROR") && (
                <Section>
                    <SectionHeading title="Create new investor for user"/>
                    <InvestorForm
                        usersWithoutInvestor={usersWithoutInvestor}
                        dealGroups={dealGroups}
                        investorGroups={investorGroups}
                        onSubmit={onSubmit}
                        submitText="Create investor"
                    />
                </Section>
            )}
        </>
    )
}

export default InvestorNew