import {FAQEntryApiClient, FaqEntryOpenApi} from "../../generated"
import {mapFaqEntryToOpenApi, mapOpenApiToFaqEntry} from "./faq-entry.mapper"
import {FaqEntryType} from "./faq-entry.type"

export class FaqEntryApi {

    constructor(private apiClient: FAQEntryApiClient) {
    }

    public async create(faqEntry: FaqEntryType): Promise<FaqEntryType> {
        const savedFaqEntry = await this.apiClient.faqEntryCreate({ faqEntryOpenApi: mapFaqEntryToOpenApi(faqEntry) })
        return mapOpenApiToFaqEntry(savedFaqEntry)
    }

    public async delete(id: string): Promise<void> {
        await this.apiClient.faqEntryDelete({ id })
    }

    public async get(id: string): Promise<FaqEntryType> {
        const faqEntry = await this.apiClient.faqEntryGet({ id })
        return mapOpenApiToFaqEntry(faqEntry)
    }

    public async getAll(): Promise<FaqEntryType[]> {
        return (await this.apiClient.faqEntryGetAll())
            .map((faqEntry: FaqEntryOpenApi) => mapOpenApiToFaqEntry(faqEntry))
    }

    public async update(id: string, faqEntry: FaqEntryType): Promise<FaqEntryType> {
        const response = await this.apiClient.faqEntryUpdate({ id, faqEntryOpenApi: mapFaqEntryToOpenApi(faqEntry) })
        return mapOpenApiToFaqEntry(response)
    }

}