import {DealPublicationValidationPreview, DealPublicationValidationPreviewItem} from "./validation.type"
import {mapOpenApiToDeal} from "../../deal.mapper"
import {
    DealPublicationValidationPreviewItemOpenApi,
    DealPublicationValidationPreviewOpenApi
} from "../../../../generated"

export function mapOpenApiToDealPublicationValidationPreview(preview: DealPublicationValidationPreviewOpenApi): DealPublicationValidationPreview {
    return {
        deal: mapOpenApiToDeal(preview.deal),
        items: preview.items.map(mapOpenApiToDealPublicationValidationPreviewItem)
    }
}

export function mapOpenApiToDealPublicationValidationPreviewItem(item: DealPublicationValidationPreviewItemOpenApi): DealPublicationValidationPreviewItem {
    return {
        message: item.message,
        type: item.type
    }
}