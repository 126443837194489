import React, {useContext, useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import Button from "../../tech/button/button.component"
import {ButtonStyle} from "../../tech/button/style.enum"
import {INVESTOR_GROUP, INVESTOR_GROUP_NEW} from "../../paths"
import tableStyles from "../../tech/table/table.module.css"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import {InvestorGroupType} from "./investor-group.type"

const InvestorGroupOverview = () => {
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [groups, setGroups] = useState<InvestorGroupType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                setGroups(await fetchClient.investorGroupApi.getAll())
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && (
                <Section>
                    <SectionHeading
                        title="Investor groups"
                        subTitle={`${groups.length} investor groups`}
                        button={<Button
                            title="New Investor Group"
                            style={ButtonStyle.SECONDARY}
                            onClick={() => navigate(INVESTOR_GROUP_NEW)}
                            type="button"
                        />}
                    />
                    <div className={tableStyles.tableWrapper}>
                        <table className={tableStyles.tablePointer}>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th>ID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {groups.map((group, i) => (
                                <tr
                                    key={i}
                                    onClick={() => navigate(INVESTOR_GROUP(group.id!))}
                                >
                                    <td>{group.name}</td>
                                    <td>{group.description}</td>
                                    <td>{group.id}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </Section>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load investor groups"
                />
            )}
        </>
    )
}

export default InvestorGroupOverview