import {ActionsSortOptionType} from "../../../tech/actions/sort/sort.type"
import {getSort} from "../../../tech/actions/sort/sort.util"
import {DealWithValuationsSortOpenApi} from "../../../generated"

export const DEFAULT_SORT = {
    id: DealWithValuationsSortOpenApi.NumberValuationsAscending,
    label: "Number of valuations (ASC)"
}

export function assembleSortOptions(): ActionsSortOptionType<DealWithValuationsSortOpenApi>[] {
    return [
        {
            id: DealWithValuationsSortOpenApi.FinalDeadlineAscending,
            label: "Final Deadline (ASC)"
        },
        {
            id: DealWithValuationsSortOpenApi.FinalDeadlineDescending,
            label: "Final Deadline (DESC)"
        },
        {
            id: DealWithValuationsSortOpenApi.MaxTargetAscending,
            label: "Maximum Target (ASC)"
        },
        {
            id: DealWithValuationsSortOpenApi.MaxTargetDescending,
            label: "Maximum Target (DESC)"
        },
        {
            id: DealWithValuationsSortOpenApi.MinTargetAscending,
            label: "Minimum Target (ASC)"
        },
        {
            id: DealWithValuationsSortOpenApi.MinTargetDescending,
            label: "Minimum Target (DESC)"
        },
        {
            id: DealWithValuationsSortOpenApi.MinTicketAscending,
            label: "Minimum Ticket (ASC)"
        },
        {
            id: DealWithValuationsSortOpenApi.MinTicketDescending,
            label: "Minimum Ticket (DESC)"
        },
        {
            id: DealWithValuationsSortOpenApi.ModifiedAscending,
            label: "Modified (ASC)"
        },
        {
            id: DealWithValuationsSortOpenApi.ModifiedDescending,
            label: "Modified (DESC)"
        },
        {
            id: DealWithValuationsSortOpenApi.NameAscending,
            label: "Name (ASC)"
        },
        {
            id: DealWithValuationsSortOpenApi.NameDescending,
            label: "Name (DESC)"
        },
        DEFAULT_SORT,
        {
            id: DealWithValuationsSortOpenApi.NumberValuationsDescending,
            label: "Number of valuations (DESC)"
        },
        {
            id: DealWithValuationsSortOpenApi.OutcomeAscending,
            label: "Outcome (ASC)"
        },
        {
            id: DealWithValuationsSortOpenApi.OutcomeDescending,
            label: "Outcome (DESC)"
        },
    ]
}

export function getCurrentSortOption(
    options: ActionsSortOptionType<DealWithValuationsSortOpenApi>[],
    searchParams: URLSearchParams
): ActionsSortOptionType<DealWithValuationsSortOpenApi> {
    return (options.find(o => o.id === getSort(searchParams)) || DEFAULT_SORT)
}