/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CurrencyOpenApi } from './CurrencyOpenApi';
import {
    CurrencyOpenApiFromJSON,
    CurrencyOpenApiFromJSONTyped,
    CurrencyOpenApiToJSON,
} from './CurrencyOpenApi';
import type { DealIndustryOpenApi } from './DealIndustryOpenApi';
import {
    DealIndustryOpenApiFromJSON,
    DealIndustryOpenApiFromJSONTyped,
    DealIndustryOpenApiToJSON,
} from './DealIndustryOpenApi';
import type { DealOutcomeOpenApi } from './DealOutcomeOpenApi';
import {
    DealOutcomeOpenApiFromJSON,
    DealOutcomeOpenApiFromJSONTyped,
    DealOutcomeOpenApiToJSON,
} from './DealOutcomeOpenApi';
import type { DealStageOpenApi } from './DealStageOpenApi';
import {
    DealStageOpenApiFromJSON,
    DealStageOpenApiFromJSONTyped,
    DealStageOpenApiToJSON,
} from './DealStageOpenApi';

/**
 * 
 * @export
 * @interface DealFiltersOpenApi
 */
export interface DealFiltersOpenApi {
    /**
     * 
     * @type {string}
     * @memberof DealFiltersOpenApi
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DealFiltersOpenApi
     */
    categoryName?: string;
    /**
     * 
     * @type {CurrencyOpenApi}
     * @memberof DealFiltersOpenApi
     */
    currency?: CurrencyOpenApi;
    /**
     * 
     * @type {string}
     * @memberof DealFiltersOpenApi
     */
    dealGroupName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DealFiltersOpenApi
     */
    exclusive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealFiltersOpenApi
     */
    exited?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DealFiltersOpenApi
     */
    geographyName?: string;
    /**
     * 
     * @type {DealIndustryOpenApi}
     * @memberof DealFiltersOpenApi
     */
    industry?: DealIndustryOpenApi;
    /**
     * 
     * @type {string}
     * @memberof DealFiltersOpenApi
     */
    investorGroupName?: string;
    /**
     * 
     * @type {string}
     * @memberof DealFiltersOpenApi
     */
    name?: string;
    /**
     * 
     * @type {DealOutcomeOpenApi}
     * @memberof DealFiltersOpenApi
     */
    outcome?: DealOutcomeOpenApi;
    /**
     * 
     * @type {boolean}
     * @memberof DealFiltersOpenApi
     */
    published?: boolean;
    /**
     * 
     * @type {DealStageOpenApi}
     * @memberof DealFiltersOpenApi
     */
    stage?: DealStageOpenApi;
}

/**
 * Check if a given object implements the DealFiltersOpenApi interface.
 */
export function instanceOfDealFiltersOpenApi(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DealFiltersOpenApiFromJSON(json: any): DealFiltersOpenApi {
    return DealFiltersOpenApiFromJSONTyped(json, false);
}

export function DealFiltersOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealFiltersOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'categoryName': !exists(json, 'categoryName') ? undefined : json['categoryName'],
        'currency': !exists(json, 'currency') ? undefined : CurrencyOpenApiFromJSON(json['currency']),
        'dealGroupName': !exists(json, 'dealGroupName') ? undefined : json['dealGroupName'],
        'exclusive': !exists(json, 'exclusive') ? undefined : json['exclusive'],
        'exited': !exists(json, 'exited') ? undefined : json['exited'],
        'geographyName': !exists(json, 'geographyName') ? undefined : json['geographyName'],
        'industry': !exists(json, 'industry') ? undefined : DealIndustryOpenApiFromJSON(json['industry']),
        'investorGroupName': !exists(json, 'investorGroupName') ? undefined : json['investorGroupName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'outcome': !exists(json, 'outcome') ? undefined : DealOutcomeOpenApiFromJSON(json['outcome']),
        'published': !exists(json, 'published') ? undefined : json['published'],
        'stage': !exists(json, 'stage') ? undefined : DealStageOpenApiFromJSON(json['stage']),
    };
}

export function DealFiltersOpenApiToJSON(value?: DealFiltersOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'categoryName': value.categoryName,
        'currency': CurrencyOpenApiToJSON(value.currency),
        'dealGroupName': value.dealGroupName,
        'exclusive': value.exclusive,
        'exited': value.exited,
        'geographyName': value.geographyName,
        'industry': DealIndustryOpenApiToJSON(value.industry),
        'investorGroupName': value.investorGroupName,
        'name': value.name,
        'outcome': DealOutcomeOpenApiToJSON(value.outcome),
        'published': value.published,
        'stage': DealStageOpenApiToJSON(value.stage),
    };
}

