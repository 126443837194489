import React, {FunctionComponent} from "react"
import {
    Control,
    FieldErrors,
    useFieldArray,
    UseFormGetValues,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch
} from "react-hook-form"
import {DealInvestorGroupType} from "./deal-investor-group.type"
import {DealType} from "../deal.type"
import {InvestorGroupType} from "../../investor-group/investor-group.type"
import DealInvestorGroupSelectAdd from "./add.component"
import DealInvestorGroupSelectSingle from "./single.component"
import {ShareClassType} from "../../share-class/share-class.type"

type DealInvestorGroupSelectProps = {
    groups: DealInvestorGroupType[]
    groupsRaw: InvestorGroupType[]
    shareClasses: ShareClassType[]
    updateShareClasses: (s: ShareClassType[]) => void
    control: Control<DealType, any>
    errors: FieldErrors<DealType>
    register: UseFormRegister<DealType>
    getValues: UseFormGetValues<DealType>
    setValue: UseFormSetValue<DealType>
    watch: UseFormWatch<DealType>
}

const DealInvestorGroupSelect: FunctionComponent<DealInvestorGroupSelectProps> = ({
    groups,
    groupsRaw,
    shareClasses,
    updateShareClasses,
    control,
    errors,
    register,
    getValues,
    setValue,
    watch
}) => {
    const { fields, append, remove } = useFieldArray({ name: "investorGroups", control })

    if (groupsRaw.length < 1) {
        return <div>No investor groups available.</div>
    }

    return (
        <div>
            {fields.map((f, index) => (
                <DealInvestorGroupSelectSingle
                    field={f}
                    index={index}
                    errors={errors}
                    register={register}
                    remove={remove}
                    getValues={getValues}
                    setValue={setValue}
                    watch={watch}
                    shareClasses={shareClasses}
                    updateShareClasses={updateShareClasses}
                    key={f.id}
                />
            ))}
            <DealInvestorGroupSelectAdd
                add={append}
                groups={groups}
                groupsRaw={groupsRaw}
            />
        </div>
    )
}

export default DealInvestorGroupSelect