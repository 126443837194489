import {InvestorType, InvestorWithDetailsType} from "./investor.type"
import {
    InvestorApiClient,
    InvestorFiltersOpenApi,
    InvestorSortOpenApi,
    InvestorWithDetailsOpenApi,
    KycLogOpenApi,
    SubscriptionLogOpenApi
} from "../../generated"
import {mapInvestorToOpenApi, mapOpenApiToInvestorWithDetails} from "./investor.mapper"
import {mapOpenApiToKycLog} from "../kyc/log/kyc-log.mapper"
import {KycLogType} from "../kyc/log/kyc-log.type"
import {PaginatedType, PaginationType} from "../../tech/actions/pagination/pagination.type"
import {SubscriptionLogType} from "../subscription/log/log.type"
import {mapOpenApiToSubscriptionLog} from "../subscription/log/log.mapper"
import {ExtractedResponse, FetchResponse} from "../../tech/response/response.type"
import {extractError} from "../../tech/response/extract.util"

export class InvestorApi {

    constructor(private apiClient: InvestorApiClient) {
    }

    public async create(investor: InvestorType): Promise<ExtractedResponse<InvestorWithDetailsType>> {
        try {
            const savedInvestor = await this.apiClient.investorCreate({ investorOpenApi: mapInvestorToOpenApi(investor) })
            return new FetchResponse(mapOpenApiToInvestorWithDetails(savedInvestor))
        }
        catch (err) {
            return await extractError(err)
        }
    }

    public async delete(id: string): Promise<void> {
        await this.apiClient.investorDelete({ id })
    }

    public async getAll(): Promise<InvestorWithDetailsType[]> {
        return (await this.apiClient.investorGetAll())
            .map((investor: InvestorWithDetailsOpenApi) => mapOpenApiToInvestorWithDetails(investor))
    }

    public async getAllPaginated(
        sort: InvestorSortOpenApi,
        filters?: InvestorFiltersOpenApi,
        pagination?: PaginationType
    ): Promise<PaginatedType<InvestorWithDetailsType>> {
        const result = await this.apiClient.investorGetAllPaginated({
            investorFiltersOpenApi: filters,
            elementsPerPage: pagination?.elementsPerPage,
            page: pagination?.page,
            sort
        })
        return {
            elements: result.investors
                .map((investor: InvestorWithDetailsOpenApi) => mapOpenApiToInvestorWithDetails(investor)),
            total: result.total
        }
    }

    public async getAllPepNotCleared(): Promise<InvestorWithDetailsType[]> {
        return (await this.apiClient.investorGetAllPepNotCleared())
            .map((investor: InvestorWithDetailsOpenApi) => mapOpenApiToInvestorWithDetails(investor))
    }

    public async getAllKycLogs(id: string): Promise<KycLogType[]> {
        return (await this.apiClient.investorKycLogsGetAll({ id }))
            .map((kycLog: KycLogOpenApi) => mapOpenApiToKycLog(kycLog))
    }

    public async getAllSubscriptionLogs(id: string): Promise<SubscriptionLogType[]> {
        return (await this.apiClient.investorSubscriptionLogsGetAll({ id }))
            .map((subscriptionLog: SubscriptionLogOpenApi) => mapOpenApiToSubscriptionLog(subscriptionLog))
    }

    public async get(id: string): Promise<InvestorWithDetailsType> {
        const investor = await this.apiClient.investorGet({ id })
        return mapOpenApiToInvestorWithDetails(investor)
    }

    public async update(id: string, investor: InvestorType): Promise<ExtractedResponse<InvestorWithDetailsType>> {
        try {
            const response = await this.apiClient.investorUpdate({ id, investorOpenApi: mapInvestorToOpenApi(investor) })
            return new FetchResponse(mapOpenApiToInvestorWithDetails(response))
        }
        catch (err) {
            return await extractError(err)
        }
    }

}