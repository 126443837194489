import React, {FunctionComponent, ReactNode} from "react"
import {AlertType} from "./type.enum"
import styles from "./alert.module.sass"

type AlertProps = {
    type: AlertType
    text: string | ReactNode
    noMargin?: boolean
}

const Alert: FunctionComponent<AlertProps> = ({ type, text, noMargin= false }) => {
    return (
        <div className={`${styles.alert} ${getAlertClass(type)} ${noMargin ? styles.noMargin : ""}`}>
            {text}
        </div>
    )
}

export default Alert

const getAlertClass = (alertType: AlertType) => {
    switch (alertType) {
        case AlertType.SUCCESS:
            return styles.alertSuccess
        case AlertType.INFO:
            return styles.alertInfo
        case AlertType.WARNING:
            return styles.alertWarning
        case AlertType.ERROR:
            return styles.alertError
    }
}