import {CompanyApiClient, CompanyFiltersOpenApi, CompanyOpenApi, CompanySortOpenApi} from "../../generated"
import {PaginatedType, PaginationType} from "../../tech/actions/pagination/pagination.type"
import {CompanyType} from "./company.type"
import {mapCompanyToOpenApi, mapOpenApiToCompany} from "./company.mapper"

export class CompanyApi {

    constructor(private apiClient: CompanyApiClient) {
    }

    public async create(company: CompanyType): Promise<CompanyType> {
        const savedCompany = await this.apiClient.companyCreate({ companyOpenApi: mapCompanyToOpenApi(company) })
        return mapOpenApiToCompany(savedCompany)
    }

    public async delete(id: string): Promise<void> {
        await this.apiClient.companyDelete({ id })
    }

    public async get(id: string): Promise<CompanyType> {
        const company = await this.apiClient.companyGet({ id })
        return mapOpenApiToCompany(company)
    }

    public async getAll(): Promise<CompanyType[]> {
        return (await this.apiClient.companyGetAll())
            .map((company: CompanyOpenApi) => mapOpenApiToCompany(company))
    }

    public async getAllPaginated(
        sort: CompanySortOpenApi,
        filters?: CompanyFiltersOpenApi,
        pagination?: PaginationType
    ): Promise<PaginatedType<CompanyType>> {
        const result = await this.apiClient.companyGetAllPaginated({
            companyFiltersOpenApi: filters,
            elementsPerPage: pagination?.elementsPerPage,
            page: pagination?.page,
            sort
        })
        return {
            elements: result.companies.map((company: CompanyOpenApi) => mapOpenApiToCompany(company)),
            total: result.total
        }
    }

    public async update(id: string, company: CompanyType): Promise<CompanyType> {
        const response = await this.apiClient.companyUpdate({ id, companyOpenApi: mapCompanyToOpenApi(company) })
        return mapOpenApiToCompany(response)
    }

}