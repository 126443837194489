import React, {FunctionComponent} from "react"
import {FieldErrors, UseFieldArrayRemove, UseFormRegister, UseFormSetValue, UseFormWatch} from "react-hook-form"
import {DealType} from "../deal.type"
import FormError from "../../../tech/form/error.component"
import {DealDocumentTypeOpenApi, FileTypeOpenApi, MimeTypeOpenApi} from "../../../generated"
import FileUploadSelect from "../../file/upload-select/upload-select.component"
import {FileSelectSearchType} from "../../file/upload-select/select/select.component"
import {FileType} from "../../file/file.type"
import styles from "./single.module.sass"

type DealDocumentSelectSingleProps = {
    index: number
    errors: FieldErrors<DealType>
    register: UseFormRegister<DealType>
    remove: UseFieldArrayRemove
    setValue: UseFormSetValue<DealType>
    watch: UseFormWatch<DealType>
    fetchFiles: (
        mimeTypes: MimeTypeOpenApi[],
        searchType: FileSelectSearchType,
        searchValue: string
    ) => Promise<FileType[]>
}

const DealDocumentSelectSingle: FunctionComponent<DealDocumentSelectSingleProps> = ({
    index,
    errors,
    register,
    remove,
    setValue,
    watch,
    fetchFiles
}) => {
    const del = (i: number) => remove(i)

    return (
        <div className={styles.box}>
            <input type="hidden" {...register(`documents.${index}.id`,)} />
            <label>
                <span>Type</span>
                <select
                    {...register(`documents.${index}.type`)}
                >
                    {Object.entries(DealDocumentTypeOpenApi).map((o, i) => (
                        <option
                            value={o[1]}
                            key={`deal-document-type-${index}-${i}`}
                        >
                            {o[1]}
                        </option>
                    ))}
                </select>
                <FormError field={`documents.${index}.type`} errors={errors}/>
            </label>
            <div>
                <span>File</span>
                <FileUploadSelect
                    accept={["PDF"]}
                    currentFile={watch(`documents.${index}.file`)}
                    defaultFileType={FileTypeOpenApi.BackofficePrivateFile}
                    filesProvider={(searchType: FileSelectSearchType, searchValue: string) => fetchFiles(
                        [MimeTypeOpenApi.Pdf],
                        searchType,
                        searchValue
                    )}
                    setFormValue={(value: FileType) => setValue(`documents.${index}.file`, value)}
                />
                <FormError field={`documents.${index}.file`} errors={errors}/>
            </div>
            {watch(`documents.${index}.type`) === DealDocumentTypeOpenApi.Other && (
                <>
                    <div>
                        <label>
                            <span>Title</span>
                            <input
                                {...register(
                                    `documents.${index}.title`,
                                    {
                                        required: "Title is required."
                                    }
                                )}
                                type="text"
                            />
                            <FormError field={`documents.${index}.title`} errors={errors}/>
                        </label>
                    </div>
                    <div>
                        <label>
                            <span>Description</span>
                            <input
                                {...register(
                                    `documents.${index}.description`,
                                    {
                                        required: "Description is required."
                                    }
                                )}
                                type="text"
                            />
                            <FormError field={`documents.${index}.description`} errors={errors}/>
                        </label>
                    </div>
                </>
            )}
            <div>
                <label>
                    <span>Order</span>
                    <input
                        {...register(
                            `documents.${index}.order`,
                            {
                                required: "Order is required."
                            }
                        )}
                        type="number"
                        min={0}
                    />
                    <FormError field={`documents.${index}.order`} errors={errors}/>
                </label>
            </div>
            <div className={styles.delete}>
                <button
                    type="button"
                    onClick={() => del(index)}
                >
                    Delete
                </button>
            </div>
        </div>
    )
}

export default DealDocumentSelectSingle