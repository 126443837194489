/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CompanyContactFiltersOpenApi,
  CompanyContactOpenApi,
  CompanyContactPaginatedResponseOpenApi,
  CompanyContactSortOpenApi,
  CompanyFiltersOpenApi,
  CompanyOpenApi,
  CompanyPaginatedResponseOpenApi,
  CompanySortOpenApi,
  ValidationResponseOpenApi,
} from '../models';
import {
    CompanyContactFiltersOpenApiFromJSON,
    CompanyContactFiltersOpenApiToJSON,
    CompanyContactOpenApiFromJSON,
    CompanyContactOpenApiToJSON,
    CompanyContactPaginatedResponseOpenApiFromJSON,
    CompanyContactPaginatedResponseOpenApiToJSON,
    CompanyContactSortOpenApiFromJSON,
    CompanyContactSortOpenApiToJSON,
    CompanyFiltersOpenApiFromJSON,
    CompanyFiltersOpenApiToJSON,
    CompanyOpenApiFromJSON,
    CompanyOpenApiToJSON,
    CompanyPaginatedResponseOpenApiFromJSON,
    CompanyPaginatedResponseOpenApiToJSON,
    CompanySortOpenApiFromJSON,
    CompanySortOpenApiToJSON,
    ValidationResponseOpenApiFromJSON,
    ValidationResponseOpenApiToJSON,
} from '../models';

export interface CompanyContactCreateRequest {
    companyContactOpenApi?: CompanyContactOpenApi;
}

export interface CompanyContactDeleteRequest {
    id: string;
}

export interface CompanyContactGetRequest {
    id: string;
}

export interface CompanyContactGetAllPaginatedRequest {
    elementsPerPage?: number;
    page?: number;
    sort?: CompanyContactSortOpenApi;
    companyContactFiltersOpenApi?: CompanyContactFiltersOpenApi;
}

export interface CompanyContactUpdateRequest {
    id: string;
    companyContactOpenApi?: CompanyContactOpenApi;
}

export interface CompanyCreateRequest {
    companyOpenApi?: CompanyOpenApi;
}

export interface CompanyDeleteRequest {
    id: string;
}

export interface CompanyGetRequest {
    id: string;
}

export interface CompanyGetAllPaginatedRequest {
    elementsPerPage?: number;
    page?: number;
    sort?: CompanySortOpenApi;
    companyFiltersOpenApi?: CompanyFiltersOpenApi;
}

export interface CompanyUpdateRequest {
    id: string;
    companyOpenApi?: CompanyOpenApi;
}

/**
 * 
 */
export class CompanyApiClient extends runtime.BaseAPI {

    /**
     * Create new company contact
     */
    async companyContactCreateRaw(requestParameters: CompanyContactCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyContactOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/company/contact`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyContactOpenApiToJSON(requestParameters.companyContactOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyContactOpenApiFromJSON(jsonValue));
    }

    /**
     * Create new company contact
     */
    async companyContactCreate(requestParameters: CompanyContactCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyContactOpenApi> {
        const response = await this.companyContactCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete company contact by id
     */
    async companyContactDeleteRaw(requestParameters: CompanyContactDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companyContactDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/company/contact/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete company contact by id
     */
    async companyContactDelete(requestParameters: CompanyContactDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.companyContactDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get company contact by id
     */
    async companyContactGetRaw(requestParameters: CompanyContactGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyContactOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companyContactGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/company/contact/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyContactOpenApiFromJSON(jsonValue));
    }

    /**
     * Get company contact by id
     */
    async companyContactGet(requestParameters: CompanyContactGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyContactOpenApi> {
        const response = await this.companyContactGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all company contacts
     */
    async companyContactGetAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanyContactOpenApi>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/company/contact`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyContactOpenApiFromJSON));
    }

    /**
     * Get all company contacts
     */
    async companyContactGetAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanyContactOpenApi>> {
        const response = await this.companyContactGetAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all company contacts (paginated)
     */
    async companyContactGetAllPaginatedRaw(requestParameters: CompanyContactGetAllPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyContactPaginatedResponseOpenApi>> {
        const queryParameters: any = {};

        if (requestParameters.elementsPerPage !== undefined) {
            queryParameters['elementsPerPage'] = requestParameters.elementsPerPage;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/company/contact/paginated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyContactFiltersOpenApiToJSON(requestParameters.companyContactFiltersOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyContactPaginatedResponseOpenApiFromJSON(jsonValue));
    }

    /**
     * Get all company contacts (paginated)
     */
    async companyContactGetAllPaginated(requestParameters: CompanyContactGetAllPaginatedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyContactPaginatedResponseOpenApi> {
        const response = await this.companyContactGetAllPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update company contact
     */
    async companyContactUpdateRaw(requestParameters: CompanyContactUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyContactOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companyContactUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/company/contact/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyContactOpenApiToJSON(requestParameters.companyContactOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyContactOpenApiFromJSON(jsonValue));
    }

    /**
     * Update company contact
     */
    async companyContactUpdate(requestParameters: CompanyContactUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyContactOpenApi> {
        const response = await this.companyContactUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create new company
     */
    async companyCreateRaw(requestParameters: CompanyCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyOpenApiToJSON(requestParameters.companyOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyOpenApiFromJSON(jsonValue));
    }

    /**
     * Create new company
     */
    async companyCreate(requestParameters: CompanyCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyOpenApi> {
        const response = await this.companyCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete company by id
     */
    async companyDeleteRaw(requestParameters: CompanyDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companyDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/company/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete company by id
     */
    async companyDelete(requestParameters: CompanyDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.companyDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get company by id
     */
    async companyGetRaw(requestParameters: CompanyGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companyGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/company/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyOpenApiFromJSON(jsonValue));
    }

    /**
     * Get company by id
     */
    async companyGet(requestParameters: CompanyGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyOpenApi> {
        const response = await this.companyGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all companies
     */
    async companyGetAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanyOpenApi>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/company`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyOpenApiFromJSON));
    }

    /**
     * Get all companies
     */
    async companyGetAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanyOpenApi>> {
        const response = await this.companyGetAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all companies (paginated)
     */
    async companyGetAllPaginatedRaw(requestParameters: CompanyGetAllPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyPaginatedResponseOpenApi>> {
        const queryParameters: any = {};

        if (requestParameters.elementsPerPage !== undefined) {
            queryParameters['elementsPerPage'] = requestParameters.elementsPerPage;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/company/paginated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyFiltersOpenApiToJSON(requestParameters.companyFiltersOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyPaginatedResponseOpenApiFromJSON(jsonValue));
    }

    /**
     * Get all companies (paginated)
     */
    async companyGetAllPaginated(requestParameters: CompanyGetAllPaginatedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyPaginatedResponseOpenApi> {
        const response = await this.companyGetAllPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update company
     */
    async companyUpdateRaw(requestParameters: CompanyUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companyUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/company/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyOpenApiToJSON(requestParameters.companyOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyOpenApiFromJSON(jsonValue));
    }

    /**
     * Update company
     */
    async companyUpdate(requestParameters: CompanyUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyOpenApi> {
        const response = await this.companyUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
