import React, {useContext, useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import CompanyForm from "./form.component"
import {SubmitHandler} from "react-hook-form"
import {normalizeValues} from "../../tech/form/form.util"
import {CompanyType} from "./company.type"
import {CompanyContactType} from "./contact/contact.type"
import {DealType} from "../deal/deal.type"

const CompanyEdit = () => {
    let { id } = useParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR" | "SUCCESS">("LOADING")
    const [company, setCompany] = useState<CompanyType>()
    const [contacts, setContacts] = useState<CompanyContactType[]>([])
    const [deals, setDeals] = useState<DealType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const [
                    fetchedCompany,
                    fetchedContacts,
                    fetchedDeals,
                ] = await Promise.all([
                    fetchClient.companyApi.get(id!),
                    fetchClient.companyContactApi.getAll(),
                    fetchClient.dealApi.getAll(),
                ])
                setCompany(fetchedCompany)
                setContacts(fetchedContacts)
                setDeals(fetchedDeals)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const onSubmit: SubmitHandler<CompanyType> = async (company) => {
        setState("LOADING")
        try {
            setCompany(await fetchClient.companyApi.update(id!, normalizeValues(company)))
            window.scrollTo({ top: 0 })
            setState("SUCCESS")
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="An error occurred. Please try again or contact the tech team."
                />
            )}
            {state === "SUCCESS" && (
                <Alert
                    type={AlertType.SUCCESS}
                    text="Saved successfully."
                />
            )}
            {(state === "LOADED" || state === "ERROR" || state === "SUCCESS") && company && (
                <Section>
                    <SectionHeading title={`Edit company: ${company.legalName}`}/>
                    <CompanyForm
                        company={company}
                        contacts={contacts}
                        deals={deals}
                        onSubmit={onSubmit}
                        submitText="Save company"
                    />
                </Section>
            )}
        </>
    )
}

export default CompanyEdit