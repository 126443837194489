/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ManualReconciliationBankAccountTransferOriginOpenApi } from './ManualReconciliationBankAccountTransferOriginOpenApi';
import {
    ManualReconciliationBankAccountTransferOriginOpenApiFromJSON,
    ManualReconciliationBankAccountTransferOriginOpenApiFromJSONTyped,
    ManualReconciliationBankAccountTransferOriginOpenApiToJSON,
} from './ManualReconciliationBankAccountTransferOriginOpenApi';

/**
 * 
 * @export
 * @interface PaymentDetailsAccountManualReconciliationOpenApi
 */
export interface PaymentDetailsAccountManualReconciliationOpenApi {
    /**
     * 
     * @type {ManualReconciliationBankAccountTransferOriginOpenApi}
     * @memberof PaymentDetailsAccountManualReconciliationOpenApi
     */
    transferOrigin: ManualReconciliationBankAccountTransferOriginOpenApi;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetailsAccountManualReconciliationOpenApi
     */
    swift?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetailsAccountManualReconciliationOpenApi
     */
    achRoutingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetailsAccountManualReconciliationOpenApi
     */
    aba?: string;
}

/**
 * Check if a given object implements the PaymentDetailsAccountManualReconciliationOpenApi interface.
 */
export function instanceOfPaymentDetailsAccountManualReconciliationOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "transferOrigin" in value;

    return isInstance;
}

export function PaymentDetailsAccountManualReconciliationOpenApiFromJSON(json: any): PaymentDetailsAccountManualReconciliationOpenApi {
    return PaymentDetailsAccountManualReconciliationOpenApiFromJSONTyped(json, false);
}

export function PaymentDetailsAccountManualReconciliationOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentDetailsAccountManualReconciliationOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transferOrigin': ManualReconciliationBankAccountTransferOriginOpenApiFromJSON(json['transferOrigin']),
        'swift': !exists(json, 'swift') ? undefined : json['swift'],
        'achRoutingNumber': !exists(json, 'achRoutingNumber') ? undefined : json['achRoutingNumber'],
        'aba': !exists(json, 'aba') ? undefined : json['aba'],
    };
}

export function PaymentDetailsAccountManualReconciliationOpenApiToJSON(value?: PaymentDetailsAccountManualReconciliationOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transferOrigin': ManualReconciliationBankAccountTransferOriginOpenApiToJSON(value.transferOrigin),
        'swift': value.swift,
        'achRoutingNumber': value.achRoutingNumber,
        'aba': value.aba,
    };
}

