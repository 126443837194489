import React, {FunctionComponent} from "react"
import {getStringRepresentation} from "../upload-select.util"
import {FILE} from "../../../../paths"
import {FileType} from "../../file.type"
import FilePreviewImage from "../../preview/image.component"
import styles from "./current-file.module.sass"

type FileUploadCurrentFilePreviewProps = {
    currentFile?: FileType
}

const FileUploadCurrentFilePreview: FunctionComponent<FileUploadCurrentFilePreviewProps> =({ currentFile }) => {
    if (!currentFile) return <></>

    if (!currentFile.source) {
        return (
            <div className={styles.currentFile}>
                <a
                    href={FILE(currentFile.id)}
                    className={styles.text}
                    target="_blank"
                    rel="noreferrer"
                >
                    {getStringRepresentation(currentFile)}
                </a>
            </div>
        )
    }

    return (
        <div className={styles.currentFile}>
            <div className={styles.image}>
                <FilePreviewImage
                    currentFile={currentFile}
                    maxHeight={40}
                />
            </div>
            <div>
                <div className={styles.text}>
                    <a
                        href={FILE(currentFile.id)}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {getStringRepresentation(currentFile)}
                    </a>
                </div>
            </div>
        </div>
    )
}

export default FileUploadCurrentFilePreview