import {SyndicateOpenApi} from "../../generated"
import {SyndicateType} from "./syndicate.type"
import {mapInvestorGroupToOpenApi, mapOpenApiToInvestorGroup} from "../investor-group/investor-group.mapper"
import {mapOpenApiToUser, mapUserToOpenApi} from "../user/user.mapper"
import {mapFileToOpenApi, mapOpenApiToFile} from "../file/file.mapper"

export function mapOpenApiToSyndicate(syndicate: SyndicateOpenApi): SyndicateType {
    return {
        id: syndicate.id,
        created: syndicate.created,
        modified: syndicate.modified,
        name: syndicate.name,
        logo: mapOpenApiToFile(syndicate.logo),
        investorGroups: syndicate.investorGroups.map(mapOpenApiToInvestorGroup),
        managers: syndicate.managers.map(mapOpenApiToUser)
    }
}

export function mapSyndicateToOpenApi(syndicate: SyndicateType): SyndicateOpenApi {
    return {
        id: syndicate.id,
        created: syndicate.created,
        modified: syndicate.modified,
        name: syndicate.name,
        logo: mapFileToOpenApi(syndicate.logo),
        investorGroups: syndicate.investorGroups.map(mapInvestorGroupToOpenApi),
        managers: syndicate.managers.map(mapUserToOpenApi)
    }
}