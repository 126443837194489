/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FFileOpenApi } from './FFileOpenApi';
import {
    FFileOpenApiFromJSON,
    FFileOpenApiFromJSONTyped,
    FFileOpenApiToJSON,
} from './FFileOpenApi';

/**
 * 
 * @export
 * @interface FilePaginatedResponseOpenApi
 */
export interface FilePaginatedResponseOpenApi {
    /**
     * 
     * @type {Array<FFileOpenApi>}
     * @memberof FilePaginatedResponseOpenApi
     */
    files: Array<FFileOpenApi>;
    /**
     * 
     * @type {number}
     * @memberof FilePaginatedResponseOpenApi
     */
    total: number;
}

/**
 * Check if a given object implements the FilePaginatedResponseOpenApi interface.
 */
export function instanceOfFilePaginatedResponseOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "files" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function FilePaginatedResponseOpenApiFromJSON(json: any): FilePaginatedResponseOpenApi {
    return FilePaginatedResponseOpenApiFromJSONTyped(json, false);
}

export function FilePaginatedResponseOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilePaginatedResponseOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'files': ((json['files'] as Array<any>).map(FFileOpenApiFromJSON)),
        'total': json['total'],
    };
}

export function FilePaginatedResponseOpenApiToJSON(value?: FilePaginatedResponseOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'files': ((value.files as Array<any>).map(FFileOpenApiToJSON)),
        'total': value.total,
    };
}

