/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SinglePurposeVehicleOpenApi } from './SinglePurposeVehicleOpenApi';
import {
    SinglePurposeVehicleOpenApiFromJSON,
    SinglePurposeVehicleOpenApiFromJSONTyped,
    SinglePurposeVehicleOpenApiToJSON,
} from './SinglePurposeVehicleOpenApi';

/**
 * 
 * @export
 * @interface SinglePurposeVehiclePaginatedResponseOpenApi
 */
export interface SinglePurposeVehiclePaginatedResponseOpenApi {
    /**
     * 
     * @type {Array<SinglePurposeVehicleOpenApi>}
     * @memberof SinglePurposeVehiclePaginatedResponseOpenApi
     */
    singlePurposeVehicles: Array<SinglePurposeVehicleOpenApi>;
    /**
     * 
     * @type {number}
     * @memberof SinglePurposeVehiclePaginatedResponseOpenApi
     */
    total: number;
}

/**
 * Check if a given object implements the SinglePurposeVehiclePaginatedResponseOpenApi interface.
 */
export function instanceOfSinglePurposeVehiclePaginatedResponseOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "singlePurposeVehicles" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function SinglePurposeVehiclePaginatedResponseOpenApiFromJSON(json: any): SinglePurposeVehiclePaginatedResponseOpenApi {
    return SinglePurposeVehiclePaginatedResponseOpenApiFromJSONTyped(json, false);
}

export function SinglePurposeVehiclePaginatedResponseOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SinglePurposeVehiclePaginatedResponseOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'singlePurposeVehicles': ((json['singlePurposeVehicles'] as Array<any>).map(SinglePurposeVehicleOpenApiFromJSON)),
        'total': json['total'],
    };
}

export function SinglePurposeVehiclePaginatedResponseOpenApiToJSON(value?: SinglePurposeVehiclePaginatedResponseOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'singlePurposeVehicles': ((value.singlePurposeVehicles as Array<any>).map(SinglePurposeVehicleOpenApiToJSON)),
        'total': value.total,
    };
}

