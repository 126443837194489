/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const NotificationTypeOpenApi = {
    InvestmentDealFinalizedPaid: 'INVESTMENT_DEAL_FINALIZED_PAID',
    InvestmentDealFinalizedUnpaid: 'INVESTMENT_DEAL_FINALIZED_UNPAID',
    InvestmentDealUnsuccessful: 'INVESTMENT_DEAL_UNSUCCESSFUL',
    InvestmentDeclined: 'INVESTMENT_DECLINED',
    InvestmentPaymentInitial: 'INVESTMENT_PAYMENT_INITIAL',
    InvestmentPaymentReceipt: 'INVESTMENT_PAYMENT_RECEIPT',
    InvestmentPaymentReminder: 'INVESTMENT_PAYMENT_REMINDER',
    InvestorAddedToSyndicate: 'INVESTOR_ADDED_TO_SYNDICATE',
    KycRejected: 'KYC_REJECTED',
    KycUpdateNecessary: 'KYC_UPDATE_NECESSARY',
    OnboardingCompleted: 'ONBOARDING_COMPLETED',
    OnboardingInitialReminder: 'ONBOARDING_INITIAL_REMINDER',
    SubscriptionCanceled: 'SUBSCRIPTION_CANCELED',
    SubscriptionChanged: 'SUBSCRIPTION_CHANGED',
    SubscriptionConfirmation: 'SUBSCRIPTION_CONFIRMATION',
    SubscriptionReactivated: 'SUBSCRIPTION_REACTIVATED',
    SyndicateInvitation: 'SYNDICATE_INVITATION',
    WaitlistConfirmedInvitation: 'WAITLIST_CONFIRMED_INVITATION',
    Welcome: 'WELCOME'
} as const;
export type NotificationTypeOpenApi = typeof NotificationTypeOpenApi[keyof typeof NotificationTypeOpenApi];


export function NotificationTypeOpenApiFromJSON(json: any): NotificationTypeOpenApi {
    return NotificationTypeOpenApiFromJSONTyped(json, false);
}

export function NotificationTypeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationTypeOpenApi {
    return json as NotificationTypeOpenApi;
}

export function NotificationTypeOpenApiToJSON(value?: NotificationTypeOpenApi | null): any {
    return value as any;
}

