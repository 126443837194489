/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SubscriptionLogTypeOpenApi = {
    Cancelled: 'CANCELLED',
    Reactivated: 'REACTIVATED',
    Subscribed: 'SUBSCRIBED',
    Changed: 'CHANGED'
} as const;
export type SubscriptionLogTypeOpenApi = typeof SubscriptionLogTypeOpenApi[keyof typeof SubscriptionLogTypeOpenApi];


export function SubscriptionLogTypeOpenApiFromJSON(json: any): SubscriptionLogTypeOpenApi {
    return SubscriptionLogTypeOpenApiFromJSONTyped(json, false);
}

export function SubscriptionLogTypeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionLogTypeOpenApi {
    return json as SubscriptionLogTypeOpenApi;
}

export function SubscriptionLogTypeOpenApiToJSON(value?: SubscriptionLogTypeOpenApi | null): any {
    return value as any;
}

