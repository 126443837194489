import {ExchangeRateOpenApi} from "../../generated"
import {ExchangeRateType} from "./exchange-rate.type"

export function mapOpenApiToExchangeRate(exchangeRate: ExchangeRateOpenApi): ExchangeRateType {
    return {
        rate: exchangeRate.rate,
        date: exchangeRate.date
    }
}

export function mapExchangeRateToOpenApi(exchangeRate: ExchangeRateType): ExchangeRateOpenApi {
    return {
        rate: exchangeRate.rate,
        date: exchangeRate.date
    }
}