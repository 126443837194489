/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DealPublicationValidationPreviewItemTypeOpenApi } from './DealPublicationValidationPreviewItemTypeOpenApi';
import {
    DealPublicationValidationPreviewItemTypeOpenApiFromJSON,
    DealPublicationValidationPreviewItemTypeOpenApiFromJSONTyped,
    DealPublicationValidationPreviewItemTypeOpenApiToJSON,
} from './DealPublicationValidationPreviewItemTypeOpenApi';

/**
 * 
 * @export
 * @interface DealPublicationValidationPreviewItemOpenApi
 */
export interface DealPublicationValidationPreviewItemOpenApi {
    /**
     * 
     * @type {string}
     * @memberof DealPublicationValidationPreviewItemOpenApi
     */
    message: string;
    /**
     * 
     * @type {DealPublicationValidationPreviewItemTypeOpenApi}
     * @memberof DealPublicationValidationPreviewItemOpenApi
     */
    type: DealPublicationValidationPreviewItemTypeOpenApi;
}

/**
 * Check if a given object implements the DealPublicationValidationPreviewItemOpenApi interface.
 */
export function instanceOfDealPublicationValidationPreviewItemOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function DealPublicationValidationPreviewItemOpenApiFromJSON(json: any): DealPublicationValidationPreviewItemOpenApi {
    return DealPublicationValidationPreviewItemOpenApiFromJSONTyped(json, false);
}

export function DealPublicationValidationPreviewItemOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealPublicationValidationPreviewItemOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': json['message'],
        'type': DealPublicationValidationPreviewItemTypeOpenApiFromJSON(json['type']),
    };
}

export function DealPublicationValidationPreviewItemOpenApiToJSON(value?: DealPublicationValidationPreviewItemOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'type': DealPublicationValidationPreviewItemTypeOpenApiToJSON(value.type),
    };
}

