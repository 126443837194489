/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CurrencyOpenApi } from './CurrencyOpenApi';
import {
    CurrencyOpenApiFromJSON,
    CurrencyOpenApiFromJSONTyped,
    CurrencyOpenApiToJSON,
} from './CurrencyOpenApi';
import type { ValuationCalculationMethodOpenApi } from './ValuationCalculationMethodOpenApi';
import {
    ValuationCalculationMethodOpenApiFromJSON,
    ValuationCalculationMethodOpenApiFromJSONTyped,
    ValuationCalculationMethodOpenApiToJSON,
} from './ValuationCalculationMethodOpenApi';

/**
 * 
 * @export
 * @interface ValuationOpenApi
 */
export interface ValuationOpenApi {
    /**
     * 
     * @type {string}
     * @memberof ValuationOpenApi
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ValuationOpenApi
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ValuationOpenApi
     */
    modified?: Date;
    /**
     * 
     * @type {CurrencyOpenApi}
     * @memberof ValuationOpenApi
     */
    currency: CurrencyOpenApi;
    /**
     * 
     * @type {ValuationCalculationMethodOpenApi}
     * @memberof ValuationOpenApi
     */
    calculationMethod: ValuationCalculationMethodOpenApi;
    /**
     * 
     * @type {number}
     * @memberof ValuationOpenApi
     */
    pricePerShare?: number;
    /**
     * 
     * @type {number}
     * @memberof ValuationOpenApi
     */
    valuation?: number;
    /**
     * 
     * @type {Date}
     * @memberof ValuationOpenApi
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof ValuationOpenApi
     */
    discount?: number;
    /**
     * 
     * @type {string}
     * @memberof ValuationOpenApi
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ValuationOpenApi
     */
    dealId: string;
}

/**
 * Check if a given object implements the ValuationOpenApi interface.
 */
export function instanceOfValuationOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "calculationMethod" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "dealId" in value;

    return isInstance;
}

export function ValuationOpenApiFromJSON(json: any): ValuationOpenApi {
    return ValuationOpenApiFromJSONTyped(json, false);
}

export function ValuationOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValuationOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'currency': CurrencyOpenApiFromJSON(json['currency']),
        'calculationMethod': ValuationCalculationMethodOpenApiFromJSON(json['calculationMethod']),
        'pricePerShare': !exists(json, 'pricePerShare') ? undefined : json['pricePerShare'],
        'valuation': !exists(json, 'valuation') ? undefined : json['valuation'],
        'date': (new Date(json['date'])),
        'discount': !exists(json, 'discount') ? undefined : json['discount'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'dealId': json['dealId'],
    };
}

export function ValuationOpenApiToJSON(value?: ValuationOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'currency': CurrencyOpenApiToJSON(value.currency),
        'calculationMethod': ValuationCalculationMethodOpenApiToJSON(value.calculationMethod),
        'pricePerShare': value.pricePerShare,
        'valuation': value.valuation,
        'date': (value.date.toISOString().substr(0,10)),
        'discount': value.discount,
        'description': value.description,
        'dealId': value.dealId,
    };
}

