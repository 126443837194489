import {DealManualReconciliationBankAccountType} from "./deal-manual-reconciliation-bank-account.type"
import {DealManualReconciliationBankAccountOpenApi} from "../../../generated"
import {
    mapManualReconciliationBankAccountToOpenApi,
    mapOpenApiToManualReconciliationBankAccount
} from "../../reconciliation/account/manual-reconciliation-bank-account.mapper"

export function mapOpenApiToDealManualReconciliationBankAccount(account: DealManualReconciliationBankAccountOpenApi): DealManualReconciliationBankAccountType {
    return {
        manualReconciliationBankAccount: mapOpenApiToManualReconciliationBankAccount(account.manualReconciliationBankAccount),
        order: account.order
    }
}

export function mapDealManualReconciliationBankAccountToOpenApi(account: DealManualReconciliationBankAccountType): DealManualReconciliationBankAccountOpenApi {
    return {
        manualReconciliationBankAccount: mapManualReconciliationBankAccountToOpenApi(account.manualReconciliationBankAccount),
        order: account.order,
    }
}