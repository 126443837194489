/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SyndicateSortOpenApi = {
    CreatedAscending: 'CREATED_ASCENDING',
    CreatedDescending: 'CREATED_DESCENDING',
    ModifiedAscending: 'MODIFIED_ASCENDING',
    ModifiedDescending: 'MODIFIED_DESCENDING',
    NameAscending: 'NAME_ASCENDING',
    NameDescending: 'NAME_DESCENDING'
} as const;
export type SyndicateSortOpenApi = typeof SyndicateSortOpenApi[keyof typeof SyndicateSortOpenApi];


export function SyndicateSortOpenApiFromJSON(json: any): SyndicateSortOpenApi {
    return SyndicateSortOpenApiFromJSONTyped(json, false);
}

export function SyndicateSortOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyndicateSortOpenApi {
    return json as SyndicateSortOpenApi;
}

export function SyndicateSortOpenApiToJSON(value?: SyndicateSortOpenApi | null): any {
    return value as any;
}

