/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvestorKycOpenApi
 */
export interface InvestorKycOpenApi {
    /**
     * 
     * @type {boolean}
     * @memberof InvestorKycOpenApi
     */
    complete?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorKycOpenApi
     */
    needsUpdate?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof InvestorKycOpenApi
     */
    identityVerified?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvestorKycOpenApi
     */
    passportValidTill?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvestorKycOpenApi
     */
    proofOfAddressValidTill?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorKycOpenApi
     */
    proofOfAiStatusAvailable: boolean;
    /**
     * 
     * @type {Date}
     * @memberof InvestorKycOpenApi
     */
    politicallyExposedStatusClarified?: Date;
}

/**
 * Check if a given object implements the InvestorKycOpenApi interface.
 */
export function instanceOfInvestorKycOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "proofOfAiStatusAvailable" in value;

    return isInstance;
}

export function InvestorKycOpenApiFromJSON(json: any): InvestorKycOpenApi {
    return InvestorKycOpenApiFromJSONTyped(json, false);
}

export function InvestorKycOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorKycOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'complete': !exists(json, 'complete') ? undefined : json['complete'],
        'needsUpdate': !exists(json, 'needsUpdate') ? undefined : json['needsUpdate'],
        'identityVerified': !exists(json, 'identityVerified') ? undefined : (new Date(json['identityVerified'])),
        'passportValidTill': !exists(json, 'passportValidTill') ? undefined : (new Date(json['passportValidTill'])),
        'proofOfAddressValidTill': !exists(json, 'proofOfAddressValidTill') ? undefined : (new Date(json['proofOfAddressValidTill'])),
        'proofOfAiStatusAvailable': json['proofOfAiStatusAvailable'],
        'politicallyExposedStatusClarified': !exists(json, 'politicallyExposedStatusClarified') ? undefined : (new Date(json['politicallyExposedStatusClarified'])),
    };
}

export function InvestorKycOpenApiToJSON(value?: InvestorKycOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'complete': value.complete,
        'needsUpdate': value.needsUpdate,
        'identityVerified': value.identityVerified === undefined ? undefined : (value.identityVerified.toISOString()),
        'passportValidTill': value.passportValidTill === undefined ? undefined : (value.passportValidTill.toISOString().substr(0,10)),
        'proofOfAddressValidTill': value.proofOfAddressValidTill === undefined ? undefined : (value.proofOfAddressValidTill.toISOString().substr(0,10)),
        'proofOfAiStatusAvailable': value.proofOfAiStatusAvailable,
        'politicallyExposedStatusClarified': value.politicallyExposedStatusClarified === undefined ? undefined : (value.politicallyExposedStatusClarified.toISOString()),
    };
}

