/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DealOpenApi } from './DealOpenApi';
import {
    DealOpenApiFromJSON,
    DealOpenApiFromJSONTyped,
    DealOpenApiToJSON,
} from './DealOpenApi';
import type { InvestorOpenApi } from './InvestorOpenApi';
import {
    InvestorOpenApiFromJSON,
    InvestorOpenApiFromJSONTyped,
    InvestorOpenApiToJSON,
} from './InvestorOpenApi';
import type { SyndicateInvitationCreationResponseStateOpenApi } from './SyndicateInvitationCreationResponseStateOpenApi';
import {
    SyndicateInvitationCreationResponseStateOpenApiFromJSON,
    SyndicateInvitationCreationResponseStateOpenApiFromJSONTyped,
    SyndicateInvitationCreationResponseStateOpenApiToJSON,
} from './SyndicateInvitationCreationResponseStateOpenApi';
import type { SyndicateInvitationOpenApi } from './SyndicateInvitationOpenApi';
import {
    SyndicateInvitationOpenApiFromJSON,
    SyndicateInvitationOpenApiFromJSONTyped,
    SyndicateInvitationOpenApiToJSON,
} from './SyndicateInvitationOpenApi';

/**
 * 
 * @export
 * @interface SyndicateInvitationCreationResponseOpenApi
 */
export interface SyndicateInvitationCreationResponseOpenApi {
    /**
     * 
     * @type {Array<DealOpenApi>}
     * @memberof SyndicateInvitationCreationResponseOpenApi
     */
    deals?: Array<DealOpenApi>;
    /**
     * 
     * @type {InvestorOpenApi}
     * @memberof SyndicateInvitationCreationResponseOpenApi
     */
    investor?: InvestorOpenApi;
    /**
     * 
     * @type {SyndicateInvitationOpenApi}
     * @memberof SyndicateInvitationCreationResponseOpenApi
     */
    invitation?: SyndicateInvitationOpenApi;
    /**
     * 
     * @type {SyndicateInvitationCreationResponseStateOpenApi}
     * @memberof SyndicateInvitationCreationResponseOpenApi
     */
    state: SyndicateInvitationCreationResponseStateOpenApi;
}

/**
 * Check if a given object implements the SyndicateInvitationCreationResponseOpenApi interface.
 */
export function instanceOfSyndicateInvitationCreationResponseOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function SyndicateInvitationCreationResponseOpenApiFromJSON(json: any): SyndicateInvitationCreationResponseOpenApi {
    return SyndicateInvitationCreationResponseOpenApiFromJSONTyped(json, false);
}

export function SyndicateInvitationCreationResponseOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyndicateInvitationCreationResponseOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deals': !exists(json, 'deals') ? undefined : ((json['deals'] as Array<any>).map(DealOpenApiFromJSON)),
        'investor': !exists(json, 'investor') ? undefined : InvestorOpenApiFromJSON(json['investor']),
        'invitation': !exists(json, 'invitation') ? undefined : SyndicateInvitationOpenApiFromJSON(json['invitation']),
        'state': SyndicateInvitationCreationResponseStateOpenApiFromJSON(json['state']),
    };
}

export function SyndicateInvitationCreationResponseOpenApiToJSON(value?: SyndicateInvitationCreationResponseOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deals': value.deals === undefined ? undefined : ((value.deals as Array<any>).map(DealOpenApiToJSON)),
        'investor': InvestorOpenApiToJSON(value.investor),
        'invitation': SyndicateInvitationOpenApiToJSON(value.invitation),
        'state': SyndicateInvitationCreationResponseStateOpenApiToJSON(value.state),
    };
}

