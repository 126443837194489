import {WallexCollectionSynchronizationAsynchronousActionType} from "./action.type"
import {WallexCollectionSynchronizationAsynchronousActionOpenApi} from "../../../../../generated"

export function mapOpenApiToWallexCollectionSynchronizationAsynchronousAction(
    action: WallexCollectionSynchronizationAsynchronousActionOpenApi
): WallexCollectionSynchronizationAsynchronousActionType {
    return new WallexCollectionSynchronizationAsynchronousActionType({
        id: action.id,
        created: action.created,
        modified: action.modified,
        state: action.state,
        message: action.message,
        processed: action.processed,
        wallexCollectionId: action.wallexCollectionId
    })
}