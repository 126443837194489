
export type ComponentStateType = "ERROR_ACTION"
    | "ERROR_LOADING"
    | "LOADED"
    | "LOADING"
    | "LOADING_WITH_DATA"
    | "SUCCESS"

export interface ComponentStateWithType<T> {
    data?: T
    type: ComponentStateType
}

export type ComponentState<T> = ComponentStateErrorAction<T>
    | ComponentStateErrorLoading<T>
    | ComponentStateLoaded<T>
    | ComponentStateLoading<T>
    | ComponentStateLoadingWithData<T>
    | ComponentStateSuccess<T>

export class ComponentStateErrorAction<T> implements ComponentStateWithType<T> {
    private readonly _data: T
    private readonly _errors?: string[]

    constructor(data: T, errors?: string[]) {
        this._data = data
        this._errors = errors
    }

    get data(): T {
        return this._data
    }

    get errors(): string[] | undefined {
        return this._errors
    }

    public get type(): "ERROR_ACTION" {
        return "ERROR_ACTION"
    }
}

export class ComponentStateErrorLoading<T> implements ComponentStateWithType<T> {
    public get type(): "ERROR_LOADING" {
        return "ERROR_LOADING"
    }
}

export class ComponentStateLoaded<T> implements ComponentStateWithType<T> {
    private readonly _data: T

    constructor(data: T) {
        this._data = data
    }

    get data(): T {
        return this._data
    }

    public get type(): "LOADED" {
        return "LOADED"
    }
}

export class ComponentStateLoading<T> implements ComponentStateWithType<T> {
    public get type(): "LOADING" {
        return "LOADING"
    }
}

export class ComponentStateLoadingWithData<T> implements ComponentStateWithType<T> {
    private readonly _data: T

    constructor(data: T) {
        this._data = data
    }

    get data(): T {
        return this._data
    }

    public get type(): "LOADING_WITH_DATA" {
        return "LOADING_WITH_DATA"
    }
}

export class ComponentStateSuccess<T> implements ComponentStateWithType<T> {
    private readonly _data: T

    constructor(data: T) {
        this._data = data
    }

    get data(): T {
        return this._data
    }

    public get type(): "SUCCESS" {
        return "SUCCESS"
    }
}