import React, {useState} from "react"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import FileUpload from "../upload/upload.component"
import {FileType} from "../file.type"
import {useNavigate} from "react-router-dom"
import {FILE} from "../../../paths"

const FileNew = () => {
    const navigate = useNavigate()
    const [file, setFile] = useState<FileType>()

    if (file !== undefined) {
        navigate(FILE(file.id))
    }

    return (
        <Section>
            <SectionHeading title="Upload new file"/>
            <Alert
                type={AlertType.INFO}
                text={
                    <>
                        All files uploaded here are publically available via the internet. Do not upload confidential files here. <strong>You can change the visibility afterwards.</strong>
                    </>
                }
            />
            {file === undefined && (
                <FileUpload
                    id="file-library-upload"
                    updateFile={setFile}
                />
            )}
        </Section>
    )
}

export default FileNew