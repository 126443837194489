import React, {useContext, useState} from "react"
import {SubmitHandler} from "react-hook-form"
import {useNavigate} from "react-router-dom"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import DealCategoryForm from "./form.component"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {DEAL_CATEGORY} from "../../paths"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import {normalizeValues} from "../../tech/form/form.util"
import {DealCategoryType} from "./deal-category.type"

const DealCategoryNew = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const navigate = useNavigate()
    const [state, setState] = useState<"NONE" | "LOADING" | "ERROR">("NONE")

    const onSubmit: SubmitHandler<DealCategoryType> = async (category) => {
        setState("LOADING")
        try {
            const savedCategory = await fetchClient.dealCategoryApi.create(normalizeValues(category))
            navigate(DEAL_CATEGORY(savedCategory.id!))
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to save deal category."
                />
            )}
            {(state === "NONE" || state === "ERROR") && (
                <Section>
                    <SectionHeading title="Create new deal category"/>
                    <DealCategoryForm onSubmit={onSubmit} submitText="Create deal category" />
                </Section>
            )}
        </>
    )
}

export default DealCategoryNew