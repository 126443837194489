/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FileTypeOpenApi = {
    BackofficePrivateFile: 'BACKOFFICE_PRIVATE_FILE',
    BackofficePublicFile: 'BACKOFFICE_PUBLIC_FILE',
    PaperlessDocument: 'PAPERLESS_DOCUMENT',
    UserUpload: 'USER_UPLOAD'
} as const;
export type FileTypeOpenApi = typeof FileTypeOpenApi[keyof typeof FileTypeOpenApi];


export function FileTypeOpenApiFromJSON(json: any): FileTypeOpenApi {
    return FileTypeOpenApiFromJSONTyped(json, false);
}

export function FileTypeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileTypeOpenApi {
    return json as FileTypeOpenApi;
}

export function FileTypeOpenApiToJSON(value?: FileTypeOpenApi | null): any {
    return value as any;
}

