import React, {FunctionComponent, useState} from "react"
import {FileType} from "../../file.type"
import Alert from "../../../../tech/alert/alert.component"
import {AlertType} from "../../../../tech/alert/type.enum"
import LoadingDots from "../../../../tech/loading/dots/dots.component"
import FileCard from "../../overview/card/card.component"
import Button from "../../../../tech/button/button.component"
import {ButtonStyle} from "../../../../tech/button/style.enum"
import {preventSubmissionOnEnter} from "../../../../tech/form/form.util"
import styles from "./select.module.sass"

export type FileSelectSearchType = "ID" | "IDENTIFIER"

type FileSelectProps = {
    close: () => void
    filesProvider: (searchType: FileSelectSearchType, searchValue: string) => Promise<FileType[]>
    setFormValue: (value: FileType) => void
}

const FileSelect: FunctionComponent<FileSelectProps> = ({
    close,
    filesProvider,
    setFormValue
}) => {
    const [files, setFiles] = useState<FileType[]>([])
    const [searchType, setSearchType] = useState<FileSelectSearchType>("IDENTIFIER")
    const [searchValue, setSearchValue] = useState<string>("")
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADED")

    async function fetch() {
        setState("LOADING")
        try {
            setFiles(await filesProvider(searchType, searchValue))
            setState("LOADED")
        }
        catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    function select(file: FileType) {
        setFormValue(file)
        close()
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && (
                <>
                    <div className={styles.search}>
                        <div>
                            <select
                                onChange={(e) => setSearchType(e.target.value as FileSelectSearchType)}
                                onKeyDown={preventSubmissionOnEnter}
                            >
                                <option value={"IDENTIFIER"}>IDENTIFIER</option>
                                <option value={"ID"}>ID</option>
                            </select>
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder={searchType === "ID" ? "ID" : "Identifier"}
                                onChange={e => setSearchValue(e.target.value)}
                                onKeyDown={preventSubmissionOnEnter}
                            />
                        </div>
                        <div>
                            <Button
                                title="Search"
                                style={ButtonStyle.PRIMARY}
                                type="button"
                                onClick={fetch}
                            />
                        </div>
                    </div>
                    {files.length > 0 ? (
                        <div className={styles.files}>
                            {files.map(f => (
                                <div
                                    className={styles.card}
                                    key={`file-card-${f.id}`}
                                >
                                    <FileCard
                                        file={f}
                                        key={f.id}
                                    />
                                    <div className={styles.select}>
                                        <button
                                            type="button"
                                            onClick={() => select(f)}
                                        >
                                            Select
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <>No files available.</>
                    )}
                </>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load files."
                />
            )}
        </>
    )
}

export default FileSelect