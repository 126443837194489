import {WaitlistApiClient, WaitlistEntryOpenApi, WaitlistFiltersOpenApi, WaitlistSortOpenApi} from "../../generated"
import {WaitlistEntryType} from "./waitlist.type"
import {mapOpenApiToWaitlistEntry, mapWaitlistEntryToOpenApi} from "./waitlist.mapper"
import {PaginatedType, PaginationType} from "../../tech/actions/pagination/pagination.type"
import {extractError} from "../../tech/response/extract.util"
import {ExtractedResponse, FetchResponse} from "../../tech/response/response.type"

export class WaitlistApi {

    constructor(private apiClient: WaitlistApiClient) {
    }

    public async create(waitlistEntry: WaitlistEntryType): Promise<ExtractedResponse<WaitlistEntryType>> {
        try {
            return new FetchResponse(mapOpenApiToWaitlistEntry(await this.apiClient.waitlistEntryCreate({
                waitlistEntryOpenApi: mapWaitlistEntryToOpenApi(waitlistEntry)
            })));
        }
        catch (err) {
            return await extractError(err)
        }
    }

    public async delete(id: string): Promise<void> {
        await this.apiClient.waitlistEntryDelete({ id })
    }

    public async getAllPaginated(
        sort: WaitlistSortOpenApi,
        filters?: WaitlistFiltersOpenApi,
        pagination?: PaginationType
    ): Promise<PaginatedType<WaitlistEntryType>> {
        const result = await this.apiClient.waitlistGetAllPaginated({
            waitlistFiltersOpenApi: filters,
            elementsPerPage: pagination?.elementsPerPage,
            page: pagination?.page,
            sort
        })
        return {
            elements: result.entries.map((entry: WaitlistEntryOpenApi) => mapOpenApiToWaitlistEntry(entry)),
            total: result.total
        }
    }

    public async get(id: string): Promise<WaitlistEntryType> {
        const waitlistEntry = await this.apiClient.waitlistEntryGet({ id })
        return mapOpenApiToWaitlistEntry(waitlistEntry)
    }

    public async update(id: string, waitlistEntry: WaitlistEntryType): Promise<WaitlistEntryType> {
        const response = await this.apiClient.waitlistEntryUpdate({ id, waitlistEntryOpenApi: mapWaitlistEntryToOpenApi(waitlistEntry) })
        return mapOpenApiToWaitlistEntry(response)
    }

}