/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DealOpenApi } from './DealOpenApi';
import {
    DealOpenApiFromJSON,
    DealOpenApiFromJSONTyped,
    DealOpenApiToJSON,
} from './DealOpenApi';

/**
 * 
 * @export
 * @interface DealPaginatedResponseOpenApi
 */
export interface DealPaginatedResponseOpenApi {
    /**
     * 
     * @type {Array<DealOpenApi>}
     * @memberof DealPaginatedResponseOpenApi
     */
    deals: Array<DealOpenApi>;
    /**
     * 
     * @type {number}
     * @memberof DealPaginatedResponseOpenApi
     */
    total: number;
}

/**
 * Check if a given object implements the DealPaginatedResponseOpenApi interface.
 */
export function instanceOfDealPaginatedResponseOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deals" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function DealPaginatedResponseOpenApiFromJSON(json: any): DealPaginatedResponseOpenApi {
    return DealPaginatedResponseOpenApiFromJSONTyped(json, false);
}

export function DealPaginatedResponseOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealPaginatedResponseOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deals': ((json['deals'] as Array<any>).map(DealOpenApiFromJSON)),
        'total': json['total'],
    };
}

export function DealPaginatedResponseOpenApiToJSON(value?: DealPaginatedResponseOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deals': ((value.deals as Array<any>).map(DealOpenApiToJSON)),
        'total': value.total,
    };
}

