import React, {FunctionComponent, useContext, useEffect, useState} from "react"
import {Link} from "react-router-dom"
import LoadingDots from "../../tech/loading/dots/dots.component"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {InvestmentType} from "../investment/investment.type"
import {INVESTMENT} from "../../paths"
import styles from "./dashboard.module.css"

const DashboardPendingInvestments: FunctionComponent = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [investments, setInvestments] = useState<InvestmentType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                setInvestments(await fetchClient.investmentApi.getPendingInvestments())
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    return (
        <div className={styles.box}>
            <div>
                <h3>Pending investments</h3>
                {state === "LOADED" && (
                    <>
                        {investments.length > 0 ? (
                            <>
                                For these investments, the contract has to be signed or payment is due:
                                <ul>
                                    {investments.map(i => (
                                        <li key={i.investor.id}>
                                            <Link to={INVESTMENT(i.id)}>
                                                {i.deal.name.completeOnboarding}:{" "}
                                                {i.investor.person?.fullName}{" "}
                                                ({!i.signed ? "Contract not yet signed" : "Not paid yet"})
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <>No pending investments found.</>
                        )}
                    </>
                )}
                {state === "LOADING" && <LoadingDots/>}
                {state === "ERROR" && <i>Error while loading.</i>}
            </div>
        </div>
    )
}

export default DashboardPendingInvestments