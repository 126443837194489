import {ShareClassType} from "./share-class.type"
import {ShareClassOpenApi} from "../../generated"
import {mapDealFeeStructureToOpenApi, mapOpenApiToDealFeeStructure} from "../deal/fee/deal-fee-structure.mapper"

export function mapOpenApiToShareClass(shareClass: ShareClassOpenApi): ShareClassType {
    return {
        id: shareClass.id,
        name: shareClass.name,
        nameInDealContract: shareClass.nameInDealContract,
        feeStructure: mapOpenApiToDealFeeStructure(shareClass.feeStructure)
    }
}

export function mapShareClassToOpenApi(shareClass: ShareClassType): ShareClassOpenApi {
    return {
        id: shareClass.id,
        name: shareClass.name,
        nameInDealContract: shareClass.nameInDealContract,
        feeStructure: mapDealFeeStructureToOpenApi(shareClass.feeStructure)
    }
}