import React, {useContext, useEffect, useState} from "react"
import {Navigate, useParams} from "react-router-dom"
import Section from "../../../tech/section/section.component"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import {DealType} from "../deal.type"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {DEALS} from "../../../paths"
import {match, P} from "ts-pattern"
import {
    ComponentState,
    ComponentStateErrorAction,
    ComponentStateErrorLoading, ComponentStateLoaded,
    ComponentStateLoading,
    ComponentStateLoadingWithData,
    ComponentStateSuccess
} from "../../../tech/state/state.type"
import BackendValidationErrorsAlert from "../../../tech/validation/alert.component"

const DealDelete = () => {
    let { id } = useParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<ComponentState<DealType | undefined>>(new ComponentStateLoading())

    useEffect(() => {
        const fetch = async () => {
            try {
                setState(new ComponentStateLoaded(await fetchClient.dealApi.get(id!)))
            } catch (err) {
                console.error(err)
                setState(new ComponentStateErrorLoading())
            }
        }
        fetch()
    }, [fetchClient, id])

    const del = async () => {
        if (state.type !== "LOADED" && state.type !== "ERROR_ACTION") {
            throw new Error("Not allowed.")
        }
        setState(new ComponentStateLoadingWithData(state.data))
        const res = await fetchClient.dealApi.delete(id!)
        match(res)
            .with(
                { type: "RESPONSE" },
                (r) => setState(new ComponentStateSuccess(state.data))
            )
            .with(
                { type: "RESPONSE_ERROR" },
                () => setState(new ComponentStateErrorAction(state.data))
            )
            .with(
                { type: "VALIDATION_ERRORS" },
                (r) => setState(new ComponentStateErrorAction(state.data, r.errors))
            )
            .exhaustive()
    }

    return match(state)
        .with(
            { type: "LOADED" },
            (s) => (
                <Section>
                    <div className="mb-5">
                        Do you really want to delete deal <strong>{s.data?.name.completeOnboarding}</strong> with
                        id <strong>{s.data?.id}</strong>?
                    </div>
                    <Button
                        title="Delete permanently"
                        style={ButtonStyle.DELETE}
                        onClick={del}
                        type="button"
                    />
                </Section>
            )
        )
        .with(
            {type: "ERROR_ACTION"},
            (s) => (
                <>
                    {s.type === "ERROR_ACTION" && s.errors && <BackendValidationErrorsAlert errors={s.errors}/>}
                    {s.type === "ERROR_ACTION" && !s.errors && (
                        <Alert
                            type={AlertType.ERROR}
                            text="Failed to delete."
                        />
                    )}
                </>
            )
        )
        .with(
            { type: "ERROR_LOADING" },
            () => (
                <Alert
                    type={AlertType.ERROR}
                    text="Loading failed."
                />
            )
        )
        .with(
            P.union({ type: "LOADING" }, { type: "LOADING_WITH_DATA" }),
            () => <LoadingDots/>
        )
        .with(
            { type: "SUCCESS" },
            (s) => <Navigate to={DEALS}/>
        )
        .exhaustive()
}

export default DealDelete