import React, {FunctionComponent} from "react"
import styles from "./circle.module.sass"

type LoadingCircleProps = {
    width: number
    height: number
}

const LoadingCircle: FunctionComponent<LoadingCircleProps> = ({ width, height }) => {
    return (
        <div
            className={styles.loadingCircle}
            style={{ width, height }}
        >
            <div style={{ width, height }}/>
            <div style={{ width, height }}/>
            <div style={{ width, height }}/>
            <div style={{ width, height }}/>
        </div>
    )
}

export default LoadingCircle