import React, {FunctionComponent, useContext, useEffect, useState} from "react"
import LoadingDots from "../../tech/loading/dots/dots.component"
import {DealType} from "../deal/deal.type"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {Link} from "react-router-dom"
import {COMPANIES} from "../../paths"
import styles from "./dashboard.module.css"

const DashboardDealsWithoutCompany: FunctionComponent = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [dealsWithoutCompany, setDealsWithoutCompany] = useState<DealType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                setDealsWithoutCompany(await fetchClient.dealApi.getAllWithoutCompany())
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    return (
        <div className={styles.box}>
            <div>
                <h3>Deals without company</h3>
                {state === "LOADED" && (
                    <>
                        {dealsWithoutCompany.length > 0 ? (
                            <>
                                The following deals have no company assigned:
                                <ul>
                                    {dealsWithoutCompany.map(d => (
                                        <li key={d.id!}>
                                            <Link to={COMPANIES}>
                                                {d.name.completeOnboarding}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <>No deals without company.</>
                        )}
                    </>
                )}
                {state === "LOADING" && <LoadingDots/>}
                {state === "ERROR" && <i>Error while loading.</i>}
            </div>
        </div>
    )
}

export default DashboardDealsWithoutCompany