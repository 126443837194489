import React, {FunctionComponent} from "react"
import {SubmitHandler, useForm} from "react-hook-form"
import formStyles from "../../tech/form/form.module.css"
import FormError from "../../tech/form/error.component"
import {DealGroupType} from "./deal-group.type"

type DealGroupFormProps = {
    dealGroup?: DealGroupType
    onSubmit: SubmitHandler<DealGroupType>
    submitText: string
}

const DealGroupForm: FunctionComponent<DealGroupFormProps> = ({ dealGroup, onSubmit, submitText }) => {
    const { register, handleSubmit, formState: { errors } } = useForm<DealGroupType>({
        defaultValues: dealGroup
    })

    return (
        <form
            className={formStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <input type="hidden" {...register("id")} />
            <input type="hidden" {...register("sequentialId")} />

            {dealGroup && (
                <label>
                    <span>ID:</span> {dealGroup.id}<br/>
                </label>
            )}

            <label>
                <span>Name</span>
                <input
                    type="text"
                    placeholder="Name"
                    {...register("name", {
                        required: "Name is required.",
                        minLength: { value: 3, message: "Too short." }
                    })}
                />
                <FormError field="name" errors={errors} />
            </label>

            <label>
                <span>Description</span>
                <textarea
                    {...register("description", {
                        maxLength: { value: 500, message: "Too long." }
                    })}
                ></textarea>
                <FormError field="description" errors={errors} />
            </label>

            <button
                type="submit"
                className={formStyles.submitButton}
            >
                {submitText}
            </button>
        </form>
    )
}

export default DealGroupForm