/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvestorOnboardingStageOpenApi } from './InvestorOnboardingStageOpenApi';
import {
    InvestorOnboardingStageOpenApiFromJSON,
    InvestorOnboardingStageOpenApiFromJSONTyped,
    InvestorOnboardingStageOpenApiToJSON,
} from './InvestorOnboardingStageOpenApi';
import type { InvestorOnboardingStageTypeOpenApi } from './InvestorOnboardingStageTypeOpenApi';
import {
    InvestorOnboardingStageTypeOpenApiFromJSON,
    InvestorOnboardingStageTypeOpenApiFromJSONTyped,
    InvestorOnboardingStageTypeOpenApiToJSON,
} from './InvestorOnboardingStageTypeOpenApi';

/**
 * 
 * @export
 * @interface InvestorOnboardingStageWrapperOpenApi
 */
export interface InvestorOnboardingStageWrapperOpenApi {
    /**
     * 
     * @type {InvestorOnboardingStageTypeOpenApi}
     * @memberof InvestorOnboardingStageWrapperOpenApi
     */
    type: InvestorOnboardingStageTypeOpenApi;
    /**
     * 
     * @type {InvestorOnboardingStageOpenApi}
     * @memberof InvestorOnboardingStageWrapperOpenApi
     */
    stage: InvestorOnboardingStageOpenApi;
}

/**
 * Check if a given object implements the InvestorOnboardingStageWrapperOpenApi interface.
 */
export function instanceOfInvestorOnboardingStageWrapperOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "stage" in value;

    return isInstance;
}

export function InvestorOnboardingStageWrapperOpenApiFromJSON(json: any): InvestorOnboardingStageWrapperOpenApi {
    return InvestorOnboardingStageWrapperOpenApiFromJSONTyped(json, false);
}

export function InvestorOnboardingStageWrapperOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorOnboardingStageWrapperOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': InvestorOnboardingStageTypeOpenApiFromJSON(json['type']),
        'stage': InvestorOnboardingStageOpenApiFromJSON(json['stage']),
    };
}

export function InvestorOnboardingStageWrapperOpenApiToJSON(value?: InvestorOnboardingStageWrapperOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': InvestorOnboardingStageTypeOpenApiToJSON(value.type),
        'stage': InvestorOnboardingStageOpenApiToJSON(value.stage),
    };
}

