/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AsynchronousActionStateOpenApi } from './AsynchronousActionStateOpenApi';
import {
    AsynchronousActionStateOpenApiFromJSON,
    AsynchronousActionStateOpenApiFromJSONTyped,
    AsynchronousActionStateOpenApiToJSON,
} from './AsynchronousActionStateOpenApi';
import type { NotificationAttributesOpenApi } from './NotificationAttributesOpenApi';
import {
    NotificationAttributesOpenApiFromJSON,
    NotificationAttributesOpenApiFromJSONTyped,
    NotificationAttributesOpenApiToJSON,
} from './NotificationAttributesOpenApi';
import type { NotificationChannelOpenApi } from './NotificationChannelOpenApi';
import {
    NotificationChannelOpenApiFromJSON,
    NotificationChannelOpenApiFromJSONTyped,
    NotificationChannelOpenApiToJSON,
} from './NotificationChannelOpenApi';
import type { NotificationReferenceOpenApi } from './NotificationReferenceOpenApi';
import {
    NotificationReferenceOpenApiFromJSON,
    NotificationReferenceOpenApiFromJSONTyped,
    NotificationReferenceOpenApiToJSON,
} from './NotificationReferenceOpenApi';
import type { NotificationSeriesOpenApi } from './NotificationSeriesOpenApi';
import {
    NotificationSeriesOpenApiFromJSON,
    NotificationSeriesOpenApiFromJSONTyped,
    NotificationSeriesOpenApiToJSON,
} from './NotificationSeriesOpenApi';
import type { NotificationTypeOpenApi } from './NotificationTypeOpenApi';
import {
    NotificationTypeOpenApiFromJSON,
    NotificationTypeOpenApiFromJSONTyped,
    NotificationTypeOpenApiToJSON,
} from './NotificationTypeOpenApi';

/**
 * 
 * @export
 * @interface NotificationAsynchronousActionOpenApi
 */
export interface NotificationAsynchronousActionOpenApi {
    /**
     * 
     * @type {string}
     * @memberof NotificationAsynchronousActionOpenApi
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof NotificationAsynchronousActionOpenApi
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof NotificationAsynchronousActionOpenApi
     */
    modified: Date;
    /**
     * 
     * @type {AsynchronousActionStateOpenApi}
     * @memberof NotificationAsynchronousActionOpenApi
     */
    state: AsynchronousActionStateOpenApi;
    /**
     * 
     * @type {string}
     * @memberof NotificationAsynchronousActionOpenApi
     */
    message?: string;
    /**
     * 
     * @type {Date}
     * @memberof NotificationAsynchronousActionOpenApi
     */
    processed?: Date;
    /**
     * 
     * @type {number}
     * @memberof NotificationAsynchronousActionOpenApi
     */
    retryCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof NotificationAsynchronousActionOpenApi
     */
    retryTime?: Date;
    /**
     * 
     * @type {NotificationAttributesOpenApi}
     * @memberof NotificationAsynchronousActionOpenApi
     */
    attributes?: NotificationAttributesOpenApi;
    /**
     * 
     * @type {NotificationChannelOpenApi}
     * @memberof NotificationAsynchronousActionOpenApi
     */
    channel: NotificationChannelOpenApi;
    /**
     * 
     * @type {NotificationReferenceOpenApi}
     * @memberof NotificationAsynchronousActionOpenApi
     */
    reference: NotificationReferenceOpenApi;
    /**
     * 
     * @type {Date}
     * @memberof NotificationAsynchronousActionOpenApi
     */
    scheduled?: Date;
    /**
     * 
     * @type {NotificationSeriesOpenApi}
     * @memberof NotificationAsynchronousActionOpenApi
     */
    series?: NotificationSeriesOpenApi;
    /**
     * 
     * @type {NotificationTypeOpenApi}
     * @memberof NotificationAsynchronousActionOpenApi
     */
    type: NotificationTypeOpenApi;
}

/**
 * Check if a given object implements the NotificationAsynchronousActionOpenApi interface.
 */
export function instanceOfNotificationAsynchronousActionOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "channel" in value;
    isInstance = isInstance && "reference" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function NotificationAsynchronousActionOpenApiFromJSON(json: any): NotificationAsynchronousActionOpenApi {
    return NotificationAsynchronousActionOpenApiFromJSONTyped(json, false);
}

export function NotificationAsynchronousActionOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationAsynchronousActionOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'state': AsynchronousActionStateOpenApiFromJSON(json['state']),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'processed': !exists(json, 'processed') ? undefined : (new Date(json['processed'])),
        'retryCount': !exists(json, 'retryCount') ? undefined : json['retryCount'],
        'retryTime': !exists(json, 'retryTime') ? undefined : (new Date(json['retryTime'])),
        'attributes': !exists(json, 'attributes') ? undefined : NotificationAttributesOpenApiFromJSON(json['attributes']),
        'channel': NotificationChannelOpenApiFromJSON(json['channel']),
        'reference': NotificationReferenceOpenApiFromJSON(json['reference']),
        'scheduled': !exists(json, 'scheduled') ? undefined : (new Date(json['scheduled'])),
        'series': !exists(json, 'series') ? undefined : NotificationSeriesOpenApiFromJSON(json['series']),
        'type': NotificationTypeOpenApiFromJSON(json['type']),
    };
}

export function NotificationAsynchronousActionOpenApiToJSON(value?: NotificationAsynchronousActionOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'modified': (value.modified.toISOString()),
        'state': AsynchronousActionStateOpenApiToJSON(value.state),
        'message': value.message,
        'processed': value.processed === undefined ? undefined : (value.processed.toISOString()),
        'retryCount': value.retryCount,
        'retryTime': value.retryTime === undefined ? undefined : (value.retryTime.toISOString()),
        'attributes': NotificationAttributesOpenApiToJSON(value.attributes),
        'channel': NotificationChannelOpenApiToJSON(value.channel),
        'reference': NotificationReferenceOpenApiToJSON(value.reference),
        'scheduled': value.scheduled === undefined ? undefined : (value.scheduled.toISOString()),
        'series': NotificationSeriesOpenApiToJSON(value.series),
        'type': NotificationTypeOpenApiToJSON(value.type),
    };
}

