import React, {ReactNode} from "react"
import ActionsSort, {ActionsSortProps} from "./sort/sort.component"
import ActionsFilter, {ActionsFilterProps} from "./filter/filter.component"
import styles from "./actions.module.sass"
import Pagination, {PaginationProps} from "./pagination/pagination.component"

type ActionsProps<S, F> = {
    children: ReactNode
    filter: ActionsFilterProps<F>
    pagination?: PaginationProps
    sort: ActionsSortProps<S>
}

function Actions<S, F>({
    children,
    filter,
    pagination,
    sort
}: ActionsProps<S, F>) {
    const p = pagination && (
        <Pagination
            label={pagination.label}
            elementsPerPage={pagination.elementsPerPage}
            numberElements={pagination.numberElements}
        />
    )
    return (
        <div className={styles.actions}>
            <div className={styles.top}>
                <div className={styles.sortAndFilter}>
                    <div className={styles.part}>
                        <ActionsSort
                            current={sort.current}
                            options={sort.options}
                        />
                    </div>
                    {sort.options.length > 0 && filter.filters.length > 0 && (
                        <div className={styles.spacer}/>
                    )}
                    <div className={styles.part}>
                        <ActionsFilter
                            current={filter.current}
                            filters={filter.filters}
                        />
                    </div>
                </div>
                {p}
            </div>
            <div className={styles.middle}>
                {children}
            </div>
            <div className={styles.bottom}>
                {p}
            </div>
        </div>
    )
}

export default Actions