import React, {useContext, useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import ManualReconciliationBankAccountForm from "./form.component"
import {SubmitHandler} from "react-hook-form"
import {normalizeValues} from "../../../tech/form/form.util"
import {ManualReconciliationBankAccountWithDealsType} from "./manual-reconciliation-bank-account.type"
import {SinglePurposeVehicleSortOpenApi} from "../../../generated"
import {SinglePurposeVehicleType} from "../../single-purpose-vehicle/single-purpose-vehicle.type"
import BackendValidationErrorsAlert from "../../../tech/validation/alert.component"
import {match} from "ts-pattern"

const ManualReconciliationBankAccountEdit = () => {
    let { id } = useParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR" | "SUCCESS">("LOADING")
    const [account, setAccount] = useState<ManualReconciliationBankAccountWithDealsType>()
    const [singlePurposeVehicles, setSinglePurposeVehicles] = useState<SinglePurposeVehicleType[]>([])
    const [errors, setErrors] = useState<string[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const [a, spvs] = await Promise.all([
                    fetchClient.reconciliationApi.getManualReconciliationBankAccount(id!),
                    fetchClient.singlePurposeVehicleApi.getAllPaginated(SinglePurposeVehicleSortOpenApi.NameAscending)
                ])
                setAccount(a)
                setSinglePurposeVehicles(spvs.elements)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const onSubmit: SubmitHandler<ManualReconciliationBankAccountWithDealsType> = async (account) => {
        setState("LOADING")
        const result = await fetchClient.reconciliationApi.updateManualReconciliationBankAccount(id!, normalizeValues(account))
        match(result)
            .with(
                { type: "RESPONSE" },
                (res) => {
                    window.scrollTo({ top: 0 })
                    setState("SUCCESS")
                }
            )
            .with(
                { type: "RESPONSE_ERROR" },
                () => {
                    window.scrollTo({ top: 0 })
                    setState("ERROR")
                }
            )
            .with(
                { type: "VALIDATION_ERRORS" },
                (res) => {
                    window.scrollTo({ top: 0 })
                    setState("ERROR")
                    setErrors(res.errors)
                }
            )
            .exhaustive()
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <>
                    {errors && errors.length > 0 ? (
                        <BackendValidationErrorsAlert errors={errors}/>
                    ) : (
                        <Alert
                            type={AlertType.ERROR}
                            text="Failed to save manual reconciliation bank account."
                        />
                    )}
                </>
            )}
            {state === "SUCCESS" && (
                <Alert
                    type={AlertType.SUCCESS}
                    text="Saved successfully."
                />
            )}
            {(state === "LOADED" || state === "ERROR" || state === "SUCCESS") && account && (
                <Section>
                    <SectionHeading title={`Edit Manual Reconciliation Bank Account: ${account.number} (${account.holderSinglePurposeVehicle.name})`}/>
                    <ManualReconciliationBankAccountForm
                        account={account}
                        singlePurposeVehicles={singlePurposeVehicles}
                        onSubmit={onSubmit}
                        submitText="Save Manual Reconciliation Bank Account"
                    />
                </Section>
            )}
        </>
    )
}

export default ManualReconciliationBankAccountEdit