/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CollectionDeviationStateOpenApi = {
    Open: 'OPEN',
    Completed: 'COMPLETED'
} as const;
export type CollectionDeviationStateOpenApi = typeof CollectionDeviationStateOpenApi[keyof typeof CollectionDeviationStateOpenApi];


export function CollectionDeviationStateOpenApiFromJSON(json: any): CollectionDeviationStateOpenApi {
    return CollectionDeviationStateOpenApiFromJSONTyped(json, false);
}

export function CollectionDeviationStateOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectionDeviationStateOpenApi {
    return json as CollectionDeviationStateOpenApi;
}

export function CollectionDeviationStateOpenApiToJSON(value?: CollectionDeviationStateOpenApi | null): any {
    return value as any;
}

