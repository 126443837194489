import {UserApiClient, UserFiltersOpenApi, UserOpenApi, UserSortOpenApi} from "../../generated"
import {UserType} from "./user.type"
import {mapOpenApiToUser, mapUserToOpenApi} from "./user.mapper"
import {PaginatedType, PaginationType} from "../../tech/actions/pagination/pagination.type"

export class UserApi {

    constructor(private apiClient: UserApiClient) {
    }

    public async getAllPaginated(
        sort: UserSortOpenApi,
        filters?: UserFiltersOpenApi,
        pagination?: PaginationType
    ): Promise<PaginatedType<UserType>> {
        const result = await this.apiClient.userGetAllPaginated({
            userFiltersOpenApi: filters,
            elementsPerPage: pagination?.elementsPerPage,
            page: pagination?.page,
            sort
        })
        return {
            elements: result.users.map((user: UserOpenApi) => mapOpenApiToUser(user)),
            total: result.total
        }
    }

    public async getAllWithoutInvestor(): Promise<UserType[]> {
        return (await this.apiClient.userGetAllWithoutInvestor())
            .map((user: UserOpenApi) => mapOpenApiToUser(user))
    }

    public async get(id: string): Promise<UserType> {
        const user = await this.apiClient.userGet({ id })
        return mapOpenApiToUser(user)
    }

    public async update(id: string, user: UserType): Promise<UserType> {
        const response = await this.apiClient.userUpdate({ id, userOpenApi: mapUserToOpenApi(user) })
        return mapOpenApiToUser(response)
    }

}