/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FileSourceTypeOpenApi = {
    ImgixScrSet: 'IMGIX_SCR_SET',
    PublicS3Url: 'PUBLIC_S3_URL'
} as const;
export type FileSourceTypeOpenApi = typeof FileSourceTypeOpenApi[keyof typeof FileSourceTypeOpenApi];


export function FileSourceTypeOpenApiFromJSON(json: any): FileSourceTypeOpenApi {
    return FileSourceTypeOpenApiFromJSONTyped(json, false);
}

export function FileSourceTypeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileSourceTypeOpenApi {
    return json as FileSourceTypeOpenApi;
}

export function FileSourceTypeOpenApiToJSON(value?: FileSourceTypeOpenApi | null): any {
    return value as any;
}

