/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KycWaitingForReviewOnboardingStageOpenApi
 */
export interface KycWaitingForReviewOnboardingStageOpenApi {
    /**
     * 
     * @type {string}
     * @memberof KycWaitingForReviewOnboardingStageOpenApi
     */
    objectType?: string;
}

/**
 * Check if a given object implements the KycWaitingForReviewOnboardingStageOpenApi interface.
 */
export function instanceOfKycWaitingForReviewOnboardingStageOpenApi(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function KycWaitingForReviewOnboardingStageOpenApiFromJSON(json: any): KycWaitingForReviewOnboardingStageOpenApi {
    return KycWaitingForReviewOnboardingStageOpenApiFromJSONTyped(json, false);
}

export function KycWaitingForReviewOnboardingStageOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): KycWaitingForReviewOnboardingStageOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectType': !exists(json, 'objectType') ? undefined : json['objectType'],
    };
}

export function KycWaitingForReviewOnboardingStageOpenApiToJSON(value?: KycWaitingForReviewOnboardingStageOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectType': value.objectType,
    };
}

