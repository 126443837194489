import {DealWithValuationsType} from "./deal-with-valuations.type"
import {DealWithValuationsOpenApi} from "../../../generated"
import {mapOpenApiToValuation} from "../../valuation/valuation.mapper"
import {mapOpenApiToDeal} from "../deal.mapper"

export function mapOpenApiToDealWithValuations(dealWithValuation: DealWithValuationsOpenApi): DealWithValuationsType {
    return {
        deal: mapOpenApiToDeal(dealWithValuation.deal),
        valuations: dealWithValuation.valuations.map(valuation => mapOpenApiToValuation(valuation))
    }
}