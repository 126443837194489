/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CollectionAccountOpenApi,
  CollectionDeviationOpenApi,
  CollectionDeviationStateOpenApi,
  CollectionDeviationUpdateRequestOpenApi,
  CollectionOpenApi,
} from '../models';
import {
    CollectionAccountOpenApiFromJSON,
    CollectionAccountOpenApiToJSON,
    CollectionDeviationOpenApiFromJSON,
    CollectionDeviationOpenApiToJSON,
    CollectionDeviationStateOpenApiFromJSON,
    CollectionDeviationStateOpenApiToJSON,
    CollectionDeviationUpdateRequestOpenApiFromJSON,
    CollectionDeviationUpdateRequestOpenApiToJSON,
    CollectionOpenApiFromJSON,
    CollectionOpenApiToJSON,
} from '../models';

export interface CollectionAccountGetForInvestmentIdRequest {
    investmentId: string;
}

export interface CollectionDeviationGetRequest {
    id: string;
}

export interface CollectionDeviationGetAllRequest {
    investmentId?: string;
    state?: CollectionDeviationStateOpenApi;
}

export interface CollectionDeviationUpdateRequest {
    id: string;
    collectionDeviationUpdateRequestOpenApi?: CollectionDeviationUpdateRequestOpenApi;
}

export interface CollectionGetAllForInvestmentIdRequest {
    investmentId: string;
}

/**
 * 
 */
export class CollectionApiClient extends runtime.BaseAPI {

    /**
     * Get collection account for given investment id
     */
    async collectionAccountGetForInvestmentIdRaw(requestParameters: CollectionAccountGetForInvestmentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectionAccountOpenApi>> {
        if (requestParameters.investmentId === null || requestParameters.investmentId === undefined) {
            throw new runtime.RequiredError('investmentId','Required parameter requestParameters.investmentId was null or undefined when calling collectionAccountGetForInvestmentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/collection/account/{investmentId}`.replace(`{${"investmentId"}}`, encodeURIComponent(String(requestParameters.investmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectionAccountOpenApiFromJSON(jsonValue));
    }

    /**
     * Get collection account for given investment id
     */
    async collectionAccountGetForInvestmentId(requestParameters: CollectionAccountGetForInvestmentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectionAccountOpenApi> {
        const response = await this.collectionAccountGetForInvestmentIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get collection deviation by id
     */
    async collectionDeviationGetRaw(requestParameters: CollectionDeviationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectionDeviationOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling collectionDeviationGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/collection/deviation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectionDeviationOpenApiFromJSON(jsonValue));
    }

    /**
     * Get collection deviation by id
     */
    async collectionDeviationGet(requestParameters: CollectionDeviationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectionDeviationOpenApi> {
        const response = await this.collectionDeviationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all collection deviations
     */
    async collectionDeviationGetAllRaw(requestParameters: CollectionDeviationGetAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CollectionDeviationOpenApi>>> {
        const queryParameters: any = {};

        if (requestParameters.investmentId !== undefined) {
            queryParameters['investmentId'] = requestParameters.investmentId;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/collection/deviation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CollectionDeviationOpenApiFromJSON));
    }

    /**
     * Get all collection deviations
     */
    async collectionDeviationGetAll(requestParameters: CollectionDeviationGetAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CollectionDeviationOpenApi>> {
        const response = await this.collectionDeviationGetAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update collection deviation
     */
    async collectionDeviationUpdateRaw(requestParameters: CollectionDeviationUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectionDeviationOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling collectionDeviationUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/collection/deviation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CollectionDeviationUpdateRequestOpenApiToJSON(requestParameters.collectionDeviationUpdateRequestOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectionDeviationOpenApiFromJSON(jsonValue));
    }

    /**
     * Update collection deviation
     */
    async collectionDeviationUpdate(requestParameters: CollectionDeviationUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectionDeviationOpenApi> {
        const response = await this.collectionDeviationUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get collections for given investment id
     */
    async collectionGetAllForInvestmentIdRaw(requestParameters: CollectionGetAllForInvestmentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CollectionOpenApi>>> {
        if (requestParameters.investmentId === null || requestParameters.investmentId === undefined) {
            throw new runtime.RequiredError('investmentId','Required parameter requestParameters.investmentId was null or undefined when calling collectionGetAllForInvestmentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/collection/{investmentId}`.replace(`{${"investmentId"}}`, encodeURIComponent(String(requestParameters.investmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CollectionOpenApiFromJSON));
    }

    /**
     * Get collections for given investment id
     */
    async collectionGetAllForInvestmentId(requestParameters: CollectionGetAllForInvestmentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CollectionOpenApi>> {
        const response = await this.collectionGetAllForInvestmentIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
