import React from "react"
import {useSearchParams} from "react-router-dom"
import {ArrowsUpDownIcon} from "@heroicons/react/24/outline"
import {ActionsSortOptionType} from "./sort.type"
import {setSort} from "./sort.util"
import styles from "./sort.module.sass"

export type ActionsSortProps<T> = {
    current: ActionsSortOptionType<T>
    options: ActionsSortOptionType<T>[]
}

function ActionsSort<T>({
    current,
    options
}: ActionsSortProps<T>) {
    const [searchParams, setSearchParams] = useSearchParams()

    if (options.length < 1) {
        return <></>
    }

    return (
        <div className={styles.sort}>
            <div className={styles.icon}>
                <div>
                    <ArrowsUpDownIcon/>
                </div>
            </div>
            <div className={styles.select}>
                <div>
                    <select
                        defaultValue={`${current.id}`}
                        onChange={(e) => setSearchParams(setSort(e.target.value, searchParams))}
                    >
                        {options.map(sortOption => (
                            <option
                                value={`${sortOption.id}`}
                                key={`sort-option-${sortOption.id}`}
                            >
                                {sortOption.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    )
}

export default ActionsSort