/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValidationErrorOpenApi } from './ValidationErrorOpenApi';
import {
    ValidationErrorOpenApiFromJSON,
    ValidationErrorOpenApiFromJSONTyped,
    ValidationErrorOpenApiToJSON,
} from './ValidationErrorOpenApi';

/**
 * 
 * @export
 * @interface ValidationResponseOpenApi
 */
export interface ValidationResponseOpenApi {
    /**
     * 
     * @type {Array<ValidationErrorOpenApi>}
     * @memberof ValidationResponseOpenApi
     */
    errors?: Array<ValidationErrorOpenApi>;
}

/**
 * Check if a given object implements the ValidationResponseOpenApi interface.
 */
export function instanceOfValidationResponseOpenApi(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ValidationResponseOpenApiFromJSON(json: any): ValidationResponseOpenApi {
    return ValidationResponseOpenApiFromJSONTyped(json, false);
}

export function ValidationResponseOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationResponseOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errors': !exists(json, 'errors') ? undefined : ((json['errors'] as Array<any>).map(ValidationErrorOpenApiFromJSON)),
    };
}

export function ValidationResponseOpenApiToJSON(value?: ValidationResponseOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errors': value.errors === undefined ? undefined : ((value.errors as Array<any>).map(ValidationErrorOpenApiToJSON)),
    };
}

