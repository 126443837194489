/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SyndicateOpenApi } from './SyndicateOpenApi';
import {
    SyndicateOpenApiFromJSON,
    SyndicateOpenApiFromJSONTyped,
    SyndicateOpenApiToJSON,
} from './SyndicateOpenApi';

/**
 * 
 * @export
 * @interface SyndicatePaginatedResponseOpenApi
 */
export interface SyndicatePaginatedResponseOpenApi {
    /**
     * 
     * @type {Array<SyndicateOpenApi>}
     * @memberof SyndicatePaginatedResponseOpenApi
     */
    syndicates: Array<SyndicateOpenApi>;
    /**
     * 
     * @type {number}
     * @memberof SyndicatePaginatedResponseOpenApi
     */
    total: number;
}

/**
 * Check if a given object implements the SyndicatePaginatedResponseOpenApi interface.
 */
export function instanceOfSyndicatePaginatedResponseOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "syndicates" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function SyndicatePaginatedResponseOpenApiFromJSON(json: any): SyndicatePaginatedResponseOpenApi {
    return SyndicatePaginatedResponseOpenApiFromJSONTyped(json, false);
}

export function SyndicatePaginatedResponseOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyndicatePaginatedResponseOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'syndicates': ((json['syndicates'] as Array<any>).map(SyndicateOpenApiFromJSON)),
        'total': json['total'],
    };
}

export function SyndicatePaginatedResponseOpenApiToJSON(value?: SyndicatePaginatedResponseOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'syndicates': ((value.syndicates as Array<any>).map(SyndicateOpenApiToJSON)),
        'total': value.total,
    };
}

