import React, {FunctionComponent} from "react"
import FilePreview from "../../preview/preview.component"
import {FileType} from "../../file.type"
import {FileTypeOpenApi} from "../../../../generated"
import styles from "./card.module.sass"

type FileCardProps = {
    file: FileType
}

const FileCard: FunctionComponent<FileCardProps> = ({ file }) => {
    return (
        <div className={styles.card}>
            <FilePreview
                file={file}
                maxHeight={65}
            />
            <div className={styles.identifier}>
                {file.identifier}
            </div>
            <div className={`${styles.id} ${file.identifier ? styles.small : ""}`}>
                {file.id}
            </div>
            <div className={styles.type}>
                {file.type === FileTypeOpenApi.BackofficePublicFile
                    ? "public"
                    : "private"}
            </div>
        </div>
    )
}

export default FileCard