import {CurrencyOpenApi} from "../../generated"

export const mapCurrencyToString = (currency: CurrencyOpenApi): string => {
    switch (currency) {
        case "AUD": return "Australian Dollar (AUD)"
        case "CHF": return "Swiss Franc (CHF)"
        case "EUR": return "Euro (EUR)"
        case "GBP": return "Pound sterling (GBP)"
        case "SGD": return "Singapore Dollar (SGD)"
        case "USD": return "US-Dollar (USD)"
    }
}