/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FaqEntryOpenApi,
  ValidationResponseOpenApi,
} from '../models';
import {
    FaqEntryOpenApiFromJSON,
    FaqEntryOpenApiToJSON,
    ValidationResponseOpenApiFromJSON,
    ValidationResponseOpenApiToJSON,
} from '../models';

export interface FaqEntryCreateRequest {
    faqEntryOpenApi?: FaqEntryOpenApi;
}

export interface FaqEntryDeleteRequest {
    id: string;
}

export interface FaqEntryGetRequest {
    id: string;
}

export interface FaqEntryUpdateRequest {
    id: string;
    faqEntryOpenApi?: FaqEntryOpenApi;
}

/**
 * 
 */
export class FAQEntryApiClient extends runtime.BaseAPI {

    /**
     * Create new FAQ entry
     */
    async faqEntryCreateRaw(requestParameters: FaqEntryCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FaqEntryOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/faq-entry`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FaqEntryOpenApiToJSON(requestParameters.faqEntryOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FaqEntryOpenApiFromJSON(jsonValue));
    }

    /**
     * Create new FAQ entry
     */
    async faqEntryCreate(requestParameters: FaqEntryCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FaqEntryOpenApi> {
        const response = await this.faqEntryCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete FAQ entry by id
     */
    async faqEntryDeleteRaw(requestParameters: FaqEntryDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling faqEntryDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/faq-entry/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete FAQ entry by id
     */
    async faqEntryDelete(requestParameters: FaqEntryDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.faqEntryDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get FAQ entry by id
     */
    async faqEntryGetRaw(requestParameters: FaqEntryGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FaqEntryOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling faqEntryGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/faq-entry/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FaqEntryOpenApiFromJSON(jsonValue));
    }

    /**
     * Get FAQ entry by id
     */
    async faqEntryGet(requestParameters: FaqEntryGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FaqEntryOpenApi> {
        const response = await this.faqEntryGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all FAQ entries
     */
    async faqEntryGetAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FaqEntryOpenApi>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/faq-entry`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FaqEntryOpenApiFromJSON));
    }

    /**
     * Get all FAQ entries
     */
    async faqEntryGetAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FaqEntryOpenApi>> {
        const response = await this.faqEntryGetAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all FAQ entries with public visibility
     */
    async faqEntryGetAllPublicRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FaqEntryOpenApi>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/faq-entry-public`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FaqEntryOpenApiFromJSON));
    }

    /**
     * Get all FAQ entries with public visibility
     */
    async faqEntryGetAllPublic(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FaqEntryOpenApi>> {
        const response = await this.faqEntryGetAllPublicRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update FAQ entry
     */
    async faqEntryUpdateRaw(requestParameters: FaqEntryUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FaqEntryOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling faqEntryUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/faq-entry/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FaqEntryOpenApiToJSON(requestParameters.faqEntryOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FaqEntryOpenApiFromJSON(jsonValue));
    }

    /**
     * Update FAQ entry
     */
    async faqEntryUpdate(requestParameters: FaqEntryUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FaqEntryOpenApi> {
        const response = await this.faqEntryUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
