import React, {FunctionComponent} from "react"
import {BankDetailsType} from "./collection-account.type"
import {mapCurrencyToString} from "../../currency/currency.mapper"
import styles from "./collection-account.module.css"

type BankDetailsProps = {
    bankDetails: BankDetailsType
}

const BankDetails: FunctionComponent<BankDetailsProps> = ({ bankDetails }) => {
    return (
        <table className={styles.bankDetails}>
            <tbody>
            {bankDetails.accountNumber && (
                <tr>
                    <td><strong>{bankDetails.accountNumber.type === "IBAN" ? "IBAN" : "Account number"}</strong></td>
                    <td>{bankDetails.accountNumber.number}</td>
                </tr>
            )}
            {bankDetails.bank && (
                <tr>
                    <td><strong>Bank</strong></td>
                    <td>
                        {bankDetails.bank.name}<br/>
                        {bankDetails.bank.address}, {bankDetails.bank.country}
                    </td>
                </tr>
            )}
            <tr>
                <td><strong>Currency</strong></td>
                <td>{mapCurrencyToString(bankDetails.currency)}</td>
            </tr>
            <tr>
                <td><strong>Payment type</strong></td>
                <td>{bankDetails.paymentType}</td>
            </tr>
            <tr>
                <td><strong>Holder name</strong></td>
                <td>{bankDetails.holderName}</td>
            </tr>
            {bankDetails.intermediaryBank && (
                <tr>
                    <td><strong>Intermediary Bank</strong></td>
                    <td>
                        {bankDetails.intermediaryBank.name}<br/>
                        {bankDetails.intermediaryBank.bicSwift}
                    </td>
                </tr>
            )}
            {bankDetails.routing && (
                <tr>
                    <td><strong>Routing</strong></td>
                    <td>
                        Code: {bankDetails.routing.code}<br/>
                        <small>
                            (Routing code type: {bankDetails.routing.codeType})
                        </small>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    )
}

export default BankDetails