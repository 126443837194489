import React, {useContext, useState} from "react"
import {SubmitHandler} from "react-hook-form"
import {useNavigate} from "react-router-dom"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import {COMPANY_CONTACT} from "../../../paths"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import {normalizeValues} from "../../../tech/form/form.util"
import {CompanyContactType} from "./contact.type"
import CompanyContactForm from "./form.component"

const CompanyContactNew = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const navigate = useNavigate()
    const [state, setState] = useState<"NONE" | "LOADING" | "ERROR">("NONE")

    const onSubmit: SubmitHandler<CompanyContactType> = async (contact) => {
        setState("LOADING")
        try {
            const savedContact = await fetchClient.companyContactApi.create(normalizeValues(contact))
            navigate(COMPANY_CONTACT(savedContact.id!))
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to save company contact."
                />
            )}
            {(state === "NONE" || state === "ERROR") && (
                <Section>
                    <SectionHeading title="Create new company contact"/>
                    <CompanyContactForm
                        onSubmit={onSubmit}
                        submitText="Create company contact"
                    />
                </Section>
            )}
        </>
    )
}

export default CompanyContactNew