import {FunctionComponent, ReactNode} from "react"
import {NavLink} from "react-router-dom"
import styles from "./sidebar.module.css"

type SidebarLinkProps = {
    path: string
    title: string
    toggleSidebar: () => void
    badge?: ReactNode
}

const SidebarLink: FunctionComponent<SidebarLinkProps> = ({
    path,
    title,
    toggleSidebar,
    badge
}) => {
    return (
        <NavLink
            to={path}
            className={({ isActive }) => (isActive ? styles.sidebarLinkActive : styles.sidebarLink)}
            onClick={toggleSidebar}
        >
            <div>
                <span>{title}</span>
            </div>
            {badge && (
                <div>
                    {badge}
                </div>
            )}
        </NavLink>
    )
}

export default SidebarLink