import {DealCategoryApiClient, DealCategoryOpenApi} from "../../generated"
import {mapDealCategoryToOpenApi, mapOpenApiToDealCategory} from "./deal-category.mapper"
import {DealCategoryType} from "./deal-category.type"

export class DealCategoryApi {

    constructor(private apiClient: DealCategoryApiClient) {
    }

    public async create(category: DealCategoryType): Promise<DealCategoryType> {
        const savedDealCategory = await this.apiClient.dealCategoryCreate({ dealCategoryOpenApi: mapDealCategoryToOpenApi(category) })
        return mapOpenApiToDealCategory(savedDealCategory)
    }

    public async delete(id: string): Promise<void> {
        await this.apiClient.dealCategoryDelete({ id })
    }

    public async getAll(): Promise<DealCategoryType[]> {
        return (await this.apiClient.dealCategoryGetAll())
            .map((category: DealCategoryOpenApi) => mapOpenApiToDealCategory(category))
    }

    public async get(id: string): Promise<DealCategoryType> {
        const category = await this.apiClient.dealCategoryGet({ id })
        return mapOpenApiToDealCategory(category)
    }

    public async update(id: string, category: DealCategoryType): Promise<DealCategoryType> {
        const response = await this.apiClient.dealCategoryUpdate({ id, dealCategoryOpenApi: mapDealCategoryToOpenApi(category) })
        return mapOpenApiToDealCategory(response)
    }

}