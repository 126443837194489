import React, {FunctionComponent} from "react"
import {FieldErrors, UseFieldArrayRemove, UseFormRegister, UseFormSetValue, UseFormWatch} from "react-hook-form"
import FormError from "../../../tech/form/error.component"
import {FileTypeOpenApi, MimeTypeOpenApi} from "../../../generated"
import FileUploadSelect from "../../file/upload-select/upload-select.component"
import {FileSelectSearchType} from "../../file/upload-select/select/select.component"
import {FileType} from "../../file/file.type"
import {UpdateType} from "../update.type"
import styles from "./single.module.sass"

type FileSelectSingleProps = {
    index: number
    errors: FieldErrors<UpdateType>
    register: UseFormRegister<UpdateType>
    remove: UseFieldArrayRemove
    setValue: UseFormSetValue<UpdateType>
    watch: UseFormWatch<UpdateType>
    fetchFiles: (
        mimeTypes: MimeTypeOpenApi[],
        searchType: FileSelectSearchType,
        searchValue: string
    ) => Promise<FileType[]>
}

const FileSelectSingle: FunctionComponent<FileSelectSingleProps> = ({
    index,
    errors,
    register,
    remove,
    setValue,
    watch,
    fetchFiles
}) => {
    const del = (i: number) => remove(i)

    return (
        <div className={styles.box}>
            <input type="hidden" {...register(`files.${index}.sequentialId`,)} />

            <div>
                <span>File</span>
                <FileUploadSelect
                    accept={["PDF"]}
                    currentFile={watch(`files.${index}.file`)}
                    defaultFileType={FileTypeOpenApi.BackofficePrivateFile}
                    filesProvider={(searchType: FileSelectSearchType, searchValue: string) => fetchFiles(
                        [MimeTypeOpenApi.Pdf],
                        searchType,
                        searchValue
                    )}
                    setFormValue={(value: FileType) => setValue(`files.${index}.file`, value)}
                />
                <FormError field={`documents.${index}.file`} errors={errors}/>
            </div>

            <div>
                <label>
                    <span>Title</span>
                    <input
                        {...register(
                            `files.${index}.title`,
                            {
                                required: "Title is required."
                            }
                        )}
                        type="text"
                    />
                    <FormError field={`documents.${index}.title`} errors={errors}/>
                </label>
            </div>

            <div>
                <label>
                    <span>Order</span>
                    <input
                        {...register(
                            `files.${index}.order`,
                            {
                                required: "Order is required."
                            }
                        )}
                        type="number"
                        min={0}
                    />
                    <FormError field={`documents.${index}.order`} errors={errors}/>
                </label>
            </div>

            <div className={styles.delete}>
                <button
                    type="button"
                    onClick={() => del(index)}
                >
                    Delete
                </button>
            </div>
        </div>
    )
}

export default FileSelectSingle