/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AsynchronousActionTypeOpenApi = {
    InvestmentDocumentSynchronization: 'INVESTMENT_DOCUMENT_SYNCHRONIZATION',
    Notification: 'NOTIFICATION',
    StripeCustomerUpdate: 'STRIPE_CUSTOMER_UPDATE',
    StripeEvent: 'STRIPE_EVENT',
    WallexCollectionAccountCreation: 'WALLEX_COLLECTION_ACCOUNT_CREATION',
    WallexCollectionSynchronization: 'WALLEX_COLLECTION_SYNCHRONIZATION'
} as const;
export type AsynchronousActionTypeOpenApi = typeof AsynchronousActionTypeOpenApi[keyof typeof AsynchronousActionTypeOpenApi];


export function AsynchronousActionTypeOpenApiFromJSON(json: any): AsynchronousActionTypeOpenApi {
    return AsynchronousActionTypeOpenApiFromJSONTyped(json, false);
}

export function AsynchronousActionTypeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): AsynchronousActionTypeOpenApi {
    return json as AsynchronousActionTypeOpenApi;
}

export function AsynchronousActionTypeOpenApiToJSON(value?: AsynchronousActionTypeOpenApi | null): any {
    return value as any;
}

