import React, {FunctionComponent, useContext, useState} from "react"
import {ButtonStyle} from "../../../../tech/button/style.enum"
import Button from "../../../../tech/button/button.component"
import LoadingCircle from "../../../../tech/loading/circle/circle.component"
import {FetchClient} from "../../../../tech/fetch/client"
import FetchContext from "../../../../tech/fetch/fetch.context"
import styles from "./download-deal-contracts.module.sass"

type DownloadDealContractsProps = {
    dealId: string
    numberOfSignedInvestments: number
}

const DownloadDealContracts: FunctionComponent<DownloadDealContractsProps> = ({
    dealId,
    numberOfSignedInvestments
}) => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [blob, setBlob] = useState<Blob>()

    async function download() {
        if (blob) {
            downloadFile(blob)
        }
        else {
            setLoading(true)
            const blob = await fetchClient.dealApi.getSignedContractsZip(dealId)
            downloadFile(blob)
            setBlob(blob)
            setLoading(false)
        }
    }

    if (loading) {
        return (
            <div className={styles.loading}>
                <LoadingCircle
                    width={16}
                    height={16}
                />
            </div>
        )
    }

    if (numberOfSignedInvestments < 1) {
        return (
            <div className={styles.noContracts}>
                No signed deal contracts available.
            </div>
        )
    }

    return (
        <Button
            title={numberOfSignedInvestments > 1 ? `Download ${numberOfSignedInvestments} signed deal contracts` : "Download signed deal contract"}
            style={ButtonStyle.SECONDARY}
            onClick={download}
            type="button"
        />
    )
}

export default DownloadDealContracts

function downloadFile(blob: Blob) {
    const downloadUrl = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = downloadUrl
    link.download = "deal-contracts.zip"
    document.body.appendChild(link)
    link.click()
    link.remove()
    URL.revokeObjectURL(downloadUrl)
}