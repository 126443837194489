/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationAttributesPreviousSubscriptionOpenApi } from './NotificationAttributesPreviousSubscriptionOpenApi';
import {
    NotificationAttributesPreviousSubscriptionOpenApiFromJSON,
    NotificationAttributesPreviousSubscriptionOpenApiFromJSONTyped,
    NotificationAttributesPreviousSubscriptionOpenApiToJSON,
} from './NotificationAttributesPreviousSubscriptionOpenApi';

/**
 * 
 * @export
 * @interface NotificationAttributesOpenApi
 */
export interface NotificationAttributesOpenApi {
    /**
     * 
     * @type {string}
     * @memberof NotificationAttributesOpenApi
     */
    dealName?: string;
    /**
     * 
     * @type {NotificationAttributesPreviousSubscriptionOpenApi}
     * @memberof NotificationAttributesOpenApi
     */
    previousSubscription?: NotificationAttributesPreviousSubscriptionOpenApi;
}

/**
 * Check if a given object implements the NotificationAttributesOpenApi interface.
 */
export function instanceOfNotificationAttributesOpenApi(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotificationAttributesOpenApiFromJSON(json: any): NotificationAttributesOpenApi {
    return NotificationAttributesOpenApiFromJSONTyped(json, false);
}

export function NotificationAttributesOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationAttributesOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dealName': !exists(json, 'dealName') ? undefined : json['dealName'],
        'previousSubscription': !exists(json, 'previousSubscription') ? undefined : NotificationAttributesPreviousSubscriptionOpenApiFromJSON(json['previousSubscription']),
    };
}

export function NotificationAttributesOpenApiToJSON(value?: NotificationAttributesOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dealName': value.dealName,
        'previousSubscription': NotificationAttributesPreviousSubscriptionOpenApiToJSON(value.previousSubscription),
    };
}

