/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateFiltersOpenApi
 */
export interface UpdateFiltersOpenApi {
    /**
     * 
     * @type {string}
     * @memberof UpdateFiltersOpenApi
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFiltersOpenApi
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFiltersOpenApi
     */
    companyLegalName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFiltersOpenApi
     */
    dealId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFiltersOpenApi
     */
    dealName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFiltersOpenApi
     */
    dealNameAlsoViaCompany?: string;
}

/**
 * Check if a given object implements the UpdateFiltersOpenApi interface.
 */
export function instanceOfUpdateFiltersOpenApi(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateFiltersOpenApiFromJSON(json: any): UpdateFiltersOpenApi {
    return UpdateFiltersOpenApiFromJSONTyped(json, false);
}

export function UpdateFiltersOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateFiltersOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyLegalName': !exists(json, 'companyLegalName') ? undefined : json['companyLegalName'],
        'dealId': !exists(json, 'dealId') ? undefined : json['dealId'],
        'dealName': !exists(json, 'dealName') ? undefined : json['dealName'],
        'dealNameAlsoViaCompany': !exists(json, 'dealNameAlsoViaCompany') ? undefined : json['dealNameAlsoViaCompany'],
    };
}

export function UpdateFiltersOpenApiToJSON(value?: UpdateFiltersOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'companyId': value.companyId,
        'companyLegalName': value.companyLegalName,
        'dealId': value.dealId,
        'dealName': value.dealName,
        'dealNameAlsoViaCompany': value.dealNameAlsoViaCompany,
    };
}

