import {DocumentApiClient, DocumentTemplateOpenApi} from "../../generated"
import {mapOpenApiToDocumentTemplate} from "./template/document-template.mapper"
import {DocumentTemplateType} from "./template/document-template.type"

export class DocumentApi {

    constructor(private apiClient: DocumentApiClient) {
    }

    public async getAll(): Promise<DocumentTemplateType[]> {
        return (await this.apiClient.documentTemplateGetAll())
            .map((template: DocumentTemplateOpenApi) => mapOpenApiToDocumentTemplate(template))
    }

}