/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { KycDocumentUploadOpenApi } from './KycDocumentUploadOpenApi';
import {
    KycDocumentUploadOpenApiFromJSON,
    KycDocumentUploadOpenApiFromJSONTyped,
    KycDocumentUploadOpenApiToJSON,
} from './KycDocumentUploadOpenApi';

/**
 * 
 * @export
 * @interface KycDocumentUploadNecessaryOnboardingStageOpenApi
 */
export interface KycDocumentUploadNecessaryOnboardingStageOpenApi {
    /**
     * 
     * @type {string}
     * @memberof KycDocumentUploadNecessaryOnboardingStageOpenApi
     */
    objectType?: string;
    /**
     * 
     * @type {KycDocumentUploadOpenApi}
     * @memberof KycDocumentUploadNecessaryOnboardingStageOpenApi
     */
    passport: KycDocumentUploadOpenApi;
    /**
     * 
     * @type {KycDocumentUploadOpenApi}
     * @memberof KycDocumentUploadNecessaryOnboardingStageOpenApi
     */
    proofOfAddress: KycDocumentUploadOpenApi;
    /**
     * 
     * @type {KycDocumentUploadOpenApi}
     * @memberof KycDocumentUploadNecessaryOnboardingStageOpenApi
     */
    proofOfAiStatus: KycDocumentUploadOpenApi;
}

/**
 * Check if a given object implements the KycDocumentUploadNecessaryOnboardingStageOpenApi interface.
 */
export function instanceOfKycDocumentUploadNecessaryOnboardingStageOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "passport" in value;
    isInstance = isInstance && "proofOfAddress" in value;
    isInstance = isInstance && "proofOfAiStatus" in value;

    return isInstance;
}

export function KycDocumentUploadNecessaryOnboardingStageOpenApiFromJSON(json: any): KycDocumentUploadNecessaryOnboardingStageOpenApi {
    return KycDocumentUploadNecessaryOnboardingStageOpenApiFromJSONTyped(json, false);
}

export function KycDocumentUploadNecessaryOnboardingStageOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): KycDocumentUploadNecessaryOnboardingStageOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectType': !exists(json, 'objectType') ? undefined : json['objectType'],
        'passport': KycDocumentUploadOpenApiFromJSON(json['passport']),
        'proofOfAddress': KycDocumentUploadOpenApiFromJSON(json['proofOfAddress']),
        'proofOfAiStatus': KycDocumentUploadOpenApiFromJSON(json['proofOfAiStatus']),
    };
}

export function KycDocumentUploadNecessaryOnboardingStageOpenApiToJSON(value?: KycDocumentUploadNecessaryOnboardingStageOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectType': value.objectType,
        'passport': KycDocumentUploadOpenApiToJSON(value.passport),
        'proofOfAddress': KycDocumentUploadOpenApiToJSON(value.proofOfAddress),
        'proofOfAiStatus': KycDocumentUploadOpenApiToJSON(value.proofOfAiStatus),
    };
}

