import {DealType} from "./deal.type"
import {normalizeValues} from "../../tech/form/form.util"
import {DealGeographyType} from "../deal-geography/deal-geography.type"
import {SinglePurposeVehicleType} from "../single-purpose-vehicle/single-purpose-vehicle.type"
import {getEnvironment} from "../../tech/environment/environment.util"
import {Environment} from "../../tech/environment/environment.enum"
import {ShareClassType} from "../share-class/share-class.type"

export function normalizeDeal(
    deal: DealType,
    geographies: DealGeographyType[],
    shareClasses: ShareClassType[],
    singlePurposeVehicles: SinglePurposeVehicleType[],
) {
    const normalized: DealType = normalizeValues(deal)

    // Categories
    if (!normalized.categories) {
        normalized.categories = []
    }

    // Company
    if (normalized.company?.legalName === undefined) {
        normalized.company = undefined
    }

    // Documents
    if (!normalized.documents) {
        normalized.documents = []
    }

    // Event
    if (normalized.event?.title === undefined && normalized.event?.start === undefined) {
        normalized.event = undefined
    }

    // Geography
    if (normalized.geography && normalized.geography.id) {
        normalized.geography = geographies.filter(g => g.id === normalized.geography!.id)[0]
    }
    else {
        normalized.geography = undefined
    }

    // Image
    if (normalized.image?.id === "-") {
        normalized.image = undefined
    }

    // Investors
    normalized.investors = normalizeStringToStringArray(normalized.investors)

    // Investor Groups
    if (!normalized.investorGroups) {
        normalized.investorGroups = []
    }

    // Investor Groups - share class
    normalized.investorGroups = normalized.investorGroups
        .map(ig => {
            if (ig.shareClass) {
                ig.shareClass = shareClasses.find(shareClass => shareClass.id === ig.shareClass!.id!)!
            }
            return ig
        })

    // Logo
    if (normalized.logo?.id === "-") {
        normalized.logo = undefined
    }

    // Manual Reconciliation Bank Accounts
    if (!normalized.manualReconciliationBankAccounts) {
        normalized.manualReconciliationBankAccounts = []
    }

    // Outcome
    if (deal.outcome === undefined || deal.outcome === null) {
        normalized.outcome = "NONE"
    }

    // Publicly available (data points)
    if (!deal.publiclyAvailable) {
        normalized.publiclyAvailableDataPoints = undefined
    }

    // Single purpose vehicle
    if (normalized.singlePurposeVehicle.id) {
        normalized.singlePurposeVehicle = singlePurposeVehicles.filter(spv => spv.id === normalized.singlePurposeVehicle!.id)[0]
    }

    // Slide deck
    if (normalized.slideDeck?.id === "-") {
        normalized.slideDeck = undefined
    }
    
    // Terms tags
    normalized.termsTags = normalizeStringToStringArray(normalized.termsTags)

    // Video YouTube code
    if (normalized.videoYoutubeCode === "") {
        normalized.videoYoutubeCode = undefined
    }

    return normalized
}

export function normalizeStringToStringArray(val: string | string[]): string[] {
    if (val === undefined) {
        val = []
    }
    else if (typeof val === "string" && !Array.isArray(val)) {
        val = val.split(",").map(s => s.trim())
    }
    return val
}

export function getDealUrl(id: string): string {
    switch (getEnvironment()) {
        case Environment.DEVELOP:
        case Environment.PREVIEW:
            return "https://app-integration.nonpublic.io/deal/" + id
        case Environment.PRODUCTION:
            return "https://app.nonpublic.io/deal/" + id
        case Environment.LOCAL:
            return "http://localhost:3000/deal/" + id
    }
}