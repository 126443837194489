/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvestorOpenApi } from './InvestorOpenApi';
import {
    InvestorOpenApiFromJSON,
    InvestorOpenApiFromJSONTyped,
    InvestorOpenApiToJSON,
} from './InvestorOpenApi';
import type { SubscriptionLogTypeOpenApi } from './SubscriptionLogTypeOpenApi';
import {
    SubscriptionLogTypeOpenApiFromJSON,
    SubscriptionLogTypeOpenApiFromJSONTyped,
    SubscriptionLogTypeOpenApiToJSON,
} from './SubscriptionLogTypeOpenApi';
import type { SubscriptionPaymentIntervalOpenApi } from './SubscriptionPaymentIntervalOpenApi';
import {
    SubscriptionPaymentIntervalOpenApiFromJSON,
    SubscriptionPaymentIntervalOpenApiFromJSONTyped,
    SubscriptionPaymentIntervalOpenApiToJSON,
} from './SubscriptionPaymentIntervalOpenApi';
import type { SubscriptionTypeOpenApi } from './SubscriptionTypeOpenApi';
import {
    SubscriptionTypeOpenApiFromJSON,
    SubscriptionTypeOpenApiFromJSONTyped,
    SubscriptionTypeOpenApiToJSON,
} from './SubscriptionTypeOpenApi';

/**
 * 
 * @export
 * @interface SubscriptionLogOpenApi
 */
export interface SubscriptionLogOpenApi {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLogOpenApi
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof SubscriptionLogOpenApi
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof SubscriptionLogOpenApi
     */
    modified: Date;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLogOpenApi
     */
    stripeSubscriptionId: string;
    /**
     * 
     * @type {SubscriptionLogTypeOpenApi}
     * @memberof SubscriptionLogOpenApi
     */
    type: SubscriptionLogTypeOpenApi;
    /**
     * 
     * @type {SubscriptionTypeOpenApi}
     * @memberof SubscriptionLogOpenApi
     */
    subscriptionType?: SubscriptionTypeOpenApi;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionLogOpenApi
     */
    price?: number;
    /**
     * 
     * @type {SubscriptionPaymentIntervalOpenApi}
     * @memberof SubscriptionLogOpenApi
     */
    paymentInterval?: SubscriptionPaymentIntervalOpenApi;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLogOpenApi
     */
    cancellationReason?: string;
    /**
     * 
     * @type {Date}
     * @memberof SubscriptionLogOpenApi
     */
    terminationDate?: Date;
    /**
     * 
     * @type {InvestorOpenApi}
     * @memberof SubscriptionLogOpenApi
     */
    investor: InvestorOpenApi;
}

/**
 * Check if a given object implements the SubscriptionLogOpenApi interface.
 */
export function instanceOfSubscriptionLogOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "stripeSubscriptionId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "investor" in value;

    return isInstance;
}

export function SubscriptionLogOpenApiFromJSON(json: any): SubscriptionLogOpenApi {
    return SubscriptionLogOpenApiFromJSONTyped(json, false);
}

export function SubscriptionLogOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionLogOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'stripeSubscriptionId': json['stripeSubscriptionId'],
        'type': SubscriptionLogTypeOpenApiFromJSON(json['type']),
        'subscriptionType': !exists(json, 'subscriptionType') ? undefined : SubscriptionTypeOpenApiFromJSON(json['subscriptionType']),
        'price': !exists(json, 'price') ? undefined : json['price'],
        'paymentInterval': !exists(json, 'paymentInterval') ? undefined : SubscriptionPaymentIntervalOpenApiFromJSON(json['paymentInterval']),
        'cancellationReason': !exists(json, 'cancellationReason') ? undefined : json['cancellationReason'],
        'terminationDate': !exists(json, 'terminationDate') ? undefined : (new Date(json['terminationDate'])),
        'investor': InvestorOpenApiFromJSON(json['investor']),
    };
}

export function SubscriptionLogOpenApiToJSON(value?: SubscriptionLogOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'modified': (value.modified.toISOString()),
        'stripeSubscriptionId': value.stripeSubscriptionId,
        'type': SubscriptionLogTypeOpenApiToJSON(value.type),
        'subscriptionType': SubscriptionTypeOpenApiToJSON(value.subscriptionType),
        'price': value.price,
        'paymentInterval': SubscriptionPaymentIntervalOpenApiToJSON(value.paymentInterval),
        'cancellationReason': value.cancellationReason,
        'terminationDate': value.terminationDate === undefined ? undefined : (value.terminationDate.toISOString().substr(0,10)),
        'investor': InvestorOpenApiToJSON(value.investor),
    };
}

