import {UserOpenApi} from "../../generated"
import {UserType} from "./user.type"

export function mapOpenApiToUser(user: UserOpenApi): UserType {
    return {
        id: user.id,
        created: user.created,
        sequentialId: user.sequentialId,
        userId: user.userId,
        email: user.email,
        role: user.role
    }
}

export function mapUserToOpenApi(user: UserType): UserOpenApi {
    return {
        id: user.id,
        created: user.created,
        sequentialId: user.sequentialId,
        userId: user.userId,
        email: user.email,
        role: user.role
    }
}