import {CurrencyOpenApi} from "../../../generated"

export type InvestmentFeesType = {
    placement: InvestmentFeeWithAmountType
    management: number
    carry: number
    exchange?: InvestmentFeeWithAmountType
}

export class InvestmentFeeWithAmountType {
    public amount: number
    public percent: number

    constructor({
        amount,
        percent
    } : {
        amount: number
        percent: number
    }) {
        this.amount = amount
        this.percent = percent
    }

    public getAmountFormatted(currency: CurrencyOpenApi): string {
        return this.amount.toLocaleString("en", {
            currency: currency,
            style: 'currency',
            maximumFractionDigits: 2
        })
    }
}