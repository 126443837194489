import React, {FunctionComponent} from "react"
import {SubmitHandler, useForm} from "react-hook-form"
import formStyles from "../../tech/form/form.module.css"
import FormError from "../../tech/form/error.component"
import {FaqEntryType} from "./faq-entry.type"
import {FaqEntryVisibilityOpenApi} from "../../generated"
import MarkdownEditor from "../../tech/markdown/editor.component"

type FaqEntryFormProps = {
    faqEntry?: FaqEntryType
    onSubmit: SubmitHandler<FaqEntryType>
    submitText: string
}

const FaqEntryForm: FunctionComponent<FaqEntryFormProps> = ({ faqEntry, onSubmit, submitText }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue
    } = useForm<FaqEntryType>({
        defaultValues: faqEntry
    })

    return (
        <form
            className={formStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <input type="hidden" {...register("id")} />

            {faqEntry && (
                <label>
                    <span>ID:</span> {faqEntry.id}<br/>
                </label>
            )}

            <label>
                <span>Title</span>
                <input
                    type="text"
                    placeholder="Title"
                    {...register("title", {
                        required: "Title is required.",
                        minLength: {value: 3, message: "Too short."}
                    })}
                />
                <FormError field="title" errors={errors}/>
            </label>

            <label>
                <span>Order</span>
                <input
                    {...register(
                        "order",
                        {
                            required: "Order is required."
                        }
                    )}
                    type="number"
                />
                <FormError field="order" errors={errors}/>
            </label>

            <MarkdownEditor
                label="Text"
                fieldId="text"
                markdown={watch("text")}
                onChange={(val: string) => setValue("text", val)}
                errors={errors}
            />
            <input
                type="hidden"
                {...register(
                    "text",
                    {
                        required: "Text is required.",
                        validate: value => {
                            if (value === undefined) return true
                            if (value.trim() === "") return "Text is required."
                        }
                    }
                )}
            />

            <label>
                <span>Category</span>
                <input
                    type="text"
                    placeholder="Category"
                    {...register("category", {
                        required: "Category is required.",
                        minLength: {value: 2, message: "Too short."}
                    })}
                />
                <FormError field="category" errors={errors}/>
            </label>

            <label>
                <span>Visibility</span>
                <select {...register("visibility")}>
                    {Object.entries(FaqEntryVisibilityOpenApi).map((t, i) => (
                        <option value={t[1]} key={i}>{t[1]}</option>
                    ))}
                </select>
                <FormError field="types" errors={errors}/>
            </label>

            <button
                type="submit"
                className={formStyles.submitButton}
            >
                {submitText}
            </button>
        </form>
    )
}

export default FaqEntryForm