/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DealOpenApi,
  InvestorOnboardingOpenApi,
  InvestorWithDetailsOpenApi,
  SyndicateFiltersOpenApi,
  SyndicateInvitationConfirmAddingExistingInvestorRequestOpenApi,
  SyndicateInvitationCreationResponseOpenApi,
  SyndicateInvitationOpenApi,
  SyndicateOpenApi,
  SyndicatePaginatedResponseOpenApi,
  SyndicateSortOpenApi,
  ValidationResponseOpenApi,
} from '../models';
import {
    DealOpenApiFromJSON,
    DealOpenApiToJSON,
    InvestorOnboardingOpenApiFromJSON,
    InvestorOnboardingOpenApiToJSON,
    InvestorWithDetailsOpenApiFromJSON,
    InvestorWithDetailsOpenApiToJSON,
    SyndicateFiltersOpenApiFromJSON,
    SyndicateFiltersOpenApiToJSON,
    SyndicateInvitationConfirmAddingExistingInvestorRequestOpenApiFromJSON,
    SyndicateInvitationConfirmAddingExistingInvestorRequestOpenApiToJSON,
    SyndicateInvitationCreationResponseOpenApiFromJSON,
    SyndicateInvitationCreationResponseOpenApiToJSON,
    SyndicateInvitationOpenApiFromJSON,
    SyndicateInvitationOpenApiToJSON,
    SyndicateOpenApiFromJSON,
    SyndicateOpenApiToJSON,
    SyndicatePaginatedResponseOpenApiFromJSON,
    SyndicatePaginatedResponseOpenApiToJSON,
    SyndicateSortOpenApiFromJSON,
    SyndicateSortOpenApiToJSON,
    ValidationResponseOpenApiFromJSON,
    ValidationResponseOpenApiToJSON,
} from '../models';

export interface SyndicateCreateRequest {
    syndicateOpenApi?: SyndicateOpenApi;
}

export interface SyndicateDeleteRequest {
    id: string;
}

export interface SyndicateGetRequest {
    id: string;
}

export interface SyndicateGetAllDealsRequest {
    syndicateId: string;
}

export interface SyndicateGetAllPaginatedRequest {
    elementsPerPage?: number;
    page?: number;
    sort?: SyndicateSortOpenApi;
    syndicateFiltersOpenApi?: SyndicateFiltersOpenApi;
}

export interface SyndicateInvitationCreateRequest {
    syndicateId: string;
    syndicateInvitationOpenApi?: SyndicateInvitationOpenApi;
}

export interface SyndicateInvitationCreateConfirmAddingExistingInvestorRequest {
    syndicateId: string;
    syndicateInvitationConfirmAddingExistingInvestorRequestOpenApi?: SyndicateInvitationConfirmAddingExistingInvestorRequestOpenApi;
}

export interface SyndicateInvitationDeleteRequest {
    syndicateId: string;
    invitationId: string;
}

export interface SyndicateInvitationGetRequest {
    syndicateId: string;
    invitationId: string;
}

export interface SyndicateInvitationGetAllNotAlreadyEnteredRequest {
    syndicateId: string;
}

export interface SyndicateInvitationResendRequest {
    syndicateId: string;
    invitationId: string;
}

export interface SyndicateJoinRequest {
    syndicateId: string;
}

export interface SyndicateMemberGetAllRequest {
    syndicateId: string;
}

export interface SyndicateMemberRemoveRequest {
    syndicateId: string;
    investorId: string;
}

export interface SyndicateUpdateRequest {
    id: string;
    syndicateOpenApi?: SyndicateOpenApi;
}

/**
 * 
 */
export class SyndicateApiClient extends runtime.BaseAPI {

    /**
     * Create new syndicate
     */
    async syndicateCreateRaw(requestParameters: SyndicateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SyndicateOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/syndicate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SyndicateOpenApiToJSON(requestParameters.syndicateOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SyndicateOpenApiFromJSON(jsonValue));
    }

    /**
     * Create new syndicate
     */
    async syndicateCreate(requestParameters: SyndicateCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SyndicateOpenApi> {
        const response = await this.syndicateCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete syndicate by id
     */
    async syndicateDeleteRaw(requestParameters: SyndicateDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling syndicateDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/syndicate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete syndicate by id
     */
    async syndicateDelete(requestParameters: SyndicateDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.syndicateDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get syndicate by id
     */
    async syndicateGetRaw(requestParameters: SyndicateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SyndicateOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling syndicateGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/syndicate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SyndicateOpenApiFromJSON(jsonValue));
    }

    /**
     * Get syndicate by id
     */
    async syndicateGet(requestParameters: SyndicateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SyndicateOpenApi> {
        const response = await this.syndicateGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all deals of a syndicate
     */
    async syndicateGetAllDealsRaw(requestParameters: SyndicateGetAllDealsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DealOpenApi>>> {
        if (requestParameters.syndicateId === null || requestParameters.syndicateId === undefined) {
            throw new runtime.RequiredError('syndicateId','Required parameter requestParameters.syndicateId was null or undefined when calling syndicateGetAllDeals.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/syndicate/{syndicateId}/deals`.replace(`{${"syndicateId"}}`, encodeURIComponent(String(requestParameters.syndicateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DealOpenApiFromJSON));
    }

    /**
     * Get all deals of a syndicate
     */
    async syndicateGetAllDeals(requestParameters: SyndicateGetAllDealsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DealOpenApi>> {
        const response = await this.syndicateGetAllDealsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all syndicates (paginated)
     */
    async syndicateGetAllPaginatedRaw(requestParameters: SyndicateGetAllPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SyndicatePaginatedResponseOpenApi>> {
        const queryParameters: any = {};

        if (requestParameters.elementsPerPage !== undefined) {
            queryParameters['elementsPerPage'] = requestParameters.elementsPerPage;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/syndicate/paginated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SyndicateFiltersOpenApiToJSON(requestParameters.syndicateFiltersOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SyndicatePaginatedResponseOpenApiFromJSON(jsonValue));
    }

    /**
     * Get all syndicates (paginated)
     */
    async syndicateGetAllPaginated(requestParameters: SyndicateGetAllPaginatedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SyndicatePaginatedResponseOpenApi> {
        const response = await this.syndicateGetAllPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create new syndicate invitation
     */
    async syndicateInvitationCreateRaw(requestParameters: SyndicateInvitationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SyndicateInvitationCreationResponseOpenApi>> {
        if (requestParameters.syndicateId === null || requestParameters.syndicateId === undefined) {
            throw new runtime.RequiredError('syndicateId','Required parameter requestParameters.syndicateId was null or undefined when calling syndicateInvitationCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/syndicate/{syndicateId}/invitation`.replace(`{${"syndicateId"}}`, encodeURIComponent(String(requestParameters.syndicateId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SyndicateInvitationOpenApiToJSON(requestParameters.syndicateInvitationOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SyndicateInvitationCreationResponseOpenApiFromJSON(jsonValue));
    }

    /**
     * Create new syndicate invitation
     */
    async syndicateInvitationCreate(requestParameters: SyndicateInvitationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SyndicateInvitationCreationResponseOpenApi> {
        const response = await this.syndicateInvitationCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Confirm adding an existing investor to syndicate
     */
    async syndicateInvitationCreateConfirmAddingExistingInvestorRaw(requestParameters: SyndicateInvitationCreateConfirmAddingExistingInvestorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestorWithDetailsOpenApi>> {
        if (requestParameters.syndicateId === null || requestParameters.syndicateId === undefined) {
            throw new runtime.RequiredError('syndicateId','Required parameter requestParameters.syndicateId was null or undefined when calling syndicateInvitationCreateConfirmAddingExistingInvestor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/syndicate/{syndicateId}/invitation/confirm`.replace(`{${"syndicateId"}}`, encodeURIComponent(String(requestParameters.syndicateId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SyndicateInvitationConfirmAddingExistingInvestorRequestOpenApiToJSON(requestParameters.syndicateInvitationConfirmAddingExistingInvestorRequestOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorWithDetailsOpenApiFromJSON(jsonValue));
    }

    /**
     * Confirm adding an existing investor to syndicate
     */
    async syndicateInvitationCreateConfirmAddingExistingInvestor(requestParameters: SyndicateInvitationCreateConfirmAddingExistingInvestorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestorWithDetailsOpenApi> {
        const response = await this.syndicateInvitationCreateConfirmAddingExistingInvestorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete invitation
     */
    async syndicateInvitationDeleteRaw(requestParameters: SyndicateInvitationDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.syndicateId === null || requestParameters.syndicateId === undefined) {
            throw new runtime.RequiredError('syndicateId','Required parameter requestParameters.syndicateId was null or undefined when calling syndicateInvitationDelete.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling syndicateInvitationDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/syndicate/{syndicateId}/invitation/{invitationId}`.replace(`{${"syndicateId"}}`, encodeURIComponent(String(requestParameters.syndicateId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete invitation
     */
    async syndicateInvitationDelete(requestParameters: SyndicateInvitationDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.syndicateInvitationDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get syndicate invitation by id
     */
    async syndicateInvitationGetRaw(requestParameters: SyndicateInvitationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SyndicateInvitationOpenApi>> {
        if (requestParameters.syndicateId === null || requestParameters.syndicateId === undefined) {
            throw new runtime.RequiredError('syndicateId','Required parameter requestParameters.syndicateId was null or undefined when calling syndicateInvitationGet.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling syndicateInvitationGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/syndicate/{syndicateId}/invitation/{invitationId}`.replace(`{${"syndicateId"}}`, encodeURIComponent(String(requestParameters.syndicateId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SyndicateInvitationOpenApiFromJSON(jsonValue));
    }

    /**
     * Get syndicate invitation by id
     */
    async syndicateInvitationGet(requestParameters: SyndicateInvitationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SyndicateInvitationOpenApi> {
        const response = await this.syndicateInvitationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all invitations of syndicate not entered yet
     */
    async syndicateInvitationGetAllNotAlreadyEnteredRaw(requestParameters: SyndicateInvitationGetAllNotAlreadyEnteredRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SyndicateInvitationOpenApi>>> {
        if (requestParameters.syndicateId === null || requestParameters.syndicateId === undefined) {
            throw new runtime.RequiredError('syndicateId','Required parameter requestParameters.syndicateId was null or undefined when calling syndicateInvitationGetAllNotAlreadyEntered.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/syndicate/{syndicateId}/invitation`.replace(`{${"syndicateId"}}`, encodeURIComponent(String(requestParameters.syndicateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SyndicateInvitationOpenApiFromJSON));
    }

    /**
     * Get all invitations of syndicate not entered yet
     */
    async syndicateInvitationGetAllNotAlreadyEntered(requestParameters: SyndicateInvitationGetAllNotAlreadyEnteredRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SyndicateInvitationOpenApi>> {
        const response = await this.syndicateInvitationGetAllNotAlreadyEnteredRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resend syndicate invitation
     */
    async syndicateInvitationResendRaw(requestParameters: SyndicateInvitationResendRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.syndicateId === null || requestParameters.syndicateId === undefined) {
            throw new runtime.RequiredError('syndicateId','Required parameter requestParameters.syndicateId was null or undefined when calling syndicateInvitationResend.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling syndicateInvitationResend.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/syndicate/{syndicateId}/invitation/{invitationId}/resend`.replace(`{${"syndicateId"}}`, encodeURIComponent(String(requestParameters.syndicateId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resend syndicate invitation
     */
    async syndicateInvitationResend(requestParameters: SyndicateInvitationResendRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.syndicateInvitationResendRaw(requestParameters, initOverrides);
    }

    /**
     * Join syndicate as current user
     */
    async syndicateJoinRaw(requestParameters: SyndicateJoinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestorOnboardingOpenApi>> {
        if (requestParameters.syndicateId === null || requestParameters.syndicateId === undefined) {
            throw new runtime.RequiredError('syndicateId','Required parameter requestParameters.syndicateId was null or undefined when calling syndicateJoin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/syndicate/{syndicateId}/join`.replace(`{${"syndicateId"}}`, encodeURIComponent(String(requestParameters.syndicateId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorOnboardingOpenApiFromJSON(jsonValue));
    }

    /**
     * Join syndicate as current user
     */
    async syndicateJoin(requestParameters: SyndicateJoinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestorOnboardingOpenApi> {
        const response = await this.syndicateJoinRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all members of syndicate
     */
    async syndicateMemberGetAllRaw(requestParameters: SyndicateMemberGetAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InvestorWithDetailsOpenApi>>> {
        if (requestParameters.syndicateId === null || requestParameters.syndicateId === undefined) {
            throw new runtime.RequiredError('syndicateId','Required parameter requestParameters.syndicateId was null or undefined when calling syndicateMemberGetAll.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/syndicate/{syndicateId}/member`.replace(`{${"syndicateId"}}`, encodeURIComponent(String(requestParameters.syndicateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvestorWithDetailsOpenApiFromJSON));
    }

    /**
     * Get all members of syndicate
     */
    async syndicateMemberGetAll(requestParameters: SyndicateMemberGetAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InvestorWithDetailsOpenApi>> {
        const response = await this.syndicateMemberGetAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove investor (member) from syndicate
     */
    async syndicateMemberRemoveRaw(requestParameters: SyndicateMemberRemoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.syndicateId === null || requestParameters.syndicateId === undefined) {
            throw new runtime.RequiredError('syndicateId','Required parameter requestParameters.syndicateId was null or undefined when calling syndicateMemberRemove.');
        }

        if (requestParameters.investorId === null || requestParameters.investorId === undefined) {
            throw new runtime.RequiredError('investorId','Required parameter requestParameters.investorId was null or undefined when calling syndicateMemberRemove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/syndicate/{syndicateId}/member/{investorId}`.replace(`{${"syndicateId"}}`, encodeURIComponent(String(requestParameters.syndicateId))).replace(`{${"investorId"}}`, encodeURIComponent(String(requestParameters.investorId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove investor (member) from syndicate
     */
    async syndicateMemberRemove(requestParameters: SyndicateMemberRemoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.syndicateMemberRemoveRaw(requestParameters, initOverrides);
    }

    /**
     * Update syndicate
     */
    async syndicateUpdateRaw(requestParameters: SyndicateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SyndicateOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling syndicateUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/syndicate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SyndicateOpenApiToJSON(requestParameters.syndicateOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SyndicateOpenApiFromJSON(jsonValue));
    }

    /**
     * Update syndicate
     */
    async syndicateUpdate(requestParameters: SyndicateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SyndicateOpenApi> {
        const response = await this.syndicateUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
