import {ActionsSortOptionType} from "../../tech/actions/sort/sort.type"
import {getSort} from "../../tech/actions/sort/sort.util"
import {SyndicateSortOpenApi} from "../../generated"

export const DEFAULT_SORT = {
    id: SyndicateSortOpenApi.ModifiedDescending,
    label: "Modified (DESC)"
}

export function assembleSortOptions(): ActionsSortOptionType<SyndicateSortOpenApi>[] {
    return [
        {
            id: SyndicateSortOpenApi.CreatedAscending,
            label: "Created (ASC)"
        },
        {
            id: SyndicateSortOpenApi.CreatedDescending,
            label: "Created (DESC)"
        },
        {
            id: SyndicateSortOpenApi.NameAscending,
            label: "Legal Name (ASC)"
        },
        {
            id: SyndicateSortOpenApi.NameDescending,
            label: "Legal Name (DESC)"
        },
        {
            id: SyndicateSortOpenApi.ModifiedAscending,
            label: "Modified (ASC)"
        },
        DEFAULT_SORT,
    ]
}

export function getCurrentSortOption(
    options: ActionsSortOptionType<SyndicateSortOpenApi>[],
    searchParams: URLSearchParams
): ActionsSortOptionType<SyndicateSortOpenApi> {
    return (options.find(o => o.id === getSort(searchParams)) || DEFAULT_SORT)
}