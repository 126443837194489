/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DealOpenApi } from './DealOpenApi';
import {
    DealOpenApiFromJSON,
    DealOpenApiFromJSONTyped,
    DealOpenApiToJSON,
} from './DealOpenApi';
import type { ValuationOpenApi } from './ValuationOpenApi';
import {
    ValuationOpenApiFromJSON,
    ValuationOpenApiFromJSONTyped,
    ValuationOpenApiToJSON,
} from './ValuationOpenApi';

/**
 * 
 * @export
 * @interface DealWithValuationsOpenApi
 */
export interface DealWithValuationsOpenApi {
    /**
     * 
     * @type {DealOpenApi}
     * @memberof DealWithValuationsOpenApi
     */
    deal: DealOpenApi;
    /**
     * 
     * @type {Array<ValuationOpenApi>}
     * @memberof DealWithValuationsOpenApi
     */
    valuations: Array<ValuationOpenApi>;
}

/**
 * Check if a given object implements the DealWithValuationsOpenApi interface.
 */
export function instanceOfDealWithValuationsOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deal" in value;
    isInstance = isInstance && "valuations" in value;

    return isInstance;
}

export function DealWithValuationsOpenApiFromJSON(json: any): DealWithValuationsOpenApi {
    return DealWithValuationsOpenApiFromJSONTyped(json, false);
}

export function DealWithValuationsOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealWithValuationsOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deal': DealOpenApiFromJSON(json['deal']),
        'valuations': ((json['valuations'] as Array<any>).map(ValuationOpenApiFromJSON)),
    };
}

export function DealWithValuationsOpenApiToJSON(value?: DealWithValuationsOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deal': DealOpenApiToJSON(value.deal),
        'valuations': ((value.valuations as Array<any>).map(ValuationOpenApiToJSON)),
    };
}

