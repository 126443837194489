import React, {FunctionComponent, ReactElement, ReactNode} from "react"
import {ButtonStyle} from "./style.enum"
import styles from "./button.module.sass"

type ButtonProps = {
    title: string | ReactNode
    style: ButtonStyle
    type: "submit" | "reset" | "button"
    onClick?: Function
    icon?: {
        elem: ReactElement
        left: boolean
    }
    small?: boolean
    disabled?: boolean
}

const Button: FunctionComponent<ButtonProps> = ({
    title,
    style,
    type,
    onClick,
    icon,
    small = false,
    disabled = false
}) => {
    const content = icon ? (
        <>
            {icon.left ? <>{icon.elem} {title}</> : <>{title} {icon.elem}</>}
        </>
    ) : (
        <>{title}</>
    )

    return onClick ? (
        <button
            type={type}
            className={`${styles.button} ${getButtonClass(style, small)} ${disabled ? styles.disabled : ""}`}
            onClick={() => onClick()}
            disabled={disabled}
        >
            {content}
        </button>
    ) : (
        <button
            type={type}
            className={`${getButtonClass(style, small)} ${disabled ? styles.disabled : ""}`}
            disabled={disabled}
        >
            {content}
        </button>
    )
}

export default Button

const getButtonClass = (style: ButtonStyle, small: boolean) => {
    switch (style) {
        case ButtonStyle.PRIMARY:
            return `${styles.buttonPrimary} ${small ? styles.small : ""}`
        case ButtonStyle.SECONDARY:
            return `${styles.buttonSecondary} ${small ? styles.small : ""}`
        case ButtonStyle.DELETE:
            return `${styles.buttonDelete} ${small ? styles.small : ""}`
    }
}