import {ResponseWithType} from "./response.type"

export class FetchResponseError implements ResponseWithType {
    private readonly _message: string
    private readonly _status?: number

    constructor(message: string, status?: number) {
        this._message = message
        this._status = status
    }


    get message(): string {
        return this._message
    }

    get status(): number | undefined {
        return this._status
    }

    public get type(): "RESPONSE_ERROR" {
        return "RESPONSE_ERROR"
    }
}