/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AsynchronousActionStateOpenApi } from './AsynchronousActionStateOpenApi';
import {
    AsynchronousActionStateOpenApiFromJSON,
    AsynchronousActionStateOpenApiFromJSONTyped,
    AsynchronousActionStateOpenApiToJSON,
} from './AsynchronousActionStateOpenApi';

/**
 * 
 * @export
 * @interface StripeEventAsynchronousActionOpenApi
 */
export interface StripeEventAsynchronousActionOpenApi {
    /**
     * 
     * @type {string}
     * @memberof StripeEventAsynchronousActionOpenApi
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof StripeEventAsynchronousActionOpenApi
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof StripeEventAsynchronousActionOpenApi
     */
    modified: Date;
    /**
     * 
     * @type {AsynchronousActionStateOpenApi}
     * @memberof StripeEventAsynchronousActionOpenApi
     */
    state: AsynchronousActionStateOpenApi;
    /**
     * 
     * @type {string}
     * @memberof StripeEventAsynchronousActionOpenApi
     */
    message?: string;
    /**
     * 
     * @type {Date}
     * @memberof StripeEventAsynchronousActionOpenApi
     */
    processed?: Date;
    /**
     * 
     * @type {number}
     * @memberof StripeEventAsynchronousActionOpenApi
     */
    retryCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof StripeEventAsynchronousActionOpenApi
     */
    retryTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof StripeEventAsynchronousActionOpenApi
     */
    stripeEventId: string;
}

/**
 * Check if a given object implements the StripeEventAsynchronousActionOpenApi interface.
 */
export function instanceOfStripeEventAsynchronousActionOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "stripeEventId" in value;

    return isInstance;
}

export function StripeEventAsynchronousActionOpenApiFromJSON(json: any): StripeEventAsynchronousActionOpenApi {
    return StripeEventAsynchronousActionOpenApiFromJSONTyped(json, false);
}

export function StripeEventAsynchronousActionOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeEventAsynchronousActionOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'state': AsynchronousActionStateOpenApiFromJSON(json['state']),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'processed': !exists(json, 'processed') ? undefined : (new Date(json['processed'])),
        'retryCount': !exists(json, 'retryCount') ? undefined : json['retryCount'],
        'retryTime': !exists(json, 'retryTime') ? undefined : (new Date(json['retryTime'])),
        'stripeEventId': json['stripeEventId'],
    };
}

export function StripeEventAsynchronousActionOpenApiToJSON(value?: StripeEventAsynchronousActionOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'modified': (value.modified.toISOString()),
        'state': AsynchronousActionStateOpenApiToJSON(value.state),
        'message': value.message,
        'processed': value.processed === undefined ? undefined : (value.processed.toISOString()),
        'retryCount': value.retryCount,
        'retryTime': value.retryTime === undefined ? undefined : (value.retryTime.toISOString()),
        'stripeEventId': value.stripeEventId,
    };
}

