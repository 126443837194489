/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CollectionReceiverPaymentTypeOpenApi = {
    Priority: 'PRIORITY',
    Regular: 'REGULAR'
} as const;
export type CollectionReceiverPaymentTypeOpenApi = typeof CollectionReceiverPaymentTypeOpenApi[keyof typeof CollectionReceiverPaymentTypeOpenApi];


export function CollectionReceiverPaymentTypeOpenApiFromJSON(json: any): CollectionReceiverPaymentTypeOpenApi {
    return CollectionReceiverPaymentTypeOpenApiFromJSONTyped(json, false);
}

export function CollectionReceiverPaymentTypeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectionReceiverPaymentTypeOpenApi {
    return json as CollectionReceiverPaymentTypeOpenApi;
}

export function CollectionReceiverPaymentTypeOpenApiToJSON(value?: CollectionReceiverPaymentTypeOpenApi | null): any {
    return value as any;
}

