/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DealWithValuationsOpenApi,
  ValuationsSaveRequestOpenApi,
} from '../models';
import {
    DealWithValuationsOpenApiFromJSON,
    DealWithValuationsOpenApiToJSON,
    ValuationsSaveRequestOpenApiFromJSON,
    ValuationsSaveRequestOpenApiToJSON,
} from '../models';

export interface ValuationSaveAllRequest {
    dealId?: string;
    valuationsSaveRequestOpenApi?: ValuationsSaveRequestOpenApi;
}

/**
 * 
 */
export class ValuationApiClient extends runtime.BaseAPI {

    /**
     * Save all valuations
     */
    async valuationSaveAllRaw(requestParameters: ValuationSaveAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealWithValuationsOpenApi>> {
        const queryParameters: any = {};

        if (requestParameters.dealId !== undefined) {
            queryParameters['dealId'] = requestParameters.dealId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/valuation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValuationsSaveRequestOpenApiToJSON(requestParameters.valuationsSaveRequestOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealWithValuationsOpenApiFromJSON(jsonValue));
    }

    /**
     * Save all valuations
     */
    async valuationSaveAll(requestParameters: ValuationSaveAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealWithValuationsOpenApi> {
        const response = await this.valuationSaveAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
