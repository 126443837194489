import React, {FunctionComponent, useContext, useState} from "react"
import Modal from "../../../../../../tech/modal/modal.component"
import {ShareClassType} from "../../../../../share-class/share-class.type"
import {FetchClient} from "../../../../../../tech/fetch/client"
import FetchContext from "../../../../../../tech/fetch/fetch.context"
import DealInvestorGroupFeeStructureShareClassForm from "../form/form.component"
import styles from "./new.module.sass"
import Alert from "../../../../../../tech/alert/alert.component"
import {AlertType} from "../../../../../../tech/alert/type.enum"

type DealInvestorGroupFeeStructureShareClassNewProps = {
    addOrUpdateShareClassAndSelect: (shareClass: ShareClassType) => void
    close: () => void
}

const DealInvestorGroupFeeStructureShareClassNew: FunctionComponent<DealInvestorGroupFeeStructureShareClassNewProps> = ({
    addOrUpdateShareClassAndSelect,
    close
}) => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [error, setError] = useState<boolean>(false)

    async function save(shareClassToSave: ShareClassType) {
        try {
            addOrUpdateShareClassAndSelect(await fetchClient.shareClassApi.create(shareClassToSave))
            close()
        }
        catch (err) {
            console.error(err)
            setError(true)
        }
    }

    return (
        <Modal close={close}>
            <div className={styles.new}>
                {error ? (
                    <Alert
                        type={AlertType.ERROR}
                        text="Failed to save."
                    />
                ) : (
                    <DealInvestorGroupFeeStructureShareClassForm save={save}/>
                )}
            </div>
        </Modal>
    )
}

export default DealInvestorGroupFeeStructureShareClassNew