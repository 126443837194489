import {SubscriptionOpenApi, SubscriptionTypeOpenApi} from "../../generated"
import {SubscriptionType} from "./subscription.type"
import {mapOpenApiToCancellation} from "./cancellation/cancellation.mapper"

export function mapOpenApiToSubscription(subscription: SubscriptionOpenApi): SubscriptionType {
    return {
        logId: subscription.logId,
        logCreated: subscription.logCreated,
        logModified: subscription.logModified,
        stripeId: subscription.stripeId,
        type: subscription.type,
        price: subscription.price,
        paymentInterval: subscription.paymentInterval,
        cancellation: subscription.cancellation && mapOpenApiToCancellation(subscription.cancellation)
    }
}

export function mapSubscriptionTypeToString(subscriptionType: SubscriptionTypeOpenApi) {
    switch (subscriptionType) {
        case "PRO":
            return "Pro"
        case "ELITE":
            return "Elite"
    }
}