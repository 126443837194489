/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JavaScriptErrorOpenApi
 */
export interface JavaScriptErrorOpenApi {
    /**
     * 
     * @type {Date}
     * @memberof JavaScriptErrorOpenApi
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof JavaScriptErrorOpenApi
     */
    error: string;
    /**
     * 
     * @type {string}
     * @memberof JavaScriptErrorOpenApi
     */
    frontendUrl: string;
    /**
     * 
     * @type {string}
     * @memberof JavaScriptErrorOpenApi
     */
    userAgent?: string;
    /**
     * 
     * @type {string}
     * @memberof JavaScriptErrorOpenApi
     */
    stacktrace?: string;
}

/**
 * Check if a given object implements the JavaScriptErrorOpenApi interface.
 */
export function instanceOfJavaScriptErrorOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "error" in value;
    isInstance = isInstance && "frontendUrl" in value;

    return isInstance;
}

export function JavaScriptErrorOpenApiFromJSON(json: any): JavaScriptErrorOpenApi {
    return JavaScriptErrorOpenApiFromJSONTyped(json, false);
}

export function JavaScriptErrorOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): JavaScriptErrorOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': (new Date(json['date'])),
        'error': json['error'],
        'frontendUrl': json['frontendUrl'],
        'userAgent': !exists(json, 'userAgent') ? undefined : json['userAgent'],
        'stacktrace': !exists(json, 'stacktrace') ? undefined : json['stacktrace'],
    };
}

export function JavaScriptErrorOpenApiToJSON(value?: JavaScriptErrorOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': (value.date.toISOString()),
        'error': value.error,
        'frontendUrl': value.frontendUrl,
        'userAgent': value.userAgent,
        'stacktrace': value.stacktrace,
    };
}

