/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SyndicateForPresentationOpenApi } from './SyndicateForPresentationOpenApi';
import {
    SyndicateForPresentationOpenApiFromJSON,
    SyndicateForPresentationOpenApiFromJSONTyped,
    SyndicateForPresentationOpenApiToJSON,
} from './SyndicateForPresentationOpenApi';

/**
 * 
 * @export
 * @interface InvestorSyndicationOpenApi
 */
export interface InvestorSyndicationOpenApi {
    /**
     * 
     * @type {Array<SyndicateForPresentationOpenApi>}
     * @memberof InvestorSyndicationOpenApi
     */
    memberships: Array<SyndicateForPresentationOpenApi>;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorSyndicationOpenApi
     */
    syndicateOnlyInvestor: boolean;
}

/**
 * Check if a given object implements the InvestorSyndicationOpenApi interface.
 */
export function instanceOfInvestorSyndicationOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "memberships" in value;
    isInstance = isInstance && "syndicateOnlyInvestor" in value;

    return isInstance;
}

export function InvestorSyndicationOpenApiFromJSON(json: any): InvestorSyndicationOpenApi {
    return InvestorSyndicationOpenApiFromJSONTyped(json, false);
}

export function InvestorSyndicationOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorSyndicationOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberships': ((json['memberships'] as Array<any>).map(SyndicateForPresentationOpenApiFromJSON)),
        'syndicateOnlyInvestor': json['syndicateOnlyInvestor'],
    };
}

export function InvestorSyndicationOpenApiToJSON(value?: InvestorSyndicationOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberships': ((value.memberships as Array<any>).map(SyndicateForPresentationOpenApiToJSON)),
        'syndicateOnlyInvestor': value.syndicateOnlyInvestor,
    };
}

