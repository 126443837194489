import React, {FunctionComponent, useContext, useEffect, useMemo, useState} from "react"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import {DealWithValuationsType} from "../../deal/valuation/deal-with-valuations.type"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import SectionHeading from "../../../tech/section/section-heading.component"
import Section from "../../../tech/section/section.component"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import ValuationsForSingleDealForm from "./form.component"
import formStyles from "../../../tech/form/form.module.css"
import {SubmitHandler, useFieldArray, useForm} from "react-hook-form"
import {compareDates} from "../../../tech/sort/sort.util"
import {CurrencyOpenApi, ValuationCalculationMethodOpenApi} from "../../../generated"
import {normalizeValues} from "../../../tech/form/form.util"
import {ValuationsForSingleDealBackofficeType} from "./single-deal.type"
import styles from "./single-deal.module.sass"
import FormError from "../../../tech/form/error.component"

type ValuationsForSingleDealProps = {
    dealId: string
}

const ValuationsForSingleDeal: FunctionComponent<ValuationsForSingleDealProps> = ({ dealId }) => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR_LOADING" | "ERROR_SAVING" | "SUCCESS">("LOADING")
    const [dealWithValuations, setDealWithValuations] = useState<DealWithValuationsType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                setDealWithValuations(await fetchClient.dealApi.getWithValuations(dealId))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR_LOADING")
            }
        }
        fetch()
    }, [fetchClient, dealId])

    const {
        control,
        register,
        reset,
        unregister,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<ValuationsForSingleDealBackofficeType>({
        defaultValues: useMemo(() => {
            return fill(dealWithValuations);
        }, [dealWithValuations])
    })

    useEffect(() => {
        reset(fill(dealWithValuations))
    }, [dealWithValuations, reset])

    const onSubmit: SubmitHandler<ValuationsForSingleDealBackofficeType> = async (formValues) => {
        setState("LOADING")
        window.scrollTo({ top: 0 })
        try {
            const valuationsToSave = formValues.valuations
                .map(valuation => ({
                    ...valuation,
                    calculationMethod: formValues.calculationMethod
                }))
                .map(normalizeValues)
            await fetchClient.valuationApi.saveAll(dealId, valuationsToSave)
            setState("SUCCESS")
        } catch (err) {
            console.error(err)
            setState("ERROR_SAVING")
        }
    }

    const { fields, append, remove } = useFieldArray({ name: "valuations", control })

    const add = () => append({
        calculationMethod: ValuationCalculationMethodOpenApi.PricePerShare,
        currency: CurrencyOpenApi.Usd,
        date: new Date(),
        dealId: dealWithValuations!.deal.id!
    })

    const del = (i: number) => remove(i)

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "SUCCESS" && (
                <Alert
                    type={AlertType.SUCCESS}
                    text="Successfully saved."
                />
            )}
            {state === "ERROR_SAVING" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Saving failed."
                />
            )}
            {(state === "LOADED" || state === "SUCCESS") && dealWithValuations && (
                <Section>
                    <SectionHeading title={`Valuations for deal ${dealWithValuations.deal.name.completeOnboarding}`}/>
                    <form
                        className={formStyles.form}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <label>
                            <span>Calculation Method</span>
                            <select
                                {...register("calculationMethod")}
                            >
                                {Object.entries(ValuationCalculationMethodOpenApi).map((c, index) => (
                                    <option
                                        value={c[1]}
                                        key={index}
                                    >
                                        {c[1]}
                                    </option>
                                ))}
                            </select>
                            <FormError field="calculationMethod" errors={errors}/>
                        </label>
                        {fields
                            .sort((a, b) => compareDates(a.date, b.date, "ASCENDING"))
                            .map((valuation, index) => (
                                <div
                                    className={styles.valuation}
                                    key={valuation.id}
                                >
                                    <ValuationsForSingleDealForm
                                        calculationMethod={watch("calculationMethod") as ValuationCalculationMethodOpenApi}
                                        valuation={valuation}
                                        control={control}
                                        errors={errors}
                                        index={index}
                                        register={register}
                                        unregister={unregister}
                                    />
                                    <div className={styles.actions}>
                                        <button
                                            type="button"
                                            onClick={() => del(index)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            ))}
                        <div className={styles.addValuation}>
                            <Button
                                title="Add Valuation"
                                style={ButtonStyle.SECONDARY}
                                type="button"
                                onClick={add}
                            />
                        </div>
                        <button
                            type="submit"
                            className={formStyles.submitButton}
                        >
                            Save
                        </button>
                    </form>
                </Section>
            )}
            {state === "ERROR_LOADING" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load."
                />
            )}
        </>
    )
}

export default ValuationsForSingleDeal

function fill(dealWithValuations: DealWithValuationsType | undefined): ValuationsForSingleDealBackofficeType {
    return {
        calculationMethod: (dealWithValuations?.valuations.length || 0) > 0
            ? dealWithValuations!.valuations[0].calculationMethod
            : ValuationCalculationMethodOpenApi.PricePerShare,
        valuations: dealWithValuations?.valuations || []
    }
}