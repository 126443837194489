import React, {useContext, useState} from "react"
import {SubmitHandler} from "react-hook-form"
import {Navigate} from "react-router-dom"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import {UpdateType} from "./update.type"
import UpdateForm from "./form.component"
import {UPDATE} from "../../paths"
import {normalizeValues} from "../../tech/form/form.util"
import {match, P} from "ts-pattern"
import BackendValidationErrorsAlert from "../../tech/validation/alert.component"
import {
    ComponentState,
    ComponentStateErrorAction,
    ComponentStateLoaded,
    ComponentStateLoadingWithData,
    ComponentStateSuccess
} from "../../tech/state/state.type"

const UpdateNew = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<ComponentState<UpdateType | undefined>>(new ComponentStateLoaded(undefined))

    const onSubmit: SubmitHandler<UpdateType> = async (update) => {
        if (state.type !== "LOADED" && state.type !== "ERROR_ACTION") {
            throw new Error("Not allowed.")
        }
        setState(new ComponentStateLoadingWithData(state.data))
        const res = await fetchClient.updateApi.create(normalizeValues(update))
        match(res)
            .with(
                { type: "RESPONSE" },
                (r) => setState(new ComponentStateSuccess(r.val))
            )
            .with(
                { type: "RESPONSE_ERROR" },
                () => {
                    window.scrollTo({ top: 0 })
                    setState(new ComponentStateErrorAction(update))
                }
            )
            .with(
                { type: "VALIDATION_ERRORS" },
                (res) => {
                    window.scrollTo({ top: 0 })
                    setState(new ComponentStateErrorAction(update, res.errors))
                }
            )
            .exhaustive()
    }

    return match(state)
        .with(
            P.union({ type: "LOADED" }, { type: "ERROR_ACTION" }),
            (s) => (
                <Section>
                    <SectionHeading title="Create new update"/>
                    {s.type === "ERROR_ACTION" && s.errors && <BackendValidationErrorsAlert errors={s.errors}/>}
                    {s.type === "ERROR_ACTION" && !s.errors && (
                        <Alert
                            type={AlertType.ERROR}
                            text="Failed to save."
                        />
                    )}
                    <UpdateForm
                        update={s.data}
                        onSubmit={onSubmit}
                        submitText="Create update"
                    />
                </Section>
            )
        )
        .with(
            { type: "ERROR_LOADING" },
            () => (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load."
                />
            )
        )
        .with(
            P.union({ type: "LOADING" }, { type: "LOADING_WITH_DATA" }),
            () => <LoadingDots/>
        )
        .with(
            { type: "SUCCESS" },
            (s) => <Navigate to={UPDATE(s.data!.id!)}/>
        )
        .exhaustive()
}

export default UpdateNew