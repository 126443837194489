import {getFilter, getFilterValue} from "../../tech/actions/filter/filter.util"
import {ActionsFilterType, ActionsFilterTypeAndValueType} from "../../tech/actions/filter/filter.type"

export type InvestmentFilterType = "ID" | "PAID"

export function assembleFilterOptions(): ActionsFilterType<InvestmentFilterType>[] {
    return [
        {
            type: "ID",
            label: "ID",
            value: "STRING"
        },
        {
            type: "PAID",
            label: "Paid / Unpaid",
            value: "BOOLEAN"
        },
    ]
}

export function getCurrentFilter(
    filters: ActionsFilterType<InvestmentFilterType>[],
    searchParams: URLSearchParams
): ActionsFilterTypeAndValueType<InvestmentFilterType> | undefined {
    const foundFilter = filters.find(f => f.type === getFilter(searchParams))
    const foundFilterValue = getFilterValue(searchParams)
    if (foundFilter && foundFilterValue) {
        return {
            type: foundFilter.type,
            value: foundFilterValue
        }
    }
    return undefined
}