import {CompanyContactOpenApi} from "../../../generated"
import {CompanyContactType} from "./contact.type"
import {mapContactToOpenApi, mapOpenApiToContact} from "../../contact/contact.mapper"

export function mapOpenApiToCompanyContact(contact: CompanyContactOpenApi): CompanyContactType {
    return {
        id: contact.id,
        sequentialId: contact.sequentialId,
        created: contact.created,
        modified: contact.modified,
        name: contact.name,
        contact: mapOpenApiToContact(contact.contact),
        types: contact.types
    }
}

export function mapCompanyContactToOpenApi(contact: CompanyContactType): CompanyContactOpenApi {
    return {
        id: contact.id,
        sequentialId: contact.sequentialId,
        created: contact.created,
        modified: contact.modified,
        name: contact.name,
        contact: mapContactToOpenApi(contact.contact),
        types: contact.types
    }
}