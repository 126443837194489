import React, {useContext, useEffect, useMemo, useState} from "react"
import {useSearchParams} from "react-router-dom"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import ValuationOverviewDealsTable from "./overview-deals-table.componet"
import {PaginatedType} from "../../../tech/actions/pagination/pagination.type"
import {DealWithValuationsType} from "../../deal/valuation/deal-with-valuations.type"
import {emptyPaginated, getPagination} from "../../../tech/actions/pagination/pagination.util"
import {assembleFilterOptions, getCurrentFilter, mapToDealFiltersOpenApi} from "../../deal/filter.util"
import {assembleSortOptions, getCurrentSortOption} from "./sort.util"
import Actions from "../../../tech/actions/actions.component"

const ValuationOverviewDeals = () => {
    const DEALS_PER_PAGE = 50
    const [searchParams] = useSearchParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [
        dealsWithValuations,
        setDealsWithValuations
    ] = useState<PaginatedType<DealWithValuationsType>>(emptyPaginated())

    const sortOptions = assembleSortOptions()
    const currentSortOption = useMemo(
        () => getCurrentSortOption(sortOptions, searchParams),
        [sortOptions, searchParams]
    )

    const filters = useMemo(() => assembleFilterOptions(), [])
    const currentFilter = useMemo(
        () => getCurrentFilter(filters, searchParams),
        [filters, searchParams]
    )

    const pagination = useMemo(() => getPagination(DEALS_PER_PAGE, searchParams), [searchParams])

    useEffect(() => {
        const fetch = async () => {
            try {
                setState("LOADING")
                setDealsWithValuations(await fetchClient.dealApi.getAllWithValuationsPaginated(
                    currentSortOption.id,
                    mapToDealFiltersOpenApi(currentFilter),
                    pagination
                ))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, currentFilter, currentSortOption.id, pagination])

    return (
        <Section>
            <SectionHeading
                title="Valuations"
                subTitle={`${dealsWithValuations.total} deals`}
            />
            <Actions
                filter={{
                    current: currentFilter,
                    filters
                }}
                pagination={{
                    label: "deals",
                    elementsPerPage: DEALS_PER_PAGE,
                    numberElements: dealsWithValuations.total
                }}
                sort={{
                    current: currentSortOption,
                    options: sortOptions
                }}
            >
                {state === "LOADING" && <LoadingDots/>}
                {state === "LOADED" && (
                    <ValuationOverviewDealsTable dealWithValuations={dealsWithValuations.elements}/>
                )}
                {state === "ERROR" && (
                    <Alert
                        type={AlertType.ERROR}
                        text="Failed to load valuations"
                    />
                )}
            </Actions>
        </Section>
    )
}

export default ValuationOverviewDeals