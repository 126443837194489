import {DealGeographyOpenApi} from "../../generated"
import {DealGeographyType} from "./deal-geography.type"

export function mapOpenApiToDealGeography(geography: DealGeographyOpenApi): DealGeographyType {
    return {
        id: geography.id,
        name: geography.name,
        slug: geography.slug
    }
}

export function mapDealGeographyToOpenApi(geography: DealGeographyType): DealGeographyOpenApi {
    return {
        id: geography.id,
        name: geography.name,
        slug: geography.slug
    }
}