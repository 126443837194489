/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FileSortOpenApi = {
    CreatedAscending: 'CREATED_ASCENDING',
    CreatedDescending: 'CREATED_DESCENDING',
    IdentifierAscending: 'IDENTIFIER_ASCENDING',
    IdentifierDescending: 'IDENTIFIER_DESCENDING'
} as const;
export type FileSortOpenApi = typeof FileSortOpenApi[keyof typeof FileSortOpenApi];


export function FileSortOpenApiFromJSON(json: any): FileSortOpenApi {
    return FileSortOpenApiFromJSONTyped(json, false);
}

export function FileSortOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileSortOpenApi {
    return json as FileSortOpenApi;
}

export function FileSortOpenApiToJSON(value?: FileSortOpenApi | null): any {
    return value as any;
}

