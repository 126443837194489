import React, {useContext, useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import DealCategoryForm from "./form.component"
import {SubmitHandler} from "react-hook-form"
import {normalizeValues} from "../../tech/form/form.util"
import {DealCategoryType} from "./deal-category.type"

const DealCategoryEdit = () => {
    let { id } = useParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR" | "SUCCESS">("LOADING")
    const [category, setCategory] = useState<DealCategoryType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                setCategory(await fetchClient.dealCategoryApi.get(id!))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const onSubmit: SubmitHandler<DealCategoryType> = async (category) => {
        setState("LOADING")
        try {
            setCategory(await fetchClient.dealCategoryApi.update(id!, normalizeValues(category)))
            window.scrollTo({ top: 0 })
            setState("SUCCESS")
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="An error occurred. Please try again or contact the tech team."
                />
            )}
            {state === "SUCCESS" && (
                <Alert
                    type={AlertType.SUCCESS}
                    text="Saved successfully."
                />
            )}
            {(state === "LOADED" || state === "ERROR" || state === "SUCCESS") && category && (
                <Section>
                    <SectionHeading title={`Edit deal category: ${category.name}`}/>
                    <DealCategoryForm
                        category={category}
                        onSubmit={onSubmit}
                        submitText="Save deal category"
                    />
                </Section>
            )}
        </>
    )
}

export default DealCategoryEdit