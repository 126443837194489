/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DealGroupOpenApi } from './DealGroupOpenApi';
import {
    DealGroupOpenApiFromJSON,
    DealGroupOpenApiFromJSONTyped,
    DealGroupOpenApiToJSON,
} from './DealGroupOpenApi';
import type { InvestorGroupOpenApi } from './InvestorGroupOpenApi';
import {
    InvestorGroupOpenApiFromJSON,
    InvestorGroupOpenApiFromJSONTyped,
    InvestorGroupOpenApiToJSON,
} from './InvestorGroupOpenApi';
import type { InvestorKycOpenApi } from './InvestorKycOpenApi';
import {
    InvestorKycOpenApiFromJSON,
    InvestorKycOpenApiFromJSONTyped,
    InvestorKycOpenApiToJSON,
} from './InvestorKycOpenApi';
import type { PersonOpenApi } from './PersonOpenApi';
import {
    PersonOpenApiFromJSON,
    PersonOpenApiFromJSONTyped,
    PersonOpenApiToJSON,
} from './PersonOpenApi';
import type { UserOpenApi } from './UserOpenApi';
import {
    UserOpenApiFromJSON,
    UserOpenApiFromJSONTyped,
    UserOpenApiToJSON,
} from './UserOpenApi';

/**
 * 
 * @export
 * @interface InvestorOpenApi
 */
export interface InvestorOpenApi {
    /**
     * 
     * @type {string}
     * @memberof InvestorOpenApi
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof InvestorOpenApi
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvestorOpenApi
     */
    modified: Date;
    /**
     * 
     * @type {UserOpenApi}
     * @memberof InvestorOpenApi
     */
    user?: UserOpenApi;
    /**
     * 
     * @type {PersonOpenApi}
     * @memberof InvestorOpenApi
     */
    person?: PersonOpenApi;
    /**
     * 
     * @type {Array<DealGroupOpenApi>}
     * @memberof InvestorOpenApi
     */
    dealGroups?: Array<DealGroupOpenApi>;
    /**
     * 
     * @type {Array<InvestorGroupOpenApi>}
     * @memberof InvestorOpenApi
     */
    investorGroups?: Array<InvestorGroupOpenApi>;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorOpenApi
     */
    aiStatusDeclared: boolean;
    /**
     * 
     * @type {InvestorKycOpenApi}
     * @memberof InvestorOpenApi
     */
    kyc?: InvestorKycOpenApi;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorOpenApi
     */
    started: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorOpenApi
     */
    imported: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvestorOpenApi
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestorOpenApi
     */
    stripeCustomerId: string;
}

/**
 * Check if a given object implements the InvestorOpenApi interface.
 */
export function instanceOfInvestorOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "aiStatusDeclared" in value;
    isInstance = isInstance && "started" in value;
    isInstance = isInstance && "imported" in value;
    isInstance = isInstance && "stripeCustomerId" in value;

    return isInstance;
}

export function InvestorOpenApiFromJSON(json: any): InvestorOpenApi {
    return InvestorOpenApiFromJSONTyped(json, false);
}

export function InvestorOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'user': !exists(json, 'user') ? undefined : UserOpenApiFromJSON(json['user']),
        'person': !exists(json, 'person') ? undefined : PersonOpenApiFromJSON(json['person']),
        'dealGroups': !exists(json, 'dealGroups') ? undefined : ((json['dealGroups'] as Array<any>).map(DealGroupOpenApiFromJSON)),
        'investorGroups': !exists(json, 'investorGroups') ? undefined : ((json['investorGroups'] as Array<any>).map(InvestorGroupOpenApiFromJSON)),
        'aiStatusDeclared': json['aiStatusDeclared'],
        'kyc': !exists(json, 'kyc') ? undefined : InvestorKycOpenApiFromJSON(json['kyc']),
        'started': json['started'],
        'imported': json['imported'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'stripeCustomerId': json['stripeCustomerId'],
    };
}

export function InvestorOpenApiToJSON(value?: InvestorOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'modified': (value.modified.toISOString()),
        'user': UserOpenApiToJSON(value.user),
        'person': PersonOpenApiToJSON(value.person),
        'dealGroups': value.dealGroups === undefined ? undefined : ((value.dealGroups as Array<any>).map(DealGroupOpenApiToJSON)),
        'investorGroups': value.investorGroups === undefined ? undefined : ((value.investorGroups as Array<any>).map(InvestorGroupOpenApiToJSON)),
        'aiStatusDeclared': value.aiStatusDeclared,
        'kyc': InvestorKycOpenApiToJSON(value.kyc),
        'started': value.started,
        'imported': value.imported,
        'companyName': value.companyName,
        'stripeCustomerId': value.stripeCustomerId,
    };
}

