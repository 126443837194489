import React, {FunctionComponent} from "react"
import {FieldErrors, UseFormRegister} from "react-hook-form"
import FormError from "../../../../../tech/form/error.component"
import {DealType} from "../../../deal.type"
import {FeeStructureSourceType} from "../source.type"

type DealInvestorGroupFeeStructureCustomProps = {
    index: number
    errors: FieldErrors<DealType>
    register: UseFormRegister<DealType>
    feeStructureSource: FeeStructureSourceType
}

const DealInvestorGroupFeeStructureCustom: FunctionComponent<DealInvestorGroupFeeStructureCustomProps> = ({
    index,
    errors,
    register,
    feeStructureSource
}) => {
    return (
        <>
            <label>
                <span>Setup fee (%)</span>
                <input
                    type="number"
                    step={0.05}
                    defaultValue={1.5}
                    {...register(
                        `investorGroups.${index}.feeStructure.placementFee`,
                        {
                            validate: (placementFee) => {
                                if (feeStructureSource === "CUSTOM" && placementFee === undefined) return "Setup fee is required."
                                return true
                            },
                            min: {value: 0, message: "Must not be negative."},
                            max: {value: 100, message: "Must not be greater than 100 percent."}
                        }
                    )}
                />
                <FormError field={`investorGroups.${index}.feeStructure.placementFee`} errors={errors}/>
            </label>
            <label>
                <span>Setup fee explanation</span>
                <input
                    type="text"
                    placeholder="Setup fee explanation"
                    {...register(`investorGroups.${index}.feeStructure.placementFeeExplanation`)}
                />
                <FormError field={`investorGroups.${index}.feeStructure.placementFeeExplanation`} errors={errors}/>
            </label>
            <label>
                <span>Management fee (%)</span>
                <input
                    type="number"
                    step={0.05}
                    defaultValue={0}
                    {...register(
                        `investorGroups.${index}.feeStructure.managementFee`,
                        {
                            validate: (placementFee) => {
                                if (feeStructureSource === "CUSTOM" && placementFee === undefined) return "Management fee is required."
                                return true
                            },
                            min: {value: 0, message: "Must not be negative."},
                            max: {value: 100, message: "Must not be greater than 100 percent."}
                        }
                    )}
                />
                <FormError field={`investorGroups.${index}.feeStructure.managementFee`} errors={errors}/>
            </label>
            <label>
                <span>Carry (%)</span>
                <input
                    type="number"
                    step={0.05}
                    defaultValue={20}
                    {...register(
                        `investorGroups.${index}.feeStructure.carry`,
                        {
                            validate: (placementFee) => {
                                if (feeStructureSource === "CUSTOM" && placementFee === undefined) return "Carry is required."
                                return true
                            },
                            min: {value: 0, message: "Must not be negative."},
                            max: {value: 100, message: "Must not be greater than 100 percent."}
                        }
                    )}
                />
                <FormError field={`investorGroups.${index}.feeStructure.carry`} errors={errors}/>
            </label>
        </>
    )
}

export default DealInvestorGroupFeeStructureCustom