import {SinglePurposeVehicleOpenApi} from "../../generated"
import {SinglePurposeVehicleType} from "./single-purpose-vehicle.type"

export function mapOpenApiToSinglePurposeVehicle(singlePurposeVehicle: SinglePurposeVehicleOpenApi): SinglePurposeVehicleType {
    return {
        id: singlePurposeVehicle.id,
        created: singlePurposeVehicle.created,
        modified: singlePurposeVehicle.modified,
        name: singlePurposeVehicle.name,
        address: singlePurposeVehicle.address,
    }
}

export function mapSinglePurposeVehicleToOpenApi(singlePurposeVehicle: SinglePurposeVehicleType): SinglePurposeVehicleOpenApi {
    return {
        id: singlePurposeVehicle.id,
        created: singlePurposeVehicle.created,
        modified: singlePurposeVehicle.modified,
        name: singlePurposeVehicle.name,
        address: singlePurposeVehicle.address,
    }
}