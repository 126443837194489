import {DealGeographyApiClient, DealGeographyOpenApi} from "../../generated"
import {mapDealGeographyToOpenApi, mapOpenApiToDealGeography} from "./deal-geography.mapper"
import {DealGeographyType} from "./deal-geography.type"

export class DealGeographyApi {

    constructor(private apiClient: DealGeographyApiClient) {
    }

    public async create(geography: DealGeographyType): Promise<DealGeographyType> {
        const savedDealGeography = await this.apiClient.dealGeographyCreate({ dealGeographyOpenApi: mapDealGeographyToOpenApi(geography) })
        return mapOpenApiToDealGeography(savedDealGeography)
    }

    public async delete(id: string): Promise<void> {
        await this.apiClient.dealGeographyDelete({ id })
    }

    public async getAll(): Promise<DealGeographyType[]> {
        return (await this.apiClient.dealGeographyGetAll())
            .map((geography: DealGeographyOpenApi) => mapOpenApiToDealGeography(geography))
    }

    public async get(id: string): Promise<DealGeographyType> {
        const geography = await this.apiClient.dealGeographyGet({ id })
        return mapOpenApiToDealGeography(geography)
    }

    public async update(id: string, geography: DealGeographyType): Promise<DealGeographyType> {
        const response = await this.apiClient.dealGeographyUpdate({ id, dealGeographyOpenApi: mapDealGeographyToOpenApi(geography) })
        return mapOpenApiToDealGeography(response)
    }

}