/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpdateFileOpenApi } from './UpdateFileOpenApi';
import {
    UpdateFileOpenApiFromJSON,
    UpdateFileOpenApiFromJSONTyped,
    UpdateFileOpenApiToJSON,
} from './UpdateFileOpenApi';

/**
 * 
 * @export
 * @interface UpdateForDealOpenApi
 */
export interface UpdateForDealOpenApi {
    /**
     * 
     * @type {string}
     * @memberof UpdateForDealOpenApi
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateForDealOpenApi
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateForDealOpenApi
     */
    description: string;
    /**
     * 
     * @type {Array<UpdateFileOpenApi>}
     * @memberof UpdateForDealOpenApi
     */
    files: Array<UpdateFileOpenApi>;
}

/**
 * Check if a given object implements the UpdateForDealOpenApi interface.
 */
export function instanceOfUpdateForDealOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "files" in value;

    return isInstance;
}

export function UpdateForDealOpenApiFromJSON(json: any): UpdateForDealOpenApi {
    return UpdateForDealOpenApiFromJSONTyped(json, false);
}

export function UpdateForDealOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateForDealOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date': (new Date(json['date'])),
        'description': json['description'],
        'files': ((json['files'] as Array<any>).map(UpdateFileOpenApiFromJSON)),
    };
}

export function UpdateForDealOpenApiToJSON(value?: UpdateForDealOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': (value.date.toISOString().substr(0,10)),
        'description': value.description,
        'files': ((value.files as Array<any>).map(UpdateFileOpenApiToJSON)),
    };
}

