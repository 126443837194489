/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DealExitTypeOpenApi = {
    Dead: 'DEAD',
    Exit: 'EXIT'
} as const;
export type DealExitTypeOpenApi = typeof DealExitTypeOpenApi[keyof typeof DealExitTypeOpenApi];


export function DealExitTypeOpenApiFromJSON(json: any): DealExitTypeOpenApi {
    return DealExitTypeOpenApiFromJSONTyped(json, false);
}

export function DealExitTypeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealExitTypeOpenApi {
    return json as DealExitTypeOpenApi;
}

export function DealExitTypeOpenApiToJSON(value?: DealExitTypeOpenApi | null): any {
    return value as any;
}

