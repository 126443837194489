import React, {useContext, useEffect, useState} from "react"
import Section from "../../tech/section/section.component"
import {useParams} from "react-router-dom"
import SyndicateMembersOverview from "./member/members.component"
import SyndicateInvitationsOverview from "./invitation/invitations.component"
import {SyndicateType} from "./syndicate.type"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {InvestorWithDetailsType} from "../investor/investor.type"
import {SyndicateInvitationType} from "./invitation/invitation.type"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import styles from "./member-invitations.module.sass"

const SyndicateMembersAndInvitationsOverview = () => {
    let { syndicateId } = useParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [syndicate, setSyndicate] = useState<SyndicateType>()
    const [invitations, setInvitations] = useState<SyndicateInvitationType[]>([])
    const [members, setMembers] = useState<InvestorWithDetailsType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const [
                    fetchedSyndicate,
                    fetchedInvitations,
                    fetchedMembers
                ] = await Promise.all([
                    fetchClient.syndicateApi.get(syndicateId!),
                    fetchClient.syndicateApi.getAllInvitationsNotAlreadyEntered(syndicateId!),
                    fetchClient.syndicateApi.getAllMembers(syndicateId!)
                ])
                setSyndicate(fetchedSyndicate)
                setInvitations(fetchedInvitations)
                setMembers(fetchedMembers)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, syndicateId])

    return (
        <Section>
            <div className={styles.title}>
                {syndicate ? (
                    <>Syndicate: <i>{syndicate.name}</i></>
                ) : (
                    <>Syndicate ID: <i>{syndicateId}</i></>
                )}
            </div>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && syndicate && (
                <>
                    <SyndicateInvitationsOverview
                        syndicate={syndicate}
                        invitations={invitations}
                    />
                    <br/><br/><br/>
                    <SyndicateMembersOverview
                        syndicate={syndicate}
                        members={members}
                    />
                </>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load syndicate members and invitations."
                />
            )}
        </Section>
    )
}

export default SyndicateMembersAndInvitationsOverview