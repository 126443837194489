import React, {FunctionComponent} from "react"
import {Control, FieldErrors, useFieldArray, UseFormRegister, UseFormSetValue, UseFormWatch} from "react-hook-form"
import {DealType} from "../deal.type"
import {DealDocumentType} from "./document.type"
import DealDocumentSelectSingle from "./single.component"
import {DealDocumentTypeOpenApi, MimeTypeOpenApi} from "../../../generated"
import styles from "./select.module.sass"
import {FileSelectSearchType} from "../../file/upload-select/select/select.component"
import {FileType} from "../../file/file.type"

type DealDocumentSelectProps = {
    documents: DealDocumentType[]
    control: Control<DealType, any>
    errors: FieldErrors<DealType>
    register: UseFormRegister<DealType>
    setValue: UseFormSetValue<DealType>
    watch: UseFormWatch<DealType>
    fetchFiles: (
        mimeTypes: MimeTypeOpenApi[],
        searchType: FileSelectSearchType,
        searchValue: string
    ) => Promise<FileType[]>
}

const DealDocumentSelect: FunctionComponent<DealDocumentSelectProps> = ({
    documents,
    control,
    errors,
    register,
    setValue,
    watch,
    fetchFiles
}) => {
    const { fields, append, remove } = useFieldArray({ name: "documents", control })

    function add() {
        const newOrder = documents.map(d => d.order).filter(o => o !== undefined)
        append({
            // @ts-ignore
            file: undefined,
            type: DealDocumentTypeOpenApi.Other,
            order: newOrder.length > 0 ? Math.max(...newOrder) + 1 : 0
        })
    }

    return (
        <div>
            {fields.map((d, index) => (
                <DealDocumentSelectSingle
                    index={index}
                    errors={errors}
                    register={register}
                    remove={remove}
                    setValue={setValue}
                    watch={watch}
                    fetchFiles={fetchFiles}
                    key={d.id}
                />
            ))}
            <button
                type="button"
                onClick={add}
                className={styles.add}
            >
                Add a deal document
            </button>
        </div>
    )
}

export default DealDocumentSelect