import {SinglePurposeVehicleSortOpenApi} from "../../generated"
import {ActionsSortOptionType} from "../../tech/actions/sort/sort.type"
import {getSort} from "../../tech/actions/sort/sort.util"

export const DEFAULT_SORT = {
    id: SinglePurposeVehicleSortOpenApi.ModifiedDescending,
    label: "Modified (DESC)"
}

export function assembleSortOptions(): ActionsSortOptionType<SinglePurposeVehicleSortOpenApi>[] {
    return [
        {
            id: SinglePurposeVehicleSortOpenApi.CreatedAscending,
            label: "Created (ASC)"
        },
        {
            id: SinglePurposeVehicleSortOpenApi.CreatedDescending,
            label: "Created (DESC)"
        },
        {
            id: SinglePurposeVehicleSortOpenApi.ModifiedAscending,
            label: "Modified (ASC)"
        },
        DEFAULT_SORT,
        {
            id: SinglePurposeVehicleSortOpenApi.NameAscending,
            label: "Name (ASC)"
        },
        {
            id: SinglePurposeVehicleSortOpenApi.NameDescending,
            label: "Name (DESC)"
        },
    ]
}

export function getCurrentSortOption(
    options: ActionsSortOptionType<SinglePurposeVehicleSortOpenApi>[],
    searchParams: URLSearchParams
): ActionsSortOptionType<SinglePurposeVehicleSortOpenApi> {
    return (options.find(o => o.id === getSort(searchParams)) || DEFAULT_SORT)
}