/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CountryOpenApi } from './CountryOpenApi';
import {
    CountryOpenApiFromJSON,
    CountryOpenApiFromJSONTyped,
    CountryOpenApiToJSON,
} from './CountryOpenApi';

/**
 * 
 * @export
 * @interface BankOpenApi
 */
export interface BankOpenApi {
    /**
     * 
     * @type {string}
     * @memberof BankOpenApi
     */
    address?: string;
    /**
     * 
     * @type {CountryOpenApi}
     * @memberof BankOpenApi
     */
    country?: CountryOpenApi;
    /**
     * 
     * @type {string}
     * @memberof BankOpenApi
     */
    name?: string;
}

/**
 * Check if a given object implements the BankOpenApi interface.
 */
export function instanceOfBankOpenApi(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BankOpenApiFromJSON(json: any): BankOpenApi {
    return BankOpenApiFromJSONTyped(json, false);
}

export function BankOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : json['address'],
        'country': !exists(json, 'country') ? undefined : CountryOpenApiFromJSON(json['country']),
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function BankOpenApiToJSON(value?: BankOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'country': CountryOpenApiToJSON(value.country),
        'name': value.name,
    };
}

