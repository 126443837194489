import {FetchResponseError} from "./error.type"
import {ValidationErrors} from "../validation/validation.type"

export interface ResponseWithType {
    type: "RESPONSE" | "RESPONSE_ERROR" | "VALIDATION_ERRORS"
}

export class FetchResponse<T> implements ResponseWithType {
    private readonly _val: T

    constructor(val: T) {
        this._val = val
    }

    public get type(): "RESPONSE" {
        return "RESPONSE"
    }

    get val(): T {
        return this._val
    }
}

export type ExtractedResponse<T> = FetchResponse<T> | FetchResponseError | ValidationErrors