/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ManualReconciliationBankAccountWithDealsOpenApi,
  ManualReconciliationLogFiltersOpenApi,
  ManualReconciliationLogOpenApi,
  ManualReconciliationLogPaginatedResponseOpenApi,
  ManualReconciliationLogSortOpenApi,
  ValidationResponseOpenApi,
} from '../models';
import {
    ManualReconciliationBankAccountWithDealsOpenApiFromJSON,
    ManualReconciliationBankAccountWithDealsOpenApiToJSON,
    ManualReconciliationLogFiltersOpenApiFromJSON,
    ManualReconciliationLogFiltersOpenApiToJSON,
    ManualReconciliationLogOpenApiFromJSON,
    ManualReconciliationLogOpenApiToJSON,
    ManualReconciliationLogPaginatedResponseOpenApiFromJSON,
    ManualReconciliationLogPaginatedResponseOpenApiToJSON,
    ManualReconciliationLogSortOpenApiFromJSON,
    ManualReconciliationLogSortOpenApiToJSON,
    ValidationResponseOpenApiFromJSON,
    ValidationResponseOpenApiToJSON,
} from '../models';

export interface ManualReconciliationBankAccountCreateRequest {
    manualReconciliationBankAccountWithDealsOpenApi?: ManualReconciliationBankAccountWithDealsOpenApi;
}

export interface ManualReconciliationBankAccountDeleteRequest {
    id: string;
}

export interface ManualReconciliationBankAccountGetRequest {
    id: string;
}

export interface ManualReconciliationBankAccountUpdateRequest {
    id: string;
    manualReconciliationBankAccountWithDealsOpenApi?: ManualReconciliationBankAccountWithDealsOpenApi;
}

export interface ManualReconciliationLogCreateRequest {
    manualReconciliationLogOpenApi?: ManualReconciliationLogOpenApi;
}

export interface ManualReconciliationLogGetRequest {
    id: string;
}

export interface ManualReconciliationLogGetAllByInvestmentIdRequest {
    investmentId: string;
}

export interface ManualReconciliationLogGetAllPaginatedRequest {
    elementsPerPage?: number;
    page?: number;
    sort?: ManualReconciliationLogSortOpenApi;
    manualReconciliationLogFiltersOpenApi?: ManualReconciliationLogFiltersOpenApi;
}

export interface ManualReconciliationLogUpdateRequest {
    id: string;
    manualReconciliationLogOpenApi?: ManualReconciliationLogOpenApi;
}

/**
 * 
 */
export class ReconciliationApiClient extends runtime.BaseAPI {

    /**
     * Create new bank account for manual reconciliation
     */
    async manualReconciliationBankAccountCreateRaw(requestParameters: ManualReconciliationBankAccountCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManualReconciliationBankAccountWithDealsOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/reconciliation/bank-account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManualReconciliationBankAccountWithDealsOpenApiToJSON(requestParameters.manualReconciliationBankAccountWithDealsOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManualReconciliationBankAccountWithDealsOpenApiFromJSON(jsonValue));
    }

    /**
     * Create new bank account for manual reconciliation
     */
    async manualReconciliationBankAccountCreate(requestParameters: ManualReconciliationBankAccountCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManualReconciliationBankAccountWithDealsOpenApi> {
        const response = await this.manualReconciliationBankAccountCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete bank account for manual reconciliation by id
     */
    async manualReconciliationBankAccountDeleteRaw(requestParameters: ManualReconciliationBankAccountDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manualReconciliationBankAccountDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/reconciliation/bank-account/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete bank account for manual reconciliation by id
     */
    async manualReconciliationBankAccountDelete(requestParameters: ManualReconciliationBankAccountDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.manualReconciliationBankAccountDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get bank account for manual reconciliation by id
     */
    async manualReconciliationBankAccountGetRaw(requestParameters: ManualReconciliationBankAccountGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManualReconciliationBankAccountWithDealsOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manualReconciliationBankAccountGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/reconciliation/bank-account/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManualReconciliationBankAccountWithDealsOpenApiFromJSON(jsonValue));
    }

    /**
     * Get bank account for manual reconciliation by id
     */
    async manualReconciliationBankAccountGet(requestParameters: ManualReconciliationBankAccountGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManualReconciliationBankAccountWithDealsOpenApi> {
        const response = await this.manualReconciliationBankAccountGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all bank accounts for manual reconciliation
     */
    async manualReconciliationBankAccountGetAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ManualReconciliationBankAccountWithDealsOpenApi>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/reconciliation/bank-account`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ManualReconciliationBankAccountWithDealsOpenApiFromJSON));
    }

    /**
     * Get all bank accounts for manual reconciliation
     */
    async manualReconciliationBankAccountGetAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ManualReconciliationBankAccountWithDealsOpenApi>> {
        const response = await this.manualReconciliationBankAccountGetAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update bank account for manual reconciliation
     */
    async manualReconciliationBankAccountUpdateRaw(requestParameters: ManualReconciliationBankAccountUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManualReconciliationBankAccountWithDealsOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manualReconciliationBankAccountUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/reconciliation/bank-account/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ManualReconciliationBankAccountWithDealsOpenApiToJSON(requestParameters.manualReconciliationBankAccountWithDealsOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManualReconciliationBankAccountWithDealsOpenApiFromJSON(jsonValue));
    }

    /**
     * Update bank account for manual reconciliation
     */
    async manualReconciliationBankAccountUpdate(requestParameters: ManualReconciliationBankAccountUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManualReconciliationBankAccountWithDealsOpenApi> {
        const response = await this.manualReconciliationBankAccountUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create new manual reconciliation log
     */
    async manualReconciliationLogCreateRaw(requestParameters: ManualReconciliationLogCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManualReconciliationLogOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/reconciliation/log`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManualReconciliationLogOpenApiToJSON(requestParameters.manualReconciliationLogOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManualReconciliationLogOpenApiFromJSON(jsonValue));
    }

    /**
     * Create new manual reconciliation log
     */
    async manualReconciliationLogCreate(requestParameters: ManualReconciliationLogCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManualReconciliationLogOpenApi> {
        const response = await this.manualReconciliationLogCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get manual reconciliation log by id
     */
    async manualReconciliationLogGetRaw(requestParameters: ManualReconciliationLogGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManualReconciliationLogOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manualReconciliationLogGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/reconciliation/log/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManualReconciliationLogOpenApiFromJSON(jsonValue));
    }

    /**
     * Get manual reconciliation log by id
     */
    async manualReconciliationLogGet(requestParameters: ManualReconciliationLogGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManualReconciliationLogOpenApi> {
        const response = await this.manualReconciliationLogGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all manual reconciliation logs by investmentId
     */
    async manualReconciliationLogGetAllByInvestmentIdRaw(requestParameters: ManualReconciliationLogGetAllByInvestmentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ManualReconciliationLogOpenApi>>> {
        if (requestParameters.investmentId === null || requestParameters.investmentId === undefined) {
            throw new runtime.RequiredError('investmentId','Required parameter requestParameters.investmentId was null or undefined when calling manualReconciliationLogGetAllByInvestmentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/reconciliation/log/investment/{investmentId}`.replace(`{${"investmentId"}}`, encodeURIComponent(String(requestParameters.investmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ManualReconciliationLogOpenApiFromJSON));
    }

    /**
     * Get all manual reconciliation logs by investmentId
     */
    async manualReconciliationLogGetAllByInvestmentId(requestParameters: ManualReconciliationLogGetAllByInvestmentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ManualReconciliationLogOpenApi>> {
        const response = await this.manualReconciliationLogGetAllByInvestmentIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all manual reconciliation logs (paginated)
     */
    async manualReconciliationLogGetAllPaginatedRaw(requestParameters: ManualReconciliationLogGetAllPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManualReconciliationLogPaginatedResponseOpenApi>> {
        const queryParameters: any = {};

        if (requestParameters.elementsPerPage !== undefined) {
            queryParameters['elementsPerPage'] = requestParameters.elementsPerPage;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/reconciliation/log/paginated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManualReconciliationLogFiltersOpenApiToJSON(requestParameters.manualReconciliationLogFiltersOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManualReconciliationLogPaginatedResponseOpenApiFromJSON(jsonValue));
    }

    /**
     * Get all manual reconciliation logs (paginated)
     */
    async manualReconciliationLogGetAllPaginated(requestParameters: ManualReconciliationLogGetAllPaginatedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManualReconciliationLogPaginatedResponseOpenApi> {
        const response = await this.manualReconciliationLogGetAllPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update manual reconciliation log
     */
    async manualReconciliationLogUpdateRaw(requestParameters: ManualReconciliationLogUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManualReconciliationLogOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manualReconciliationLogUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/reconciliation/log/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ManualReconciliationLogOpenApiToJSON(requestParameters.manualReconciliationLogOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManualReconciliationLogOpenApiFromJSON(jsonValue));
    }

    /**
     * Update manual reconciliation log
     */
    async manualReconciliationLogUpdate(requestParameters: ManualReconciliationLogUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManualReconciliationLogOpenApi> {
        const response = await this.manualReconciliationLogUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
