/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileTypeOpenApi } from './FileTypeOpenApi';
import {
    FileTypeOpenApiFromJSON,
    FileTypeOpenApiFromJSONTyped,
    FileTypeOpenApiToJSON,
} from './FileTypeOpenApi';

/**
 * 
 * @export
 * @interface FileUpdateOpenApi
 */
export interface FileUpdateOpenApi {
    /**
     * 
     * @type {string}
     * @memberof FileUpdateOpenApi
     */
    identifier?: string;
    /**
     * 
     * @type {FileTypeOpenApi}
     * @memberof FileUpdateOpenApi
     */
    type: FileTypeOpenApi;
}

/**
 * Check if a given object implements the FileUpdateOpenApi interface.
 */
export function instanceOfFileUpdateOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function FileUpdateOpenApiFromJSON(json: any): FileUpdateOpenApi {
    return FileUpdateOpenApiFromJSONTyped(json, false);
}

export function FileUpdateOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileUpdateOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'type': FileTypeOpenApiFromJSON(json['type']),
    };
}

export function FileUpdateOpenApiToJSON(value?: FileUpdateOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'type': FileTypeOpenApiToJSON(value.type),
    };
}

