/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CurrencyOpenApi } from './CurrencyOpenApi';
import {
    CurrencyOpenApiFromJSON,
    CurrencyOpenApiFromJSONTyped,
    CurrencyOpenApiToJSON,
} from './CurrencyOpenApi';
import type { DealOpenApi } from './DealOpenApi';
import {
    DealOpenApiFromJSON,
    DealOpenApiFromJSONTyped,
    DealOpenApiToJSON,
} from './DealOpenApi';
import type { DocumentOpenApi } from './DocumentOpenApi';
import {
    DocumentOpenApiFromJSON,
    DocumentOpenApiFromJSONTyped,
    DocumentOpenApiToJSON,
} from './DocumentOpenApi';
import type { ExchangeRateOpenApi } from './ExchangeRateOpenApi';
import {
    ExchangeRateOpenApiFromJSON,
    ExchangeRateOpenApiFromJSONTyped,
    ExchangeRateOpenApiToJSON,
} from './ExchangeRateOpenApi';
import type { InvestmentFeesOpenApi } from './InvestmentFeesOpenApi';
import {
    InvestmentFeesOpenApiFromJSON,
    InvestmentFeesOpenApiFromJSONTyped,
    InvestmentFeesOpenApiToJSON,
} from './InvestmentFeesOpenApi';
import type { InvestorOpenApi } from './InvestorOpenApi';
import {
    InvestorOpenApiFromJSON,
    InvestorOpenApiFromJSONTyped,
    InvestorOpenApiToJSON,
} from './InvestorOpenApi';

/**
 * 
 * @export
 * @interface InvestmentOpenApi
 */
export interface InvestmentOpenApi {
    /**
     * 
     * @type {string}
     * @memberof InvestmentOpenApi
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof InvestmentOpenApi
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvestmentOpenApi
     */
    modified: Date;
    /**
     * 
     * @type {number}
     * @memberof InvestmentOpenApi
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentOpenApi
     */
    amountWithPlacementFee: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentOpenApi
     */
    amountWithPlacementFeeAndExchangeFee?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentOpenApi
     */
    amountWithPlacementFeeAndExchangeFeeAtExchangeRate?: number;
    /**
     * 
     * @type {CurrencyOpenApi}
     * @memberof InvestmentOpenApi
     */
    currency: CurrencyOpenApi;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentOpenApi
     */
    agreed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentOpenApi
     */
    signed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentOpenApi
     */
    paymentInstructionsSent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentOpenApi
     */
    paid: boolean;
    /**
     * 
     * @type {DealOpenApi}
     * @memberof InvestmentOpenApi
     */
    deal: DealOpenApi;
    /**
     * 
     * @type {Array<DocumentOpenApi>}
     * @memberof InvestmentOpenApi
     */
    documents: Array<DocumentOpenApi>;
    /**
     * 
     * @type {InvestorOpenApi}
     * @memberof InvestmentOpenApi
     */
    investor: InvestorOpenApi;
    /**
     * 
     * @type {InvestmentFeesOpenApi}
     * @memberof InvestmentOpenApi
     */
    fees: InvestmentFeesOpenApi;
    /**
     * 
     * @type {string}
     * @memberof InvestmentOpenApi
     */
    shareClassNameInDealContract: string;
    /**
     * 
     * @type {ExchangeRateOpenApi}
     * @memberof InvestmentOpenApi
     */
    exchangeRate?: ExchangeRateOpenApi;
    /**
     * 
     * @type {string}
     * @memberof InvestmentOpenApi
     */
    objectType: string;
}

/**
 * Check if a given object implements the InvestmentOpenApi interface.
 */
export function instanceOfInvestmentOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "amountWithPlacementFee" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "agreed" in value;
    isInstance = isInstance && "signed" in value;
    isInstance = isInstance && "paymentInstructionsSent" in value;
    isInstance = isInstance && "paid" in value;
    isInstance = isInstance && "deal" in value;
    isInstance = isInstance && "documents" in value;
    isInstance = isInstance && "investor" in value;
    isInstance = isInstance && "fees" in value;
    isInstance = isInstance && "shareClassNameInDealContract" in value;
    isInstance = isInstance && "objectType" in value;

    return isInstance;
}

export function InvestmentOpenApiFromJSON(json: any): InvestmentOpenApi {
    return InvestmentOpenApiFromJSONTyped(json, false);
}

export function InvestmentOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestmentOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'amount': json['amount'],
        'amountWithPlacementFee': json['amountWithPlacementFee'],
        'amountWithPlacementFeeAndExchangeFee': !exists(json, 'amountWithPlacementFeeAndExchangeFee') ? undefined : json['amountWithPlacementFeeAndExchangeFee'],
        'amountWithPlacementFeeAndExchangeFeeAtExchangeRate': !exists(json, 'amountWithPlacementFeeAndExchangeFeeAtExchangeRate') ? undefined : json['amountWithPlacementFeeAndExchangeFeeAtExchangeRate'],
        'currency': CurrencyOpenApiFromJSON(json['currency']),
        'agreed': json['agreed'],
        'signed': json['signed'],
        'paymentInstructionsSent': json['paymentInstructionsSent'],
        'paid': json['paid'],
        'deal': DealOpenApiFromJSON(json['deal']),
        'documents': ((json['documents'] as Array<any>).map(DocumentOpenApiFromJSON)),
        'investor': InvestorOpenApiFromJSON(json['investor']),
        'fees': InvestmentFeesOpenApiFromJSON(json['fees']),
        'shareClassNameInDealContract': json['shareClassNameInDealContract'],
        'exchangeRate': !exists(json, 'exchangeRate') ? undefined : ExchangeRateOpenApiFromJSON(json['exchangeRate']),
        'objectType': json['objectType'],
    };
}

export function InvestmentOpenApiToJSON(value?: InvestmentOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'modified': (value.modified.toISOString()),
        'amount': value.amount,
        'amountWithPlacementFee': value.amountWithPlacementFee,
        'amountWithPlacementFeeAndExchangeFee': value.amountWithPlacementFeeAndExchangeFee,
        'amountWithPlacementFeeAndExchangeFeeAtExchangeRate': value.amountWithPlacementFeeAndExchangeFeeAtExchangeRate,
        'currency': CurrencyOpenApiToJSON(value.currency),
        'agreed': value.agreed,
        'signed': value.signed,
        'paymentInstructionsSent': value.paymentInstructionsSent,
        'paid': value.paid,
        'deal': DealOpenApiToJSON(value.deal),
        'documents': ((value.documents as Array<any>).map(DocumentOpenApiToJSON)),
        'investor': InvestorOpenApiToJSON(value.investor),
        'fees': InvestmentFeesOpenApiToJSON(value.fees),
        'shareClassNameInDealContract': value.shareClassNameInDealContract,
        'exchangeRate': ExchangeRateOpenApiToJSON(value.exchangeRate),
        'objectType': value.objectType,
    };
}

