/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentDetailsAccountManualReconciliationOpenApi } from './PaymentDetailsAccountManualReconciliationOpenApi';
import {
    PaymentDetailsAccountManualReconciliationOpenApiFromJSON,
    PaymentDetailsAccountManualReconciliationOpenApiFromJSONTyped,
    PaymentDetailsAccountManualReconciliationOpenApiToJSON,
} from './PaymentDetailsAccountManualReconciliationOpenApi';

/**
 * 
 * @export
 * @interface PaymentDetailsAccountOpenApi
 */
export interface PaymentDetailsAccountOpenApi {
    /**
     * 
     * @type {string}
     * @memberof PaymentDetailsAccountOpenApi
     */
    accountHolder: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetailsAccountOpenApi
     */
    bankAddress: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetailsAccountOpenApi
     */
    bankName: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetailsAccountOpenApi
     */
    number: string;
    /**
     * 
     * @type {PaymentDetailsAccountManualReconciliationOpenApi}
     * @memberof PaymentDetailsAccountOpenApi
     */
    manualReconciliation?: PaymentDetailsAccountManualReconciliationOpenApi;
}

/**
 * Check if a given object implements the PaymentDetailsAccountOpenApi interface.
 */
export function instanceOfPaymentDetailsAccountOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accountHolder" in value;
    isInstance = isInstance && "bankAddress" in value;
    isInstance = isInstance && "bankName" in value;
    isInstance = isInstance && "number" in value;

    return isInstance;
}

export function PaymentDetailsAccountOpenApiFromJSON(json: any): PaymentDetailsAccountOpenApi {
    return PaymentDetailsAccountOpenApiFromJSONTyped(json, false);
}

export function PaymentDetailsAccountOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentDetailsAccountOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accountHolder': json['accountHolder'],
        'bankAddress': json['bankAddress'],
        'bankName': json['bankName'],
        'number': json['number'],
        'manualReconciliation': !exists(json, 'manualReconciliation') ? undefined : PaymentDetailsAccountManualReconciliationOpenApiFromJSON(json['manualReconciliation']),
    };
}

export function PaymentDetailsAccountOpenApiToJSON(value?: PaymentDetailsAccountOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accountHolder': value.accountHolder,
        'bankAddress': value.bankAddress,
        'bankName': value.bankName,
        'number': value.number,
        'manualReconciliation': PaymentDetailsAccountManualReconciliationOpenApiToJSON(value.manualReconciliation),
    };
}

