import React, {createRef, FunctionComponent} from "react"
import {DealInvestorGroupType} from "./deal-investor-group.type"
import {InvestorGroupType} from "../../investor-group/investor-group.type"
import styles from "../select.module.sass"

type DealInvestorGroupSelectAddProps = {
    add: (group: DealInvestorGroupType) => void
    groups: DealInvestorGroupType[]
    groupsRaw: InvestorGroupType[]
}

const DealInvestorGroupSelectAdd: FunctionComponent<DealInvestorGroupSelectAddProps> = ({ add, groups, groupsRaw }) => {
    const selectRef = createRef<HTMLSelectElement>()

    const notAlreadySelectedGroups = groupsRaw.filter(ig => !groups.map(g => g.id).includes(ig.id))

    const onAdd = () => {
        const foundInvestorGroup = groupsRaw.filter(g => g.id === selectRef.current!.value)[0]
        add({
            id: foundInvestorGroup.id!,
            name: foundInvestorGroup.name,
            description: foundInvestorGroup.description
        })
    }

    return (
        <div className={styles.add}>
            {notAlreadySelectedGroups.length > 0 ? (
                <>
                    <select ref={selectRef}>
                        {notAlreadySelectedGroups.map(g => (
                            <option
                                key={`add-option-${g.id}`}
                                value={g.id}
                            >
                                {g.name}
                            </option>
                        ))}
                    </select>
                    <button
                        type="button"
                        onClick={onAdd}
                    >
                        Add
                    </button>
                </>
            ) : (
                <>No more investor groups available.</>
            )}
        </div>
    )
}

export default DealInvestorGroupSelectAdd