/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { KycDocumentUploadStateOpenApi } from './KycDocumentUploadStateOpenApi';
import {
    KycDocumentUploadStateOpenApiFromJSON,
    KycDocumentUploadStateOpenApiFromJSONTyped,
    KycDocumentUploadStateOpenApiToJSON,
} from './KycDocumentUploadStateOpenApi';

/**
 * 
 * @export
 * @interface KycDocumentUploadOpenApi
 */
export interface KycDocumentUploadOpenApi {
    /**
     * 
     * @type {KycDocumentUploadStateOpenApi}
     * @memberof KycDocumentUploadOpenApi
     */
    state: KycDocumentUploadStateOpenApi;
    /**
     * 
     * @type {string}
     * @memberof KycDocumentUploadOpenApi
     */
    rejectionComment?: string;
    /**
     * 
     * @type {Date}
     * @memberof KycDocumentUploadOpenApi
     */
    validTill?: Date;
}

/**
 * Check if a given object implements the KycDocumentUploadOpenApi interface.
 */
export function instanceOfKycDocumentUploadOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function KycDocumentUploadOpenApiFromJSON(json: any): KycDocumentUploadOpenApi {
    return KycDocumentUploadOpenApiFromJSONTyped(json, false);
}

export function KycDocumentUploadOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): KycDocumentUploadOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': KycDocumentUploadStateOpenApiFromJSON(json['state']),
        'rejectionComment': !exists(json, 'rejectionComment') ? undefined : json['rejectionComment'],
        'validTill': !exists(json, 'validTill') ? undefined : (new Date(json['validTill'])),
    };
}

export function KycDocumentUploadOpenApiToJSON(value?: KycDocumentUploadOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': KycDocumentUploadStateOpenApiToJSON(value.state),
        'rejectionComment': value.rejectionComment,
        'validTill': value.validTill === undefined ? undefined : (value.validTill.toISOString().substr(0,10)),
    };
}

