/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DealWaitingStateOpenApi = {
    MinimumAmount: 'WAITING_FOR_MINIMUM_AMOUNT',
    ManualPaymentInstructionsTrigger: 'WAITING_FOR_MANUAL_PAYMENT_INSTRUCTIONS_TRIGGER',
    Payments: 'WAITING_FOR_PAYMENTS'
} as const;
export type DealWaitingStateOpenApi = typeof DealWaitingStateOpenApi[keyof typeof DealWaitingStateOpenApi];


export function DealWaitingStateOpenApiFromJSON(json: any): DealWaitingStateOpenApi {
    return DealWaitingStateOpenApiFromJSONTyped(json, false);
}

export function DealWaitingStateOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealWaitingStateOpenApi {
    return json as DealWaitingStateOpenApi;
}

export function DealWaitingStateOpenApiToJSON(value?: DealWaitingStateOpenApi | null): any {
    return value as any;
}

