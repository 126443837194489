/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccountNumberTypeOpenApi } from './AccountNumberTypeOpenApi';
import {
    AccountNumberTypeOpenApiFromJSON,
    AccountNumberTypeOpenApiFromJSONTyped,
    AccountNumberTypeOpenApiToJSON,
} from './AccountNumberTypeOpenApi';

/**
 * 
 * @export
 * @interface AccountNumberOpenApi
 */
export interface AccountNumberOpenApi {
    /**
     * 
     * @type {string}
     * @memberof AccountNumberOpenApi
     */
    number?: string;
    /**
     * 
     * @type {AccountNumberTypeOpenApi}
     * @memberof AccountNumberOpenApi
     */
    type?: AccountNumberTypeOpenApi;
}

/**
 * Check if a given object implements the AccountNumberOpenApi interface.
 */
export function instanceOfAccountNumberOpenApi(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AccountNumberOpenApiFromJSON(json: any): AccountNumberOpenApi {
    return AccountNumberOpenApiFromJSONTyped(json, false);
}

export function AccountNumberOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountNumberOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': !exists(json, 'number') ? undefined : json['number'],
        'type': !exists(json, 'type') ? undefined : AccountNumberTypeOpenApiFromJSON(json['type']),
    };
}

export function AccountNumberOpenApiToJSON(value?: AccountNumberOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'type': AccountNumberTypeOpenApiToJSON(value.type),
    };
}

