import {ContactOpenApi} from "../../generated"
import {ContactType} from "./contact.type"

export function mapOpenApiToContact(contact: ContactOpenApi): ContactType {
    return {
        email: contact.email,
        phone: contact.phone,
        linkedIn: contact.linkedIn
    }
}

export function mapContactToOpenApi(contact: ContactType): ContactOpenApi {
    return {
        email: contact.email,
        phone: contact.phone,
        linkedIn: contact.linkedIn
    }
}