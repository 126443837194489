/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DealWithValuationsOpenApi } from './DealWithValuationsOpenApi';
import {
    DealWithValuationsOpenApiFromJSON,
    DealWithValuationsOpenApiFromJSONTyped,
    DealWithValuationsOpenApiToJSON,
} from './DealWithValuationsOpenApi';

/**
 * 
 * @export
 * @interface DealWithValuationsPaginatedResponseOpenApi
 */
export interface DealWithValuationsPaginatedResponseOpenApi {
    /**
     * 
     * @type {Array<DealWithValuationsOpenApi>}
     * @memberof DealWithValuationsPaginatedResponseOpenApi
     */
    dealsWithValuations: Array<DealWithValuationsOpenApi>;
    /**
     * 
     * @type {number}
     * @memberof DealWithValuationsPaginatedResponseOpenApi
     */
    total: number;
}

/**
 * Check if a given object implements the DealWithValuationsPaginatedResponseOpenApi interface.
 */
export function instanceOfDealWithValuationsPaginatedResponseOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dealsWithValuations" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function DealWithValuationsPaginatedResponseOpenApiFromJSON(json: any): DealWithValuationsPaginatedResponseOpenApi {
    return DealWithValuationsPaginatedResponseOpenApiFromJSONTyped(json, false);
}

export function DealWithValuationsPaginatedResponseOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealWithValuationsPaginatedResponseOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dealsWithValuations': ((json['dealsWithValuations'] as Array<any>).map(DealWithValuationsOpenApiFromJSON)),
        'total': json['total'],
    };
}

export function DealWithValuationsPaginatedResponseOpenApiToJSON(value?: DealWithValuationsPaginatedResponseOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dealsWithValuations': ((value.dealsWithValuations as Array<any>).map(DealWithValuationsOpenApiToJSON)),
        'total': value.total,
    };
}

