/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FFileOpenApi } from './FFileOpenApi';
import {
    FFileOpenApiFromJSON,
    FFileOpenApiFromJSONTyped,
    FFileOpenApiToJSON,
} from './FFileOpenApi';

/**
 * 
 * @export
 * @interface UpdateFileOpenApi
 */
export interface UpdateFileOpenApi {
    /**
     * 
     * @type {number}
     * @memberof UpdateFileOpenApi
     */
    sequentialId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateFileOpenApi
     */
    title: string;
    /**
     * 
     * @type {FFileOpenApi}
     * @memberof UpdateFileOpenApi
     */
    file: FFileOpenApi;
    /**
     * 
     * @type {number}
     * @memberof UpdateFileOpenApi
     */
    order: number;
}

/**
 * Check if a given object implements the UpdateFileOpenApi interface.
 */
export function instanceOfUpdateFileOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "file" in value;
    isInstance = isInstance && "order" in value;

    return isInstance;
}

export function UpdateFileOpenApiFromJSON(json: any): UpdateFileOpenApi {
    return UpdateFileOpenApiFromJSONTyped(json, false);
}

export function UpdateFileOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateFileOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sequentialId': !exists(json, 'sequentialId') ? undefined : json['sequentialId'],
        'title': json['title'],
        'file': FFileOpenApiFromJSON(json['file']),
        'order': json['order'],
    };
}

export function UpdateFileOpenApiToJSON(value?: UpdateFileOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sequentialId': value.sequentialId,
        'title': value.title,
        'file': FFileOpenApiToJSON(value.file),
        'order': value.order,
    };
}

