import React, {FunctionComponent, useContext, useState} from "react"
import Modal from "../../../../../../tech/modal/modal.component"
import {ShareClassType} from "../../../../../share-class/share-class.type"
import {FetchClient} from "../../../../../../tech/fetch/client"
import FetchContext from "../../../../../../tech/fetch/fetch.context"
import Alert from "../../../../../../tech/alert/alert.component"
import {AlertType} from "../../../../../../tech/alert/type.enum"
import DealInvestorGroupFeeStructureShareClassForm from "../form/form.component"
import styles from "./edit.module.sass"

type DealInvestorGroupFeeStructureShareClassEditProps = {
    addOrUpdateShareClassAndSelect: (shareClass: ShareClassType) => void
    close: () => void
    shareClass: ShareClassType
}

const DealInvestorGroupFeeStructureShareClassEdit: FunctionComponent<DealInvestorGroupFeeStructureShareClassEditProps> = ({
    addOrUpdateShareClassAndSelect,
    close,
    shareClass
}) => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [error, setError] = useState<boolean>(false)

    async function save(shareClassToSave: ShareClassType) {
        try {
            addOrUpdateShareClassAndSelect(await fetchClient.shareClassApi.update(shareClass.id!, shareClassToSave))
            close()
        }
        catch (err) {
            console.error(err)
            setError(true)
        }
    }

    return (
        <Modal close={close}>
            <div className={styles.edit}>
                {error ? (
                    <Alert
                        type={AlertType.ERROR}
                        text="Failed to save."
                    />
                ) : (
                    <DealInvestorGroupFeeStructureShareClassForm
                        save={save}
                        shareClass={shareClass}
                    />
                )}
            </div>
        </Modal>
    )
}

export default DealInvestorGroupFeeStructureShareClassEdit