import React, {FunctionComponent} from "react"
import {DealType} from "../deal.type"
import {useNavigate} from "react-router-dom"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {DEAL_OUTCOME, DEAL_PUBLISH, DEAL_SEND_PAYMENT_INSTRUCTIONS, DEAL_UNPUBLISH} from "../../../paths"
import styles from "./buttons.module.sass"

type DealEditButtonsProps = {
    deal: DealType
}

const DealEditButtons: FunctionComponent<DealEditButtonsProps> = ({ deal, }) => {
    const navigate = useNavigate()
    return (
        <div className={styles.buttons}>
            {deal.paymentInstructionsTrigger === "MANUAL" && !deal.paymentInstructionsManuallyTriggered && deal.published && (
                <Button
                    title="Send payment instructions"
                    style={ButtonStyle.SECONDARY}
                    type="button"
                    onClick={() => navigate(DEAL_SEND_PAYMENT_INSTRUCTIONS(deal.id!))}
                />
            )}
            {deal.outcome === "NONE" && deal.published && (
                <Button
                    title="Set deal outcome"
                    style={ButtonStyle.SECONDARY}
                    type="button"
                    onClick={() => navigate(DEAL_OUTCOME(deal.id!))}
                />
            )}
            <Button
                title={deal.published ? "Unpublish" : "Publish"}
                style={ButtonStyle.SECONDARY}
                onClick={() => deal.published ? navigate(DEAL_UNPUBLISH(deal.id!)) : navigate(DEAL_PUBLISH(deal.id!))}
                type="button"
            />
        </div>
    )
}

export default DealEditButtons