import {ActionsFilterType, ActionsFilterTypeAndValueType} from "../../../tech/actions/filter/filter.type"
import {getFilter, getFilterValue} from "../../../tech/actions/filter/filter.util"
import {CompanyContactFiltersOpenApi} from "../../../generated"

export type CompanyContactFilterType = "ID"
    | "NAME"
    | "EMAIL"

export function assembleFilterOptions(): ActionsFilterType<CompanyContactFilterType>[] {
    return [
        {
            type: "ID",
            label: "ID",
            value: "STRING"
        },
        {
            type: "NAME",
            label: "Name",
            value: "STRING"
        },
        {
            type: "EMAIL",
            label: "Email",
            value: "STRING"
        },
    ]
}

export function getCurrentFilter(
    filters: ActionsFilterType<CompanyContactFilterType>[],
    searchParams: URLSearchParams
): ActionsFilterTypeAndValueType<CompanyContactFilterType> | undefined {
    const foundFilter = filters.find(f => f.type === getFilter(searchParams))
    const foundFilterValue = getFilterValue(searchParams)
    if (foundFilter && foundFilterValue) {
        return {
            type: foundFilter.type,
            value: foundFilterValue
        }
    }
    return undefined
}

export function mapToCompanyContactFiltersOpenApi(filter: ActionsFilterTypeAndValueType<CompanyContactFilterType> | undefined): CompanyContactFiltersOpenApi | undefined {
    if (filter === undefined) return undefined

    switch (filter.type) {
        case "ID":
            return { id: filter.value }
        case "NAME":
            return { name: filter.value }
        case "EMAIL":
            return { email: filter.value }
    }
}