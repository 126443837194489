import {ShareClassApiClient} from "../../generated"
import {ShareClassType} from "./share-class.type"
import {mapOpenApiToShareClass, mapShareClassToOpenApi} from "./share-class.mapper"

export class ShareClassApi {

    constructor(private apiClient: ShareClassApiClient) {
    }

    public async create(shareClass: ShareClassType): Promise<ShareClassType> {
        const savedShareClass = await this.apiClient.shareClassCreate({
            shareClassOpenApi: mapShareClassToOpenApi(shareClass)
        })
        return mapOpenApiToShareClass(savedShareClass)
    }

    public async get(id: string): Promise<ShareClassType> {
        const shareClass = await this.apiClient.shareClassGet({ id })
        return mapOpenApiToShareClass(shareClass)
    }

    public async getAll(): Promise<ShareClassType[]> {
        return (await this.apiClient.shareClassGetAll())
            .map(mapOpenApiToShareClass)
    }

    public async update(id: string, shareClass: ShareClassType): Promise<ShareClassType> {
        const response = await this.apiClient.shareClassUpdate({
            id,
            shareClassOpenApi: mapShareClassToOpenApi(shareClass)
        })
        return mapOpenApiToShareClass(response)
    }

}