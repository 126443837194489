import {SubscriptionLogOpenApi} from "../../../generated"
import {mapOpenApiToInvestor} from "../../investor/investor.mapper"
import {SubscriptionLogType} from "./log.type"

export function mapOpenApiToSubscriptionLog(subscriptionLog: SubscriptionLogOpenApi): SubscriptionLogType {
    return {
        id: subscriptionLog.id,
        created: subscriptionLog.created,
        modified: subscriptionLog.modified,
        stripeSubscriptionId: subscriptionLog.stripeSubscriptionId,
        type: subscriptionLog.type,
        subscriptionType: subscriptionLog.subscriptionType,
        price: subscriptionLog.price,
        paymentInterval: subscriptionLog.paymentInterval,
        cancellationReason: subscriptionLog.cancellationReason,
        terminationDate: subscriptionLog.terminationDate,
        investor: mapOpenApiToInvestor(subscriptionLog.investor)
    }
}