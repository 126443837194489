import {DealQuestionOpenApi} from "../../generated"
import {DealQuestionType} from "./deal-question.type"
import {mapOpenApiToInvestor} from "../investor/investor.mapper"

export function mapOpenApiToDealQuestion(dealQuestion: DealQuestionOpenApi): DealQuestionType {
    return {
        id: dealQuestion.id,
        created: dealQuestion.created,
        modified: dealQuestion.modified,
        question: dealQuestion.question,
        resolved: dealQuestion.resolved,
        investor: mapOpenApiToInvestor(dealQuestion.investor)
    }
}