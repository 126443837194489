import React, {useContext, useState} from "react"
import {SubmitHandler} from "react-hook-form"
import {useNavigate} from "react-router-dom"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import DealGroupForm from "./form.component"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {DEAL_GROUP} from "../../paths"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import {DealGroupType} from "./deal-group.type"
import {normalizeValues} from "../../tech/form/form.util"

const DealGroupNew = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const navigate = useNavigate()
    const [state, setState] = useState<"NONE" | "LOADING" | "ERROR">("NONE")

    const onSubmit: SubmitHandler<DealGroupType> = async (dealGroup) => {
        setState("LOADING")
        try {
            const savedDealGroup = await fetchClient.dealGroupApi.create(normalizeValues(dealGroup))
            navigate(DEAL_GROUP(savedDealGroup.id!))
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to save deal group."
                />
            )}
            {(state === "NONE" || state === "ERROR") && (
                <Section>
                    <SectionHeading title="Create new deal group"/>
                    <DealGroupForm onSubmit={onSubmit} submitText="Create deal group" />
                </Section>
            )}
        </>
    )
}

export default DealGroupNew