import {AccountNumberOpenApi, BankOpenApi, CollectionAccountBankDetailsOpenApi, CollectionAccountOpenApi, IntermediaryBankOpenApi, RoutingOpenApi} from "../../../generated"
import {AccountNumberType, BankDetailsType, BankType, CollectionAccountType, IntermediaryBankType, RoutingType} from "./collection-account.type"

export function mapOpenApiToCollectionAccount(collectionAccount: CollectionAccountOpenApi): CollectionAccountType {
    return {
        id: collectionAccount.id,
        wallexId: collectionAccount.wallexId,
        wallexAccountId: collectionAccount.wallexAccountId,
        created: collectionAccount.created,
        modified: collectionAccount.modified,
        wallexCreated: collectionAccount.wallexCreated,
        bankDetailsList: collectionAccount.bankDetailsList.map(d => mapOpenApiToBankDetails(d)),
        currency: collectionAccount.currency,
        description: collectionAccount.description,
        purpose: collectionAccount.purpose,
        reference: collectionAccount.reference,
    }
}

export function mapOpenApiToBankDetails(bankDetails: CollectionAccountBankDetailsOpenApi): BankDetailsType {
    return {
        id: bankDetails.id,
        accountNumber: bankDetails.accountNumber && mapOpenApiToAccountNumber(bankDetails.accountNumber),
        bank: bankDetails.bank && mapOpenApiToBank(bankDetails.bank),
        currency: bankDetails.currency,
        holderName: bankDetails.holderName,
        intermediaryBank: bankDetails.intermediaryBank && mapOpenApiToIntermediaryBank(bankDetails.intermediaryBank),
        paymentType: bankDetails.paymentType,
        routing: bankDetails.routing && mapOpenApiToRouting(bankDetails.routing),
    }
}

export function mapOpenApiToAccountNumber(accountNumber: AccountNumberOpenApi): AccountNumberType {
    return {
        number: accountNumber.number,
        type: accountNumber.type,
    }
}

export function mapOpenApiToBank(bank: BankOpenApi): BankType {
    return {
        address: bank.address,
        country: bank.country,
        name: bank.name,
    }
}

export function mapOpenApiToIntermediaryBank(intermediaryBank: IntermediaryBankOpenApi): IntermediaryBankType {
    return {
        name: intermediaryBank.name,
        bicSwift: intermediaryBank.bicSwift,
    }
}

export function mapOpenApiToRouting(routing: RoutingOpenApi): RoutingType {
    return {
        code: routing.code,
        codeType: routing.codeType,
    }
}