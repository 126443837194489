/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CollectionDeviationTypeOpenApi = {
    AmountDeviates: 'AMOUNT_DEVIATES',
    PaymentReceived72HoursAfterExchangeRateDate: 'PAYMENT_RECEIVED_72_HOURS_AFTER_EXCHANGE_RATE_DATE'
} as const;
export type CollectionDeviationTypeOpenApi = typeof CollectionDeviationTypeOpenApi[keyof typeof CollectionDeviationTypeOpenApi];


export function CollectionDeviationTypeOpenApiFromJSON(json: any): CollectionDeviationTypeOpenApi {
    return CollectionDeviationTypeOpenApiFromJSONTyped(json, false);
}

export function CollectionDeviationTypeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectionDeviationTypeOpenApi {
    return json as CollectionDeviationTypeOpenApi;
}

export function CollectionDeviationTypeOpenApiToJSON(value?: CollectionDeviationTypeOpenApi | null): any {
    return value as any;
}

