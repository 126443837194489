/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvestorOpenApi } from './InvestorOpenApi';
import {
    InvestorOpenApiFromJSON,
    InvestorOpenApiFromJSONTyped,
    InvestorOpenApiToJSON,
} from './InvestorOpenApi';
import type { InvestorSyndicationOpenApi } from './InvestorSyndicationOpenApi';
import {
    InvestorSyndicationOpenApiFromJSON,
    InvestorSyndicationOpenApiFromJSONTyped,
    InvestorSyndicationOpenApiToJSON,
} from './InvestorSyndicationOpenApi';
import type { SubscriptionOpenApi } from './SubscriptionOpenApi';
import {
    SubscriptionOpenApiFromJSON,
    SubscriptionOpenApiFromJSONTyped,
    SubscriptionOpenApiToJSON,
} from './SubscriptionOpenApi';

/**
 * 
 * @export
 * @interface InvestorWithDetailsOpenApi
 */
export interface InvestorWithDetailsOpenApi {
    /**
     * 
     * @type {InvestorOpenApi}
     * @memberof InvestorWithDetailsOpenApi
     */
    investor: InvestorOpenApi;
    /**
     * 
     * @type {SubscriptionOpenApi}
     * @memberof InvestorWithDetailsOpenApi
     */
    subscription?: SubscriptionOpenApi;
    /**
     * 
     * @type {InvestorSyndicationOpenApi}
     * @memberof InvestorWithDetailsOpenApi
     */
    syndication: InvestorSyndicationOpenApi;
}

/**
 * Check if a given object implements the InvestorWithDetailsOpenApi interface.
 */
export function instanceOfInvestorWithDetailsOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "investor" in value;
    isInstance = isInstance && "syndication" in value;

    return isInstance;
}

export function InvestorWithDetailsOpenApiFromJSON(json: any): InvestorWithDetailsOpenApi {
    return InvestorWithDetailsOpenApiFromJSONTyped(json, false);
}

export function InvestorWithDetailsOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorWithDetailsOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'investor': InvestorOpenApiFromJSON(json['investor']),
        'subscription': !exists(json, 'subscription') ? undefined : SubscriptionOpenApiFromJSON(json['subscription']),
        'syndication': InvestorSyndicationOpenApiFromJSON(json['syndication']),
    };
}

export function InvestorWithDetailsOpenApiToJSON(value?: InvestorWithDetailsOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'investor': InvestorOpenApiToJSON(value.investor),
        'subscription': SubscriptionOpenApiToJSON(value.subscription),
        'syndication': InvestorSyndicationOpenApiToJSON(value.syndication),
    };
}

