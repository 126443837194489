/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DealPublicationValidationPreviewItemTypeOpenApi = {
    Hint: 'HINT',
    Blocker: 'BLOCKER'
} as const;
export type DealPublicationValidationPreviewItemTypeOpenApi = typeof DealPublicationValidationPreviewItemTypeOpenApi[keyof typeof DealPublicationValidationPreviewItemTypeOpenApi];


export function DealPublicationValidationPreviewItemTypeOpenApiFromJSON(json: any): DealPublicationValidationPreviewItemTypeOpenApi {
    return DealPublicationValidationPreviewItemTypeOpenApiFromJSONTyped(json, false);
}

export function DealPublicationValidationPreviewItemTypeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealPublicationValidationPreviewItemTypeOpenApi {
    return json as DealPublicationValidationPreviewItemTypeOpenApi;
}

export function DealPublicationValidationPreviewItemTypeOpenApiToJSON(value?: DealPublicationValidationPreviewItemTypeOpenApi | null): any {
    return value as any;
}

