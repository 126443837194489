/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactOpenApi
 */
export interface ContactOpenApi {
    /**
     * 
     * @type {string}
     * @memberof ContactOpenApi
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ContactOpenApi
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactOpenApi
     */
    linkedIn?: string;
}

/**
 * Check if a given object implements the ContactOpenApi interface.
 */
export function instanceOfContactOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function ContactOpenApiFromJSON(json: any): ContactOpenApi {
    return ContactOpenApiFromJSONTyped(json, false);
}

export function ContactOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'linkedIn': !exists(json, 'linkedIn') ? undefined : json['linkedIn'],
    };
}

export function ContactOpenApiToJSON(value?: ContactOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'phone': value.phone,
        'linkedIn': value.linkedIn,
    };
}

