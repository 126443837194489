/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyContactOpenApi } from './CompanyContactOpenApi';
import {
    CompanyContactOpenApiFromJSON,
    CompanyContactOpenApiFromJSONTyped,
    CompanyContactOpenApiToJSON,
} from './CompanyContactOpenApi';
import type { DealOpenApi } from './DealOpenApi';
import {
    DealOpenApiFromJSON,
    DealOpenApiFromJSONTyped,
    DealOpenApiToJSON,
} from './DealOpenApi';

/**
 * 
 * @export
 * @interface CompanyOpenApi
 */
export interface CompanyOpenApi {
    /**
     * 
     * @type {string}
     * @memberof CompanyOpenApi
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof CompanyOpenApi
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanyOpenApi
     */
    modified?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyOpenApi
     */
    legalName: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyOpenApi
     */
    url?: string;
    /**
     * 
     * @type {Array<CompanyContactOpenApi>}
     * @memberof CompanyOpenApi
     */
    contacts: Array<CompanyContactOpenApi>;
    /**
     * 
     * @type {Array<DealOpenApi>}
     * @memberof CompanyOpenApi
     */
    deals: Array<DealOpenApi>;
}

/**
 * Check if a given object implements the CompanyOpenApi interface.
 */
export function instanceOfCompanyOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "legalName" in value;
    isInstance = isInstance && "contacts" in value;
    isInstance = isInstance && "deals" in value;

    return isInstance;
}

export function CompanyOpenApiFromJSON(json: any): CompanyOpenApi {
    return CompanyOpenApiFromJSONTyped(json, false);
}

export function CompanyOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'legalName': json['legalName'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'contacts': ((json['contacts'] as Array<any>).map(CompanyContactOpenApiFromJSON)),
        'deals': ((json['deals'] as Array<any>).map(DealOpenApiFromJSON)),
    };
}

export function CompanyOpenApiToJSON(value?: CompanyOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'legalName': value.legalName,
        'url': value.url,
        'contacts': ((value.contacts as Array<any>).map(CompanyContactOpenApiToJSON)),
        'deals': ((value.deals as Array<any>).map(DealOpenApiToJSON)),
    };
}

