/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AccountNumberTypeOpenApi = {
    AccountNumber: 'ACCOUNT_NUMBER',
    Iban: 'IBAN'
} as const;
export type AccountNumberTypeOpenApi = typeof AccountNumberTypeOpenApi[keyof typeof AccountNumberTypeOpenApi];


export function AccountNumberTypeOpenApiFromJSON(json: any): AccountNumberTypeOpenApi {
    return AccountNumberTypeOpenApiFromJSONTyped(json, false);
}

export function AccountNumberTypeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountNumberTypeOpenApi {
    return json as AccountNumberTypeOpenApi;
}

export function AccountNumberTypeOpenApiToJSON(value?: AccountNumberTypeOpenApi | null): any {
    return value as any;
}

