import {writeToCsv} from "./write"

export function exportAsCsv(
    fileName: string,
    objects: object[],
    pathsToIgnore?: string[],
    pathsToIncludeDespiteIgnore?: string[]
) {
    const data = writeToCsv(objects, pathsToIgnore, pathsToIncludeDespiteIgnore)
    const csv = data
        .map(e => e
            .map(x => x.toString().replaceAll('"', ''))
            .map(x => '"' + x + '"')
            .join(";"))
        .join("\n")
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8," })
    const objUrl = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.setAttribute("href", objUrl);
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
}