import {InvestmentDocumentSynchronizationAsynchronousActionOpenApi} from "../../../../../generated"
import {InvestmentDocumentSynchronizationAsynchronousActionType} from "./action.type"

export function mapOpenApiToInvestmentDocumentSynchronizationAsynchronousAction(
    action: InvestmentDocumentSynchronizationAsynchronousActionOpenApi
): InvestmentDocumentSynchronizationAsynchronousActionType {
    return new InvestmentDocumentSynchronizationAsynchronousActionType({
        id: action.id,
        created: action.created,
        modified: action.modified,
        state: action.state,
        message: action.message,
        processed: action.processed,
        investmentId: action.investmentId
    })
}