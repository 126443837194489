import React, {FunctionComponent} from "react"
import {SubmitHandler, useForm} from "react-hook-form"
import formStyles from "../../tech/form/form.module.css"
import FormError from "../../tech/form/error.component"
import {SinglePurposeVehicleType} from "./single-purpose-vehicle.type"
import {formatDatetime} from "../../tech/format/format.util"

type SinglePurposeVehicleFormProps = {
    singlePurposeVehicle?: SinglePurposeVehicleType
    onSubmit: SubmitHandler<SinglePurposeVehicleType>
    submitText: string
}

const SinglePurposeVehicleForm: FunctionComponent<SinglePurposeVehicleFormProps> = ({ singlePurposeVehicle, onSubmit, submitText }) => {
    const { register, handleSubmit, formState: { errors } } = useForm<SinglePurposeVehicleType>({
        defaultValues: singlePurposeVehicle
    })

    return (
        <form
            className={formStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <input type="hidden" {...register("id")} />

            {singlePurposeVehicle && (
                <label>
                    <span>ID:</span> {singlePurposeVehicle.id}<br/>
                    <span>Created:</span> {formatDatetime(singlePurposeVehicle.created)}<br/>
                    <span>Modified:</span> {formatDatetime(singlePurposeVehicle.modified)}
                </label>
            )}

            <label>
                <span>Name</span>
                <input
                    type="text"
                    placeholder="Name"
                    {...register("name", {
                        required: "Name is required.",
                        minLength: {value: 3, message: "Too short."}
                    })}
                />
                <FormError field="name" errors={errors}/>
            </label>

            <label>
                <span>Address</span>
                <textarea
                    {...register(
                        "address",
                        {
                            required: "Address is required."
                        }
                    )}
                />
                <FormError field="address" errors={errors}/>
            </label>

            <button
                type="submit"
                className={formStyles.submitButton}
            >
                {submitText}
            </button>
        </form>
    )
}

export default SinglePurposeVehicleForm