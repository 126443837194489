import {CollectionDeviationOpenApi} from "../../../generated"
import {CollectionDeviationType} from "./collection-deviation.type"
import {mapOpenApiToCollection} from "../collection.mapper"
import {mapOpenApiToInvestment} from "../../investment/investment.mapper"
import {mapOpenApiToManualReconciliationLog} from "../../reconciliation/log/manual-reconciliation-log.mapper"

export function mapOpenApiToCollectionDeviation(collectionDeviation: CollectionDeviationOpenApi): CollectionDeviationType {
    return {
        id: collectionDeviation.id,
        created: collectionDeviation.created,
        modified: collectionDeviation.modified,
        state: collectionDeviation.state,
        type: collectionDeviation.type,
        collections: collectionDeviation.collections.map(mapOpenApiToCollection),
        manualReconciliationLogs: collectionDeviation.manualReconciliationLogs.map(mapOpenApiToManualReconciliationLog),
        investment: mapOpenApiToInvestment(collectionDeviation.investment),
    }
}