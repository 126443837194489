/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DealGroupOpenApi,
  ValidationResponseOpenApi,
} from '../models';
import {
    DealGroupOpenApiFromJSON,
    DealGroupOpenApiToJSON,
    ValidationResponseOpenApiFromJSON,
    ValidationResponseOpenApiToJSON,
} from '../models';

export interface DealGroupCreateRequest {
    dealGroupOpenApi?: DealGroupOpenApi;
}

export interface DealGroupDeleteRequest {
    id: string;
}

export interface DealGroupGetRequest {
    id: string;
}

export interface DealGroupUpdateRequest {
    id: string;
    dealGroupOpenApi?: DealGroupOpenApi;
}

/**
 * 
 */
export class DealGroupApiClient extends runtime.BaseAPI {

    /**
     * Create new deal group
     */
    async dealGroupCreateRaw(requestParameters: DealGroupCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealGroupOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal-group`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DealGroupOpenApiToJSON(requestParameters.dealGroupOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealGroupOpenApiFromJSON(jsonValue));
    }

    /**
     * Create new deal group
     */
    async dealGroupCreate(requestParameters: DealGroupCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealGroupOpenApi> {
        const response = await this.dealGroupCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete deal group by id
     */
    async dealGroupDeleteRaw(requestParameters: DealGroupDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealGroupDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal-group/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete deal group by id
     */
    async dealGroupDelete(requestParameters: DealGroupDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dealGroupDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get deal group by id
     */
    async dealGroupGetRaw(requestParameters: DealGroupGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealGroupOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealGroupGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal-group/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealGroupOpenApiFromJSON(jsonValue));
    }

    /**
     * Get deal group by id
     */
    async dealGroupGet(requestParameters: DealGroupGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealGroupOpenApi> {
        const response = await this.dealGroupGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all deal groups
     */
    async dealGroupGetAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DealGroupOpenApi>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal-group`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DealGroupOpenApiFromJSON));
    }

    /**
     * Get all deal groups
     */
    async dealGroupGetAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DealGroupOpenApi>> {
        const response = await this.dealGroupGetAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update deal group
     */
    async dealGroupUpdateRaw(requestParameters: DealGroupUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealGroupOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealGroupUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal-group/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DealGroupOpenApiToJSON(requestParameters.dealGroupOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealGroupOpenApiFromJSON(jsonValue));
    }

    /**
     * Update deal group
     */
    async dealGroupUpdate(requestParameters: DealGroupUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealGroupOpenApi> {
        const response = await this.dealGroupUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
