import {FileType} from "./file.type"

export function getStringRepresentation(file: FileType) {
    return file.identifier === undefined
        ? file.id
        : `${file.identifier} (${file.id})`
}

export function getIdentifierOrDefaultToId(file: FileType) {
    return file.identifier === undefined
        ? file.id
        : file.identifier
}