import {ActionsFilterType, ActionsFilterTypeAndValueType} from "../../tech/actions/filter/filter.type"
import {UserFiltersOpenApi, UserRoleOpenApi} from "../../generated"
import {getFilter, getFilterValue} from "../../tech/actions/filter/filter.util"
import {mapUserRoleToString} from "./user.type"

export type UserFilterType = "ID" | "USER_ID" | "EMAIL" | "ROLE"

export function assembleFilterOptions(): ActionsFilterType<UserFilterType>[] {
    return [
        {
            type: "ID",
            label: "ID",
            value: "STRING"
        },
        {
            type: "USER_ID",
            label: "User ID",
            value: "STRING"
        },
        {
            type: "EMAIL",
            label: "Email",
            value: "STRING"
        },
        {
            type: "ROLE",
            label: "Role",
            value: {
                type: "ENUM",
                values: Object.entries(UserRoleOpenApi)
                    .map(r => ({
                        label: mapUserRoleToString(r[1]),
                        value: r[1]
                    })
                )
            }
        }
    ]
}

export function getCurrentFilter(
    filters: ActionsFilterType<UserFilterType>[],
    searchParams: URLSearchParams
): ActionsFilterTypeAndValueType<UserFilterType> | undefined {
    const foundFilter = filters.find(f => f.type === getFilter(searchParams))
    const foundFilterValue = getFilterValue(searchParams)
    if (foundFilter && foundFilterValue) {
        return {
            type: foundFilter.type,
            value: foundFilterValue
        }
    }
    return undefined
}

export function mapToUserFiltersOpenApi(filter: ActionsFilterTypeAndValueType<UserFilterType> | undefined): UserFiltersOpenApi | undefined {
    if (filter === undefined) return undefined

    switch (filter.type) {
        case "ID":
            return { id: filter.value }
        case "USER_ID":
            return { userId: filter.value }
        case "EMAIL":
            return { email: filter.value }
        case "ROLE":
            return { role: filter.value }
    }
}