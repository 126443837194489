import {UserSortOpenApi} from "../../generated"
import {ActionsSortOptionType} from "../../tech/actions/sort/sort.type"
import {getSort} from "../../tech/actions/sort/sort.util"

export const DEFAULT_SORT = {
    id: UserSortOpenApi.CreatedDescending,
    label: "Created (DESC)"
}

export function assembleSortOptions(): ActionsSortOptionType<UserSortOpenApi>[] {
    return [
        {
            id: UserSortOpenApi.CreatedAscending,
            label: "Created (ASC)"
        },
        DEFAULT_SORT,
        {
            id: UserSortOpenApi.RoleAscending,
            label: "Role (ASC)"
        },
        {
            id: UserSortOpenApi.RoleDescending,
            label: "Role (DESC)"
        },
    ]
}

export function getCurrentSortOption(
    options: ActionsSortOptionType<UserSortOpenApi>[],
    searchParams: URLSearchParams
): ActionsSortOptionType<UserSortOpenApi> {
    return (options.find(o => o.id === getSort(searchParams)) || DEFAULT_SORT)
}