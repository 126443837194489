import React, {FunctionComponent} from "react"
import {Control, Controller, FieldErrors, UseFormRegister, UseFormUnregister} from "react-hook-form"
import {ValuationType} from "../valuation.type"
import {DateTime} from "luxon"
import FormError from "../../../tech/form/error.component"
import {CurrencyOpenApi, ValuationCalculationMethodOpenApi} from "../../../generated"
import {mapCurrencyToString} from "../../currency/currency.mapper"
import {ValuationsForSingleDealBackofficeType} from "./single-deal.type"
import styles from "./form.module.sass"
import {formatDatetime} from "../../../tech/format/format.util"

type ValuationsForSingleDealFormProps = {
    calculationMethod: ValuationCalculationMethodOpenApi
    valuation: ValuationType
    control: Control<ValuationsForSingleDealBackofficeType, any>
    errors: FieldErrors<ValuationsForSingleDealBackofficeType>
    index: number
    register: UseFormRegister<ValuationsForSingleDealBackofficeType>
    unregister: UseFormUnregister<ValuationsForSingleDealBackofficeType>
}

const ValuationsForSingleDealForm: FunctionComponent<ValuationsForSingleDealFormProps> = ({
    calculationMethod,
    valuation,
    control,
    errors,
    index,
    register,
}) => {
    return (
        <>
            <input type="hidden" {...register(`valuations.${index}.id`)} />
            <input type="hidden" {...register(`valuations.${index}.dealId`)} />

            <div className={styles.meta}>
                <div className={styles.metaIndex}>
                    #{index + 1}
                </div>
                {valuation && (
                    <div className={styles.valuationMeta}>
                        {valuation.created && (
                            <>
                                Created:{" "}
                                {formatDatetime(valuation.created)}
                            </>
                        )}
                        {valuation.modified && (
                            <>
                                <br/>
                                Modified:{" "}
                                {formatDatetime(valuation.modified)}
                            </>
                        )}
                    </div>
                )}
            </div>

            <label>
                <span>Currency</span>
                <select
                    {...register(`valuations.${index}.currency`)}
                >
                    {Object.entries(CurrencyOpenApi).map((c, index) => (
                        <option
                            value={c[1]}
                            key={index}
                        >
                            {mapCurrencyToString(c[1])}
                        </option>
                    ))}
                </select>
                <FormError field={`valuations.${index}.currency`} errors={errors}/>
            </label>

            <label>
                <span>Price per Share</span>
                <input
                    type="number"
                    step=".001"
                    {...register(`valuations.${index}.pricePerShare`, {
                        min: {value: 0, message: "Must not be negative."},
                        validate: (value) => {
                            if (calculationMethod === ValuationCalculationMethodOpenApi.PricePerShare && !value) return "Valuation must be set for calculation method PRICE_PER_SHARE."
                            return true
                        }
                    })}
                />
                <FormError field={`valuations.${index}.pricePerShare`} errors={errors}/>
            </label>

            <label>
                <span>Valuation</span>
                <input
                    type="number"
                    step="1"
                    {...register(`valuations.${index}.valuation`, {
                        min: {value: 0, message: "Must not be negative."},
                        validate: (value) => {
                            if (calculationMethod === ValuationCalculationMethodOpenApi.Valuation && !value) return "Valuation must be set for calculation method VALUATION."
                            return true
                        }
                    })}
                />
                <FormError field={`valuations.${index}.valuation`} errors={errors}/>
            </label>

            <label>
                <span>Date</span>
                <Controller
                    name={`valuations.${index}.date`}
                    control={control}
                    render={({field: {onChange, value}}) => {
                        const isoDate = value !== undefined ? DateTime.fromJSDate(value).toISODate() as string : undefined
                        return (
                            <input
                                type="date"
                                defaultValue={isoDate}
                                onChange={e => onChange(new Date(e.target.value))}
                            />
                        )
                    }}
                    rules={{
                        required: "Date of valuation is required."
                    }}
                />
                <FormError field={`valuations.${index}.currency`} errors={errors}/>
            </label>

            <label>
                <span>
                    Discount (%){" "}
                    <small style={{color: "#EF4444"}}>(does not have any effect at the moment)</small>
                </span>
                <input
                    type="number"
                    step={0.05}
                    defaultValue={0}
                    {...register(`valuations.${index}.discount`, {
                        min: {value: 0, message: "Must not be negative."},
                        max: {value: 100, message: "Must not be greater than 100 percent."}
                    })}
                />
                <FormError field={`valuations.${index}.discount`} errors={errors}/>
            </label>

            <label>
                <span>Description</span>
                <input
                    type="text"
                    placeholder="Description"
                    {...register(`valuations.${index}.description`)}
                />
                <FormError field={`valuations.${index}.description`} errors={errors}/>
            </label>
        </>
    )
}

export default ValuationsForSingleDealForm