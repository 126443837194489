import React, {useContext, useEffect, useMemo, useState} from "react"
import {Link, useNavigate, useSearchParams} from "react-router-dom"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import Button from "../../tech/button/button.component"
import {ButtonStyle} from "../../tech/button/style.enum"
import {COMPANY, DEAL, UPDATE, UPDATE_NEW} from "../../paths"
import tableStyles from "../../tech/table/table.module.css"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import {UpdateType} from "./update.type"
import {formatDate} from "../../tech/format/format.util"
import {PaginatedType} from "../../tech/actions/pagination/pagination.type"
import {emptyPaginated, getPagination} from "../../tech/actions/pagination/pagination.util"
import {assembleSortOptions, getCurrentSortOption} from "./sort.util"
import {assembleFilterOptions, getCurrentFilter, mapToUpdateFiltersOpenApi} from "./filter.util"
import Actions from "../../tech/actions/actions.component"

const UpdateOverview = () => {
    const UPDATES_PER_PAGE = 50
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [updates, setUpdates] = useState<PaginatedType<UpdateType>>(emptyPaginated)

    const sortOptions = assembleSortOptions()
    const currentSortOption = useMemo(
        () => getCurrentSortOption(sortOptions, searchParams),
        [sortOptions, searchParams]
    )

    const filters = useMemo(() => assembleFilterOptions(), [])
    const currentFilter = useMemo(
        () => getCurrentFilter(filters, searchParams),
        [filters, searchParams]
    )

    const pagination = useMemo(() => getPagination(UPDATES_PER_PAGE, searchParams), [searchParams])

    useEffect(() => {
        const fetch = async () => {
            try {
                setUpdates(await fetchClient.updateApi.getAllPaginated(
                    currentSortOption.id,
                    mapToUpdateFiltersOpenApi(currentFilter),
                    pagination
                ))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, currentFilter, currentSortOption.id, pagination])

    return (
        <Section>
            <SectionHeading
                title="Updates"
                subTitle={`${updates.total} updates`}
                button={<Button
                    title="New Update"
                    style={ButtonStyle.SECONDARY}
                    onClick={() => navigate(UPDATE_NEW)}
                    type="button"
                />}
            />
            <Actions
                filter={{
                    current: currentFilter,
                    filters
                }}
                pagination={{
                    label: "companies",
                    elementsPerPage: UPDATES_PER_PAGE,
                    numberElements: updates.total
                }}
                sort={{
                    current: currentSortOption,
                    options: sortOptions
                }}
            >
                {state === "LOADING" && <LoadingDots/>}
                {state === "LOADED" && (
                    <div className={tableStyles.tableWrapper}>
                        <table className={tableStyles.tablePointer}>
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Company</th>
                                <th>Deal</th>
                                <th>Number of files</th>
                                <th>ID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {updates.elements.map((update, i) => (
                                <tr
                                    key={i}
                                    onClick={() => navigate(UPDATE(update.id!))}
                                >
                                    <td>{formatDate(update.date)}</td>
                                    <td>
                                        {update.companyOrDeal.type === "COMPANY" && (
                                            <Link to={COMPANY(update.companyOrDeal.company.id!)}>
                                                {update.companyOrDeal.company.legalName}
                                            </Link>
                                        )}
                                    </td>
                                    <td>
                                        {update.companyOrDeal.type === "DEAL" && (
                                            <Link to={DEAL(update.companyOrDeal.deal.id!)}>
                                                {update.companyOrDeal.deal.name.completeOnboarding}
                                            </Link>
                                        )}
                                    </td>
                                    <td>{update.files.length}</td>
                                    <td>{update.id}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {state === "ERROR" && (
                    <Alert
                        type={AlertType.ERROR}
                        text="Failed to load updates"
                    />
                )}
            </Actions>
        </Section>
    )
}

export default UpdateOverview