import {normalizeValues} from "../../../tech/form/form.util"
import {SyndicateInvitationType} from "./invitation.type"
import {SyndicateType} from "../syndicate.type"

export function normalizeSyndicateInvitation(
    invitation: SyndicateInvitationType,
    syndicate: SyndicateType,
) {
    const normalized: SyndicateInvitationType = {
        ...normalizeValues(invitation),
        syndicate: syndicate
    }

    // Target investor group
    if (normalized.targetInvestorGroup.id) {
        normalized.targetInvestorGroup = syndicate.investorGroups.filter(investorGroup => investorGroup.id === normalized.targetInvestorGroup.id)[0]
    }

    return normalized
}