import React, {FunctionComponent, useState} from "react"
import {DealQuestionType} from "./deal-question.type"
import styles from "./questions.module.css"
import tableStyles from "../../tech/table/table.module.css"
import DealQuestionToggle from "./toggle-resolve.component"
import LoadingDots from "../../tech/loading/dots/dots.component"
import {formatDatetime} from "../../tech/format/format.util"

type DealQuestionsProps = {
    dealId: string
    questions: DealQuestionType[]
}

const DealQuestions: FunctionComponent<DealQuestionsProps> = ({ dealId, questions }) => {
    const [loading, setLoading] = useState<boolean>(false)

    const setIsLoading = () => setLoading(true)

    return (
        <div className={styles.questions}>
            <h2>Questions</h2>
            {loading && <LoadingDots/>}
            {!loading && (
                <>
                    {questions.length > 0 ? (
                        <div className={tableStyles.tableWrapper}>
                            <table className={tableStyles.table}>
                                <thead>
                                <tr>
                                    <th>Created</th>
                                    <th>Investor</th>
                                    <th>Question</th>
                                    <th>Email</th>
                                    <th>Resolved?</th>
                                </tr>
                                </thead>
                                <tbody>
                                {questions
                                    .sort((a, b) => b.created.getTime() - a.created.getTime())
                                    .map((question, index) => (

                                    <tr key={index}>
                                        <td>{formatDatetime(question.created)}</td>
                                        <td>{question.investor.person?.fullName}</td>
                                        <td>{question.question}</td>
                                        <td>{question.investor.person?.contact.email}</td>
                                        <td>
                                            {question.resolved ? "yes " : "no "}
                                            <DealQuestionToggle
                                                dealId={dealId}
                                                question={question}
                                                loading={loading}
                                                setLoading={setIsLoading}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <>No questions available yet.</>
                    )}
                </>
            )}
        </div>
    )
}

export default DealQuestions