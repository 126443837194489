import {StripeEventAsynchronousActionType} from "./action.type"
import {StripeEventAsynchronousActionOpenApi} from "../../../../../generated"

export function mapOpenApiToStripeEventAsynchronousAction(action: StripeEventAsynchronousActionOpenApi): StripeEventAsynchronousActionType {
    return new StripeEventAsynchronousActionType({
        id: action.id,
        created: action.created,
        modified: action.modified,
        state: action.state,
        message: action.message,
        processed: action.processed,
        stripeEventId: action.stripeEventId
    })
}