import React, {FunctionComponent} from "react"
import {Control, FieldErrors, useFieldArray, UseFormRegister, UseFormSetValue, UseFormWatch} from "react-hook-form"
import {DealType} from "../deal/deal.type"
import FormError from "../../tech/form/error.component"
import MarkdownEditor from "../../tech/markdown/editor.component"
import styles from "./tiles.module.sass"

type DealTilesProps = {
    control: Control<DealType, any>
    errors: FieldErrors<DealType>
    register: UseFormRegister<DealType>
    setValue: UseFormSetValue<DealType>
    watch: UseFormWatch<DealType>
}

const DealTiles: FunctionComponent<DealTilesProps> = ({
    control,
    errors,
    register,
    setValue,
    watch
}) => {
    const { fields, append, remove } = useFieldArray({ name: "tiles", control })

    function add() {
        const newOrder = fields.map(d => d.order).filter(o => o !== undefined)
        append({
            title: "",
            text: "",
            order: newOrder.length > 0 ? Math.max(...newOrder) + 1 : 0
        })
    }

    const del = (i: number) => remove(i)

    return (
        <div className={styles.tiles}>
            {fields.map((item, index) => (
                <div
                    className={styles.tile}
                    key={item.id}
                >
                    <input type="hidden" {...register(`tiles.${index}.id`)} />
                    <input type="hidden" {...register(`tiles.${index}.sequentialId`)} />

                    <label>
                        <span>Title</span>
                        <input
                            {...register(
                                `tiles.${index}.title`,
                                {
                                    required: "Title is required.",
                                    minLength: {value: 2, message: "Too short."}
                                }
                            )}
                            type="text"
                        />
                        <FormError field={`tiles.${index}.title`} errors={errors}/>
                    </label>

                    <label>
                        <span>Order</span>
                        <input
                            {...register(
                                `tiles.${index}.order`,
                                {
                                    required: "Order is required."
                                }
                            )}
                            type="number"
                        />
                        <FormError field={`tiles.${index}.order`} errors={errors}/>
                    </label>

                    <MarkdownEditor
                        label="Text"
                        fieldId={`tiles.${index}.text`}
                        markdown={watch(`tiles.${index}.text`)}
                        onChange={(val: string) => setValue(`tiles.${index}.text`, val)}
                        errors={errors}
                    />
                    <input
                        type="hidden"
                        {...register(
                            `tiles.${index}.text`,
                            {
                                required: "Text is required.",
                                validate: value => {
                                    if (value === undefined) return true
                                    if (value.trim() === "") return "Text is required."
                                }
                            }
                        )}
                    />

                    <div className={styles.actions}>
                        <button
                            type="button"
                            onClick={() => del(index)}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            ))}
            <div className={`${styles.actions} ${styles.add}`}>
                <button
                    type="button"
                    onClick={add}
                >
                    Add
                </button>
            </div>
        </div>
    )
}

export default DealTiles