import React, {FunctionComponent} from "react"
import styles from "./dots.module.sass"

const LoadingDots: FunctionComponent = () => {
    return (
        <div className={styles.loading}>
            <div className={styles.ellipsis}>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    )
}

export default LoadingDots