import {DealDocumentOpenApi} from "../../../generated"
import {DealDocumentType} from "./document.type"
import {mapFileToOpenApi, mapOpenApiToFile} from "../../file/file.mapper"

export function mapOpenApiToDealDocument(document: DealDocumentOpenApi): DealDocumentType {
    return {
        id: document.id,
        file: mapOpenApiToFile(document.file),
        type: document.type,
        title: document.title,
        description: document.description,
        order: document.order
    }
}

export function mapDealDocumentToOpenApi(document: DealDocumentType): DealDocumentOpenApi {
    return {
        id: document.id,
        file: mapFileToOpenApi(document.file),
        type: document.type,
        title: document.title,
        description: document.description,
        order: document.order
    }
}