/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RoutingCodeTypeOpenApi = {
    BicSwift: 'BIC_SWIFT',
    SortCode: 'SORT_CODE'
} as const;
export type RoutingCodeTypeOpenApi = typeof RoutingCodeTypeOpenApi[keyof typeof RoutingCodeTypeOpenApi];


export function RoutingCodeTypeOpenApiFromJSON(json: any): RoutingCodeTypeOpenApi {
    return RoutingCodeTypeOpenApiFromJSONTyped(json, false);
}

export function RoutingCodeTypeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoutingCodeTypeOpenApi {
    return json as RoutingCodeTypeOpenApi;
}

export function RoutingCodeTypeOpenApiToJSON(value?: RoutingCodeTypeOpenApi | null): any {
    return value as any;
}

