import React, {useContext, useEffect, useState} from "react"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import tableStyles from "../../tech/table/table.module.css"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import {useNavigate} from "react-router-dom"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import Button from "../../tech/button/button.component"
import {ButtonStyle} from "../../tech/button/style.enum"
import {DEAL_GEOGRAPHY, DEAL_GEOGRAPHY_NEW} from "../../paths"
import {DealGeographyType} from "./deal-geography.type"

const DealGeographyOverview = () => {
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [geographies, setGeographies] = useState<DealGeographyType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                setGeographies(await fetchClient.dealGeographyApi.getAll())
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && (
                <Section>
                    <SectionHeading
                        title="Deal geographies"
                        subTitle={`${geographies.length} deal geographies`}
                        button={<Button
                            title="New Deal Geography"
                            style={ButtonStyle.SECONDARY}
                            onClick={() => navigate(DEAL_GEOGRAPHY_NEW)}
                            type="button"
                        />}
                    />
                    <div className={tableStyles.tableWrapper}>
                        <table className={tableStyles.tablePointer}>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Slug</th>
                                    <th>ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {geographies.map((geography, i) => (
                                    <tr
                                        key={i}
                                        onClick={() => navigate(DEAL_GEOGRAPHY(geography.id!))}
                                    >
                                        <td>{geography.name}</td>
                                        <td>{geography.slug}</td>
                                        <td>{geography.id}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Section>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load deal geographies"
                />
            )}
        </>
    )
}

export default DealGeographyOverview