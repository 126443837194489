import {
    ManualReconciliationBankAccountWithDealsOpenApi,
    ManualReconciliationLogFiltersOpenApi,
    ManualReconciliationLogOpenApi,
    ManualReconciliationLogSortOpenApi,
    ReconciliationApiClient
} from "../../generated"
import {
    mapManualReconciliationBankAccountWithDealsToOpenApi,
    mapOpenApiToManualReconciliationBankAccountWithDeals
} from "./account/manual-reconciliation-bank-account.mapper"
import {ManualReconciliationBankAccountWithDealsType} from "./account/manual-reconciliation-bank-account.type"
import {PaginatedType, PaginationType} from "../../tech/actions/pagination/pagination.type"
import {ManualReconciliationLogType} from "./log/manual-reconciliation-log.type"
import {
    mapManualReconciliationLogToOpenApi,
    mapOpenApiToManualReconciliationLog
} from "./log/manual-reconciliation-log.mapper"
import {ExtractedResponse, FetchResponse} from "../../tech/response/response.type"
import {extractError} from "../../tech/response/extract.util"

export class ReconciliationApi {

    constructor(private apiClient: ReconciliationApiClient) {
    }

    public async createManualReconciliationBankAccount(
        account: ManualReconciliationBankAccountWithDealsType
    ): Promise<ExtractedResponse<ManualReconciliationBankAccountWithDealsType>> {
        try {
            const savedAccount = await this.apiClient.manualReconciliationBankAccountCreate({
                manualReconciliationBankAccountWithDealsOpenApi: mapManualReconciliationBankAccountWithDealsToOpenApi(account)
            })
            return new FetchResponse(mapOpenApiToManualReconciliationBankAccountWithDeals(savedAccount))
        }
        catch (err) {
            console.log("err", err)
            return await extractError(err)
        }
    }

    public async deleteManualReconciliationBankAccount(id: string): Promise<void> {
        await this.apiClient.manualReconciliationBankAccountDelete({ id })
    }

    public async getManualReconciliationBankAccount(id: string): Promise<ManualReconciliationBankAccountWithDealsType> {
        const account = await this.apiClient.manualReconciliationBankAccountGet({ id })
        return mapOpenApiToManualReconciliationBankAccountWithDeals(account)
    }

    public async getAllManualReconciliationBankAccounts(): Promise<ManualReconciliationBankAccountWithDealsType[]> {
        return (await this.apiClient.manualReconciliationBankAccountGetAll())
            .map((account: ManualReconciliationBankAccountWithDealsOpenApi) => mapOpenApiToManualReconciliationBankAccountWithDeals(account))
    }

    public async updateManualReconciliationBankAccount(
        id: string,
        account: ManualReconciliationBankAccountWithDealsType
    ): Promise<ExtractedResponse<ManualReconciliationBankAccountWithDealsType>> {
        try {
            const response = await this.apiClient.manualReconciliationBankAccountUpdate({
                id,
                manualReconciliationBankAccountWithDealsOpenApi: mapManualReconciliationBankAccountWithDealsToOpenApi(account)
            })
            return new FetchResponse(mapOpenApiToManualReconciliationBankAccountWithDeals(response))
        }
        catch (err) {
            console.log("err", err)
            return await extractError(err)
        }
    }

    public async createManualReconciliationLog(log: ManualReconciliationLogType): Promise<ManualReconciliationLogType> {
        const response = await this.apiClient.manualReconciliationLogCreate({
            manualReconciliationLogOpenApi: mapManualReconciliationLogToOpenApi(log)
        })
        return mapOpenApiToManualReconciliationLog(response)
    }

    public async getAllManualReconciliationLogsByInvestmentId(investmentId: string): Promise<ManualReconciliationLogType[]> {
        return (await this.apiClient.manualReconciliationLogGetAllByInvestmentId({ investmentId }))
            .map(mapOpenApiToManualReconciliationLog)
    }

    public async getAllManualReconciliationLogsPaginated(
        sort: ManualReconciliationLogSortOpenApi,
        filters?: ManualReconciliationLogFiltersOpenApi,
        pagination?: PaginationType
    ): Promise<PaginatedType<ManualReconciliationLogType>> {
        const result = await this.apiClient.manualReconciliationLogGetAllPaginated({
            manualReconciliationLogFiltersOpenApi: filters,
            elementsPerPage: pagination?.elementsPerPage,
            page: pagination?.page,
            sort
        })
        return {
            elements: result.logs
                .map((log: ManualReconciliationLogOpenApi) => mapOpenApiToManualReconciliationLog(log)),
            total: result.total
        }
    }

    public async getManualReconciliationLog(id: string): Promise<ManualReconciliationLogType> {
        const response = await this.apiClient.manualReconciliationLogGet({ id })
        return mapOpenApiToManualReconciliationLog(response)
    }

    public async updateManualReconciliationLog(id: string, log: ManualReconciliationLogType): Promise<ManualReconciliationLogType> {
        const response = await this.apiClient.manualReconciliationLogUpdate({
            id,
            manualReconciliationLogOpenApi: mapManualReconciliationLogToOpenApi(log)
        })
        return mapOpenApiToManualReconciliationLog(response)
    }

}