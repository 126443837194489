/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DealOpenApi } from './DealOpenApi';
import {
    DealOpenApiFromJSON,
    DealOpenApiFromJSONTyped,
    DealOpenApiToJSON,
} from './DealOpenApi';
import type { DealPublicationValidationPreviewItemOpenApi } from './DealPublicationValidationPreviewItemOpenApi';
import {
    DealPublicationValidationPreviewItemOpenApiFromJSON,
    DealPublicationValidationPreviewItemOpenApiFromJSONTyped,
    DealPublicationValidationPreviewItemOpenApiToJSON,
} from './DealPublicationValidationPreviewItemOpenApi';

/**
 * 
 * @export
 * @interface DealPublicationValidationPreviewOpenApi
 */
export interface DealPublicationValidationPreviewOpenApi {
    /**
     * 
     * @type {DealOpenApi}
     * @memberof DealPublicationValidationPreviewOpenApi
     */
    deal: DealOpenApi;
    /**
     * 
     * @type {Array<DealPublicationValidationPreviewItemOpenApi>}
     * @memberof DealPublicationValidationPreviewOpenApi
     */
    items: Array<DealPublicationValidationPreviewItemOpenApi>;
}

/**
 * Check if a given object implements the DealPublicationValidationPreviewOpenApi interface.
 */
export function instanceOfDealPublicationValidationPreviewOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deal" in value;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function DealPublicationValidationPreviewOpenApiFromJSON(json: any): DealPublicationValidationPreviewOpenApi {
    return DealPublicationValidationPreviewOpenApiFromJSONTyped(json, false);
}

export function DealPublicationValidationPreviewOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealPublicationValidationPreviewOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deal': DealOpenApiFromJSON(json['deal']),
        'items': ((json['items'] as Array<any>).map(DealPublicationValidationPreviewItemOpenApiFromJSON)),
    };
}

export function DealPublicationValidationPreviewOpenApiToJSON(value?: DealPublicationValidationPreviewOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deal': DealOpenApiToJSON(value.deal),
        'items': ((value.items as Array<any>).map(DealPublicationValidationPreviewItemOpenApiToJSON)),
    };
}

