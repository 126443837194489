import {ActionsFilterType, ActionsFilterTypeAndValueType} from "../../tech/actions/filter/filter.type"
import {getFilter, getFilterValue} from "../../tech/actions/filter/filter.util"
import {UpdateFiltersOpenApi} from "../../generated"

export type UpdateFilterType = "ID"
    | "COMPANY_ID"
    | "COMPANY_LEGAL_NAME"
    | "DEAL_ID"
    | "DEAL_NAME"
    | "DEAL_NAME_ALSO_VIA_COMPANY"

export function assembleFilterOptions(): ActionsFilterType<UpdateFilterType>[] {
    return [
        {
            type: "ID",
            label: "ID",
            value: "STRING"
        },
        {
            type: "COMPANY_ID",
            label: "Company ID",
            value: "STRING"
        },
        {
            type: "COMPANY_LEGAL_NAME",
            label: "Company Legal Name",
            value: "STRING"
        },
        {
            type: "DEAL_ID",
            label: "Deal ID",
            value: "STRING"
        },
        {
            type: "DEAL_NAME",
            label: "Deal Name",
            value: "STRING"
        },
        {
            type: "DEAL_NAME_ALSO_VIA_COMPANY",
            label: "Deal Name (also via company)",
            value: "STRING"
        },
    ]
}

export function getCurrentFilter(
    filters: ActionsFilterType<UpdateFilterType>[],
    searchParams: URLSearchParams
): ActionsFilterTypeAndValueType<UpdateFilterType> | undefined {
    const foundFilter = filters.find(f => f.type === getFilter(searchParams))
    const foundFilterValue = getFilterValue(searchParams)
    if (foundFilter && foundFilterValue) {
        return {
            type: foundFilter.type,
            value: foundFilterValue
        }
    }
    return undefined
}

export function mapToUpdateFiltersOpenApi(filter: ActionsFilterTypeAndValueType<UpdateFilterType> | undefined): UpdateFiltersOpenApi | undefined {
    if (filter === undefined) return undefined

    switch (filter.type) {
        case "ID":
            return { id: filter.value }
        case "COMPANY_ID":
            return { companyId: filter.value }
        case "COMPANY_LEGAL_NAME":
            return { companyLegalName: filter.value }
        case "DEAL_ID":
            return { dealId: filter.value }
        case "DEAL_NAME":
            return { dealName: filter.value }
        case "DEAL_NAME_ALSO_VIA_COMPANY":
            return { dealNameAlsoViaCompany: filter.value }
    }
}