import React, {FunctionComponent, useState} from "react"
import {FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch} from "react-hook-form"
import {DealType} from "../../../deal.type"
import {FeeStructureSourceType} from "../source.type"
import {ShareClassType} from "../../../../share-class/share-class.type"
import Button from "../../../../../tech/button/button.component"
import {ButtonStyle} from "../../../../../tech/button/style.enum"
import FormError from "../../../../../tech/form/error.component"
import DealInvestorGroupFeeStructureShareClassNew from "./new/new.component"
import styles from "./share-class.module.sass"
import DealInvestorGroupFeeStructureShareClassEdit from "./edit/edit.component"

type DealInvestorGroupFeeStructureShareClassProps = {
    index: number
    errors: FieldErrors<DealType>
    register: UseFormRegister<DealType>
    setValue: UseFormSetValue<DealType>
    watch: UseFormWatch<DealType>
    feeStructureSource: FeeStructureSourceType
    shareClasses: ShareClassType[]
    updateShareClasses: (s: ShareClassType[]) => void
}

const DealInvestorGroupFeeStructureShareClass: FunctionComponent<DealInvestorGroupFeeStructureShareClassProps> = ({
    index,
    errors,
    register,
    setValue,
    watch,
    feeStructureSource,
    shareClasses,
    updateShareClasses
}) => {
    const [modalNewShareClassOpened, setModalNewShareClassOpened] = useState<boolean>(false)
    const [modalEditShareClass, setModalEditShareClass] = useState<ShareClassType>()

    function addOrUpdateShareClassAndSelect(shareClass: ShareClassType) {
        const indexShareClasses = shareClasses.findIndex(s => s.id === shareClass.id)
        const shareClassesTmp = shareClasses!
        if (indexShareClasses !== -1) {
            shareClassesTmp[indexShareClasses] = { ...shareClassesTmp[indexShareClasses], ...shareClass }
        } else {
            shareClassesTmp.push(shareClass)
        }
        setValue(`investorGroups.${index}.shareClass.id`, shareClass.id)
        updateShareClasses(shareClassesTmp)
    }

    return (
        <>
            {shareClasses.length > 0 ? (
                <>
                    <select
                        {...register(
                            `investorGroups.${index}.shareClass.id`,
                            {
                                validate: (shareClass) => {
                                    if (feeStructureSource === "SHARE_CLASS" && shareClass === undefined) return "Share class is required."
                                    return true
                                }
                            }
                        )}
                    >
                        {shareClasses.map((shareClass, i) => (
                            <option
                                value={shareClass.id!}
                                key={i}
                            >
                                {shareClass.name} (Setup fee: {shareClass.feeStructure.placementFee}%, Carry: {shareClass.feeStructure.carry}%)
                            </option>
                        ))}
                    </select>
                    <FormError
                        field={`investorGroups.${index}.shareClass.id`}
                        errors={errors}
                    />
                </>
            ) : (
                <div className={styles.noShareClassesAvailable}>
                    No share classes available.
                </div>
            )}
            <div className={styles.buttons}>
                {watch(`investorGroups.${index}.shareClass`) && (
                    <Button
                        title="Edit selected share class"
                        style={ButtonStyle.SECONDARY}
                        onClick={() => setModalEditShareClass(shareClasses.find(c => c.id === watch(`investorGroups.${index}.shareClass.id`)))}
                        type="button"
                    />
                )}
                <Button
                    title="Create a new share class"
                    style={ButtonStyle.SECONDARY}
                    onClick={() => setModalNewShareClassOpened(true)}
                    type="button"
                />
            </div>
            {modalEditShareClass && (
                <DealInvestorGroupFeeStructureShareClassEdit
                    addOrUpdateShareClassAndSelect={addOrUpdateShareClassAndSelect}
                    close={() => setModalEditShareClass(undefined)}
                    shareClass={modalEditShareClass}
                />
            )}
            {modalNewShareClassOpened && (
                <DealInvestorGroupFeeStructureShareClassNew
                    addOrUpdateShareClassAndSelect={addOrUpdateShareClassAndSelect}
                    close={() => setModalNewShareClassOpened(false)}
                />
            )}
        </>
    )
}

export default DealInvestorGroupFeeStructureShareClass