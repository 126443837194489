/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpdateOpenApi } from './UpdateOpenApi';
import {
    UpdateOpenApiFromJSON,
    UpdateOpenApiFromJSONTyped,
    UpdateOpenApiToJSON,
} from './UpdateOpenApi';

/**
 * 
 * @export
 * @interface UpdatePaginatedResponseOpenApi
 */
export interface UpdatePaginatedResponseOpenApi {
    /**
     * 
     * @type {Array<UpdateOpenApi>}
     * @memberof UpdatePaginatedResponseOpenApi
     */
    updates: Array<UpdateOpenApi>;
    /**
     * 
     * @type {number}
     * @memberof UpdatePaginatedResponseOpenApi
     */
    total: number;
}

/**
 * Check if a given object implements the UpdatePaginatedResponseOpenApi interface.
 */
export function instanceOfUpdatePaginatedResponseOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "updates" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function UpdatePaginatedResponseOpenApiFromJSON(json: any): UpdatePaginatedResponseOpenApi {
    return UpdatePaginatedResponseOpenApiFromJSONTyped(json, false);
}

export function UpdatePaginatedResponseOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePaginatedResponseOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updates': ((json['updates'] as Array<any>).map(UpdateOpenApiFromJSON)),
        'total': json['total'],
    };
}

export function UpdatePaginatedResponseOpenApiToJSON(value?: UpdatePaginatedResponseOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updates': ((value.updates as Array<any>).map(UpdateOpenApiToJSON)),
        'total': value.total,
    };
}

