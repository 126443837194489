/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CompanyContactSortOpenApi = {
    CreatedAscending: 'CREATED_ASCENDING',
    CreatedDescending: 'CREATED_DESCENDING',
    ModifiedAscending: 'MODIFIED_ASCENDING',
    ModifiedDescending: 'MODIFIED_DESCENDING',
    NameAscending: 'NAME_ASCENDING',
    NameDescending: 'NAME_DESCENDING'
} as const;
export type CompanyContactSortOpenApi = typeof CompanyContactSortOpenApi[keyof typeof CompanyContactSortOpenApi];


export function CompanyContactSortOpenApiFromJSON(json: any): CompanyContactSortOpenApi {
    return CompanyContactSortOpenApiFromJSONTyped(json, false);
}

export function CompanyContactSortOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyContactSortOpenApi {
    return json as CompanyContactSortOpenApi;
}

export function CompanyContactSortOpenApiToJSON(value?: CompanyContactSortOpenApi | null): any {
    return value as any;
}

