import React, {FunctionComponent} from "react"
import Imgix from "react-imgix"
import {FileType} from "../file.type"

type FilePreviewImageProps = {
    currentFile: FileType
    maxHeight: number
}

const FilePreviewImage: FunctionComponent<FilePreviewImageProps> =({
    currentFile,
    maxHeight
}) => {
    if (currentFile.source.type === "IMGIX_SCR_SET") {
        return (
            <img
                srcSet={currentFile.source.src}
                alt={currentFile.identifier}
                style={{ maxHeight }}
            />
        )
    }

    return (
        <Imgix
            src={currentFile.mimeType === "PDF" ? `${currentFile.source.src}?page=1` : currentFile.source.src}
            height={maxHeight}
            htmlAttributes={{ alt: currentFile.identifier || currentFile.id }}
        />
    )
}

export default FilePreviewImage