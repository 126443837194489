/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SyndicateInvitationConfirmAddingExistingInvestorRequestOpenApi
 */
export interface SyndicateInvitationConfirmAddingExistingInvestorRequestOpenApi {
    /**
     * 
     * @type {string}
     * @memberof SyndicateInvitationConfirmAddingExistingInvestorRequestOpenApi
     */
    investorId: string;
    /**
     * 
     * @type {string}
     * @memberof SyndicateInvitationConfirmAddingExistingInvestorRequestOpenApi
     */
    selectedDealId?: string;
    /**
     * 
     * @type {string}
     * @memberof SyndicateInvitationConfirmAddingExistingInvestorRequestOpenApi
     */
    targetInvestorGroupId: string;
}

/**
 * Check if a given object implements the SyndicateInvitationConfirmAddingExistingInvestorRequestOpenApi interface.
 */
export function instanceOfSyndicateInvitationConfirmAddingExistingInvestorRequestOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "investorId" in value;
    isInstance = isInstance && "targetInvestorGroupId" in value;

    return isInstance;
}

export function SyndicateInvitationConfirmAddingExistingInvestorRequestOpenApiFromJSON(json: any): SyndicateInvitationConfirmAddingExistingInvestorRequestOpenApi {
    return SyndicateInvitationConfirmAddingExistingInvestorRequestOpenApiFromJSONTyped(json, false);
}

export function SyndicateInvitationConfirmAddingExistingInvestorRequestOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyndicateInvitationConfirmAddingExistingInvestorRequestOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'investorId': json['investorId'],
        'selectedDealId': !exists(json, 'selectedDealId') ? undefined : json['selectedDealId'],
        'targetInvestorGroupId': json['targetInvestorGroupId'],
    };
}

export function SyndicateInvitationConfirmAddingExistingInvestorRequestOpenApiToJSON(value?: SyndicateInvitationConfirmAddingExistingInvestorRequestOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'investorId': value.investorId,
        'selectedDealId': value.selectedDealId,
        'targetInvestorGroupId': value.targetInvestorGroupId,
    };
}

