import {SyndicateInvitationOpenApi} from "../../../generated"
import {SyndicateInvitationType} from "./invitation.type"
import {mapOpenApiToSyndicate, mapSyndicateToOpenApi} from "../syndicate.mapper"
import {mapInvestorGroupToOpenApi, mapOpenApiToInvestorGroup} from "../../investor-group/investor-group.mapper"

export function mapOpenApiToSyndicateInvitation(invitation: SyndicateInvitationOpenApi): SyndicateInvitationType {
    return {
        id: invitation.id,
        created: invitation.created,
        modified: invitation.modified,
        code: invitation.code,
        email: invitation.email,
        entered: invitation.entered,
        syndicate: mapOpenApiToSyndicate(invitation.syndicate),
        targetInvestorGroup: mapOpenApiToInvestorGroup(invitation.targetInvestorGroup)
    }
}

export function mapSyndicateInvitationToOpenApi(invitation: SyndicateInvitationType): SyndicateInvitationOpenApi {
    return {
        id: invitation.id,
        created: invitation.created,
        modified: invitation.modified,
        code: invitation.code,
        email: invitation.email,
        entered: invitation.entered,
        syndicate: mapSyndicateToOpenApi(invitation.syndicate),
        targetInvestorGroup: mapInvestorGroupToOpenApi(invitation.targetInvestorGroup)
    }
}