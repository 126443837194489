/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CollectionStatusOpenApi = {
    Completed: 'COMPLETED',
    Pending: 'PENDING',
    Rejected: 'REJECTED'
} as const;
export type CollectionStatusOpenApi = typeof CollectionStatusOpenApi[keyof typeof CollectionStatusOpenApi];


export function CollectionStatusOpenApiFromJSON(json: any): CollectionStatusOpenApi {
    return CollectionStatusOpenApiFromJSONTyped(json, false);
}

export function CollectionStatusOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectionStatusOpenApi {
    return json as CollectionStatusOpenApi;
}

export function CollectionStatusOpenApiToJSON(value?: CollectionStatusOpenApi | null): any {
    return value as any;
}

