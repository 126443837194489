/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CurrencyOpenApi = {
    Aud: 'AUD',
    Chf: 'CHF',
    Eur: 'EUR',
    Gbp: 'GBP',
    Sgd: 'SGD',
    Usd: 'USD'
} as const;
export type CurrencyOpenApi = typeof CurrencyOpenApi[keyof typeof CurrencyOpenApi];


export function CurrencyOpenApiFromJSON(json: any): CurrencyOpenApi {
    return CurrencyOpenApiFromJSONTyped(json, false);
}

export function CurrencyOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrencyOpenApi {
    return json as CurrencyOpenApi;
}

export function CurrencyOpenApiToJSON(value?: CurrencyOpenApi | null): any {
    return value as any;
}

