/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValuationOpenApi } from './ValuationOpenApi';
import {
    ValuationOpenApiFromJSON,
    ValuationOpenApiFromJSONTyped,
    ValuationOpenApiToJSON,
} from './ValuationOpenApi';

/**
 * 
 * @export
 * @interface ValuationsSaveRequestOpenApi
 */
export interface ValuationsSaveRequestOpenApi {
    /**
     * 
     * @type {Array<ValuationOpenApi>}
     * @memberof ValuationsSaveRequestOpenApi
     */
    valuations: Array<ValuationOpenApi>;
}

/**
 * Check if a given object implements the ValuationsSaveRequestOpenApi interface.
 */
export function instanceOfValuationsSaveRequestOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "valuations" in value;

    return isInstance;
}

export function ValuationsSaveRequestOpenApiFromJSON(json: any): ValuationsSaveRequestOpenApi {
    return ValuationsSaveRequestOpenApiFromJSONTyped(json, false);
}

export function ValuationsSaveRequestOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValuationsSaveRequestOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'valuations': ((json['valuations'] as Array<any>).map(ValuationOpenApiFromJSON)),
    };
}

export function ValuationsSaveRequestOpenApiToJSON(value?: ValuationsSaveRequestOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valuations': ((value.valuations as Array<any>).map(ValuationOpenApiToJSON)),
    };
}

