/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DealCategoryOpenApi,
  ValidationResponseOpenApi,
} from '../models';
import {
    DealCategoryOpenApiFromJSON,
    DealCategoryOpenApiToJSON,
    ValidationResponseOpenApiFromJSON,
    ValidationResponseOpenApiToJSON,
} from '../models';

export interface DealCategoryCreateRequest {
    dealCategoryOpenApi?: DealCategoryOpenApi;
}

export interface DealCategoryDeleteRequest {
    id: string;
}

export interface DealCategoryGetRequest {
    id: string;
}

export interface DealCategoryUpdateRequest {
    id: string;
    dealCategoryOpenApi?: DealCategoryOpenApi;
}

/**
 * 
 */
export class DealCategoryApiClient extends runtime.BaseAPI {

    /**
     * Create new deal category
     */
    async dealCategoryCreateRaw(requestParameters: DealCategoryCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealCategoryOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal-category`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DealCategoryOpenApiToJSON(requestParameters.dealCategoryOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealCategoryOpenApiFromJSON(jsonValue));
    }

    /**
     * Create new deal category
     */
    async dealCategoryCreate(requestParameters: DealCategoryCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealCategoryOpenApi> {
        const response = await this.dealCategoryCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete deal category by id
     */
    async dealCategoryDeleteRaw(requestParameters: DealCategoryDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealCategoryDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal-category/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete deal category by id
     */
    async dealCategoryDelete(requestParameters: DealCategoryDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dealCategoryDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get deal category by id
     */
    async dealCategoryGetRaw(requestParameters: DealCategoryGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealCategoryOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealCategoryGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal-category/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealCategoryOpenApiFromJSON(jsonValue));
    }

    /**
     * Get deal category by id
     */
    async dealCategoryGet(requestParameters: DealCategoryGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealCategoryOpenApi> {
        const response = await this.dealCategoryGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all deal categories
     */
    async dealCategoryGetAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DealCategoryOpenApi>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal-category`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DealCategoryOpenApiFromJSON));
    }

    /**
     * Get all deal categories
     */
    async dealCategoryGetAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DealCategoryOpenApi>> {
        const response = await this.dealCategoryGetAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update deal category
     */
    async dealCategoryUpdateRaw(requestParameters: DealCategoryUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealCategoryOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealCategoryUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/deal-category/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DealCategoryOpenApiToJSON(requestParameters.dealCategoryOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealCategoryOpenApiFromJSON(jsonValue));
    }

    /**
     * Update deal category
     */
    async dealCategoryUpdate(requestParameters: DealCategoryUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealCategoryOpenApi> {
        const response = await this.dealCategoryUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
