const PARAMETER_NAME = "sort"

export function getSort<T>(searchParams: URLSearchParams): T | undefined {
    return searchParams.has(PARAMETER_NAME)
        ? searchParams.get(PARAMETER_NAME)! as T
        : undefined
}

export function setSort<T>(value: T, searchParams: URLSearchParams): URLSearchParams {
    const newSearchParams = searchParams
    newSearchParams.set(PARAMETER_NAME, `${value}`)
    return newSearchParams
}