import React, {useContext, useState} from "react"
import {SubmitHandler} from "react-hook-form"
import {useNavigate} from "react-router-dom"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import SinglePurposeVehicleForm from "./form.component"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {SINGLE_PURPOSE_VEHICLE} from "../../paths"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import {normalizeValues} from "../../tech/form/form.util"
import {SinglePurposeVehicleType} from "./single-purpose-vehicle.type"

const SinglePurposeVehicleNew = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const navigate = useNavigate()
    const [state, setState] = useState<"NONE" | "LOADING" | "ERROR">("NONE")

    const onSubmit: SubmitHandler<SinglePurposeVehicleType> = async (singlePurposeVehicle) => {
        setState("LOADING")
        try {
            const savedSinglePurposeVehicle = await fetchClient.singlePurposeVehicleApi.create(normalizeValues(singlePurposeVehicle))
            navigate(SINGLE_PURPOSE_VEHICLE(savedSinglePurposeVehicle.id!))
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to save SPV."
                />
            )}
            {(state === "NONE" || state === "ERROR") && (
                <Section>
                    <SectionHeading title="Create new SPV"/>
                    <SinglePurposeVehicleForm
                        onSubmit={onSubmit}
                        submitText="Create SPV"
                    />
                </Section>
            )}
        </>
    )
}

export default SinglePurposeVehicleNew