/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DealDocumentTypeOpenApi = {
    InvestmentTerms: 'INVESTMENT_TERMS',
    SpvConstitution: 'SPV_CONSTITUTION',
    Other: 'OTHER'
} as const;
export type DealDocumentTypeOpenApi = typeof DealDocumentTypeOpenApi[keyof typeof DealDocumentTypeOpenApi];


export function DealDocumentTypeOpenApiFromJSON(json: any): DealDocumentTypeOpenApi {
    return DealDocumentTypeOpenApiFromJSONTyped(json, false);
}

export function DealDocumentTypeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealDocumentTypeOpenApi {
    return json as DealDocumentTypeOpenApi;
}

export function DealDocumentTypeOpenApiToJSON(value?: DealDocumentTypeOpenApi | null): any {
    return value as any;
}

