import React, {FunctionComponent, useContext, useEffect, useState} from "react"
import {Link} from "react-router-dom"
import LoadingDots from "../../tech/loading/dots/dots.component"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {ASYNCHRONOUS_ACTION_OVERVIEW} from "../../paths"
import {AsynchronousActionOverviewType} from "../../tech/asynchronous/action/overview/overview.type"
import {countErrors} from "../../tech/asynchronous/action/overview/overview.util"
import styles from "./dashboard.module.css"

const DashboardAsynchronousActionErrors: FunctionComponent = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [actionOverview, setActionOverview] = useState<AsynchronousActionOverviewType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                setActionOverview(await fetchClient.actionApi.getOverview())
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    const asynchronousErrorCount = actionOverview && countErrors(actionOverview)

    return (
        <div className={asynchronousErrorCount && asynchronousErrorCount > 0 ? styles.boxRed : styles.box}>
            <div>
                <h3>Asynchronous Action Errors</h3>
                {state === "LOADED" && (
                    <>
                        {asynchronousErrorCount && asynchronousErrorCount > 0 ? (
                            <>
                                <strong>
                                    {asynchronousErrorCount} Error(s).
                                </strong>
                                <Link to={ASYNCHRONOUS_ACTION_OVERVIEW}>
                                    &rarr; Go to asynchronous action overview
                                </Link>
                            </>
                        ) : (
                            <>No errors.</>
                        )}
                    </>
                )}
                {state === "LOADING" && <LoadingDots/>}
                {state === "ERROR" && <i>Error while loading.</i>}
            </div>
        </div>
    )
}

export default DashboardAsynchronousActionErrors