/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CancellationOpenApi } from './CancellationOpenApi';
import {
    CancellationOpenApiFromJSON,
    CancellationOpenApiFromJSONTyped,
    CancellationOpenApiToJSON,
} from './CancellationOpenApi';
import type { SubscriptionPaymentIntervalOpenApi } from './SubscriptionPaymentIntervalOpenApi';
import {
    SubscriptionPaymentIntervalOpenApiFromJSON,
    SubscriptionPaymentIntervalOpenApiFromJSONTyped,
    SubscriptionPaymentIntervalOpenApiToJSON,
} from './SubscriptionPaymentIntervalOpenApi';
import type { SubscriptionTypeOpenApi } from './SubscriptionTypeOpenApi';
import {
    SubscriptionTypeOpenApiFromJSON,
    SubscriptionTypeOpenApiFromJSONTyped,
    SubscriptionTypeOpenApiToJSON,
} from './SubscriptionTypeOpenApi';

/**
 * 
 * @export
 * @interface SubscriptionOpenApi
 */
export interface SubscriptionOpenApi {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionOpenApi
     */
    logId: string;
    /**
     * 
     * @type {Date}
     * @memberof SubscriptionOpenApi
     */
    logCreated: Date;
    /**
     * 
     * @type {Date}
     * @memberof SubscriptionOpenApi
     */
    logModified: Date;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionOpenApi
     */
    stripeId: string;
    /**
     * 
     * @type {SubscriptionTypeOpenApi}
     * @memberof SubscriptionOpenApi
     */
    type: SubscriptionTypeOpenApi;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionOpenApi
     */
    price: number;
    /**
     * 
     * @type {SubscriptionPaymentIntervalOpenApi}
     * @memberof SubscriptionOpenApi
     */
    paymentInterval: SubscriptionPaymentIntervalOpenApi;
    /**
     * 
     * @type {CancellationOpenApi}
     * @memberof SubscriptionOpenApi
     */
    cancellation?: CancellationOpenApi;
}

/**
 * Check if a given object implements the SubscriptionOpenApi interface.
 */
export function instanceOfSubscriptionOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "logId" in value;
    isInstance = isInstance && "logCreated" in value;
    isInstance = isInstance && "logModified" in value;
    isInstance = isInstance && "stripeId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "paymentInterval" in value;

    return isInstance;
}

export function SubscriptionOpenApiFromJSON(json: any): SubscriptionOpenApi {
    return SubscriptionOpenApiFromJSONTyped(json, false);
}

export function SubscriptionOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'logId': json['logId'],
        'logCreated': (new Date(json['logCreated'])),
        'logModified': (new Date(json['logModified'])),
        'stripeId': json['stripeId'],
        'type': SubscriptionTypeOpenApiFromJSON(json['type']),
        'price': json['price'],
        'paymentInterval': SubscriptionPaymentIntervalOpenApiFromJSON(json['paymentInterval']),
        'cancellation': !exists(json, 'cancellation') ? undefined : CancellationOpenApiFromJSON(json['cancellation']),
    };
}

export function SubscriptionOpenApiToJSON(value?: SubscriptionOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'logId': value.logId,
        'logCreated': (value.logCreated.toISOString()),
        'logModified': (value.logModified.toISOString()),
        'stripeId': value.stripeId,
        'type': SubscriptionTypeOpenApiToJSON(value.type),
        'price': value.price,
        'paymentInterval': SubscriptionPaymentIntervalOpenApiToJSON(value.paymentInterval),
        'cancellation': CancellationOpenApiToJSON(value.cancellation),
    };
}

