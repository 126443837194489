import {ValuationOpenApi} from "../../generated"
import {ValuationType} from "./valuation.type"

export function mapOpenApiToValuation(valuation: ValuationOpenApi): ValuationType {
    return {
        id: valuation.id,
        created: valuation.created,
        modified: valuation.modified,
        calculationMethod: valuation.calculationMethod,
        pricePerShare: valuation.pricePerShare,
        valuation: valuation.valuation,
        currency: valuation.currency,
        date: valuation.date,
        discount: valuation.discount,
        description: valuation.description,
        dealId: valuation.dealId
    }
}

export function mapValuationToOpenApi(valuation: ValuationType): ValuationOpenApi {
    return {
        id: valuation.id,
        created: valuation.created,
        modified: valuation.modified,
        calculationMethod: valuation.calculationMethod,
        pricePerShare: valuation.pricePerShare,
        valuation: valuation.valuation,
        currency: valuation.currency,
        date: valuation.date,
        discount: valuation.discount,
        description: valuation.description,
        dealId: valuation.dealId
    }
}