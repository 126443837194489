const PARAMETER_NAME = "filter"
const VALUE_PARAMETER_NAME = "filter-value"

export function getFilter(searchParams: URLSearchParams): string | undefined {
    return searchParams.has(PARAMETER_NAME) ? searchParams.get(PARAMETER_NAME)! : undefined
}

export function setFilter(value: string, searchParams: URLSearchParams): URLSearchParams {
    const newSearchParams = searchParams
    newSearchParams.set(PARAMETER_NAME, value)
    return newSearchParams
}

export function removeFilter(searchParams: URLSearchParams): URLSearchParams {
    const newSearchParams = searchParams
    newSearchParams.delete(PARAMETER_NAME)
    return newSearchParams
}

export function getFilterValue(searchParams: URLSearchParams): any | undefined {
    return searchParams.has(VALUE_PARAMETER_NAME) ? searchParams.get(VALUE_PARAMETER_NAME)! : undefined
}

export function setFilterValue(value: any, searchParams: URLSearchParams): URLSearchParams {
    const newSearchParams = searchParams
    newSearchParams.set(VALUE_PARAMETER_NAME, value)
    return newSearchParams
}

export function removeFilterValue(searchParams: URLSearchParams): URLSearchParams {
    const newSearchParams = searchParams
    newSearchParams.delete(VALUE_PARAMETER_NAME)
    return newSearchParams
}