/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DealOutcomeOpenApi = {
    None: 'NONE',
    Finalized: 'FINALIZED',
    Unsuccessful: 'UNSUCCESSFUL'
} as const;
export type DealOutcomeOpenApi = typeof DealOutcomeOpenApi[keyof typeof DealOutcomeOpenApi];


export function DealOutcomeOpenApiFromJSON(json: any): DealOutcomeOpenApi {
    return DealOutcomeOpenApiFromJSONTyped(json, false);
}

export function DealOutcomeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealOutcomeOpenApi {
    return json as DealOutcomeOpenApi;
}

export function DealOutcomeOpenApiToJSON(value?: DealOutcomeOpenApi | null): any {
    return value as any;
}

