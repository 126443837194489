/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PersonOpenApi } from './PersonOpenApi';
import {
    PersonOpenApiFromJSON,
    PersonOpenApiFromJSONTyped,
    PersonOpenApiToJSON,
} from './PersonOpenApi';

/**
 * 
 * @export
 * @interface GeneralDataSaveRequestOpenApi
 */
export interface GeneralDataSaveRequestOpenApi {
    /**
     * 
     * @type {string}
     * @memberof GeneralDataSaveRequestOpenApi
     */
    companyName?: string;
    /**
     * 
     * @type {PersonOpenApi}
     * @memberof GeneralDataSaveRequestOpenApi
     */
    person: PersonOpenApi;
}

/**
 * Check if a given object implements the GeneralDataSaveRequestOpenApi interface.
 */
export function instanceOfGeneralDataSaveRequestOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "person" in value;

    return isInstance;
}

export function GeneralDataSaveRequestOpenApiFromJSON(json: any): GeneralDataSaveRequestOpenApi {
    return GeneralDataSaveRequestOpenApiFromJSONTyped(json, false);
}

export function GeneralDataSaveRequestOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneralDataSaveRequestOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'person': PersonOpenApiFromJSON(json['person']),
    };
}

export function GeneralDataSaveRequestOpenApiToJSON(value?: GeneralDataSaveRequestOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'person': PersonOpenApiToJSON(value.person),
    };
}

