import {ActionsSortOptionType} from "../../../tech/actions/sort/sort.type"
import {getSort} from "../../../tech/actions/sort/sort.util"
import {FileSortOpenApi} from "../../../generated"

export const DEFAULT_SORT = {
    id: FileSortOpenApi.CreatedDescending,
    label: "Uploaded (DESC)"
}

export function assembleSortOptions(): ActionsSortOptionType<FileSortOpenApi>[] {
    return [
        {
            id: FileSortOpenApi.IdentifierAscending,
            label: "Identifier (ASC)"
        },
        {
            id: FileSortOpenApi.IdentifierDescending,
            label: "Identifier (DESC)"
        },
        {
            id: FileSortOpenApi.CreatedAscending,
            label: "Uploaded (ASC)"
        },
        DEFAULT_SORT
    ]
}

export function getCurrentSortOption(
    options: ActionsSortOptionType<FileSortOpenApi>[],
    searchParams: URLSearchParams
): ActionsSortOptionType<FileSortOpenApi> {
    return (options.find(o => o.id === getSort(searchParams)) || DEFAULT_SORT)
}