/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  KycLogOpenApi,
  KycLogReviewOpenApi,
} from '../models';
import {
    KycLogOpenApiFromJSON,
    KycLogOpenApiToJSON,
    KycLogReviewOpenApiFromJSON,
    KycLogReviewOpenApiToJSON,
} from '../models';

export interface KycLogGetRequest {
    id: string;
}

export interface KycLogReviewRequest {
    id: string;
    kycLogReviewOpenApi?: KycLogReviewOpenApi;
}

/**
 * 
 */
export class KycApiClient extends runtime.BaseAPI {

    /**
     * Get kyc log by id
     */
    async kycLogGetRaw(requestParameters: KycLogGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KycLogOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling kycLogGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/kyc/log/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KycLogOpenApiFromJSON(jsonValue));
    }

    /**
     * Get kyc log by id
     */
    async kycLogGet(requestParameters: KycLogGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KycLogOpenApi> {
        const response = await this.kycLogGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Review kyc log
     */
    async kycLogReviewRaw(requestParameters: KycLogReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling kycLogReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/kyc/log/review/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KycLogReviewOpenApiToJSON(requestParameters.kycLogReviewOpenApi),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Review kyc log
     */
    async kycLogReview(requestParameters: KycLogReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.kycLogReviewRaw(requestParameters, initOverrides);
    }

    /**
     * Get all kyc logs to be reviewed
     */
    async kycLogsToBeReviewedGetAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KycLogOpenApi>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/kyc/log/review`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(KycLogOpenApiFromJSON));
    }

    /**
     * Get all kyc logs to be reviewed
     */
    async kycLogsToBeReviewedGetAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KycLogOpenApi>> {
        const response = await this.kycLogsToBeReviewedGetAllRaw(initOverrides);
        return await response.value();
    }

}
