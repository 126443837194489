import React, {FunctionComponent, useState} from "react"
import {
    FieldArrayWithId,
    FieldErrors,
    UseFieldArrayRemove,
    UseFormGetValues,
    UseFormRegister,
    UseFormSetValue, UseFormWatch
} from "react-hook-form"
import {DealType} from "../deal.type"
import {FeeStructureSourceType} from "./fee-structure/source.type"
import styles from "../select.module.sass"
import DealInvestorGroupFeeStructureCustom from "./fee-structure/custom/custom.component"
import DealInvestorGroupFeeStructureShareClass from "./fee-structure/share-class/share-class.component"
import {ShareClassType} from "../../share-class/share-class.type"

type DealInvestorGroupSelectSingleProps = {
    field: FieldArrayWithId<DealType, "investorGroups">
    index: number
    errors: FieldErrors<DealType>
    register: UseFormRegister<DealType>
    remove: UseFieldArrayRemove
    getValues: UseFormGetValues<DealType>
    setValue: UseFormSetValue<DealType>
    watch: UseFormWatch<DealType>
    shareClasses: ShareClassType[]
    updateShareClasses: (s: ShareClassType[]) => void
}

const DealInvestorGroupSelectSingle: FunctionComponent<DealInvestorGroupSelectSingleProps> = ({
    field,
    index,
    errors,
    register,
    remove,
    getValues,
    setValue,
    watch,
    shareClasses,
    updateShareClasses
}) => {
    const [feeStructureSource, setFeeStructureSource] = useState<FeeStructureSourceType>(evaluateSource(getValues, index))

    function select(source: FeeStructureSourceType) {
        if (source === "CUSTOM") {
            setValue(`investorGroups.${index}.shareClass`, undefined)
            setFeeStructureSource("CUSTOM")
        }

        if (source === "SHARE_CLASS") {
            setValue(`investorGroups.${index}.feeStructure`, undefined)
            setFeeStructureSource("SHARE_CLASS")
        }

        if (source === "NONE") {
            setValue(`investorGroups.${index}.feeStructure`, undefined)
            setValue(`investorGroups.${index}.shareClass`, undefined)
            setFeeStructureSource("NONE")
        }
    }

    const del = (i: number) => remove(i)

    return (
        <div className={styles.box}>
            <input type="hidden" {...register(`investorGroups.${index}.id`,)} />
            <input type="hidden" {...register(`investorGroups.${index}.sequentialId`,)} />
            <input type="hidden" {...register(`investorGroups.${index}.name`,)} />
            <input type="hidden" {...register(`investorGroups.${index}.description`,)} />
            <div className={styles.boxTitle}>
                <div>
                    <strong>{field.name}</strong>
                </div>
                <div className={styles.delete}>
                    <button
                        type="button"
                        onClick={() => del(index)}
                    >
                        Delete
                    </button>
                </div>
            </div>

            <div className={styles.selectSource}>
                <label>
                    <input
                        type="radio"
                        name={`feeStructureSource-${index}`}
                        onChange={() => select("NONE")}
                        checked={feeStructureSource === "NONE"}
                    />
                    Fee structure of deal
                </label>
                <label>
                    <input
                        type="radio"
                        name={`feeStructureSource-${index}`}
                        onChange={() => select("CUSTOM")}
                        checked={feeStructureSource === "CUSTOM"}
                    />
                    Custom fee structure
                </label>
                <label>
                    <input
                        type="radio"
                        name={`feeStructureSource-${index}`}
                        onChange={() => select("SHARE_CLASS")}
                        checked={feeStructureSource === "SHARE_CLASS"}
                    />
                    Fee structure from share class
                </label>
            </div>

            {feeStructureSource === "CUSTOM" ? (
                <DealInvestorGroupFeeStructureCustom
                    index={index}
                    errors={errors}
                    register={register}
                    feeStructureSource={feeStructureSource}
                />
            ) : feeStructureSource === "SHARE_CLASS" ? (
                <DealInvestorGroupFeeStructureShareClass
                    index={index}
                    errors={errors}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    feeStructureSource={feeStructureSource}
                    shareClasses={shareClasses}
                    updateShareClasses={updateShareClasses}
                />
            ) : <></>}
        </div>
    )
}

export default DealInvestorGroupSelectSingle

function evaluateSource(
    getValues: UseFormGetValues<DealType>,
    index: number
): FeeStructureSourceType {
    const feeStructure = getValues(`investorGroups.${index}.feeStructure`)
    const hasCustomFeeStructure = feeStructure !== undefined
        && feeStructure.placementFee !== undefined
        && feeStructure.managementFee !== undefined
        && feeStructure.carry !== undefined
    if (hasCustomFeeStructure) {
        return "CUSTOM"
    }
    const shareClass = getValues(`investorGroups.${index}.shareClass`)
    if (shareClass !== undefined) {
        return "SHARE_CLASS"
    }
    return "NONE"
}