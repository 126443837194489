/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DealSortOpenApi = {
    FinalDeadlineAscending: 'FINAL_DEADLINE_ASCENDING',
    FinalDeadlineDescending: 'FINAL_DEADLINE_DESCENDING',
    MaxTargetAscending: 'MAX_TARGET_ASCENDING',
    MaxTargetDescending: 'MAX_TARGET_DESCENDING',
    MinTargetAscending: 'MIN_TARGET_ASCENDING',
    MinTargetDescending: 'MIN_TARGET_DESCENDING',
    MinTicketAscending: 'MIN_TICKET_ASCENDING',
    MinTicketDescending: 'MIN_TICKET_DESCENDING',
    ModifiedAscending: 'MODIFIED_ASCENDING',
    ModifiedDescending: 'MODIFIED_DESCENDING',
    NameAscending: 'NAME_ASCENDING',
    NameDescending: 'NAME_DESCENDING',
    OutcomeAscending: 'OUTCOME_ASCENDING',
    OutcomeDescending: 'OUTCOME_DESCENDING'
} as const;
export type DealSortOpenApi = typeof DealSortOpenApi[keyof typeof DealSortOpenApi];


export function DealSortOpenApiFromJSON(json: any): DealSortOpenApi {
    return DealSortOpenApiFromJSONTyped(json, false);
}

export function DealSortOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealSortOpenApi {
    return json as DealSortOpenApi;
}

export function DealSortOpenApiToJSON(value?: DealSortOpenApi | null): any {
    return value as any;
}

