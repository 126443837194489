import React, {FunctionComponent} from "react"
import {formatAmountWithCurrency} from "../../../tech/format/format.util"
import {CollectionType} from "../collection.type"

type CollectionAmountProps = {
    collections: CollectionType[]
}

const CollectionAmount: FunctionComponent<CollectionAmountProps> = ({ collections }) => {
    if (collections.length < 1) return <></>

    if (collections.every(c => c.currency === collections[0].currency)) {
        return (
            <>
                <strong>Collection amount (origin):</strong>{" "}
                {formatAmountWithCurrency(
                    collections.reduce((partialSum, c) => partialSum + c.amount.origin, 0),
                    collections[0].currency
                )}<br/>
                <strong>Collection amount (actual):</strong>{" "}
                {formatAmountWithCurrency(
                    collections.reduce((partialSum, c) => partialSum + c.amount.actual, 0),
                    collections[0].currency
                )}
            </>
        )
    }

    return (
        <>
            <strong>Collection amount (origin):</strong>{" "}
            <ul>
                {collections.map(c => (
                    <li key={`collection-${c.id}-amount-origin`}>
                        {formatAmountWithCurrency(c.amount.origin, c.currency)}
                    </li>
                ))}
            </ul>
            <br/>
            <strong>Collection amount (actual):</strong>{" "}
            <ul>
                {collections.map(c => (
                    <li key={`collection-${c.id}-amount-actual`}>
                        {formatAmountWithCurrency(c.amount.actual, c.currency)}
                    </li>
                ))}
            </ul>
        </>
    )
}

export default CollectionAmount