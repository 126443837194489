/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DealOnboardingDependentWithInvestmentOpenApi,
  InvestmentExchangePreviewOpenApi,
  InvestmentOpenApi,
  InvestmentSelectCurrencyRequestOpenApi,
  ValidationResponseOpenApi,
} from '../models';
import {
    DealOnboardingDependentWithInvestmentOpenApiFromJSON,
    DealOnboardingDependentWithInvestmentOpenApiToJSON,
    InvestmentExchangePreviewOpenApiFromJSON,
    InvestmentExchangePreviewOpenApiToJSON,
    InvestmentOpenApiFromJSON,
    InvestmentOpenApiToJSON,
    InvestmentSelectCurrencyRequestOpenApiFromJSON,
    InvestmentSelectCurrencyRequestOpenApiToJSON,
    ValidationResponseOpenApiFromJSON,
    ValidationResponseOpenApiToJSON,
} from '../models';

export interface InvestmentExchangeRatePreviewGetRequest {
    id: string;
}

export interface InvestmentExchangeRatePutRequest {
    id: string;
    investmentSelectCurrencyRequestOpenApi?: InvestmentSelectCurrencyRequestOpenApi;
}

export interface InvestmentGetRequest {
    id: string;
}

export interface InvestmentSearchRequest {
    deal?: string;
    investor?: string;
}

export interface InvestmentSearchForManualReconciliationRequest {
    dealOrInvestorName: string;
}

/**
 * 
 */
export class InvestmentApiClient extends runtime.BaseAPI {

    /**
     * Preview exchange rate and corresponding fee for currency selection
     */
    async investmentExchangeRatePreviewGetRaw(requestParameters: InvestmentExchangeRatePreviewGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InvestmentExchangePreviewOpenApi>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investmentExchangeRatePreviewGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investment/{id}/payment/exchange-rate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvestmentExchangePreviewOpenApiFromJSON));
    }

    /**
     * Preview exchange rate and corresponding fee for currency selection
     */
    async investmentExchangeRatePreviewGet(requestParameters: InvestmentExchangeRatePreviewGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InvestmentExchangePreviewOpenApi>> {
        const response = await this.investmentExchangeRatePreviewGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Select currency for investment
     */
    async investmentExchangeRatePutRaw(requestParameters: InvestmentExchangeRatePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DealOnboardingDependentWithInvestmentOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investmentExchangeRatePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investment/{id}/payment/exchange-rate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InvestmentSelectCurrencyRequestOpenApiToJSON(requestParameters.investmentSelectCurrencyRequestOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealOnboardingDependentWithInvestmentOpenApiFromJSON(jsonValue));
    }

    /**
     * Select currency for investment
     */
    async investmentExchangeRatePut(requestParameters: InvestmentExchangeRatePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DealOnboardingDependentWithInvestmentOpenApi> {
        const response = await this.investmentExchangeRatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get investment by id
     */
    async investmentGetRaw(requestParameters: InvestmentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestmentOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investmentGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestmentOpenApiFromJSON(jsonValue));
    }

    /**
     * Get investment by id
     */
    async investmentGet(requestParameters: InvestmentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestmentOpenApi> {
        const response = await this.investmentGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all pending investments to be listed in backoffice
     */
    async investmentGetAllPendingBackofficeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InvestmentOpenApi>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investment/pending/backoffice`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvestmentOpenApiFromJSON));
    }

    /**
     * Get all pending investments to be listed in backoffice
     */
    async investmentGetAllPendingBackoffice(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InvestmentOpenApi>> {
        const response = await this.investmentGetAllPendingBackofficeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all investments of current user
     */
    async investmentGetAllPendingForCurrentUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InvestmentOpenApi>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investment/pending`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvestmentOpenApiFromJSON));
    }

    /**
     * Get all investments of current user
     */
    async investmentGetAllPendingForCurrentUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InvestmentOpenApi>> {
        const response = await this.investmentGetAllPendingForCurrentUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * Search investments
     */
    async investmentSearchRaw(requestParameters: InvestmentSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InvestmentOpenApi>>> {
        const queryParameters: any = {};

        if (requestParameters.deal !== undefined) {
            queryParameters['deal'] = requestParameters.deal;
        }

        if (requestParameters.investor !== undefined) {
            queryParameters['investor'] = requestParameters.investor;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvestmentOpenApiFromJSON));
    }

    /**
     * Search investments
     */
    async investmentSearch(requestParameters: InvestmentSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InvestmentOpenApi>> {
        const response = await this.investmentSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search investments for manual reconciliation
     */
    async investmentSearchForManualReconciliationRaw(requestParameters: InvestmentSearchForManualReconciliationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InvestmentOpenApi>>> {
        if (requestParameters.dealOrInvestorName === null || requestParameters.dealOrInvestorName === undefined) {
            throw new runtime.RequiredError('dealOrInvestorName','Required parameter requestParameters.dealOrInvestorName was null or undefined when calling investmentSearchForManualReconciliation.');
        }

        const queryParameters: any = {};

        if (requestParameters.dealOrInvestorName !== undefined) {
            queryParameters['dealOrInvestorName'] = requestParameters.dealOrInvestorName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investment/search-for-manual-reconciliation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvestmentOpenApiFromJSON));
    }

    /**
     * Search investments for manual reconciliation
     */
    async investmentSearchForManualReconciliation(requestParameters: InvestmentSearchForManualReconciliationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InvestmentOpenApi>> {
        const response = await this.investmentSearchForManualReconciliationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
