import React, {FunctionComponent, useContext} from "react"
import {Control, FieldErrors, useFieldArray, UseFormRegister, UseFormSetValue, UseFormWatch} from "react-hook-form"
import FileSelectSingle from "./single.component"
import {FileSortOpenApi, MimeTypeOpenApi} from "../../../generated"
import {FileSelectSearchType} from "../../file/upload-select/select/select.component"
import {FileType} from "../../file/file.type"
import {UpdateFileType, UpdateType} from "../update.type"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import styles from "./select.module.sass"

type UpdateFileSelectProps = {
    files: UpdateFileType[]
    control: Control<UpdateType, any>
    errors: FieldErrors<UpdateType>
    register: UseFormRegister<UpdateType>
    setValue: UseFormSetValue<UpdateType>
    watch: UseFormWatch<UpdateType>
}

const UpdateFileSelect: FunctionComponent<UpdateFileSelectProps> = ({
    files,
    control,
    errors,
    register,
    setValue,
    watch
}) => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const { fields, append, remove } = useFieldArray({ name: "files", control })

    function add() {
        const newOrder = files.map(f => f.order).filter(o => o !== undefined)
        append({
            // @ts-ignore
            file: undefined,
            order: newOrder.length > 0 ? Math.max(...newOrder) + 1 : 0
        })
    }

    async function fetchFiles(
        mimeTypes: MimeTypeOpenApi[],
        searchType: FileSelectSearchType,
        searchValue: string
    ): Promise<FileType[]> {
        const result = await fetchClient.fileApi.getAllPaginated(
            FileSortOpenApi.IdentifierAscending,
            {
                id: searchType === "ID" ? searchValue : undefined,
                identifier: searchType === "IDENTIFIER" ? searchValue : undefined,
                mimeTypes
            }
        )
        return result.elements
    }

    return (
        <div>
            {fields.map((d, index) => (
                <FileSelectSingle
                    index={index}
                    errors={errors}
                    register={register}
                    remove={remove}
                    setValue={setValue}
                    watch={watch}
                    fetchFiles={fetchFiles}
                    key={d.id}
                />
            ))}
            <button
                type="button"
                onClick={add}
                className={styles.add}
            >
                Add a file
            </button>
        </div>
    )
}

export default UpdateFileSelect