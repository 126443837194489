import React from "react"
import ReactDOM from "react-dom/client"
import {BrowserRouter} from "react-router-dom"
import {getEnvironment} from "./tech/environment/environment.util"
import {Environment} from "./tech/environment/environment.enum"
import App from "./App"
import "./index.sass"

const env = getEnvironment()
if (env === Environment.LOCAL) {
    document.title = "[LOCAL] Backoffice NonPublic"
}
if (env === Environment.DEVELOP || env === Environment.PREVIEW) {
    document.title = "[INT] Backoffice NonPublic"
}

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
)
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
)
