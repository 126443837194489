import {WallexCollectionAccountCreationAsynchronousActionType} from "./action.type"
import {WallexCollectionAccountCreationAsynchronousActionOpenApi} from "../../../../../../generated"

export function mapOpenApiToWallexCollectionAccountCreationAsynchronousAction(
    action: WallexCollectionAccountCreationAsynchronousActionOpenApi
): WallexCollectionAccountCreationAsynchronousActionType {
    return new WallexCollectionAccountCreationAsynchronousActionType({
        id: action.id,
        created: action.created,
        modified: action.modified,
        state: action.state,
        message: action.message,
        processed: action.processed,
        investmentId: action.investmentId
    })
}