import {Amplify} from "aws-amplify"
import {getEnvironment} from "../environment/environment.util"
import {Environment} from "../environment/environment.enum"

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: getEnvironment() === Environment.PRODUCTION
                ? "ap-southeast-1_gqya9KaV2"
                : "ap-southeast-1_AdM19GD8w",
            userPoolClientId: getEnvironment() === Environment.PRODUCTION
                ? "2l30pl3fn9q9ev2a0pi27bg36a"
                : "42o37ukvnveq4c44hvg209dfc3",
        }
    }
})