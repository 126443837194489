import {ValuationApiClient} from "../../generated"
import {ValuationType} from "./valuation.type"
import {DealWithValuationsType} from "../deal/valuation/deal-with-valuations.type"
import {mapValuationToOpenApi} from "./valuation.mapper"
import {mapOpenApiToDealWithValuations} from "../deal/valuation/deal-with-valuations.mapper"

export class ValuationApi {

    constructor(private apiClient: ValuationApiClient) {
    }

    public async saveAll(dealId: string, valuations: ValuationType[]): Promise<DealWithValuationsType> {
        const response = await this.apiClient.valuationSaveAll({
            dealId,
            valuationsSaveRequestOpenApi: {
                valuations: valuations.map(mapValuationToOpenApi)
            }
        })
        return mapOpenApiToDealWithValuations(response)
    }

}