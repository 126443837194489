import React, {useContext, useEffect, useState} from "react"
import {Link, useNavigate, useParams} from "react-router-dom"
import {FetchClient} from "../../../../tech/fetch/client"
import FetchContext from "../../../../tech/fetch/fetch.context"
import {KycLogReviewType, KycLogType} from "../kyc-log.type"
import LoadingDots from "../../../../tech/loading/dots/dots.component"
import Alert from "../../../../tech/alert/alert.component"
import {AlertType} from "../../../../tech/alert/type.enum"
import Section from "../../../../tech/section/section.component"
import SectionHeading from "../../../../tech/section/section-heading.component"
import {FileDownloadLinkType} from "../../../file/file.type"
import KycReviewForm from "./form.component"
import {INVESTOR, KYC_REVIEWS} from "../../../../paths"
import {KycLogClassificationOpenApi, KycLogTypeOpenApi} from "../../../../generated"
import {formatDatetime} from "../../../../tech/format/format.util"
import styles from "./review.module.sass"

const OpenKycReview = () => {
    let { id } = useParams()
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [kycLog, setKycLog] = useState<KycLogType>()
    const [fileDownloadLinks, setFileDownloadLinks] = useState<FileDownloadLinkType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const fetchedKycLog = await fetchClient.kycApi.get(id!)
                setKycLog(fetchedKycLog)
                setFileDownloadLinks(await Promise.all(fetchedKycLog!.files.map(f => fetchClient.fileApi.getDownloadLink(f.id))))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const submitReview = async (review: KycLogReviewType) => {
        setState("LOADING")
        try {
            await fetchClient.kycApi.review(id!, review)
            navigate(KYC_REVIEWS)
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    const address = kycLog?.investor.person?.address

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && kycLog && (
                <Section>
                    <SectionHeading
                        title={`Review KYC log: ${kycLog.id} (${kycLog.investor.person?.fullName})`}
                        subTitle={`Investor ID: ${kycLog.investor.id} | KYC log ID: ${kycLog.id}`}
                    />
                    <div className={styles.type}>
                        <h2>Type</h2>
                        {getType(kycLog.type)}
                    </div>
                    <div className={styles.type}>
                        <h2>Classification</h2>
                        {getClassification(kycLog.classification)}
                    </div>
                    <div className={styles.created}>
                        <h2>Created</h2>
                        {formatDatetime(kycLog.created)}
                    </div>
                    <div className={styles.files}>
                        <h2>Files</h2>
                        <ul>
                            {kycLog.files.map((file, index) => {
                                const splits = file.path.split("/")
                                return (
                                    <li key={index}>
                                        <a
                                            href={fileDownloadLinks.find(l => file.id === l.fileId)!.link}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {splits[splits.length - 1]}
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className={styles.investor}>
                        <h2>Investor</h2>
                        <div className={styles.investorDetails}>
                            <div>
                                {kycLog.investor.person?.fullName}<br/>
                                {address?.line1}<br/>
                                {address?.zipOrPostalCode} {address?.city} {address?.country}<br/>
                                {kycLog.investor.person?.contact.email}
                            </div>
                        </div>
                        <Link to={INVESTOR(kycLog.investor.id)}>
                            &rarr; Go to investor
                        </Link>
                    </div>
                    <div className={styles.review}>
                        <h2>Review</h2>
                        <KycReviewForm
                            kycLogType={kycLog.type}
                            onSubmit={submitReview}
                            submitText="Submit review"
                        />
                    </div>
                </Section>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="An error occurred. Please try again or contact the tech team."
                />
            )}
        </>
    )
}

export default OpenKycReview

function getType(type: KycLogTypeOpenApi): string {
    switch (type) {
        case "PASSPORT_UPLOADED":
            return "Passport"
        case "PROOF_OF_ADDRESS_UPLOADED":
            return "Proof of address"
        case "PROOF_OF_AI_STATUS_UPLOADED":
            return "Proof of accredited investor (AI) status"
        default:
            throw new Error()
    }
}

function getClassification(classification: KycLogClassificationOpenApi): string {
    switch (classification) {
        case "DOCU_SIGN_IDENTITY_VERIFICATION":
            return "Received from DocuSign (Identity Verification)"
        case "USER_UPLOAD":
            return "User upload"
        default:
            throw new Error()
    }
}