import {AsynchronousActionStateOpenApi, AsynchronousActionTypeOpenApi} from "../../../../../generated"
import {AsynchronousActionType} from "../../action.type"

export class WallexCollectionSynchronizationAsynchronousActionType implements AsynchronousActionType {
    public id: string
    public created: Date
    public modified: Date
    public state: AsynchronousActionStateOpenApi
    public message?: string
    public processed?: Date
    public wallexCollectionId: string

    constructor({
        id,
        created,
        modified,
        state,
        message,
        processed,
        wallexCollectionId
    } : {
        id: string,
        created: Date,
        modified: Date,
        state: AsynchronousActionStateOpenApi,
        message?: string,
        processed?: Date,
        wallexCollectionId: string
    }) {
        this.id = id
        this.created = created
        this.modified = modified
        this.state = state
        this.message = message
        this.processed = processed
        this.wallexCollectionId = wallexCollectionId
    }

    get actionType(): AsynchronousActionTypeOpenApi {
        return AsynchronousActionTypeOpenApi.WallexCollectionSynchronization
    }

    get details(): { title: string, content: string }[] {
        return [{
            title: "Wallex Collection ID",
            content: this.wallexCollectionId
        }]
    }

}