import React from "react"
import {useSearchParams} from "react-router-dom"
import ValuationOverviewDeals from "./overview-deals.component"
import ValuationsForSingleDeal from "../single-deal/single-deal.component"

const ValuationOverview = () => {
    const [searchParams] = useSearchParams()

    const dealId = searchParams.get("dealId")

    return dealId
        ? <ValuationsForSingleDeal dealId={dealId}/>
        : <ValuationOverviewDeals/>
}

export default ValuationOverview