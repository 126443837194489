import { DealGroupOpenApi } from "../../generated"
import {DealGroupType} from "./deal-group.type"

export function mapOpenApiToDealGroup(group: DealGroupOpenApi): DealGroupType {
    return {
        id: group.id,
        sequentialId: group.sequentialId,
        name: group.name,
        description: group.description
    }
}

export function mapDealGroupToOpenApi(group: DealGroupType): DealGroupOpenApi {
    return {
        id: group.id,
        sequentialId: group.sequentialId,
        name: group.name,
        description: group.description
    }
}