import {ActionsFilterType, ActionsFilterTypeAndValueType} from "../../../tech/actions/filter/filter.type"
import {getFilter, getFilterValue} from "../../../tech/actions/filter/filter.util"
import {ManualReconciliationLogFiltersOpenApi} from "../../../generated"

export type ManualReconciliationLogFilterType = "ID" | "INVESTMENT_ID" | "BANK_ACCOUNT_ID"

export function assembleFilterOptions(): ActionsFilterType<ManualReconciliationLogFilterType>[] {
    return [
        {
            type: "ID",
            label: "ID",
            value: "STRING"
        },
        {
            type: "INVESTMENT_ID",
            label: "Investment ID",
            value: "STRING"
        },
        {
            type: "BANK_ACCOUNT_ID",
            label: "Bank Account ID",
            value: "STRING"
        },
    ]
}

export function getCurrentFilter(
    filters: ActionsFilterType<ManualReconciliationLogFilterType>[],
    searchParams: URLSearchParams
): ActionsFilterTypeAndValueType<ManualReconciliationLogFilterType> | undefined {
    const foundFilter = filters.find(f => f.type === getFilter(searchParams))
    const foundFilterValue = getFilterValue(searchParams)
    if (foundFilter && foundFilterValue) {
        return {
            type: foundFilter.type,
            value: foundFilterValue
        }
    }
    return undefined
}

export function mapToManualReconciliationLogFiltersOpenApi(filter: ActionsFilterTypeAndValueType<ManualReconciliationLogFilterType> | undefined): ManualReconciliationLogFiltersOpenApi | undefined {
    if (filter === undefined) return undefined

    switch (filter.type) {
        case "ID":
            return { id: filter.value }
        case "INVESTMENT_ID":
            return { investmentId: filter.value }
        case "BANK_ACCOUNT_ID":
            return { bankAccountId: filter.value }
    }
}