import {AsynchronousActionOverviewOpenApi} from "../../../../generated"
import {AsynchronousActionOverviewType} from "./overview.type"
import {mapOpenApiToStripeEventAsynchronousAction} from "../stripe/event/action.mapper"
import {mapOpenApiToStripeCustomerUpdateAsynchronousAction} from "../stripe/customer/action.mapper"
import {mapOpenApiToWallexCollectionAccountCreationAsynchronousAction} from "../wallex/collection/account/action.mapper"
import {mapOpenApiToWallexCollectionSynchronizationAsynchronousAction} from "../wallex/collection/action.mapper"
import {mapOpenApiToInvestmentDocumentSynchronizationAsynchronousAction} from "../investment/document/action.mapper"
import {mapOpenApiToNotificationAsynchronousAction} from "../notification/action.mapper"

export function mapOpenApiToAsynchronousActionOverview(action: AsynchronousActionOverviewOpenApi): AsynchronousActionOverviewType {
    return {
        investmentDocumentSynchronization: action.investmentDocumentSynchronization.map(mapOpenApiToInvestmentDocumentSynchronizationAsynchronousAction),
        notification: action.notification.map(mapOpenApiToNotificationAsynchronousAction),
        stripeCustomerUpdate: action.stripeCustomerUpdate.map(mapOpenApiToStripeCustomerUpdateAsynchronousAction),
        stripeEvent: action.stripeEvent.map(mapOpenApiToStripeEventAsynchronousAction),
        wallexCollectionAccountCreation: action.wallexCollectionAccountCreation.map(mapOpenApiToWallexCollectionAccountCreationAsynchronousAction),
        wallexCollectionSynchronization: action.wallexCollectionSynchronization.map(mapOpenApiToWallexCollectionSynchronizationAsynchronousAction)
    }
}