import {AsynchronousActionStateOpenApi, AsynchronousActionTypeOpenApi} from "../../../../../generated"
import {AsynchronousActionType} from "../../action.type"
import {InvestorType} from "../../../../../domain/investor/investor.type"
import {INVESTOR} from "../../../../../paths"

export class StripeCustomerUpdateAsynchronousActionType implements AsynchronousActionType {
    public id: string
    public created: Date
    public modified: Date
    public state: AsynchronousActionStateOpenApi
    public message?: string
    public processed?: Date
    public investor: InvestorType

    constructor({
        id,
        created,
        modified,
        state,
        message,
        processed,
        investor
    } : {
        id: string,
        created: Date,
        modified: Date,
        state: AsynchronousActionStateOpenApi,
        message?: string,
        processed?: Date,
        investor: InvestorType
    }) {
        this.id = id
        this.created = created
        this.modified = modified
        this.state = state
        this.message = message
        this.processed = processed
        this.investor = investor
    }

    get actionType(): AsynchronousActionTypeOpenApi {
        return AsynchronousActionTypeOpenApi.StripeCustomerUpdate
    }

    get details(): { title: string, content: string }[] {
        return [{
            title: "Investor ID",
            content: this.investor.id
        }, {
            title: "Investor",
            content: this.investor.person!.fullName
        }]
    }

    getLink(): { title: string, link: string } {
        return {
            title: "Go to Investor",
            link: INVESTOR(this.investor.id)
        }
    }

}