import React, {FunctionComponent} from "react"
import tableStyles from "../../../tech/table/table.module.css"
import {CollectionDeviationType, mapCollectionDeviationTypeToString} from "./collection-deviation.type"
import {formatAmountWithCurrency, formatDate} from "../../../tech/format/format.util"
import CollectionAmount from "./collection-amount.component"

type CollectionDeviationsTableProps = {
    collectionDeviations: CollectionDeviationType[]
}

const CollectionDeviationsTable: FunctionComponent<CollectionDeviationsTableProps> = ({ collectionDeviations }) => {
    if (collectionDeviations.length === 0) {
        return <>No collection deviations available yet.</>
    }

    return (
        <div className={tableStyles.tableWrapper}>
            <table className={tableStyles.table}>
                <thead>
                <tr>
                    <th>Id</th>
                    <th>Created</th>
                    <th>Modified</th>
                    <th>Type</th>
                    <th>State</th>
                    <th>Collection</th>
                    <th>Overall amount</th>
                </tr>
                </thead>
                <tbody>
                {collectionDeviations.map((collectionDeviation, i) => (
                    <tr key={i}>
                        <td>
                            {collectionDeviation.id}
                        </td>
                        <td>
                            {formatDate(collectionDeviation.created)}
                        </td>
                        <td>
                            {formatDate(collectionDeviation.modified)}
                        </td>
                        <td>
                            {mapCollectionDeviationTypeToString(collectionDeviation.type)}
                        </td>
                        <td>
                            {collectionDeviation.state}
                        </td>
                        <td>
                            {collectionDeviation.collections.length > 0 && (
                                <ul>
                                    {collectionDeviation.collections.map(c => (
                                        <li key={c.id}>
                                            <strong>Collection ID:</strong> {c.id}<br/>
                                            <strong>Amount (origin):</strong> {formatAmountWithCurrency(c.amount.origin, c.currency)}<br/>
                                            <strong>Amount (actual):</strong> {formatAmountWithCurrency(c.amount.actual, c.currency)}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {collectionDeviation.manualReconciliationLogs.length > 0 && (
                                <ul>
                                    {collectionDeviation.manualReconciliationLogs.map(l => (
                                        <li key={l.id}>
                                            <strong>Manual Reconciliation Log ID:</strong> {l.id}<br/>
                                            <strong>Amount:</strong> {formatAmountWithCurrency(l.amount, l.investment.deal.currency)}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </td>
                        <td>
                            {collectionDeviation.collections.length > 0 && (
                                <CollectionAmount collections={collectionDeviation.collections}/>
                            )}
                            {collectionDeviation.manualReconciliationLogs.length > 0 && (
                                <>
                                    {formatAmountWithCurrency(
                                        collectionDeviation.manualReconciliationLogs.reduce((partialSum, l) => partialSum + l.amount, 0),
                                        collectionDeviation.investment.deal.currency
                                    )}
                                </>
                            )}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default CollectionDeviationsTable