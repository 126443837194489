/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InvestorOnboardingStageTypeOpenApi = {
    AccreditedInvestorStatusToBeDeclared: 'ACCREDITED_INVESTOR_STATUS_TO_BE_DECLARED',
    GeneralData: 'GENERAL_DATA',
    KycDocumentUploadNecessary: 'KYC_DOCUMENT_UPLOAD_NECESSARY',
    KycWaitingForReview: 'KYC_WAITING_FOR_REVIEW',
    AcknowledgeSuccess: 'ACKNOWLEDGE_SUCCESS',
    None: 'NONE'
} as const;
export type InvestorOnboardingStageTypeOpenApi = typeof InvestorOnboardingStageTypeOpenApi[keyof typeof InvestorOnboardingStageTypeOpenApi];


export function InvestorOnboardingStageTypeOpenApiFromJSON(json: any): InvestorOnboardingStageTypeOpenApi {
    return InvestorOnboardingStageTypeOpenApiFromJSONTyped(json, false);
}

export function InvestorOnboardingStageTypeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorOnboardingStageTypeOpenApi {
    return json as InvestorOnboardingStageTypeOpenApi;
}

export function InvestorOnboardingStageTypeOpenApiToJSON(value?: InvestorOnboardingStageTypeOpenApi | null): any {
    return value as any;
}

