/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UpdateFiltersOpenApi,
  UpdateOpenApi,
  UpdatePaginatedResponseOpenApi,
  UpdateSortOpenApi,
  ValidationResponseOpenApi,
} from '../models';
import {
    UpdateFiltersOpenApiFromJSON,
    UpdateFiltersOpenApiToJSON,
    UpdateOpenApiFromJSON,
    UpdateOpenApiToJSON,
    UpdatePaginatedResponseOpenApiFromJSON,
    UpdatePaginatedResponseOpenApiToJSON,
    UpdateSortOpenApiFromJSON,
    UpdateSortOpenApiToJSON,
    ValidationResponseOpenApiFromJSON,
    ValidationResponseOpenApiToJSON,
} from '../models';

export interface UpdateCreateRequest {
    updateOpenApi?: UpdateOpenApi;
}

export interface UpdateGetRequest {
    id: string;
}

export interface UpdateGetAllPaginatedRequest {
    elementsPerPage?: number;
    page?: number;
    sort?: UpdateSortOpenApi;
    updateFiltersOpenApi?: UpdateFiltersOpenApi;
}

export interface UpdateUpdateRequest {
    id: string;
    updateOpenApi?: UpdateOpenApi;
}

/**
 * 
 */
export class UpdateApiClient extends runtime.BaseAPI {

    /**
     * Create new update
     */
    async updateCreateRaw(requestParameters: UpdateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOpenApiToJSON(requestParameters.updateOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateOpenApiFromJSON(jsonValue));
    }

    /**
     * Create new update
     */
    async updateCreate(requestParameters: UpdateCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateOpenApi> {
        const response = await this.updateCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get update by id
     */
    async updateGetRaw(requestParameters: UpdateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateOpenApiFromJSON(jsonValue));
    }

    /**
     * Get update by id
     */
    async updateGet(requestParameters: UpdateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateOpenApi> {
        const response = await this.updateGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all updates (paginated)
     */
    async updateGetAllPaginatedRaw(requestParameters: UpdateGetAllPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdatePaginatedResponseOpenApi>> {
        const queryParameters: any = {};

        if (requestParameters.elementsPerPage !== undefined) {
            queryParameters['elementsPerPage'] = requestParameters.elementsPerPage;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/update/paginated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFiltersOpenApiToJSON(requestParameters.updateFiltersOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdatePaginatedResponseOpenApiFromJSON(jsonValue));
    }

    /**
     * Get all updates (paginated)
     */
    async updateGetAllPaginated(requestParameters: UpdateGetAllPaginatedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdatePaginatedResponseOpenApi> {
        const response = await this.updateGetAllPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Update
     */
    async updateUpdateRaw(requestParameters: UpdateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOpenApiToJSON(requestParameters.updateOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateOpenApiFromJSON(jsonValue));
    }

    /**
     * Update Update
     */
    async updateUpdate(requestParameters: UpdateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateOpenApi> {
        const response = await this.updateUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
