import {FunctionComponent, useContext, useEffect, useState} from "react"
import {NavLink} from "react-router-dom"
import {
    ASYNCHRONOUS_ACTION_OVERVIEW,
    COLLECTION_DEVIATIONS,
    COMPANIES,
    COMPANY_CONTACTS,
    DASHBOARD,
    DEAL_CATEGORIES,
    DEAL_GEOGRAPHIES,
    DEAL_GROUPS,
    DEALS,
    FAQ_ENTRIES,
    FILES,
    INVESTOR_GROUPS,
    INVESTORS,
    KYC_REVIEWS,
    MANUAL_RECONCILIATION_BANK_ACCOUNTS,
    MANUAL_RECONCILIATION_LOGS,
    SINGLE_PURPOSE_VEHICLES,
    SYNDICATES, UPDATES,
    USERS,
    VALUATIONS,
    WAITLIST
} from "../../paths"
import SidebarLink from "./sidebar-link.component"
import styles from "./sidebar.module.css"
import {XMarkIcon} from "@heroicons/react/20/solid"
import {CollectionDeviationStateOpenApi} from "../../generated"
import {FetchClient} from "../fetch/client"
import FetchContext from "../fetch/fetch.context"
import {AsynchronousActionOverviewType} from "../asynchronous/action/overview/overview.type"
import {CollectionDeviationType} from "../../domain/collection/deviation/collection-deviation.type"
import {KycLogType} from "../../domain/kyc/log/kyc-log.type"
import SidebarAlertBadge from "./alert/badge.component"
import {countErrors} from "../asynchronous/action/overview/overview.util"

type SidebarProps = {
    hidden: boolean
    toggle: () => void
}

const Sidebar: FunctionComponent<SidebarProps> = ({ hidden, toggle }) => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [actionOverview, setActionOverview] = useState<AsynchronousActionOverviewType>()
    const [openCollectionDeviations, setOpenCollectionDeviations] = useState<CollectionDeviationType[]>([])
    const [openKycReviews, setOpenKycReviews] = useState<KycLogType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const [fetchedActionOverview, fetchedOpenCollectionDeviations, fetchedOpenKycReviews] = await Promise.all([
                    fetchClient.actionApi.getOverview(),
                    fetchClient.collectionApi.getAllCollectionDeviations(undefined, CollectionDeviationStateOpenApi.Open),
                    fetchClient.kycApi.getAllKycLogsToBeReviewed()
                ])
                setActionOverview(fetchedActionOverview)
                setOpenCollectionDeviations(fetchedOpenCollectionDeviations)
                setOpenKycReviews(fetchedOpenKycReviews)
            } catch (err) {
                console.error(err)
            }
        }
        fetch()
    }, [fetchClient])

    const asynchronousErrorCount = actionOverview && countErrors(actionOverview)

    return (
        <nav
            className={styles.sidebar}
            style={hidden ? { display: "none" } : {}}
        >
            <div className={styles.searchAndToggle}>
                <div className={styles.sidebarToggle}>
                    <XMarkIcon
                        className="w-10 h-10 text-teal-400"
                        onClick={toggle}
                    />
                </div>
            </div>
            <NavLink to={DASHBOARD}>
                <div className={styles.logo}>
                    <img src="/nonpublic-logo.svg" alt="NonPublic" />
                    <div className={styles.title}>
                        Backoffice
                    </div>
                </div>
            </NavLink>
            <SidebarLink
                path={DASHBOARD}
                title="Dashboard"
                toggleSidebar={toggle}
            />
            <SidebarLink
                path={ASYNCHRONOUS_ACTION_OVERVIEW}
                title="Async. Action Errors"
                toggleSidebar={toggle}
                badge={<SidebarAlertBadge count={asynchronousErrorCount}/>}
            />
            <SidebarLink
                path={rs(COLLECTION_DEVIATIONS + "?state=OPEN")}
                title="Collection deviations"
                toggleSidebar={toggle}
                badge={<SidebarAlertBadge count={openCollectionDeviations.length}/>}
            />
            <SidebarLink
                path={rs(COMPANIES)}
                title="Companies"
                toggleSidebar={toggle}
            />
            <SidebarLink
                path={rs(COMPANY_CONTACTS)}
                title="Company Contacts"
                toggleSidebar={toggle}
            />
            <SidebarLink
                path={rs(DEALS)}
                title="Deals"
                toggleSidebar={toggle}
            />
            <SidebarLink
                path={rs(DEAL_CATEGORIES)}
                title="Deal categories"
                toggleSidebar={toggle}
            />
            <SidebarLink
                path={rs(DEAL_GEOGRAPHIES)}
                title="Deal geographies"
                toggleSidebar={toggle}
            />
            <SidebarLink
                path={rs(DEAL_GROUPS)}
                title="Deal groups"
                toggleSidebar={toggle}
            />
            <SidebarLink
                path={rs(FAQ_ENTRIES)}
                title="FAQ Entries"
                toggleSidebar={toggle}
            />
            <SidebarLink
                path={rs(FILES)}
                title="Files"
                toggleSidebar={toggle}
            />
            <SidebarLink
                path={rs(INVESTORS)}
                title="Investors"
                toggleSidebar={toggle}
            />
            <SidebarLink
                path={rs(INVESTOR_GROUPS)}
                title="Investor groups"
                toggleSidebar={toggle}
            />
            <SidebarLink
                path={rs(KYC_REVIEWS)}
                title="Open KYC Reviews"
                toggleSidebar={toggle}
                badge={<SidebarAlertBadge count={openKycReviews.length}/>}
            />
            <SidebarLink
                path={rs(MANUAL_RECONCILIATION_LOGS)}
                title="Manual reconciliation"
                toggleSidebar={toggle}
            />
            <SidebarLink
                path={rs(MANUAL_RECONCILIATION_BANK_ACCOUNTS)}
                title="Manual reconciliation bank accounts"
                toggleSidebar={toggle}
            />
            <SidebarLink
                path={rs(SINGLE_PURPOSE_VEHICLES)}
                title="SPVs"
                toggleSidebar={toggle}
            />
            <SidebarLink
                path={rs(SYNDICATES)}
                title="Syndicates"
                toggleSidebar={toggle}
            />
            <SidebarLink
                path={rs(UPDATES)}
                title="Updates"
                toggleSidebar={toggle}
            />
            <SidebarLink
                path={rs(USERS)}
                title="Users"
                toggleSidebar={toggle}
            />
            <SidebarLink
                path={rs(VALUATIONS)}
                title="Valuations"
                toggleSidebar={toggle}
            />
            <SidebarLink
                path={rs(WAITLIST)}
                title="Waitlist"
                toggleSidebar={toggle}
            />
        </nav>
    )
}

export default Sidebar


function rs(path: string): string {
    return path.replace(/^\/|\/$/g, '')
}