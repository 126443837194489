import {AlertType} from "../alert/type.enum"
import React, {FunctionComponent, ReactNode} from "react"
import Alert from "../alert/alert.component"
import styles from "./alert.module.sass"

type BackendValidationErrorsAlertProps = {
    text?: string
    errors: string[]
}

const BackendValidationErrorsAlert: FunctionComponent<BackendValidationErrorsAlertProps> = ({
    text,
    errors
}) => {
    return (
        <Alert
            type={AlertType.ERROR}
            text={getText(text, errors)}
        />
    )
}

export default BackendValidationErrorsAlert

function getText(text: string | undefined, errors: string[]): string | ReactNode {
    if (errors.length < 1) {
        return text || "Backend validation failed."
    }
    return (
        <>
            <strong>{text || "Backend validation failed:"}</strong>
            <ul className={styles.list}>
                {errors.map((err, index) => (
                    <li key={`validation-error-{index}`}>{err}</li>
                ))}
            </ul>
        </>
    )
}