import React, {useContext, useEffect, useState} from "react"
import {SubmitHandler} from "react-hook-form"
import {useNavigate} from "react-router-dom"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import ManualReconciliationBankAccountForm from "./form.component"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import {MANUAL_RECONCILIATION_BANK_ACCOUNT} from "../../../paths"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import {normalizeValues} from "../../../tech/form/form.util"
import {ManualReconciliationBankAccountWithDealsType} from "./manual-reconciliation-bank-account.type"
import {SinglePurposeVehicleType} from "../../single-purpose-vehicle/single-purpose-vehicle.type"
import {SinglePurposeVehicleSortOpenApi} from "../../../generated"
import {match} from "ts-pattern"
import BackendValidationErrorsAlert from "../../../tech/validation/alert.component"

const ManualReconciliationBankAccountNew = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const navigate = useNavigate()
    const [state, setState] = useState<"NONE" | "LOADING" | "ERROR">("NONE")
    const [singlePurposeVehicles, setSinglePurposeVehicles] = useState<SinglePurposeVehicleType[]>([])
    const [errors, setErrors] = useState<string[]>([])

    useEffect(() => {
        const fetch = async () => {
            const res = await fetchClient.singlePurposeVehicleApi.getAllPaginated(SinglePurposeVehicleSortOpenApi.NameAscending)
            setSinglePurposeVehicles(res.elements)
        }
        fetch()
    }, [fetchClient])

    const onSubmit: SubmitHandler<ManualReconciliationBankAccountWithDealsType> = async (account) => {
        setState("LOADING")
        const result = await fetchClient.reconciliationApi.createManualReconciliationBankAccount(normalizeValues(account))
        match(result)
            .with(
                { type: "RESPONSE" },
                (res) => {
                    navigate(MANUAL_RECONCILIATION_BANK_ACCOUNT(res.val!.id!))
                }
            )
            .with(
                { type: "RESPONSE_ERROR" },
                () => {
                    window.scrollTo({ top: 0 })
                    setState("ERROR")
                }
            )
            .with(
                { type: "VALIDATION_ERRORS" },
                (res) => {
                    window.scrollTo({ top: 0 })
                    setState("ERROR")
                    setErrors(res.errors)
                }
            )
            .exhaustive()
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <>
                    {errors && errors.length > 0 ? (
                        <BackendValidationErrorsAlert errors={errors}/>
                    ) : (
                        <Alert
                            type={AlertType.ERROR}
                            text="Failed to save manual reconciliation bank account."
                        />
                    )}
                </>
            )}
            {(state === "NONE" || state === "ERROR") && (
                <Section>
                    <SectionHeading title="Create new manual reconciliation bank account"/>
                    <ManualReconciliationBankAccountForm
                        singlePurposeVehicles={singlePurposeVehicles}
                        onSubmit={onSubmit}
                        submitText="Create Manual Reconciliation Bank Account"
                    />
                </Section>
            )}
        </>
    )
}

export default ManualReconciliationBankAccountNew