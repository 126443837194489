import {DealQuestionType} from "./deal-question.type"
import {FunctionComponent, useContext} from "react"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import styles from "./questions.module.css"

type DealQuestionsProps = {
    dealId: string
    question: DealQuestionType
    loading: boolean
    setLoading: () => void
}

const DealQuestionToggle: FunctionComponent<DealQuestionsProps> = ({ dealId, question, loading, setLoading }) => {
    const fetchClient = useContext<FetchClient>(FetchContext)

    const toggleResolveStatus = async () => {
        setLoading()
        await fetchClient.dealApi.toggleResolveStatusQuestion(dealId, question.id)
        window.location.reload()
    }

    return (
        <span
            className={styles.toggleResolve}
            onClick={toggleResolveStatus}
        >
            {" "}
            (<span>{question.resolved ? "re-open" : "resolve"}</span>)
        </span>
    )
}

export default DealQuestionToggle