import {ManualReconciliationLogSortOpenApi} from "../../../generated"
import {ActionsSortOptionType} from "../../../tech/actions/sort/sort.type"
import {getSort} from "../../../tech/actions/sort/sort.util"

export const DEFAULT_SORT = {
    id: ManualReconciliationLogSortOpenApi.ModifiedDescending,
    label: "Modified (DESC)"
}

export function assembleSortOptions(): ActionsSortOptionType<ManualReconciliationLogSortOpenApi>[] {
    return [
        {
            id: ManualReconciliationLogSortOpenApi.CreatedAscending,
            label: "Created (ASC)"
        },
        {
            id: ManualReconciliationLogSortOpenApi.CreatedDescending,
            label: "Created (DESC)"
        },
        {
            id: ManualReconciliationLogSortOpenApi.ModifiedAscending,
            label: "Modified (ASC)"
        },
        DEFAULT_SORT,
    ]
}

export function getCurrentSortOption(
    options: ActionsSortOptionType<ManualReconciliationLogSortOpenApi>[],
    searchParams: URLSearchParams
): ActionsSortOptionType<ManualReconciliationLogSortOpenApi> {
    return (options.find(o => o.id === getSort(searchParams)) || DEFAULT_SORT)
}