/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SyndicateInvitationCreationResponseStateOpenApi = {
    ExistingInvestorNeedsConfirmation: 'EXISTING_INVESTOR_NEEDS_CONFIRMATION',
    NewInvestorInvited: 'NEW_INVESTOR_INVITED'
} as const;
export type SyndicateInvitationCreationResponseStateOpenApi = typeof SyndicateInvitationCreationResponseStateOpenApi[keyof typeof SyndicateInvitationCreationResponseStateOpenApi];


export function SyndicateInvitationCreationResponseStateOpenApiFromJSON(json: any): SyndicateInvitationCreationResponseStateOpenApi {
    return SyndicateInvitationCreationResponseStateOpenApiFromJSONTyped(json, false);
}

export function SyndicateInvitationCreationResponseStateOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyndicateInvitationCreationResponseStateOpenApi {
    return json as SyndicateInvitationCreationResponseStateOpenApi;
}

export function SyndicateInvitationCreationResponseStateOpenApiToJSON(value?: SyndicateInvitationCreationResponseStateOpenApi | null): any {
    return value as any;
}

