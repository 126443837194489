import {StripeCustomerUpdateAsynchronousActionType} from "./action.type"
import {StripeCustomerUpdateAsynchronousActionOpenApi} from "../../../../../generated"
import {mapOpenApiToInvestor} from "../../../../../domain/investor/investor.mapper"

export function mapOpenApiToStripeCustomerUpdateAsynchronousAction(action: StripeCustomerUpdateAsynchronousActionOpenApi): StripeCustomerUpdateAsynchronousActionType {
    return new StripeCustomerUpdateAsynchronousActionType({
        id: action.id,
        created: action.created,
        modified: action.modified,
        state: action.state,
        message: action.message,
        processed: action.processed,
        investor: mapOpenApiToInvestor(action.investor)
    })
}