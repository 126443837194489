import React, {useContext, useEffect, useMemo, useState} from "react"
import {useNavigate, useSearchParams} from "react-router-dom"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {FILE, FILE_NEW} from "../../../paths"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import {FileType} from "../file.type"
import FileDownload from "../download/download.component"
import Actions from "../../../tech/actions/actions.component"
import {assembleSortOptions, getCurrentSortOption} from "./sort.util"
import {assembleFilterOptions, getCurrentFilter, mapToFileFiltersOpenApi} from "./filter.util"
import {emptyPaginated, getPagination} from "../../../tech/actions/pagination/pagination.util"
import {PaginatedType} from "../../../tech/actions/pagination/pagination.type"
import styles from "./overview.module.sass"
import FileCard from "./card/card.component"
import FileLink from "../link/link.component"

const FileOverview = () => {
    const FILES_PER_PAGE = 24
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [files, setFiles] = useState<PaginatedType<FileType>>(emptyPaginated())

    const sortOptions = assembleSortOptions()
    const currentSortOption = useMemo(
        () => getCurrentSortOption(sortOptions, searchParams),
        [sortOptions, searchParams]
    )

    const filters = useMemo(() => assembleFilterOptions(), [])
    const currentFilter = useMemo(
        () => getCurrentFilter(filters, searchParams),
        [filters, searchParams]
    )

    const pagination = useMemo(() => getPagination(FILES_PER_PAGE, searchParams), [searchParams])

    useEffect(() => {
        const fetch = async () => {
            try {
                setState("LOADING")
                setFiles(await fetchClient.fileApi.getAllPaginated(
                    currentSortOption.id,
                    mapToFileFiltersOpenApi(currentFilter),
                    pagination
                ))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, currentFilter, currentSortOption.id, pagination])

    return (
        <Section>
            <SectionHeading
                title="File Library"
                subTitle={`${files.total} files`}
                button={<Button
                    title="Upload File"
                    style={ButtonStyle.SECONDARY}
                    onClick={() => navigate(FILE_NEW)}
                    type="button"
                />}
            />
            <Actions
                filter={{
                    current: currentFilter,
                    filters
                }}
                pagination={{
                    label: "files",
                    elementsPerPage: FILES_PER_PAGE,
                    numberElements: files.total
                }}
                sort={{
                    current: currentSortOption,
                    options: sortOptions
                }}
            >
                {state === "LOADING" && <LoadingDots/>}
                {state === "LOADED" && (
                    <div className={styles.files}>
                        {files.elements
                            .map((file, index) => (
                                <div
                                    className={styles.file}
                                    key={index}
                                >
                                    <div
                                        className={styles.card}
                                        onClick={() => navigate(FILE(file.id))}
                                    >
                                        <FileCard file={file}/>
                                    </div>
                                    <div className={`${styles.actions} ${file.source.type === "PUBLIC_S3_URL" ? styles.multiple : ""}`}>
                                        <div>
                                            <FileDownload file={file}/>
                                        </div>
                                        {file.source.type === "PUBLIC_S3_URL" && (
                                            <div>
                                                <FileLink file={file}/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                )}
                {state === "ERROR" && (
                    <Alert
                        type={AlertType.ERROR}
                        text="Failed to load files."
                    />
                )}
            </Actions>
        </Section>
    )
}

export default FileOverview