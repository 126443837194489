/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CollectionAmountOpenApi
 */
export interface CollectionAmountOpenApi {
    /**
     * 
     * @type {number}
     * @memberof CollectionAmountOpenApi
     */
    actual: number;
    /**
     * 
     * @type {number}
     * @memberof CollectionAmountOpenApi
     */
    origin: number;
}

/**
 * Check if a given object implements the CollectionAmountOpenApi interface.
 */
export function instanceOfCollectionAmountOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "actual" in value;
    isInstance = isInstance && "origin" in value;

    return isInstance;
}

export function CollectionAmountOpenApiFromJSON(json: any): CollectionAmountOpenApi {
    return CollectionAmountOpenApiFromJSONTyped(json, false);
}

export function CollectionAmountOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectionAmountOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actual': json['actual'],
        'origin': json['origin'],
    };
}

export function CollectionAmountOpenApiToJSON(value?: CollectionAmountOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actual': value.actual,
        'origin': value.origin,
    };
}

