/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccountNumberOpenApi } from './AccountNumberOpenApi';
import {
    AccountNumberOpenApiFromJSON,
    AccountNumberOpenApiFromJSONTyped,
    AccountNumberOpenApiToJSON,
} from './AccountNumberOpenApi';
import type { BankOpenApi } from './BankOpenApi';
import {
    BankOpenApiFromJSON,
    BankOpenApiFromJSONTyped,
    BankOpenApiToJSON,
} from './BankOpenApi';
import type { CollectionPaymentTypeOpenApi } from './CollectionPaymentTypeOpenApi';
import {
    CollectionPaymentTypeOpenApiFromJSON,
    CollectionPaymentTypeOpenApiFromJSONTyped,
    CollectionPaymentTypeOpenApiToJSON,
} from './CollectionPaymentTypeOpenApi';
import type { CurrencyOpenApi } from './CurrencyOpenApi';
import {
    CurrencyOpenApiFromJSON,
    CurrencyOpenApiFromJSONTyped,
    CurrencyOpenApiToJSON,
} from './CurrencyOpenApi';
import type { IntermediaryBankOpenApi } from './IntermediaryBankOpenApi';
import {
    IntermediaryBankOpenApiFromJSON,
    IntermediaryBankOpenApiFromJSONTyped,
    IntermediaryBankOpenApiToJSON,
} from './IntermediaryBankOpenApi';
import type { RoutingOpenApi } from './RoutingOpenApi';
import {
    RoutingOpenApiFromJSON,
    RoutingOpenApiFromJSONTyped,
    RoutingOpenApiToJSON,
} from './RoutingOpenApi';

/**
 * 
 * @export
 * @interface CollectionAccountBankDetailsOpenApi
 */
export interface CollectionAccountBankDetailsOpenApi {
    /**
     * 
     * @type {string}
     * @memberof CollectionAccountBankDetailsOpenApi
     */
    id: string;
    /**
     * 
     * @type {AccountNumberOpenApi}
     * @memberof CollectionAccountBankDetailsOpenApi
     */
    accountNumber?: AccountNumberOpenApi;
    /**
     * 
     * @type {BankOpenApi}
     * @memberof CollectionAccountBankDetailsOpenApi
     */
    bank?: BankOpenApi;
    /**
     * 
     * @type {CurrencyOpenApi}
     * @memberof CollectionAccountBankDetailsOpenApi
     */
    currency: CurrencyOpenApi;
    /**
     * 
     * @type {string}
     * @memberof CollectionAccountBankDetailsOpenApi
     */
    holderName: string;
    /**
     * 
     * @type {IntermediaryBankOpenApi}
     * @memberof CollectionAccountBankDetailsOpenApi
     */
    intermediaryBank?: IntermediaryBankOpenApi;
    /**
     * 
     * @type {CollectionPaymentTypeOpenApi}
     * @memberof CollectionAccountBankDetailsOpenApi
     */
    paymentType: CollectionPaymentTypeOpenApi;
    /**
     * 
     * @type {RoutingOpenApi}
     * @memberof CollectionAccountBankDetailsOpenApi
     */
    routing?: RoutingOpenApi;
}

/**
 * Check if a given object implements the CollectionAccountBankDetailsOpenApi interface.
 */
export function instanceOfCollectionAccountBankDetailsOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "holderName" in value;
    isInstance = isInstance && "paymentType" in value;

    return isInstance;
}

export function CollectionAccountBankDetailsOpenApiFromJSON(json: any): CollectionAccountBankDetailsOpenApi {
    return CollectionAccountBankDetailsOpenApiFromJSONTyped(json, false);
}

export function CollectionAccountBankDetailsOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectionAccountBankDetailsOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'accountNumber': !exists(json, 'accountNumber') ? undefined : AccountNumberOpenApiFromJSON(json['accountNumber']),
        'bank': !exists(json, 'bank') ? undefined : BankOpenApiFromJSON(json['bank']),
        'currency': CurrencyOpenApiFromJSON(json['currency']),
        'holderName': json['holderName'],
        'intermediaryBank': !exists(json, 'intermediaryBank') ? undefined : IntermediaryBankOpenApiFromJSON(json['intermediaryBank']),
        'paymentType': CollectionPaymentTypeOpenApiFromJSON(json['paymentType']),
        'routing': !exists(json, 'routing') ? undefined : RoutingOpenApiFromJSON(json['routing']),
    };
}

export function CollectionAccountBankDetailsOpenApiToJSON(value?: CollectionAccountBankDetailsOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'accountNumber': AccountNumberOpenApiToJSON(value.accountNumber),
        'bank': BankOpenApiToJSON(value.bank),
        'currency': CurrencyOpenApiToJSON(value.currency),
        'holderName': value.holderName,
        'intermediaryBank': IntermediaryBankOpenApiToJSON(value.intermediaryBank),
        'paymentType': CollectionPaymentTypeOpenApiToJSON(value.paymentType),
        'routing': RoutingOpenApiToJSON(value.routing),
    };
}

