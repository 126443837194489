/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AsynchronousActionStateOpenApi = {
    Cancellation: 'CANCELLATION',
    Error: 'ERROR',
    Retry: 'RETRY',
    Success: 'SUCCESS',
    Unprocessed: 'UNPROCESSED'
} as const;
export type AsynchronousActionStateOpenApi = typeof AsynchronousActionStateOpenApi[keyof typeof AsynchronousActionStateOpenApi];


export function AsynchronousActionStateOpenApiFromJSON(json: any): AsynchronousActionStateOpenApi {
    return AsynchronousActionStateOpenApiFromJSONTyped(json, false);
}

export function AsynchronousActionStateOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): AsynchronousActionStateOpenApi {
    return json as AsynchronousActionStateOpenApi;
}

export function AsynchronousActionStateOpenApiToJSON(value?: AsynchronousActionStateOpenApi | null): any {
    return value as any;
}

