/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InvestorGroupOpenApi,
  ValidationResponseOpenApi,
} from '../models';
import {
    InvestorGroupOpenApiFromJSON,
    InvestorGroupOpenApiToJSON,
    ValidationResponseOpenApiFromJSON,
    ValidationResponseOpenApiToJSON,
} from '../models';

export interface InvestorGroupCreateRequest {
    investorGroupOpenApi?: InvestorGroupOpenApi;
}

export interface InvestorGroupDeleteRequest {
    id: string;
}

export interface InvestorGroupGetRequest {
    id: string;
}

export interface InvestorGroupUpdateRequest {
    id: string;
    investorGroupOpenApi?: InvestorGroupOpenApi;
}

/**
 * 
 */
export class InvestorGroupApiClient extends runtime.BaseAPI {

    /**
     * Create new investor group
     */
    async investorGroupCreateRaw(requestParameters: InvestorGroupCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestorGroupOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor-group`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvestorGroupOpenApiToJSON(requestParameters.investorGroupOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorGroupOpenApiFromJSON(jsonValue));
    }

    /**
     * Create new investor group
     */
    async investorGroupCreate(requestParameters: InvestorGroupCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestorGroupOpenApi> {
        const response = await this.investorGroupCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete investor group by id
     */
    async investorGroupDeleteRaw(requestParameters: InvestorGroupDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investorGroupDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor-group/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete investor group by id
     */
    async investorGroupDelete(requestParameters: InvestorGroupDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.investorGroupDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get investor group by id
     */
    async investorGroupGetRaw(requestParameters: InvestorGroupGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestorGroupOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investorGroupGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor-group/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorGroupOpenApiFromJSON(jsonValue));
    }

    /**
     * Get investor group by id
     */
    async investorGroupGet(requestParameters: InvestorGroupGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestorGroupOpenApi> {
        const response = await this.investorGroupGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all investor groups
     */
    async investorGroupGetAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InvestorGroupOpenApi>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor-group`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvestorGroupOpenApiFromJSON));
    }

    /**
     * Get all investor groups
     */
    async investorGroupGetAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InvestorGroupOpenApi>> {
        const response = await this.investorGroupGetAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update investor group
     */
    async investorGroupUpdateRaw(requestParameters: InvestorGroupUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestorGroupOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investorGroupUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor-group/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InvestorGroupOpenApiToJSON(requestParameters.investorGroupOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorGroupOpenApiFromJSON(jsonValue));
    }

    /**
     * Update investor group
     */
    async investorGroupUpdate(requestParameters: InvestorGroupUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestorGroupOpenApi> {
        const response = await this.investorGroupUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
