import {
    CompanyApiClient,
    CompanyContactFiltersOpenApi,
    CompanyContactOpenApi,
    CompanyContactSortOpenApi
} from "../../../generated"
import {PaginatedType, PaginationType} from "../../../tech/actions/pagination/pagination.type"
import {CompanyContactType} from "./contact.type"
import {mapCompanyContactToOpenApi, mapOpenApiToCompanyContact} from "./contact.mapper"

export class CompanyContactApi {

    constructor(private apiClient: CompanyApiClient) {
    }

    public async create(contact: CompanyContactType): Promise<CompanyContactType> {
        const savedContact = await this.apiClient.companyContactCreate({ companyContactOpenApi: mapCompanyContactToOpenApi(contact) })
        return mapOpenApiToCompanyContact(savedContact)
    }

    public async delete(id: string): Promise<void> {
        await this.apiClient.companyContactDelete({ id })
    }

    public async get(id: string): Promise<CompanyContactType> {
        const contact = await this.apiClient.companyContactGet({ id })
        return mapOpenApiToCompanyContact(contact)
    }

    public async getAll(): Promise<CompanyContactType[]> {
        return (await this.apiClient.companyContactGetAll())
            .map((contact: CompanyContactOpenApi) => mapOpenApiToCompanyContact(contact))
    }

    public async getAllPaginated(
        sort: CompanyContactSortOpenApi,
        filters?: CompanyContactFiltersOpenApi,
        pagination?: PaginationType
    ): Promise<PaginatedType<CompanyContactType>> {
        const result = await this.apiClient.companyContactGetAllPaginated({
            companyContactFiltersOpenApi: filters,
            elementsPerPage: pagination?.elementsPerPage,
            page: pagination?.page,
            sort
        })
        return {
            elements: result.contacts.map((contact: CompanyContactOpenApi) => mapOpenApiToCompanyContact(contact)),
            total: result.total
        }
    }

    public async update(id: string, contact: CompanyContactType): Promise<CompanyContactType> {
        const response = await this.apiClient.companyContactUpdate({ id, companyContactOpenApi: mapCompanyContactToOpenApi(contact) })
        return mapOpenApiToCompanyContact(response)
    }

}