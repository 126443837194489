/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  JavaScriptErrorOpenApi,
  JavaScriptRequestErrorOpenApi,
} from '../models';
import {
    JavaScriptErrorOpenApiFromJSON,
    JavaScriptErrorOpenApiToJSON,
    JavaScriptRequestErrorOpenApiFromJSON,
    JavaScriptRequestErrorOpenApiToJSON,
} from '../models';

export interface LogBackofficeErrorRequest {
    javaScriptErrorOpenApi?: JavaScriptErrorOpenApi;
}

export interface LogBackofficeRequestErrorRequest {
    javaScriptRequestErrorOpenApi?: JavaScriptRequestErrorOpenApi;
}

export interface LogFrontendErrorRequest {
    javaScriptErrorOpenApi?: JavaScriptErrorOpenApi;
}

export interface LogFrontendRequestErrorRequest {
    javaScriptRequestErrorOpenApi?: JavaScriptRequestErrorOpenApi;
}

/**
 * 
 */
export class LoggingApiClient extends runtime.BaseAPI {

    /**
     * Log backoffice error
     */
    async logBackofficeErrorRaw(requestParameters: LogBackofficeErrorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/logging/backoffice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JavaScriptErrorOpenApiToJSON(requestParameters.javaScriptErrorOpenApi),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Log backoffice error
     */
    async logBackofficeError(requestParameters: LogBackofficeErrorRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logBackofficeErrorRaw(requestParameters, initOverrides);
    }

    /**
     * Log backoffice request error
     */
    async logBackofficeRequestErrorRaw(requestParameters: LogBackofficeRequestErrorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/logging/backoffice/request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JavaScriptRequestErrorOpenApiToJSON(requestParameters.javaScriptRequestErrorOpenApi),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Log backoffice request error
     */
    async logBackofficeRequestError(requestParameters: LogBackofficeRequestErrorRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logBackofficeRequestErrorRaw(requestParameters, initOverrides);
    }

    /**
     * Log frontend error
     */
    async logFrontendErrorRaw(requestParameters: LogFrontendErrorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/logging/frontend`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JavaScriptErrorOpenApiToJSON(requestParameters.javaScriptErrorOpenApi),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Log frontend error
     */
    async logFrontendError(requestParameters: LogFrontendErrorRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logFrontendErrorRaw(requestParameters, initOverrides);
    }

    /**
     * Log frontend request error
     */
    async logFrontendRequestErrorRaw(requestParameters: LogFrontendRequestErrorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/logging/frontend/request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JavaScriptRequestErrorOpenApiToJSON(requestParameters.javaScriptRequestErrorOpenApi),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Log frontend request error
     */
    async logFrontendRequestError(requestParameters: LogFrontendRequestErrorRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logFrontendRequestErrorRaw(requestParameters, initOverrides);
    }

}
