import React, {FunctionComponent, ReactNode} from "react"
import styles from "./section.module.sass"

type SectionHeadingProps = {
    title: string
    subTitle?: string
    button?: ReactNode
}

const SectionHeading: FunctionComponent<SectionHeadingProps> = ({title, subTitle, button}) => {
    return (
        <div className={styles.sectionHeading}>
            <div className={styles.sectionHeadingLeft}>
                <h3>{title}</h3>
                {subTitle && <h4>{subTitle}</h4>}
            </div>
            {button && (
                <div className={styles.sectionHeadingRight}>
                    {button}
                </div>
            )}
        </div>
    )
}

export default SectionHeading