import React, {ChangeEvent, useEffect, useRef, useState} from "react"
import {useSearchParams} from "react-router-dom"
import {FunnelIcon} from "@heroicons/react/24/outline"
import {ActionsFilterType, ActionsFilterTypeAndValueType, ActionsFilterValueEnumType} from "./filter.type"
import {removeFilter, removeFilterValue, setFilter, setFilterValue} from "./filter.util"
import styles from "./filter.module.sass"

export type ActionsFilterProps<T> = {
    current?: ActionsFilterTypeAndValueType<T>
    filters: ActionsFilterType<T>[]
}

function ActionsFilter<T>({ current, filters }: ActionsFilterProps<T>) {
    const valueSelectRef = useRef<HTMLSelectElement>(null)
    const valueTextRef = useRef<HTMLInputElement>(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const [
        filterType,
        setFilterType
    ] = useState<ActionsFilterType<T> | undefined>(findFilterType(filters, current))


    useEffect(() => {
        setFilterType(findFilterType(filters, current))
    }, [current, filters])

    if (filters.length < 1) {
        return <></>
    }

    const updateFilterType = (e: ChangeEvent<HTMLSelectElement>) => {
        const foundFilterType = filters.find(f => `${f.type}` === e.target.value)
        setFilterType(filters.find(f => `${f.type}` === e.target.value))
        if (foundFilterType === undefined) {
            onFilter()
        }
    }

    const onFilter = () => {
        let newFilterValue
        if (isBooleanValue(filterType) || isEnumValue(filterType)) {
            const val = valueSelectRef.current?.value
            newFilterValue = val && val !== "-" ? val : undefined
        }
        if (isStringValue(filterType)) {
            newFilterValue = valueTextRef.current?.value
        }
        if (filterType && newFilterValue && newFilterValue !== "") {
            setSearchParams(setFilterValue(newFilterValue, setFilter(`${filterType.type}`, searchParams)))
        }
        else {
            setSearchParams(removeFilter(removeFilterValue(searchParams)))
        }
    }

    return (
        <div className={styles.filter}>
            <div className={styles.icon}>
                <div>
                    <FunnelIcon/>
                </div>
            </div>
            <div>
                <div>
                    <select
                        value={`${filterType?.type}`}
                        onChange={(e) => updateFilterType(e)}
                    >
                        <option
                            value={undefined}
                            key={`filter-option-NONE`}
                        >
                            -
                        </option>
                        {filters.map(filter => (
                            <option
                                value={`${filter.type}`}
                                key={`filter-option-${filter.type}`}
                            >
                                {filter.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className={styles.value}>
                <div>
                    {isBooleanValue(filterType) && (
                        <select
                            defaultValue={current?.value}
                            onChange={onFilter}
                            ref={valueSelectRef}
                        >
                            <option key={`${filterType?.type}-none`}>-</option>
                            <option
                                value="true"
                                key={`${filterType?.type}-true`}
                            >
                                true
                            </option>
                            <option
                                value="false"
                                key={`${filterType?.type}-false`}
                            >
                                false
                            </option>
                        </select>
                    )}
                    {isEnumValue(filterType) && (
                        <select
                            defaultValue={current?.value}
                            onChange={onFilter}
                            ref={valueSelectRef}
                        >
                            <option key={`${filterType?.type}-none`}>-</option>
                            {(filterType!.value as ActionsFilterValueEnumType).values.map(v => (
                                <option
                                    value={v.value}
                                    key={v.label}
                                >
                                    {v.label}
                                </option>
                            ))}
                        </select>
                    )}
                    {isStringValue(filterType) && (
                        <input
                            type="text"
                            placeholder="Search..."
                            ref={valueTextRef}
                            onBlur={onFilter}
                            onKeyUp={(e) => {
                                if (e.key === "Enter") onFilter()
                            }}
                            defaultValue={current?.value}
                        />
                    )}
                </div>
            </div>
            <div className={styles.button}>
                <div>
                    <button onClick={onFilter}>
                        Filter
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ActionsFilter

function isBooleanValue<T>(filterType: ActionsFilterType<T> | undefined): boolean {
    return filterType !== undefined
        && filterType.value === "BOOLEAN"
}

function isEnumValue<T>(filterType: ActionsFilterType<T> | undefined): boolean {
    return filterType !== undefined
        && (filterType.value as ActionsFilterValueEnumType).type === "ENUM"
}

function isStringValue<T>(filterType: ActionsFilterType<T> | undefined): boolean {
    return filterType !== undefined
        && filterType.value === "STRING"
}

function findFilterType<T>(
    filters: ActionsFilterType<T>[],
    currentFilter?: ActionsFilterTypeAndValueType<T>
): ActionsFilterType<T> | undefined {
    return filters.find(f => f.type === currentFilter?.type)
}