import React, {FunctionComponent} from "react"
import formStyles from "../../../../../../tech/form/form.module.css"

type DealInvestorGroupFeeStructureShareClassFormErrorProps = {
    error?: string
}

const DealInvestorGroupFeeStructureShareClassFormError: FunctionComponent<DealInvestorGroupFeeStructureShareClassFormErrorProps> = ({ error }) => {
    if (error) {
        return (
            <div className={formStyles.error}>
                {error}
            </div>
        )
    }
    return <></>
}

export default DealInvestorGroupFeeStructureShareClassFormError