import React, {FunctionComponent} from "react"
import {DealManualReconciliationBankAccountType} from "./deal-manual-reconciliation-bank-account.type"
import {Control, FieldErrors, useFieldArray, UseFormRegister} from "react-hook-form"
import {DealType} from "../deal.type"
import DealManualReconciliationBankAccountSelectAdd from "./add.component"
import DealManualReconciliationBankAccountSelectSingle from "./single.component"
import {ManualReconciliationBankAccountType} from "../../reconciliation/account/manual-reconciliation-bank-account.type"

type DealManualReconciliationBankAccountSelectProps = {
    manualReconciliationBankAccounts: DealManualReconciliationBankAccountType[]
    manualReconciliationBankAccountsRaw: ManualReconciliationBankAccountType[]
    control: Control<DealType, any>
    errors: FieldErrors<DealType>
    register: UseFormRegister<DealType>
}

const DealManualReconciliationBankAccountSelect: FunctionComponent<DealManualReconciliationBankAccountSelectProps> = ({
    manualReconciliationBankAccounts,
    manualReconciliationBankAccountsRaw,
    control,
    errors,
    register
}) => {
    const { fields, append, remove } = useFieldArray({ name: "manualReconciliationBankAccounts", control })

    if (manualReconciliationBankAccountsRaw.length < 1) {
        return <div>No manual reconciliation bank accounts available.</div>
    }

    return (
        <div>
            {fields.map((f, index) => (
                <DealManualReconciliationBankAccountSelectSingle
                    field={f}
                    index={index}
                    errors={errors}
                    register={register}
                    remove={remove}
                    key={f.id}
                />
            ))}
            <DealManualReconciliationBankAccountSelectAdd
                add={append}
                manualReconciliationBankAccounts={manualReconciliationBankAccounts}
                manualReconciliationBankAccountsRaw={manualReconciliationBankAccountsRaw}
            />
        </div>
    )
}

export default DealManualReconciliationBankAccountSelect