import React, {FunctionComponent, useState} from "react"
import {CheckIcon, LinkIcon} from "@heroicons/react/24/outline"
import {FileType} from "../file.type"
import styles from "./link.module.sass"

type FileLinkProps = {
    file: FileType
}

const FileLink: FunctionComponent<FileLinkProps> = ({ file }) => {
    const [copied, setCopied] = useState<boolean>(false)

    if (file.source.type !== "PUBLIC_S3_URL") {
        return <></>
    }

    return (
        <div
            className={styles.link}
            onClick={() => {
                navigator.clipboard.writeText(file.source.src)
                setCopied(true)
            }}
        >
            {copied ? (
                <CheckIcon className={styles.icon}/>
            ) : (
                <LinkIcon className={styles.icon}/>
            )}
        </div>
    )
}

export default FileLink