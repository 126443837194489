/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FaqEntryVisibilityOpenApi = {
    All: 'ALL',
    AuthorizedOnly: 'AUTHORIZED_ONLY',
    Public: 'PUBLIC'
} as const;
export type FaqEntryVisibilityOpenApi = typeof FaqEntryVisibilityOpenApi[keyof typeof FaqEntryVisibilityOpenApi];


export function FaqEntryVisibilityOpenApiFromJSON(json: any): FaqEntryVisibilityOpenApi {
    return FaqEntryVisibilityOpenApiFromJSONTyped(json, false);
}

export function FaqEntryVisibilityOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): FaqEntryVisibilityOpenApi {
    return json as FaqEntryVisibilityOpenApi;
}

export function FaqEntryVisibilityOpenApiToJSON(value?: FaqEntryVisibilityOpenApi | null): any {
    return value as any;
}

