import React, {FunctionComponent, useContext} from "react"
import {Controller, SubmitHandler, useForm} from "react-hook-form"
import Select from "react-select"
import formStyles from "../../../tech/form/form.module.css"
import FormError from "../../../tech/form/error.component"
import {
    DealType,
    mapDealExitTypeToString,
    mapDealIndustryToString,
    mapDealOutcomeToString,
    mapDealStageToString,
    mapDealWaitingStateToString
} from "../deal.type"
import {DealGroupType} from "../../deal-group/deal-group.type"
import {mapCurrencyToString} from "../../currency/currency.mapper"
import {
    CurrencyOpenApi,
    DealExitTypeOpenApi,
    DealIndustryOpenApi,
    DealPaymentInstructionsTriggerOpenApi,
    DealPaymentProcessingTypeOpenApi,
    DealPubliclyAvailableDataPointOpenApi,
    DealStageOpenApi,
    FileSortOpenApi,
    FileTypeOpenApi,
    MimeTypeOpenApi
} from "../../../generated"
import {DocumentTemplateType} from "../../document/template/document-template.type"
import {DateTime} from "luxon"
import {FileType} from "../../file/file.type"
import {DealCategoryType} from "../../deal-category/deal-category.type"
import {DealGeographyType} from "../../deal-geography/deal-geography.type"
import DealTiles from "../../deal-tile/tiles.component"
import {InvestorGroupType} from "../../investor-group/investor-group.type"
import DealInvestorGroupSelect from "../investorgroup/select.component"
import {SinglePurposeVehicleType} from "../../single-purpose-vehicle/single-purpose-vehicle.type"
import {prepareDeal} from "../fee/form.util"
import {VALID_URL_REGEX} from "../../../tech/validation/url.util"
import {ManualReconciliationBankAccountType} from "../../reconciliation/account/manual-reconciliation-bank-account.type"
import DealManualReconciliationBankAccountSelect from "../reconciliationaccount/select.component"
import {FileSelectSearchType} from "../../file/upload-select/select/select.component"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import FileUploadSelect from "../../file/upload-select/upload-select.component"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {XMarkIcon} from "@heroicons/react/20/solid"
import {PlusIcon} from "@heroicons/react/16/solid"
import {getDealUrl} from "../deal.util"
import {formatDatetime} from "../../../tech/format/format.util"
import DealDocumentSelect from "../document/select.component"
import {ShareClassType} from "../../share-class/share-class.type"
import styles from "./form.module.sass"

type DealFormProps = {
    deal?: DealType
    dealCategories: DealCategoryType[],
    dealGeographies: DealGeographyType[],
    dealGroups: DealGroupType[]
    documentTemplates: DocumentTemplateType[]
    investorGroups: InvestorGroupType[]
    manualReconciliationBankAccounts: ManualReconciliationBankAccountType[]
    shareClasses: ShareClassType[]
    singlePurposeVehicles: SinglePurposeVehicleType[]
    updateShareClasses: (s: ShareClassType[]) => void
    onSubmit: SubmitHandler<DealType>
    submitText: string
}

const DealForm: FunctionComponent<DealFormProps> = ({
    deal,
    dealCategories,
    dealGeographies,
    dealGroups,
    documentTemplates,
    investorGroups,
    manualReconciliationBankAccounts,
    shareClasses,
    singlePurposeVehicles,
    updateShareClasses,
    onSubmit,
    submitText
}) => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        watch,
        getValues,
        setValue
    } = useForm<DealType>({
        defaultValues: deal && prepareDeal(deal),
        mode: "onBlur"
    })

    async function fetchFiles(
        mimeTypes: MimeTypeOpenApi[],
        searchType: FileSelectSearchType,
        searchValue: string
    ): Promise<FileType[]> {
        const result = await fetchClient.fileApi.getAllPaginated(
            FileSortOpenApi.IdentifierAscending,
            {
                id: searchType === "ID" ? searchValue : undefined,
                identifier: searchType === "IDENTIFIER" ? searchValue : undefined,
                mimeTypes
            }
        )
        return result.elements
    }

    // @ts-ignore
    return (
        <form
            className={formStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <input type="hidden" {...register("id")} />
            <input type="hidden" {...register("sequentialId")} />
            <input
                type="hidden"
                {...register("outcome")}
            />

            {deal && (
                <label>
                    <span>ID:</span> {deal.id}<br/>
                    <span>Created:</span> {formatDatetime(deal.created)}<br/>
                    <span>Modified:</span> {formatDatetime(deal.modified)}<br/>
                    <a
                        href={getDealUrl(deal.id!)}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <small>&rarr; Open deal in new tab</small>
                    </a>
                </label>
            )}

            <label>
                <span>Name (completed KYC)</span>
                <input
                    type="text"
                    placeholder="Name"
                    {...register("name.completeOnboarding", {
                        required: "Name (completed KYC) is required.",
                        minLength: {value: 3, message: "Too short."}
                    })}
                />
                <FormError field="name.completeOnboarding" errors={errors}/>
            </label>

            <label>
                <span>Name (incomplete KYC)</span>
                <input
                    type="text"
                    placeholder="Name"
                    {...register("name.incompleteOnboarding", {
                        required: "Name (incomplete KYC) is required.",
                        minLength: {value: 3, message: "Too short."}
                    })}
                />
                <FormError field="name.incompleteOnboarding" errors={errors}/>
            </label>

            <label>
                <span>Stage</span>
                <select
                    {...register("stage")}
                >
                    {Object.entries(DealStageOpenApi).map((s, i) => (
                        <option value={s[1]} key={i}>{mapDealStageToString(s[1])}</option>
                    ))}
                </select>
                <FormError field="stage" errors={errors}/>
            </label>

            <label>
                <span>Industry</span>
                <select
                    {...register("industry")}
                >
                    {Object.entries(DealIndustryOpenApi).map((s, i) => (
                        <option value={s[1]} key={i}>{mapDealIndustryToString(s[1])}</option>
                    ))}
                </select>
                <FormError field="industry" errors={errors}/>
            </label>

            <label>
                <span>
                    Description (completed onboarding)
                </span>
                <textarea
                    {...register("description.completeOnboarding", {
                        required: "Description (completed onboarding) is required."
                    })}
                ></textarea>
                <FormError field="description.completeOnboarding" errors={errors}/>
            </label>

            <label>
                <span>
                    Description (incomplete onboarding){" "}
                    <small style={{color: "#EF4444"}}>(Compliance: Will be shown to investors without completed KYC. Do not put any information here that refers specifically to the company, such as its name)</small>
                </span>
                <textarea
                    {...register("description.incompleteOnboarding", {
                        required: "Description (incomplete onboarding) is required."
                    })}
                ></textarea>
                <FormError field="description.incompleteOnboarding" errors={errors}/>
            </label>

            {dealCategories.length > 0 && (
                <label>
                    <span>Deal Categories</span>
                    <Controller
                        name="categories"
                        control={control}
                        render={({field: {onChange, value}}) => {
                            const options = dealCategories.map(category => ({label: category.name, value: category}))
                            const valueIds = value ? value.map(v => v.id) : []
                            return (
                                <Select
                                    options={options}
                                    value={options.filter((o) => valueIds.includes(o.value.id))}
                                    onChange={val => onChange(val.map(v => v.value))}
                                    isMulti
                                />
                            )
                        }}
                    />
                </label>
            )}

            <label>
                <span>Geography</span>
                <Controller
                    name="geography"
                    control={control}
                    render={({field: {onChange, value}}) => {
                        const options = dealGeographies.map(g => ({
                            label: g.name,
                            value: g
                        }))
                        return (
                            <Select
                                options={options}
                                value={options.filter((o) => value?.id === o.value.id)}
                                onChange={val => onChange(val?.value)}
                            />
                        )
                    }}
                    rules={{required: "Required."}}
                />
                <FormError field="geography" errors={errors}/>
            </label>

            <div className={formStyles.label}>
                <span>Logo</span>
                <FileUploadSelect
                    accept={["JPG", "PNG", "SVG"]}
                    currentFile={watch("logo")}
                    defaultFileType={FileTypeOpenApi.BackofficePublicFile}
                    filesProvider={(searchType: FileSelectSearchType, searchValue: string) => fetchFiles(
                        [MimeTypeOpenApi.Jpg, MimeTypeOpenApi.Png, MimeTypeOpenApi.Svg],
                        searchType,
                        searchValue
                    )}
                    setFormValue={(value: FileType) => setValue("logo", value)}
                />
                <FormError field="logo" errors={errors}/>
            </div>

            <div className={formStyles.label}>
                <span>Image</span>
                <FileUploadSelect
                    accept={["JPG", "PNG", "SVG"]}
                    currentFile={watch("image")}
                    defaultFileType={FileTypeOpenApi.BackofficePublicFile}
                    filesProvider={(searchType: FileSelectSearchType, searchValue: string) => fetchFiles(
                        [MimeTypeOpenApi.Jpg, MimeTypeOpenApi.Png, MimeTypeOpenApi.Svg],
                        searchType,
                        searchValue
                    )}
                    setFormValue={(value: FileType) => setValue("image", value)}
                />
                <FormError field="image" errors={errors}/>
            </div>

            <label>
                <span>Currency</span>
                <select
                    {...register("currency")}
                >
                    {Object.entries(CurrencyOpenApi).map((c, index) => (
                        <option
                            value={c[1]}
                            key={index}
                        >
                            {mapCurrencyToString(c[1])}
                        </option>
                    ))}
                </select>
                <FormError field="currency" errors={errors}/>
            </label>

            <label>
                <span>Minimum target</span>
                <input
                    type="number"
                    step={deal?.imported ? 1 : 1000}
                    defaultValue={50000}
                    {...register("minTarget", {
                        required: "Minimum target is required.",
                        min: {value: 100, message: "Too small. Must be at least 100."},
                        validate: (value) => {
                            if (value && !deal?.imported && value % 1000 !== 0) return "Must be a multiple of 1,000."
                            return true
                        },
                    })}
                />
                <FormError field="minTarget" errors={errors}/>
            </label>

            <label>
                <span>Maximum target</span>
                <input
                    type="number"
                    step={deal?.imported ? 1 : 1000}
                    {...register("maxTarget", {
                        required: "Maximum target is required.",
                        valueAsNumber: true,
                        validate: (value, formValues) => {
                            if (value && value < formValues.minTarget) return "Maximum target must not be smaller than minimum target."
                            if (value && !deal?.imported && value % 1000 !== 0) return "Must be a multiple of 1,000."
                            return true
                        }
                    })}
                />
                <FormError field="maxTarget" errors={errors}/>
            </label>

            <label>
                <span>Minimum ticket size</span>
                <input
                    type="number"
                    step={deal?.imported ? 1 : 1000}
                    defaultValue={5000}
                    {...register("minTicket", {
                        required: "Minimum ticket size is required.",
                        min: {value: 1, message: "Too small. Must be at least 1."},
                        validate: (value) => {
                            if (value && !deal?.imported && value % 1000 !== 0) return "Must be a multiple of 1,000."
                            return true
                        },
                    })}
                />
                <FormError field="minTicket" errors={errors}/>
            </label>

            <label>
                <span>Final deadline</span>
                <Controller
                    name="finalDeadline"
                    control={control}
                    render={({field: {onChange, value}}) => {
                        const isoDate = value !== undefined ? DateTime.fromJSDate(value).toISODate() as string : undefined
                        return (
                            <input
                                type="date"
                                defaultValue={isoDate}
                                onChange={e => onChange(new Date(e.target.value))}
                            />
                        )
                    }}
                    rules={{
                        required: "Final deadline is required."
                    }}
                />
                <FormError field="finalDeadline" errors={errors}/>
            </label>

            <div className={formStyles.checkbox}>
                <div className={formStyles.checkboxInner}>
                    <input
                        id="exclusive"
                        type="checkbox"
                        {...register("exclusive")}
                    />
                    <label htmlFor="exclusive">
                        Exclusive deal
                    </label>
                </div>
                <FormError field="exclusive" errors={errors}/>
            </div>

            {dealGroups.length > 0 && (
                <label>
                    <span>Deal groups</span>
                    <Controller
                        name="dealGroups"
                        control={control}
                        render={({field: {onChange, value}}) => {
                            const options = dealGroups.map(dealGroup => ({label: dealGroup.name, value: dealGroup}))
                            const valueIds = value ? value.map(v => v.id) : []
                            return (
                                <Select
                                    options={options}
                                    value={options.filter((o) => valueIds.includes(o.value.id))}
                                    onChange={val => onChange(val.map(v => v.value))}
                                    isMulti
                                />
                            )
                        }}
                    />
                </label>
            )}

            {documentTemplates.length > 0 && (
                <label>
                    <span>Document templates</span>
                    <Controller
                        name="documentTemplateIds"
                        control={control}
                        render={({field: {onChange, value}}) => {
                            const options = documentTemplates.map(documentTemplate => ({
                                label: documentTemplate.name,
                                value: documentTemplate.id
                            }))
                            const valueIds = value ? value : []
                            return (
                                <Select
                                    options={options}
                                    value={options.filter((o) => valueIds.includes(o.value))}
                                    onChange={val => onChange(val.map(v => v.value))}
                                    isMulti
                                />
                            )
                        }}
                    />
                    <FormError field="documentTemplateIds" errors={errors}/>
                </label>
            )}

            <label>
                <span>Investors</span>
                <input
                    type="text"
                    placeholder="Investors (comma separated)"
                    {...register("investors")}
                />
                <FormError field="investors" errors={errors}/>
            </label>

            <label>
                <span>Terms tags</span>
                <input
                    type="text"
                    placeholder="Terms tags (comma separated)"
                    {...register("termsTags", {
                        validate: (value) => {
                            const cumulatedLength = typeof value === "string"
                                ? (value as string).split(",").map(s => s.length).reduce((acc, c) => acc + c)
                                : 0
                            if (cumulatedLength > 24) return "The cumulated length of the terms tags must not be longer than 24 characters."
                            return true
                        }
                    })}
                />
                <FormError field="termsTags" errors={errors}/>
            </label>

            <label>
                <span>Tiles</span>
            </label>
            <DealTiles
                control={control}
                errors={errors}
                register={register}
                setValue={setValue}
                watch={watch}
            />

            <div className={formStyles.label}>
                <span>Slide deck</span>
                <FileUploadSelect
                    accept={["PDF"]}
                    currentFile={watch("slideDeck")}
                    defaultFileType={FileTypeOpenApi.BackofficePublicFile}
                    filesProvider={(searchType: FileSelectSearchType, searchValue: string) => fetchFiles([MimeTypeOpenApi.Pdf], searchType, searchValue)}
                    setFormValue={(value: FileType) => setValue("slideDeck", value)}
                />
                <FormError field="slideDeck" errors={errors}/>
            </div>

            <label>
                <span>YouTube video code</span>
                <input
                    type="text"
                    placeholder="YouTube video code"
                    {...register("videoYoutubeCode")}
                />
                <FormError field="videoYoutubeCode" errors={errors}/>
            </label>

            <label>
                <span>SPV</span>
                <Controller
                    name="singlePurposeVehicle"
                    control={control}
                    render={({field: {onChange, value}}) => {
                        const options = singlePurposeVehicles.map(spv => ({
                            label: `${spv.name} (${spv.address})`,
                            value: spv
                        }))
                        return (
                            <Select
                                options={options}
                                value={options.filter((o) => value?.id === o.value.id)}
                                onChange={val => onChange(val?.value)}
                            />
                        )
                    }}
                    rules={{
                        required: "Single Purpose Vehicle is required."
                    }}
                />
                <FormError field="singlePurposeVehicle" errors={errors}/>
            </label>

            <label>
                <span>Exited</span>
                <Controller
                    name="exited"
                    control={control}
                    render={({field: {onChange, value}}) => {
                        const isoDate = value !== undefined ? DateTime.fromJSDate(value).toISODate() as string : undefined
                        return (
                            <input
                                type="date"
                                defaultValue={isoDate}
                                onChange={e => onChange(new Date(e.target.value))}
                            />
                        )
                    }}
                />
                <FormError field="exited" errors={errors}/>
            </label>

            <label>
                <span>Exit Type</span>
                <select
                    {...register("exitType")}
                >
                    <option key={null}/>
                    {Object.entries(DealExitTypeOpenApi).map((exitType, index) => (
                        <option
                            value={exitType[1]}
                            key={index}
                        >
                            {mapDealExitTypeToString(exitType[1])}
                        </option>
                    ))}
                </select>
                <FormError field="exitType" errors={errors}/>
            </label>

            <div className={formStyles.checkbox}>
                <div className={formStyles.checkboxInner}>
                    <input
                        id="imported"
                        type="checkbox"
                        {...register("imported")}
                    />
                    <label htmlFor="imported">
                        Imported?
                    </label>
                </div>
                <FormError field="imported" errors={errors}/>
            </div>

            <h3>Company</h3>
            <small>
                If you want to assign a company to this deal, go to companies and assign this deal to any
                company.
            </small>
            <div className={styles.assignedCompany}>
                Assigned company ID: {deal?.companyId || "-"}
            </div>

            <div className={styles.eventHead}>
                <div>
                    <h3>Event</h3>
                </div>
                <div>
                    {watch("event") === undefined ? (
                        <Button
                            title="Add event"
                            style={ButtonStyle.SECONDARY}
                            type="button"
                            onClick={() => setValue("event", {
                                title: "",
                                start: new Date()
                            })}
                            icon={{
                                elem: <PlusIcon className="w-4 h-4 mr-1"/>,
                                left: true
                            }}
                            small
                        />
                    ) : (
                        <Button
                            title="Remove event"
                            style={ButtonStyle.SECONDARY}
                            type="button"
                            onClick={() => setValue("event", undefined)}
                            icon={{
                                elem: <XMarkIcon className="w-4 h-4 mr-1"/>,
                                left: true
                            }}
                            small
                        />
                    )}
                </div>
            </div>

            {watch("event") !== undefined ? (
                <>
                    <label>
                        <span>Event Title</span>
                        <input
                            type="text"
                            placeholder="Event title (required if event start is set)"
                            {...register("event.title")}
                        />
                        <FormError field="event.title" errors={errors}/>
                    </label>

                    <label>
                        <span>Event Start</span>
                        <Controller
                            name="event.start"
                            control={control}
                            render={({field: {onChange, value}}) => {
                                const formattedDate = value !== undefined ? DateTime.fromJSDate(value).toFormat("yyyy-MM-dd'T'hh:mm") as string : undefined
                                return (
                                    <input
                                        type="datetime-local"
                                        placeholder="Event start (required if event title is set)"
                                        value={formattedDate}
                                        onChange={e => onChange(new Date(e.target.value))}
                                    />
                                )
                            }}
                        />
                        <FormError field="event.start" errors={errors}/>
                    </label>

                    <label>
                        <span>Event End</span>
                        <Controller
                            name="event.end"
                            control={control}
                            render={({field: {onChange, value}}) => {
                                const formattedDate = value !== undefined ? DateTime.fromJSDate(value).toFormat("yyyy-MM-dd'T'hh:mm") as string : undefined
                                return (
                                    <input
                                        type="datetime-local"
                                        value={formattedDate}
                                        onChange={e => onChange(new Date(e.target.value))}
                                    />
                                )
                            }}
                        />
                        <FormError field="event.end" errors={errors}/>
                    </label>

                    <label>
                        <span>Event Location</span>
                        <input
                            type="text"
                            placeholder="Event location"
                            {...register("event.location")}
                        />
                        <FormError field="event.location" errors={errors}/>
                    </label>

                    <label>
                        <span>Event URL</span>
                        <input
                            type="text"
                            placeholder="Event URL"
                            {...register("event.url", {
                                pattern: {
                                    value: VALID_URL_REGEX,
                                    message: "Please enter a valid URL."
                                }
                            })}
                        />
                        <FormError field="event.url" errors={errors}/>
                    </label>
                </>
            ) : <>No event defined yet.</>}

            <h3>Fee structure</h3>
            <label>
                <span>Setup fee (%)</span>
                <input
                    type="number"
                    step={0.05}
                    defaultValue={1.5}
                    {...register("feeStructure.placementFee", {
                        required: "Setup fee is required.",
                        min: {value: 0, message: "Must not be negative."},
                        max: {value: 100, message: "Must not be greater than 100 percent."}
                    })}
                />
                <FormError field="feeStructure.placementFee" errors={errors}/>
            </label>

            <label>
                <span>Setup fee explanation</span>
                <input
                    type="text"
                    placeholder="Setup fee explanation"
                    {...register("feeStructure.placementFeeExplanation")}
                />
                <FormError field="feeStructure.placementFeeExplanation" errors={errors}/>
            </label>

            <label>
                <span>Management fee (%)</span>
                <input
                    type="number"
                    step={0.05}
                    defaultValue={0}
                    {...register("feeStructure.managementFee", {
                        required: "Management fee is required.",
                        min: {value: 0, message: "Must not be negative."},
                        max: {value: 100, message: "Must not be greater than 100 percent."}
                    })}
                />
                <FormError field="feeStructure.managementFee" errors={errors}/>
            </label>

            <label>
                <span>Carry (%)</span>
                <input
                    type="number"
                    step={0.05}
                    defaultValue={20}
                    {...register("feeStructure.carry", {
                        required: "Carry is required.",
                        min: {value: 0, message: "Must not be negative."},
                        max: {value: 100, message: "Must not be greater than 100 percent."}
                    })}
                />
                <FormError field="feeStructure.carry" errors={errors}/>
            </label>

            <h3>Share Class</h3>
            <small>(only relevant for share class name in deal contract)</small>
            <label>
                <Controller
                    name="shareClassForNameInDealContract"
                    control={control}
                    render={({field: {onChange, value}}) => {
                        const options = shareClasses.map(shareClass => ({
                            label: `${shareClass.nameInDealContract} (${shareClass.name})`,
                            value: shareClass
                        }))
                        return (
                            <Select
                                options={options}
                                value={options.filter((o) => value?.id === o.value.id)}
                                onChange={val => onChange(val?.value)}
                            />
                        )
                    }}
                    rules={{required: "Required."}}
                />
                <FormError field="shareClassForNameInDealContract" errors={errors}/>
            </label>

            <h3>Investor groups</h3>
            <DealInvestorGroupSelect
                groups={watch("investorGroups") ? watch("investorGroups") : []}
                groupsRaw={investorGroups}
                shareClasses={shareClasses}
                updateShareClasses={updateShareClasses}
                control={control}
                errors={errors}
                register={register}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
            />

            <h3>Payment Processing</h3>
            <label>
                <span>Payment Instructions Trigger</span>
                <select
                    {...register("paymentInstructionsTrigger")}
                >
                    {Object.entries(DealPaymentInstructionsTriggerOpenApi).map((trigger, index) => (
                        <option
                            value={trigger[1]}
                            key={index}
                        >
                            {trigger[1]}
                        </option>
                    ))}
                </select>
                <FormError field="paymentInstructionsTrigger" errors={errors}/>
            </label>

            {watch("paymentInstructionsTrigger") === DealPaymentInstructionsTriggerOpenApi.Manual && (
                <div className={styles.paymentInstructionsManuallyTriggered}>
                    Payment instructions triggered manually?{" "}
                    <strong>{deal?.paymentInstructionsManuallyTriggered ? formatDatetime(deal.paymentInstructionsManuallyTriggered) : "No"}</strong>
                </div>
            )}

            <label>
                <span>Payment Processing Type</span>
                <select
                    {...register("paymentProcessingType")}
                >
                    {Object.entries(DealPaymentProcessingTypeOpenApi).map((t, index) => (
                        <option
                            value={t[1]}
                            key={index}
                        >
                            {t[1]}
                        </option>
                    ))}
                </select>
                <FormError field="paymentProcessingType" errors={errors}/>
            </label>

            {watch("paymentProcessingType") === DealPaymentProcessingTypeOpenApi.ManualReconciliation && (
                <>
                    <h3>Manual Reconciliation</h3>
                    <DealManualReconciliationBankAccountSelect
                        manualReconciliationBankAccounts={watch("manualReconciliationBankAccounts") ? watch("manualReconciliationBankAccounts") : []}
                        manualReconciliationBankAccountsRaw={manualReconciliationBankAccounts}
                        control={control}
                        errors={errors}
                        register={register}
                    />
                </>
            )}

            <h3>Documents</h3>
            <DealDocumentSelect
                documents={watch("documents") ? watch("documents") : []}
                control={control}
                errors={errors}
                register={register}
                setValue={setValue}
                fetchFiles={fetchFiles}
                watch={watch}
            />

            <h3>Visibility</h3>
            <div className={formStyles.checkbox}>
                <div className={formStyles.checkboxInner}>
                    <input
                        id="publiclyAvailable"
                        type="checkbox"
                        {...register("publiclyAvailable")}
                    />
                    <label htmlFor="publiclyAvailable">
                        Publicly available?
                    </label>
                </div>
                <FormError field="publiclyAvailable" errors={errors}/>
            </div>

            {watch("publiclyAvailable") === true && (
                <>
                    {deal?.id && (
                        <div className={styles.dealLinkPublic}>
                            <a
                                href={getDealUrl(deal?.id)}
                                rel="noreferrer"
                                target="_blank"
                            >
                                {getDealUrl(deal?.id)}
                            </a>
                        </div>
                    )}
                    <label>
                        <span>Publicly available data points</span>
                        <Controller
                            name="publiclyAvailableDataPoints"
                            control={control}
                            render={({field: {onChange, value}}) => {
                                const options = Object.entries(DealPubliclyAvailableDataPointOpenApi)
                                    .map((p, i) => ({
                                        label: p[1],
                                        value: p[1]
                                    }))
                                const selectedPoints = value as DealPubliclyAvailableDataPointOpenApi[] || []
                                return (
                                    <Select
                                        options={options}
                                        value={options.filter((o) => selectedPoints.includes(o.value))}
                                        onChange={val => onChange(val.map(v => v.value))}
                                        isMulti
                                    />
                                )
                            }}
                        />
                    </label>
                </>
            )}

            <div className={formStyles.checkbox}>
                <div className={formStyles.checkboxInner}>
                    <input
                        id="showCompletelyWithoutOnboarding"
                        type="checkbox"
                        {...register("showCompletelyWithoutOnboarding")}
                    />
                    <label htmlFor="showCompletelyWithoutOnboarding">
                        Show all deal details without finalized onboarding / KYC? (only relevant for authenticated
                        users)
                    </label>
                </div>
                <FormError field="showCompletelyWithoutOnboarding" errors={errors}/>
            </div>

            {deal && (
                <div className={styles.miscellaneous}>
                    <h3>Miscellaneous</h3>
                    <ul className={styles.list}>
                        <li>
                            <strong>Published:</strong>{" "}
                            {deal.published ? "yes" : "no"}
                        </li>
                        <li>
                            <strong>Outcome:</strong>{" "}
                            {mapDealOutcomeToString(deal.outcome)}
                        </li>
                        <li>
                            <strong>Waiting State:</strong>{" "}
                            {deal.waitingState ? mapDealWaitingStateToString(deal.waitingState) : "-"}
                        </li>
                    </ul>
                </div>
            )}

            <button
                type="submit"
                className={formStyles.submitButton}
            >
                {submitText}
            </button>
        </form>
    )
}

export default DealForm