import {ActionsFilterType, ActionsFilterTypeAndValueType} from "../../tech/actions/filter/filter.type"
import {getFilter, getFilterValue} from "../../tech/actions/filter/filter.util"
import {SyndicateFiltersOpenApi} from "../../generated"

export type SyndicateFilterType = "ID" | "NAME"

export function assembleFilterOptions(): ActionsFilterType<SyndicateFilterType>[] {
    return [
        {
            type: "ID",
            label: "ID",
            value: "STRING"
        },
        {
            type: "NAME",
            label: "Name",
            value: "STRING"
        },
    ]
}

export function getCurrentFilter(
    filters: ActionsFilterType<SyndicateFilterType>[],
    searchParams: URLSearchParams
): ActionsFilterTypeAndValueType<SyndicateFilterType> | undefined {
    const foundFilter = filters.find(f => f.type === getFilter(searchParams))
    const foundFilterValue = getFilterValue(searchParams)
    if (foundFilter && foundFilterValue) {
        return {
            type: foundFilter.type,
            value: foundFilterValue
        }
    }
    return undefined
}

export function mapToSyndicateFiltersOpenApi(filter: ActionsFilterTypeAndValueType<SyndicateFilterType> | undefined): SyndicateFiltersOpenApi | undefined {
    if (filter === undefined) return undefined

    switch (filter.type) {
        case "ID":
            return { id: filter.value }
        case "NAME":
            return { name: filter.value }
    }
}