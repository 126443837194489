import {PaginatedType, PaginationType} from "./pagination.type"

const PARAMETER_NAME = "page"

export function getPage(searchParams: URLSearchParams): string | undefined {
    return searchParams.has(PARAMETER_NAME) ? searchParams.get(PARAMETER_NAME)! : undefined
}

export function setPage(page: number, searchParams: URLSearchParams): URLSearchParams {
    const newSearchParams = searchParams
    newSearchParams.set(PARAMETER_NAME, String(page))
    return newSearchParams
}

export function getPagination(elementsPerPage: number, searchParams: URLSearchParams): PaginationType {
    const page = getPage(searchParams)
    return {
        elementsPerPage,
        page: (page && parseInt(page)) || 1
    }
}

export function emptyPaginated<T>(): PaginatedType<T> {
    return {
        elements: [],
        total: 0
    }
}