/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CountryOpenApi } from './CountryOpenApi';
import {
    CountryOpenApiFromJSON,
    CountryOpenApiFromJSONTyped,
    CountryOpenApiToJSON,
} from './CountryOpenApi';

/**
 * 
 * @export
 * @interface CollectionSenderOpenApi
 */
export interface CollectionSenderOpenApi {
    /**
     * 
     * @type {string}
     * @memberof CollectionSenderOpenApi
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionSenderOpenApi
     */
    address?: string;
    /**
     * 
     * @type {CountryOpenApi}
     * @memberof CollectionSenderOpenApi
     */
    country?: CountryOpenApi;
    /**
     * 
     * @type {string}
     * @memberof CollectionSenderOpenApi
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionSenderOpenApi
     */
    routingCode?: string;
}

/**
 * Check if a given object implements the CollectionSenderOpenApi interface.
 */
export function instanceOfCollectionSenderOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CollectionSenderOpenApiFromJSON(json: any): CollectionSenderOpenApi {
    return CollectionSenderOpenApiFromJSONTyped(json, false);
}

export function CollectionSenderOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectionSenderOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'country': !exists(json, 'country') ? undefined : CountryOpenApiFromJSON(json['country']),
        'accountNumber': !exists(json, 'accountNumber') ? undefined : json['accountNumber'],
        'routingCode': !exists(json, 'routingCode') ? undefined : json['routingCode'],
    };
}

export function CollectionSenderOpenApiToJSON(value?: CollectionSenderOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'address': value.address,
        'country': CountryOpenApiToJSON(value.country),
        'accountNumber': value.accountNumber,
        'routingCode': value.routingCode,
    };
}

